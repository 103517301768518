/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { configureStore, Action, ThunkAction } from '@reduxjs/toolkit'

import { isProd } from '../config'
import rootReducer, { RootState } from './reducers'

// const middleware = [
//   !isProd &&
//     process.env.REDUX_LOG !== 'false' &&
//     require('redux-logger').default,
// ].filter(Boolean)

const store = configureStore({
  reducer: rootReducer,
  devTools: !isProd,
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({}).concat(middleware),
})

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

export default store

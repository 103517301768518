import React from 'react'

import { DisplayText } from './AlertCard.style'

const ParametersDisplay = ({ parameters }) => {
  if (!parameters?.length) return null

  const values = typeof parameters === 'string' ? [parameters] : parameters

  if (!values?.length) return null

  return (
    <DisplayText>
      <b title={values.map?.((parameter) => parameter).join('/')}>
        {values.map((parameter) => parameter).join('/')}
      </b>
    </DisplayText>
  )
}

export default ParametersDisplay

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { matchSorter } from 'match-sorter'

import { LOCATION_TYPE } from '../../config'
import { useThunk } from '../../hooks'
import { useI18n } from '../../context'

import {
  addressesSelector,
  allAddressesSelector,
  searchAddress,
  searchAllAddresses,
} from '../../redux/location'

export type AddressesSearchProps = Omit<
  AutocompleteProps<any, any, any, any>,
  'renderInput' | 'options'
> &
  TextFieldProps & {
    all?: true
    onChange?: (address?: any) => void
  }

const filterOptions = (options: any, { inputValue }: any) => {
  const opts = matchSorter(options, inputValue, {
    keys: [
      (option: any) => option.address ?? option.label ?? option.location_search,
    ],
  })

  return opts.length ? opts : options
}

const AddressesSearch = (props: AddressesSearchProps) => {
  const { value: address = null, onChange, all, ...other } = props
  const {
    freeSolo: _,
    filterOptions: __,
    filterSelectedOptions: _f,
    multiple: _m,
    ...fieldProps
  } = other

  const { t } = useI18n()

  const { dispatch, loading } = useThunk()
  const addresses = useSelector(addressesSelector)
  const allAddresses = useSelector(allAddressesSelector)

  const options = (all ? allAddresses : addresses).filter(
    (l: any) => !l.location_type || l.location_type === LOCATION_TYPE.ADDRESS
  )

  const addressInput =
    address?.address ??
    address?.label ??
    address?.location_search ??
    address ??
    ''

  const [value, setValue] = useState(address ?? '')
  const [inputValue, setInputValue] = useState(addressInput ?? '')

  useEffect(() => {
    setValue(address)
    setInputValue(addressInput)
  }, [address])

  useEffect(() => {
    dispatch(() =>
      all
        ? searchAllAddresses({
            value: inputValue || '',
            size: 1000,
            address: true,
          })
        : searchAddress(inputValue || '')
    )
  }, [inputValue])

  const handleChange = (e: any, newValue: any) => {
    setValue(newValue)
    onChange?.(newValue)
  }

  const handleInputChange = (e: any, newInputValue: any) => {
    setInputValue(newInputValue)
  }

  const getOptionSelected = (option: any, val: any) => {
    return (
      (option.address ?? option.label ?? option.location_search) ===
      (val.address ?? val.label ?? val?.location_search)
    )
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      autoHighlight
      {...other}
      loading={!!(inputValue && loading)}
      loadingText={t('common.loading')}
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={(option) =>
        option.address ?? option.label ?? option.location_search ?? option
      }
      options={options}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...(fieldProps as any)}
          inputProps={{
            ...fieldProps.inputProps,
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  )
}

export default AddressesSearch

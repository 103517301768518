export type Location = {
  id: string
  name: string
  group?: string
  [k: string]: any
}

type GroupLocationOpts = {
  [k: string]: Location[]
}

const groupLocation = (group: string, location: Location[] = []) => {
  return location.filter(Boolean).map((l: any) => {
    const data = { uid: group + (l.id || l), count: location.length }

    if (typeof l === 'string') return { ...data, label: l, name: l, group }

    return { ...data, ...l, group }
  })
}

const groupLocations = (opts: GroupLocationOpts) => {
  const res = {} as Record<string, any>
  const locations: Location[] = []

  Object.keys(opts).forEach((group) => {
    if (opts[group]) {
      locations.push(...groupLocation(group, opts[group]))
    }
  })

  locations.forEach((location) => {
    res[location.uid] = location
  })

  return Object.values(res)
}

type MergeAlertLocationsOpts = GroupLocationOpts

export const mergeAlertLocations = (opts: MergeAlertLocationsOpts) => {
  const data = opts.cities.map((c) => {
    return { id: c.id?.toString(), value: c.city, label: c.city }
  })

  data.push(
    ...opts.regions.map((rg) => {
      return { id: rg.id?.toString(), value: rg.region, label: rg.region }
    })
  )
  data.push(
    ...opts.zones.map((z) => {
      return { id: z.id?.toString(), value: z.zone, label: z.zone }
    })
  )

  const filteredData = data.filter((d) => d.value)

  const locations = filteredData.filter(
    (item, index, self) => index === self.findIndex((t) => t.id === item.id)
  )

  return locations
}

export { groupLocations }

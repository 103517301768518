export const characteristicOptions = (t: Translator) => [
  {
    value: 'building_period',
    label: t('search.Construction'),
    description: 'building_year',
    src: '/assets/img/buildingLayers/construction.png',
    legend: [
      { name: '>= 2016', color: 'rgba(0, 147, 156, 0.8)' },
      { name: '2010-2016', color: 'rgba(47, 167, 174, 0.8)' },
      { name: '2000-2010', color: 'rgba(93, 186, 191, 0.8)' },
      { name: '1990-2000', color: 'rgba(140, 206, 209, 0.8)' },
      { name: '1980-1990', color: 'rgba(186, 225, 226, 0.8)' },
      { name: '1970-1980', color: 'rgba(248, 192, 170, 0.8)' },
      { name: '1960-1970', color: 'rgba(235, 156, 128, 0.8)' },
      { name: '1950-1960', color: 'rgba(221, 119, 85, 0.8)' },
      { name: '1940-1950', color: 'rgba(208, 83, 43, 0.8)' },
      { name: '< 1940', color: 'rgba(194, 46, 0, 0.8)' },
    ],
    group: 'characteristic',
  },
  // {
  //   value: 'renovation_period',
  //   label: t('search.renovation'),
  //   description: 'renovation_year',
  //   src: '/assets/img/buildingLayers/renovation.png',
  //   legend: [
  //     { name: '>= 2016', color: 'rgba(0, 147, 156, 0.8)' },
  //     { name: '2010-2016', color: 'rgba(47, 167, 174, 0.8)' },
  //     { name: '2000-2010', color: 'rgba(93, 186, 191, 0.8)' },
  //     { name: '1990-2000', color: 'rgba(140, 206, 209, 0.8)' },
  //     { name: '1980-1990', color: 'rgba(186, 225, 226, 0.8)' },
  //     { name: '1970-1980', color: 'rgba(248, 192, 170, 0.8)' },
  //     { name: '1960-1970', color: 'rgba(235, 156, 128, 0.8)' },
  //     { name: '1950-1960', color: 'rgba(221, 119, 85, 0.8)' },
  //     { name: '1940-1950', color: 'rgba(208, 83, 43, 0.8)' },
  //     { name: '< 1940', color: 'rgba(194, 46, 0, 0.8)' },
  //   ],
  //   group: 'characteristic',
  // },
  {
    value: 'unit_nb',
    label: t('search.units'),
    description: 'current_unit_nb',
    src: '/assets/img/buildingLayers/units.png',
    legend: [
      { name: '> 20', color: 'rgba(8, 38, 73, 0.8)' },
      { name: '16-20', color: 'rgba(2, 56, 88, 0.8)' },
      { name: '12-15', color: 'rgba(4, 90, 141, 0.8)' },
      { name: '9-11', color: 'rgba(5, 112, 176, 0.8)' },
      { name: '6-8', color: 'rgba(54, 144, 192, 0.8)' },
      { name: '5', color: 'rgba(116, 169, 207, 0.8)' },
      { name: '4', color: 'rgba(166, 189, 219, 0.8)' },
      { name: '3', color: 'rgba(208, 209, 230, 0.8)' },
      { name: '2', color: 'rgba(236, 231, 242, 0.8)' },
      { name: '1', color: 'rgba(255, 247, 251, 0.8)' },
      { name: t('search.unit.noUnit'), color: 'rgba(240, 240, 240, 0.8)' },
    ],
    group: 'characteristic',
  },
  {
    value: 'protected_rank',
    label: t('search.protected'),
    description: 'protected_rank',
    src: '/assets/img/buildingLayers/protected.png',
    legend: [
      {
        name: t('search.buildings.rank1'),
        color: 'rgba(222, 45, 38, 0.8)',
      },
      {
        name: t('search.buildings.rank2'),
        color: 'rgba(251, 106, 74, 0.8)',
      },
      {
        name: t('search.buildings.rank3'),
        color: 'rgba(252, 174, 145, 0.8)',
      },
      {
        name: t('search.buildings.rank4'),
        color: 'rgba(116, 196, 118, 0.8)',
      },
      {
        name: t('search.buildings.rank5'),
        color: 'rgba(240, 240, 240, 0.8)',
      },
    ],
    group: 'characteristic',
  },
]

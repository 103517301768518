import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from '@mui/material/Card'
import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import MuiStepContent from '@mui/material/StepContent'
import Grid from '@mui/material/Grid'

import { Button } from '../../../components'
import { useI18n } from '../../../context'
import {
  changeSelectedAlert,
  clearGeojsonData,
  alertSelectedLocationsSelector,
} from '../../../redux/alert'
import AlertMap from '../AlertMap'
import { useQueryStringParser } from '../../../hooks'
import StepContent from './StepContent'
import { AlertEditorRoot } from './AlertEditor.style'

const AlertEditor = ({ alert, open, onClose }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const query = useQueryStringParser()
  const hasQuery = !!Object.values(query).length

  const selectedLocation = useSelector(alertSelectedLocationsSelector)

  const steps = [
    t('myAlert.step1'),
    t('myAlert.step2'),
    t('myAlert.step3'),
    t('myAlert.step4'),
    t('myAlert.step5'),
    // t('myAlert.step6'),
    !alert ? t('myAlert.final.create') : t('myAlert.final.update'),
  ]

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(new Set())
  const [skipped, setSkipped] = useState(new Set())

  const allStepsCompleted = completed.size === steps.length - skipped.size
  const isLastStep = activeStep === steps.length - 1

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const handleNext = () => {
    const newCompleted = new Set(completed)

    newCompleted.add(activeStep)
    setCompleted(newCompleted)

    if (!allStepsCompleted) {
      const newActiveStep =
        isLastStep && !allStepsCompleted
          ? // It's the last step, but not all steps have been completed
            // find the first step that has been completed
            steps.findIndex((_step, i) => !completed.has(i))
          : activeStep + 1

      setActiveStep(newActiveStep)
    }
  }

  useEffect(() => {
    if (hasQuery) {
      setActiveStep(selectedLocation?.length ? 5 : 1)
    }
  }, [hasQuery, selectedLocation?.length])

  useEffect(() => {
    if (alert) {
      dispatch(changeSelectedAlert(alert.id))
    } else {
      dispatch(clearGeojsonData())
    }
  }, [alert?.id])

  useEffect(() => {
    if (open) return

    setActiveStep(0)
    setCompleted(new Set())
    setSkipped(new Set())
    dispatch(clearGeojsonData())
  }, [open])

  return (
    <AlertEditorRoot container>
      <Grid xs={12} md={4} lg={3} item>
        <Card className="ControlsCard">
          <Stepper nonLinear activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step} completed={completed.has(index)}>
                <StepButton onClick={handleStep(index)}>{step}</StepButton>
                <MuiStepContent>
                  <StepContent
                    completed={allStepsCompleted}
                    index={activeStep}
                    onComplete={onClose}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                  />
                </MuiStepContent>
              </Step>
            ))}
          </Stepper>
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            size="large"
            onClick={onClose}
            disableRadius
            className="Close"
          >
            {t('common.close')}
          </Button>
        </Card>
      </Grid>

      <Grid xs={12} md={8} lg={9} item>
        <AlertMap />
      </Grid>
    </AlertEditorRoot>
  )
}

export default AlertEditor

import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getTransactions } from '../../../redux/transactionHistory'
import { getFaos } from '../../../redux/faoHistory'
import { useQueryStringParser } from '../../../hooks'
import {
  citiesSelectedSelector,
  regionsSelectedSelector,
  zonesSelectedSelector,
  alertTypeSelector,
} from '../../../redux/alert'
import { getListingHistory } from '../../../redux/listingHistory'

const date = ['1994-01-01', new Date().toISOString()]

const useHistoryParameters = (run = true) => {
  const dispatch = useDispatch()
  const query = useQueryStringParser()
  const type = useSelector(alertTypeSelector)

  const citiesSelected = useSelector(citiesSelectedSelector)
  const regionsSelected = useSelector(regionsSelectedSelector)
  const zonesSelected = useSelector(zonesSelectedSelector)

  useEffect(() => {
    if (!run) return

    const input = {
      reasonsAggsSize: 1000,
      ownershipTypesAggsSize: 1000,
      cities: citiesSelected?.map((v) => v.value) || [],
      regions: regionsSelected?.map((v) => v.value) || [],
      zones: zonesSelected?.map((v) => v.value) || [],
      dateFilter: query?.date || date,
      value: query?.value,
      size: 1,
    }

    dispatch(getTransactions(input))
    dispatch(getListingHistory(input))
    dispatch(getFaos(input))
  }, [
    citiesSelected?.length,
    regionsSelected?.length,
    zonesSelected?.length,
    type,
    run,
  ])

  return null
}

export default useHistoryParameters

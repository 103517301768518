import React from 'react'

import { CollapsePaneRoot } from './ViewMoreLess.style'

const ViewMoreLess = ({
  onToggle,
  children,
  moreText = 'More',
  lessText = 'Less',
  disable,
  more,
  ...other
}: any) => {
  return (
    <CollapsePaneRoot {...other}>
      {children}
      {!disable && (
        <div className="link" onClick={onToggle}>
          {more ? lessText : moreText}
        </div>
      )}
    </CollapsePaneRoot>
  )
}

export default ViewMoreLess

import React from 'react'

import {
  ScoreDescriptionTitle,
  ScoreDescriptionText,
} from './ScoreDescription.style'

export type ScoreDescriptionProps = {
  color?: string
  title?: React.ReactNode
  text: React.ReactNode
  fill?: string
}

const ScoreDescription = (props: ScoreDescriptionProps) => {
  const { title, color = '#0C283C', fill, text } = props

  return (
    <>
      {title && (
        <ScoreDescriptionTitle color={fill || color}>
          {title}
        </ScoreDescriptionTitle>
      )}
      <ScoreDescriptionText color={fill}>{text}</ScoreDescriptionText>
    </>
  )
}

export default ScoreDescription

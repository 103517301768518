const controlsMapSelector = (state) => state.controlsMap

const pitchSelector = (state) => state.controlsMap.pitch

const bearingSelector = (state) => state.controlsMap.bearing

const zoomMapSelector = (state) => state.controlsMap.zoom

const boundsSelector = (state) => state.controlsMap.bounds

const centerSelector = (state) => state.controlsMap.center

export {
  controlsMapSelector,
  bearingSelector,
  pitchSelector,
  zoomMapSelector,
  boundsSelector,
  centerSelector,
}

import styled, { css } from 'styled-components'

export const HistorySortBoxRoot = styled.div`
  padding: 8px 0;

  .MuiButton-root {
    padding: 8px 16px;
  }

  > div {
    padding: 8px 0;
    flex-grow: 1;
    font-size: inherit;
    padding-right: 16px;
  }

  > .MuiGrid-root {
    max-width: 450px;
  }

  .MuiTextField-root {
    min-width: 140px;
  }

  > div {
    display: flex;
    align-items: center;
    max-width: fit-content;
    overflow: auto;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  `}
`

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
`

import { createSelector } from '@reduxjs/toolkit'

const companyStateSelector = (state) => state.company

const companyUsersSelector = (state) => state.company.users

const usersAnalyticsSelector = createSelector(companyStateSelector, (company) =>
  Object.values(company.analyticsById)
)

const usersCountSelector = createSelector(companyStateSelector, (company) => ({
  count: Object.values(company.analyticsById).length,
  active: Object.values(company.analyticsById).filter(
    (u) => u.status === 'active'
  ).length,
}))

export {
  companyStateSelector,
  companyUsersSelector,
  usersAnalyticsSelector,
  usersCountSelector,
}

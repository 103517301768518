import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getFinancialAnalysis = async (landId: string) => {
  return client.get(`/users/${getCurrentUserId()}/analysis/lands/${landId}`)
}

const addFinancialAnalysis = async (data: Record<string, any>) => {
  const response = await client.post(
    `/users/${getCurrentUserId()}/analysis`,
    data
  )

  if (response.data) {
    response.data = { ...data, ...response.data }
  }

  return response
}

const updateFinancialAnalysis = async ({
  id,
  ...data
}: Record<string, any>) => {
  return client.put(`/users/${getCurrentUserId()}/analysis/${id}`, data)
}

const updateGroupedFinancialAnalysis = async ({
  id,
  ...data
}: Record<string, any>) => {
  return client.put(`/users/${getCurrentUserId()}/grouped/analysis/${id}`, data)
}

const removeFinancialAnalysis = async (noteId: string) => {
  return client.delete(`/users/${getCurrentUserId()}/analysis/${noteId}`)
}

const getGroupedFinancialAnalysis = async (landId: string) => {
  return client.get(
    `/users/${getCurrentUserId()}/analysis/grouped/lands/${landId}`
  )
}

const addGroupedFinancialAnalysis = async (data: Record<string, any>) => {
  const response = await client.post(
    `/users/${getCurrentUserId()}/grouped/analysis`,
    data
  )

  if (response.data) {
    response.data = { ...data, ...response.data }
  }

  return response
}

const removeGroupedFinancialAnalysis = async (noteId: string) => {
  return client.delete(
    `/users/${getCurrentUserId()}/grouped/analysis/${noteId}`
  )
}

const noteService = {
  addGroupedFinancialAnalysis,
  getGroupedFinancialAnalysis,
  removeFinancialAnalysis,
  removeGroupedFinancialAnalysis,
  addFinancialAnalysis,
  updateFinancialAnalysis,
  getFinancialAnalysis,
  updateGroupedFinancialAnalysis,
}

export default noteService

import styled from 'styled-components'

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const AvatarBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledSpan = styled.span`
  font-size: 80%;
  width: 100%;
`

export const BodyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`

export const ColumnDivLeft = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -5px;
`

export const ColumnDivRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: -5px;
`

export const StyledAnalysisDiv = styled.div`
  display: flex;
`

import React, { useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'

import { Snackbar } from '../../../components'
import { pauseAlert } from '../../../redux/alert'
import { useI18n } from '../../../context'
import AlertDeleteModal from '../AlertDeleteModal'
import AlertEditorDialog from '../AlertEditorDialog'
import { useThunk } from '../../../hooks'
import IOSSwitch from './IOSSwitch'
import { AlertCardActionsRoot } from './AlertCardActions.style'

const AlertCardActions = ({ alert }) => {
  const { t } = useI18n()
  const { dispatch, loading, data } = useThunk()

  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(alert.paused)
  const [openDelete, setOpenDelete] = useState(false)

  const handleEdit = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    setOpenDelete(true)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleChange = () => {
    setChecked(!checked)
    dispatch(() => pauseAlert({ id: alert.id, pause: !checked }))
  }

  return (
    <AlertCardActionsRoot>
      <IOSSwitch checked={!checked} onChange={handleChange} name="checkedB" />

      <Tooltip placement="top" title="Editer">
        <IconButton aria-label="edit" onClick={handleEdit}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip placement="top" title="Supprimer">
        <IconButton aria-label="delete" onClick={handleDelete}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <AlertDeleteModal
        alert={alert}
        open={openDelete}
        onClose={handleCloseDelete}
      />
      <AlertEditorDialog alert={alert} open={open} onClose={handleClose} />
      <Snackbar open={!loading && !!data}>
        {!checked ? t('myAlert.active') : t('myAlert.paused')}
      </Snackbar>
    </AlertCardActionsRoot>
  )
}

export default AlertCardActions

import { createSelector } from '@reduxjs/toolkit'

const underExploitedStateSelector = (state) => state.underExploited

const landUsePlansDocumentsSelector = (state) =>
  state.underExploited.landUsePlansDocuments

const futureLandUsePlansDocumentsSelector = (state) =>
  state.underExploited.futureLandUsePlansDocuments

const sortByThemeDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    Object.entries(
      documents?.reduce((acc, document) => {
        acc[document.theme] = acc[document.theme] || []
        acc[document.theme].push(document)

        return acc
      }, {})
    )?.map(([label, options]) => ({
      label,
      options,
    }))
)

const sortByOfficialIdDocumentsSelector = createSelector(
  sortByThemeDocumentsSelector,
  (sortDocuments) =>
    sortDocuments.map((documents) => ({
      label:
        documents.label && documents.label !== 'null'
          ? documents.label
          : 'other',
      options: Object.entries(
        documents.options?.reduce((acc, doc) => {
          acc[doc.official_type_id] = acc[doc.official_type_id] || []

          acc[doc.official_type_id].push(doc)

          return acc
        }, {})
      )?.map(([label, options]) => ({ label, options })),
    }))
)

const legalProvisionDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter(
      (document) => document.official_type_name === 'LegalProvision'
    )
)

const importantDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) => documents.filter((document) => document.important)
)

const lawDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter((document) => document.official_type_name === 'Law')
)

const otherDocumentsSelector = createSelector(
  landUsePlansDocumentsSelector,
  (documents) =>
    documents.filter((document) => document.official_type_name === null)
)

const valuesOveridedSelector = (state) => state.underExploited.valuesOverided

const developmentScoreOveridedSelector = (state) =>
  state.underExploited.valuesOverided?.developmentScoreOveride

const underExploitedScoreOveridedSelector = (state) =>
  state.underExploited.valuesOverided?.underExploitedScoreOveride

const underExploitedTypeZoneSelector = (state) =>
  state.underExploited.valuesOverided?.typeZone

export {
  futureLandUsePlansDocumentsSelector,
  underExploitedScoreOveridedSelector,
  developmentScoreOveridedSelector,
  underExploitedTypeZoneSelector,
  landUsePlansDocumentsSelector,
  underExploitedStateSelector,
  valuesOveridedSelector,
  lawDocumentsSelector,
  legalProvisionDocumentsSelector,
  otherDocumentsSelector,
  importantDocumentsSelector,
  sortByThemeDocumentsSelector,
  sortByOfficialIdDocumentsSelector,
}

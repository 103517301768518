export const getSelectValueKey = ({ valueKey, value, multiple }: any) => {
  if (!value) {
    return multiple ? [] : ''
  }

  if (typeof value === 'string') {
    return value
  }

  if (Array.isArray(value)) {
    return value.map((o) => o[valueKey] ?? o)
  }

  return value[valueKey]
}

export const optionsToMap = (options: any[], valueKey: string) => {
  const optsMap: Record<string, any> = {}

  options.forEach((o) => {
    optsMap[o[valueKey] ?? o] = o
  })

  return optsMap
}

export const handleSelectChange = ({
  event,
  native,
  multiple,
  optionsMap,
}: any) => {
  let selectedOption

  if (!native) {
    const selectedVal = event.target.value

    if (!multiple) {
      selectedOption = optionsMap[selectedVal]
    } else {
      selectedOption = Array.from(
        selectedVal,
        (opt: any) => optionsMap[opt]
      ).filter(Boolean)
    }

    return { selectedOption, selectedVal }
  }

  if (!multiple) {
    const selectedVal = event.target.value

    selectedOption = optionsMap[selectedVal]

    return { selectedOption, selectedVal }
  }

  const selectedVal = Array.from(
    event.target.selectedOptions,
    (opt: any) => opt.value
  ).filter(Boolean)

  selectedOption = selectedVal.map((v) => optionsMap[v])

  return { selectedOption, selectedVal }
}

import React from 'react'

import { AccordionProps } from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { AccordionRoot, StyledSpan } from './AccordionForm.style'

type Props = AccordionProps & {
  title: string
}

const AccordionForm = ({
  title,
  children,
  defaultExpanded,
  ...props
}: Props) => {
  return (
    <AccordionRoot {...props} defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledSpan>{title}</StyledSpan>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionRoot>
  )
}

export default AccordionForm

import React, { createContext, useContext } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { dateLocales } from '../../utils'
import useI18nProvider from './useI18nProvider'

type I18nState = Readonly<ReturnType<typeof useI18nProvider>>

const i18n: I18nState & { t: Translator } = {
  translations: {},
  setCurrentLanguage: () => '',
  t: (key: string) => key?.split('.').slice(-1)[0],
} as any

const I18nContext = createContext<I18nState>({
  i18n,
} as any)

/**
 * Hook for child components to get the i18n object and re-render when it changes.
 */
const useI18n = () => {
  return useContext(I18nContext)
}

export type I18nProviderProps = {
  children: React.ReactNode
  language?: string
}

const I18nProvider = ({ children, language }: I18nProviderProps) => {
  const value = useI18nProvider(language)

  if (Object.keys(value.translations).length === 0) {
    return null
  }

  const locale = dateLocales[value.currentLanguage as 'en']

  Object.keys(value).forEach((k: keyof I18nState) => {
    ;(i18n as any)[k] = value[k]
  })

  return (
    <I18nContext.Provider value={value}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        {children}
      </LocalizationProvider>
    </I18nContext.Provider>
  )
}

export { useI18n, i18n }
export default I18nProvider

import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getRegroupingLands = async () => {
  return client.get(`/users/${getCurrentUserId()}/regrouping/lands`)
}

const regroupingService = {
  getRegroupingLands,
}

export default regroupingService

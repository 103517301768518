import styled from 'styled-components'

export const FilterChipsRoot = styled.div`
  > .MuiChip-root {
    color: #2196f3;
    background: #f4f4f4;
    margin-right: 8px;
    margin-bottom: 8px;
    max-width: 200px;
  }

  > span {
    color: #2196f3;
    cursor: pointer;
  }
`

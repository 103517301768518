import chroma from 'chroma-js'
import differenceInYears from 'date-fns/differenceInYears'

export const lastYearColor = (
  year: number | string,
  startDate?: string,
  rgb = false
) => {
  const date = new Date(startDate || '2005-01-01')
  const num = differenceInYears(new Date(), date)
  const colors = chroma.scale(['ffffe5', '05392d']).colors(num + 1)

  if (!year) return chroma('#f0f0f0').rgb()

  const colorByYear: Record<string, string> = {}

  colors.forEach((c, i) => {
    colorByYear[date.getFullYear() + i] = c
  })

  const hex = colorByYear[year] || colorByYear[2005] || '#f0f0f0'

  return rgb ? chroma(hex).rgb() : hex
}

export const getLegendColors = (startDate?: string | Date) => {
  const date = new Date(
    !startDate || startDate === 'Invalid Date' ? '2005-01-01' : startDate
  )

  const num = differenceInYears(new Date(), date)
  const colors = chroma.scale(['ffffe5', '05392d']).colors(num + 1)

  const colorByYear: Record<string, string> = {}

  colors.forEach((c, i) => {
    colorByYear[date.getFullYear() + i] = c
  })

  return Object.keys(colorByYear).map((year) => {
    return { name: year, color: lastYearColor(year, date as any) }
  })
}

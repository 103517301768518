/* eslint-disable max-lines */
export const calculateUnderExploitedScore = (
  std: number,
  current: number,
  value: number
) => {
  if (std === 0) return 0
  if (value === 0) return 0

  return Number(
    Math.round(Math.max(0, (1 - Number(current) / Number(value)) * 100))
  )
}

export const calculateDevelopmentScore = (
  under_exploited_score: number,
  geo_score: number,
  legacy_score: number,
  livability_score: number
) => {
  return Math.min(
    Math.max(
      Number(
        Math.round(
          (under_exploited_score -
            0.35 * (100 - geo_score) +
            0.5 * ((100 - under_exploited_score) / 100.0) * legacy_score) *
            0.9 +
            livability_score / 10.0
        )
      ),
      0
    ),
    100
  )
}

export const calculateGeoScore = (
  area: any,
  maxLength: any,
  altitudeMin: any,
  altitudeMax: any
) => {
  if (area === 0) {
    return 0
  }

  return Number(
    Math.round(
      Math.max(
        0,
        Math.min(1, (area / (maxLength * maxLength)) * 2) * 100 -
          (altitudeMax - altitudeMin)
      )
    )
  )
}

export const getYearFromPeriod = (period: string) => {
  // Get the year from a period
  // Example: if period = '1970-1974' it returns 1972
  // :param period: year period. Example: '1970-1974'

  //  manage the exceptions
  if (period === '0-1919') {
    return 1919
  }
  if (period === '2015-x') {
    return 2015
  }

  // manage normal periods and extract the two years
  const years = period.split('-')

  return Number(years[0]) + (Number(years[1]) - Number(years[0])) / 2 || 0
}

export const calculateBuildingsGroupedAnalytics = (
  buildings: any,
  averageProtectedRank: number,
  averageConditionYear: number
  // parcel: any
) => {
  let protectedRankCumulated = 0
  let protectedRankAreaCumulated = 0
  let renovationYearCumulatedArea = 0
  let renovationYearCumulated = 0

  buildings?.forEach((building: any) => {
    const floorNb = building.floorNbOveride || building.floor_nb

    // get the protected rank
    protectedRankAreaCumulated += building.cover_area * floorNb
    if (building.protected_rank) {
      protectedRankCumulated +=
        building.protected_rank * floorNb * building.cover_area
    }

    // Get renovation year
    building.buildings_administrative.forEach(
      (building_administrative: any) => {
        let renovationYear = 0
        const currentRenovationYear =
          Number(building.renovationOveride) ||
          building_administrative.renovation_year ||
          Number(building.constructionOveride) ||
          building_administrative.building_year

        const periodYear =
          building_administrative.renovation_period ||
          building_administrative.building_period

        const area = Number(building.areaOveride) || building.area
        const buildingAdministrativefloorNb =
          Number(building.floorNbOveride) || building.floor_nb || 1

        // add the differents results to accumulator
        renovationYearCumulatedArea +=
          area * buildingAdministrativefloorNb * building.cover_area_perc

        if (currentRenovationYear) {
          renovationYear = currentRenovationYear
        } else if (periodYear) {
          renovationYear = getYearFromPeriod(periodYear)
        } else {
          // this building area shouldn't be take into account
          renovationYearCumulatedArea -=
            area * buildingAdministrativefloorNb * building.cover_area_perc
        }

        renovationYearCumulated +=
          renovationYear *
          buildingAdministrativefloorNb *
          area *
          building.cover_area_perc
      }
    )
  })

  return {
    average_protected_rank:
      protectedRankAreaCumulated > 0
        ? Number(
            Math.round(protectedRankCumulated / protectedRankAreaCumulated)
          )
        : averageProtectedRank,
    // average_renovation_year: Number(
    //   Math.round(renovationYearCumulated / renovationYearCumulatedArea)
    // ),
    average_condition_year:
      renovationYearCumulatedArea > 0
        ? Number(
            Math.round(renovationYearCumulated / renovationYearCumulatedArea)
          )
        : averageConditionYear,
  }
}
const calculateConditionScore = (x: number) =>
  Number(
    Math.round(
      Math.min(
        Math.max(
          -0.00000004 * x ** 4 +
            0.00004 * x ** 3 -
            0.012 * x ** 2 +
            1.7672 * x -
            6.5567,
          0
        ),
        100
      )
    )
  )

export const calculateLegacyScore = (
  highestProtectedRank: number,
  averageConditionYear: number,
  area: number,
  ios: number,
  currentIos: number
) => {
  if (highestProtectedRank > 2) {
    return 1
  }

  if (averageConditionYear) {
    return calculateConditionScore(
      new Date().getFullYear() - averageConditionYear
    )
  }

  return calculateUnderExploitedScore(area, ios, currentIos)
}

export const getLupDetails = (state: any, action: any) => {
  const { payload } = action

  state.parcelDetail.land_use_plans = state.parcelDetail.land_use_plans.map(
    (land_use_plan: any) => {
      const find = payload?.find(
        (lup: any) => Number(lup.id) === Number(land_use_plan.id)
      )

      if (find) {
        return {
          ...land_use_plan,
          ...find,
          geo_polygon: JSON.parse(find.geo_polygon),
        }
      }

      return land_use_plan
    }
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { DialogProps } from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'

import { deleteAlert } from '../../../redux/alert'
import { useI18n } from '../../../context'
import { AlertModal } from './AlertCard.style'

export type AlertDeleteModalProps = DialogProps & {
  alert: { id: string; [k: string]: any }
}

const AlertDeleteModal = ({
  alert,
  onClose,
  ...other
}: AlertDeleteModalProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()

  const handleDeleted = () => {
    dispatch(deleteAlert(alert.id))
  }

  return (
    <AlertModal
      onOk={handleDeleted}
      {...other}
      onClose={onClose as any}
      okText={t('common.yes')}
      cancelText={t('common.no')}
      onCancel={onClose as any}
      cancelButtonProps={{ color: 'inherit' }}
    >
      <Typography>{t('myAlert.delete')}</Typography>
    </AlertModal>
  )
}

export default AlertDeleteModal

import Paper from '@mui/material/Paper'
import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'

export const SidePaneRoot = styled(Drawer)`
  width: 280px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    position: static;
    background: inherit;
    border: beige;
    box-shadow: none;
    overflow-x: hidden;
  }
`

export const SidePaneContent = styled(Paper)`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  max-width: 300px;
  min-width: 280px;

  > .MuiIconButton-root:first-child {
    color: #9095a2;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.3s, 0.3s;
  }
`

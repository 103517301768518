import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'
import EventIcon from '@mui/icons-material/Event'
import HelpIcon from '@mui/icons-material/Help'
import MailIcon from '@mui/icons-material/Mail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { mapBy } from '@/utils'
import { useI18n } from '../../context'
import { supportsSelector, getSupports } from '../../redux'
import Modal from '../Modal'
import ProgressIndicator from '../ProgressIndicator'

const SupportModal = () => {
  const { t, currentLanguage: lang } = useI18n()

  const [open, setOpen] = useState(false)

  const supports = useSelector(supportsSelector)
  const dispatch = useDispatch()

  const supportsByName = mapBy('name', supports)

  const handleToggle = (v) => () => {
    setOpen(v)

    if (v && !supports) dispatch(getSupports())
  }

  const getValue = (name) => {
    if (!supportsByName[name]?.value) return ''

    return (
      supportsByName[name].value[lang] ||
      supportsByName[name].value.fr ||
      Object.values(supportsByName[name].value)[0]
    )
  }

  return (
    <>
      <IconButton
        aria-label="support"
        color="inherit"
        onClick={handleToggle(true)}
      >
        <HelpIcon />
      </IconButton>

      <Modal
        open={open}
        onClose={handleToggle(false)}
        title={t('common.support')}
        showCloseIcon
        cancelText={t('common.close')}
        maxWidth="xs"
        fullWidth
      >
        {!supports && (
          <Stack position="relative" minHeight="200px">
            <ProgressIndicator circular />
          </Stack>
        )}

        {supports && (
          <>
            {supportsByName.phone?.value && (
              <Stack
                direction="row"
                spacing={2}
                href={`tel:${getValue('phone')}`}
                component="a"
                color="inherit"
                mb={2}
                mt={2}
              >
                <ContactPhoneIcon />
                <Typography>{getValue('phone')}</Typography>
              </Stack>
            )}

            {supportsByName.whatsapp?.value && (
              <Stack
                direction="row"
                spacing={2}
                href={`https://wa.me/${getValue('whatsapp')
                  ?.slice(1)
                  .replaceAll(' ', '')}`}
                component="a"
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
                mb={2}
              >
                <WhatsAppIcon />
                <Typography>{getValue('whatsapp')}</Typography>
              </Stack>
            )}

            {supportsByName.email?.value && (
              <Stack
                direction="row"
                spacing={2}
                href={`mailto:${getValue('email')}`}
                component="a"
                color="inherit"
                mb={2}
              >
                <MailIcon />
                <Typography>{getValue('email')}</Typography>
              </Stack>
            )}

            {supportsByName.whatsapp?.value && (
              <Stack
                direction="row"
                spacing={2}
                title={t('common.openingHours')}
              >
                <EventIcon />
                <Typography>{getValue('openingHours')}</Typography>
              </Stack>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export default SupportModal

import React from 'react'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { InfoTitleRoot } from './SettingsStep.style'

export type InfoTitleProps = {
  text: string
  title: string
}

const InfoTitle = ({ title, text }: InfoTitleProps) => (
  <InfoTitleRoot>
    {title}
    {text && (
      <Tooltip title={text}>
        <IconButton aria-label="Description">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    )}
  </InfoTitleRoot>
)

export default InfoTitle

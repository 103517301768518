import Box from '@mui/material/Box'
import styled, { css } from 'styled-components'

export const MapFullscreenContainerRoot = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;

  &.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000000;
    min-width: 100vw;
    min-height: 100vh;

    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        z-index: 1000;
      }
    `}
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme, open }) => css`
    ${theme.breakpoints.up('md')} {
      top: ${open ? '64px' : 0};
      height: calc(100% - ${open ? '64px' : 0});
    }
  `}
`

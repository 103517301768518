/* eslint-disable max-lines */
import { EXCEL_TYPE } from '../../config'
import { API_BASE_URL } from '../apiClient/ApiClient'
import { downloadReport } from './reportingHelpers'

export const INFORMATION_REPORT = 'information'
export const DATALAB_REPORT = 'datalab'
export const ANALYSIS_REPORT = 'analysis'

const rdppfFribourgUrl =
  'https://geo.fr.ch/RDPPF_ws/RdppfSVC.svc/extract/reduced/pdf/'
const rdppfGenevaUrl =
  'https://ge.ch/terecadastrews/RdppfSVC.svc/extract/reduced/pdf/'
const rdppfVaudUrl =
  'https://www.rdppf.vd.ch/ws/RdppfSVC.svc/extract/reduced/pdf/'
const rdppfValaisUrl = 'https://rdppfvs.geopol.ch/v1/extract/reduced/pdf/'

export const regionUrlMap = {
  Vaud: rdppfVaudUrl,
  Genève: rdppfGenevaUrl,
  Fribourg: rdppfFribourgUrl,
  Valais: rdppfValaisUrl,
}

const getAndSavePdfReport = ({
  landId,
  reportType,
  reportName,
  landUsePlanStatus,
}) => {
  return fetch(
    `${API_BASE_URL}/reporting/land/${landId}/${reportType}${
      landUsePlanStatus ? `/${landUsePlanStatus}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        Authorization: `JWT ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/pdf',
        locale: localStorage.getItem('language'),
      },
    }
  )
    .then((response) => response.blob())
    .then((blob) => downloadReport(blob, reportName))
}

const generateDatalabReport = (landId, reportName) => {
  return getAndSavePdfReport({ landId, reporType: DATALAB_REPORT, reportName })
}

const generateAnalysisReport = (landId, reportName) => {
  return getAndSavePdfReport({ landId, reporType: ANALYSIS_REPORT, reportName })
}

const generateLandReport = ({
  land,
  reportType,
  hasAnalysis,
  landUsePlanStatus,
}) => {
  const landName = `${land.city_name}-${land.code_number}`

  if (reportType === 'INFORMATION') {
    return getAndSavePdfReport({
      landId: land.land_id,
      reportName: `${landName}-information.pdf`,
      landUsePlanStatus,
      reportType: INFORMATION_REPORT,
    })
  }
  if (reportType === 'DATALAB') {
    return generateDatalabReport(land.land_id, `${landName}-datalab.pdf`)
  }
  if (reportType === 'ANALYSIS') {
    return generateAnalysisReport(land.land_id, `${landName}-analysis.pdf`)
  }
  if (reportType === 'RDPPF') {
    const rdppfUrl = regionUrlMap[land.region_name]

    window.open(rdppfUrl + land.egrid_nb, '_blank', 'noopener')
  } else if (reportType === 'ALL') {
    const promises1 = getAndSavePdfReport({
      landId: land.land_id,
      reportName: `${landName}-information.pdf`,
      landUsePlanStatus,
      reportType: INFORMATION_REPORT,
    })

    if (hasAnalysis) {
      const promise2 = generateAnalysisReport({
        landId: land.land_id,
        reportName: `${landName}-analysis.pdf`,
      })

      return Promise.all([promises1, promise2])
    }

    return promises1
  }
}

const generateExcel = ({ lands, fileName: name, app }) => {
  const fileName = name || 'popetyio_export.xlsx'

  return fetch(`${API_BASE_URL}/reporting/lands/excel`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ lands, app }),
  })
    .then((response) => response.blob())
    .then((blob) =>
      downloadReport(
        blob.slice(0, blob.size, EXCEL_TYPE),
        fileName + (fileName.endsWith('.xlsx') ? '' : '.xlsx')
      )
    )
}

const generateHistoryExcel = (data) => {
  data.url = window.location.href
  const fileName = data.fileName || `${data.type || 'history'}Report.xlsx`

  return fetch(`${API_BASE_URL}/reporting/history/excel`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.blob())
    .then((blob) =>
      downloadReport(
        blob.slice(0, blob.size, EXCEL_TYPE),
        fileName + (fileName.endsWith('.xlsx') ? '' : '.xlsx')
      )
    )
}

const generateLandExcel = (data) => {
  data.url = window.location.href
  const fileName = data.fileName || `${data.type || 'land'}Report.xlsx`

  return fetch(`${API_BASE_URL}/reporting/lands-search/excel`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.blob())
    .then((blob) =>
      downloadReport(
        blob.slice(0, blob.size, EXCEL_TYPE),
        fileName + (fileName.endsWith('.xlsx') ? '' : '.xlsx')
      )
    )
}

const generateListingPdf = (data) => {
  data.url = window.location.href
  const fileName = data.fileName || `${data.type || 'history'}Report.pdf`

  return fetch(`${API_BASE_URL}/reporting/history/pdf`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.blob())
    .then((blob) =>
      downloadReport(blob, fileName + (fileName.endsWith('.pdf') ? '' : '.pdf'))
    )
}

const generateLandPdf = (data) => {
  data.url = window.location.href
  const fileName =
    data.fileName || `land${data.details ? 'details' : ''}Report.pdf`

  return fetch(`${API_BASE_URL}/reporting/lands-search/pdf`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ data }),
  })
    .then((response) => response.blob())
    .then((blob) => downloadReport(blob, fileName))
}

const generateListingStatsReport = (data) => {
  data.url = window.location.href
  const filename = data.fileName || `listingsStatsReport.pdf`

  return fetch(`${API_BASE_URL}/reporting/history/listings-stats`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('jwt')}`,
      'Content-Type': 'application/json',
      locale: localStorage.getItem('language'),
    },
    body: JSON.stringify({ data, filename }),
  })
    .then((response) => response.blob())
    .then((blob) => downloadReport(blob, filename))
}

export {
  getAndSavePdfReport,
  generateExcel,
  generateLandReport,
  generateHistoryExcel,
  generateListingStatsReport,
  generateListingPdf,
  generateLandPdf,
  generateLandExcel,
}

import React from 'react'
import { useSelector } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'

import { useQueryStringParser } from '../../../hooks'
import { faoAggregationsSelector } from '../../../redux/faoHistory'
import { FilterCategory } from '../../../components'
import { useI18n } from '../../../context'
import { maxDate, minDate } from '../../../utils'
import { DateRangeFilterContainer } from './DateRangeFilter.style'

const DateRangeFilter = ({ onChange, inquiryStartDateFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(faoAggregationsSelector)
  const params = useQueryStringParser()

  const { inquiry_start_dates: dates } = aggregations.inquiry_start_dates
  const min = dates.min_as_string
  const max = dates.max_as_string
  const docCount = aggregations.inquiry_start_dates.doc_count

  const value = {
    min: maxDate(
      inquiryStartDateFilter?.[0],
      params.inquiryStartDateFilter?.[0] ||
        (docCount > 0 ? min : inquiryStartDateFilter?.[0] || '2000-01-01')
    ),
    max: minDate(
      params.inquiryStartDateFilter?.[1],
      inquiryStartDateFilter?.[1] ||
        (docCount > 0 ? max : inquiryStartDateFilter?.[1] || new Date())
    ),
  }

  const handleChange = (name) => (newDate) => {
    const newValue = { ...value, [name]: newDate }

    onChange({ inquiryStartDateFilter: [newValue.min, newValue.max] })
  }

  return (
    <FilterCategory
      {...other}
      title={t('fao.date')}
      className="DateRangeFilter"
    >
      <DateRangeFilterContainer>
        <DatePicker
          renderInput={(p) => (
            <TextField
              {...p}
              size="small"
              margin="normal"
              inputProps={{ ...p.inputProps }}
            />
          )}
          inputFormat="dd/MM/yyyy"
          id="inquiryStartDateFilter-min"
          label="Min"
          value={value.min}
          onChange={handleChange('min')}
          maxDate={new Date(max)}
          minDate={new Date(min)}
          fullWidth
          autoOk
        />
        <DatePicker
          renderInput={(p) => (
            <TextField
              {...p}
              size="small"
              margin="normal"
              inputProps={{ ...p.inputProps }}
            />
          )}
          inputFormat="dd/MM/yyyy"
          id="inquiryStartDateFilter-max"
          label="Max"
          autoOk
          value={value.max}
          onChange={handleChange('max')}
          KeyboardButtonProps={{ 'aria-label': 'inquiry Start DateFilter max' }}
          size="small"
          minDate={new Date(value.min)}
          maxDate={new Date()}
          fullWidth
        />
      </DateRangeFilterContainer>
    </FilterCategory>
  )
}

export default DateRangeFilter

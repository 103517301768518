import React from 'react'
import { BoxProps } from '@mui/material/Box'

import { RegionName, RegionFilterItemRoot } from './RegionFilterItem.style'

export type RegionFilterItemProps = BoxProps & {
  name?: React.ReactNode
  count?: number
  selected?: boolean
}

const RegionFilterItem = ({
  name,
  count,
  selected,
  ...other
}: RegionFilterItemProps) => {
  return (
    <RegionFilterItemRoot width="100%" {...other}>
      <RegionName
        {...({ title: name } as any)}
        className={selected ? 'ellipsis selected' : 'ellipsis'}
      >
        {name}
      </RegionName>
      <span className="RegionFilterItemCount">{count}</span>
    </RegionFilterItemRoot>
  )
}

export default RegionFilterItem

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import underExploitedService from '../../services/underExploited'

const initialState = {
  landUsePlansDocuments: [],
  futureLandUsePlansDocuments: [],
  overide: [],
  valuesOverided: {},
}

export const getLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getLandUsePlanDocuments',
  underExploitedService.getLandUsePlanDocuments
)

export const getFutureLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getFutureLandUsePlanDocuments',
  underExploitedService.getFutureLandUsePlanDocuments
)

export const getDifferenceLandUsePlanDocuments = createAsyncThunk(
  'underExploited/getDifferenceLandUsePlanDocuments',
  underExploitedService.getDifferenceLandUsePlanDocuments
)

export const addOverideValues = createAsyncThunk(
  'underExploited/addOverideValues',
  underExploitedService.addOverideValuesAssignment
)

const underExploited = createSlice({
  name: 'underExploited',
  initialState,
  reducers: {
    resetOverided: (state) => {
      state.valuesOverided = initialState.valuesOverided
    },
    setOverided: (state, action) => {
      const { payload } = action

      state.valuesOverided = { ...state.valuesOverided, ...payload }
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [getLandUsePlanDocuments.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.landUsePlansDocuments = data
        state.futureLandUsePlansDocuments = []
      }
    },
    [getFutureLandUsePlanDocuments.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.futureLandUsePlansDocuments = data
        state.landUsePlansDocuments = []
      }
    },
    [getDifferenceLandUsePlanDocuments.fulfilled]: (state, action) => {
      const data = action.payload

      if (data) {
        state.landUsePlansDocuments = data?.[0]
        state.futureLandUsePlansDocuments = data?.[1]
      }
    },
    [addOverideValues.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.valuesOverided = data
      }
    },
  },
})

export const { setOverided, resetOverided } = underExploited.actions

export default underExploited.reducer

import { createSelector } from '@reduxjs/toolkit'

import { DEFAULT_PROFILE_IMG } from '../../config'

const authSelector = (state) => state.auth

const authUserSelector = (state) => state.auth.user

const supportsSelector = (state) => state.auth.supports

const userSelector = createSelector(authUserSelector, (user) => {
  if (!user) return null

  return {
    ...user,
    name: `${user.first_name} ${user.last_name}`,
    companyLogo: user.company_logo || DEFAULT_PROFILE_IMG,
  }
})

const loggedSelector = (state) => state.auth.logged

const lightVersionSelector = (state) => state.auth.lightVersion

const appSelector = createSelector(userSelector, (user) => {
  if (!user) return 'brokerinfo'

  if (user.app) return user.app

  let app = 'brokerinfo'
  const userLandDev = user?.roles?.includes('User_landdev')

  if (userLandDev) {
    app = userLandDev ? 'landdev' : 'brokerinfo'
  }

  return app
})

const authRolesSelector = (state) => state.auth.user?.roles

const isCompanyAdminSelector = createSelector(authRolesSelector, (roles) => {
  return roles?.includes('Admin_company')
})

export {
  authSelector,
  authRolesSelector,
  userSelector,
  loggedSelector,
  appSelector,
  isCompanyAdminSelector,
  lightVersionSelector,
  supportsSelector,
}

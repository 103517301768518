/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { MinMaxFilterContainer } from './MinMaxFilter.style'

const MinMaxFilter = ({
  onChange,
  value: filter,
  id,
  label = { min: 'Min', max: 'Max' },
  positive = true,
  reset,
  obj = false,
  ...other
}) => {
  let min = filter?.[0] ?? filter?.min
  let max = filter?.[1] ?? filter?.max
  const applyRef = useRef()

  if (positive) {
    if (min < 0) min = 0
    if (max < 0) max = 0
  }

  const lastFieldUpdated = useRef()

  const [value, setValue] = useState({ min, max })

  const handleChange = (e) => {
    let newValue = +e.target.value

    if (positive && newValue < 0) newValue = 0

    const newVal = { ...value, [e.target.name]: newValue }

    setValue({ ...value, [e.target.name]: newValue })
    lastFieldUpdated.current = e.target.name

    if (obj) return onChange(newVal, false)

    onChange([newVal.min, newVal.max], false)
    applyRef.current = true
  }

  const handleApply = () => {
    if (isNaN(value.min) && isNaN(value.max)) return

    if (value.min === '' && value.max === '') return

    if (!lastFieldUpdated.current) return

    const valToApply = { ...value }

    if (valToApply.max === '') valToApply.max = undefined
    if (valToApply.min === '') valToApply.min = undefined

    if (lastFieldUpdated.current === 'min' && valToApply.min > valToApply.max) {
      valToApply.max = valToApply.min
    }

    if (lastFieldUpdated.current === 'max' && valToApply.max < valToApply.min) {
      valToApply.min = valToApply.max
    }

    setValue(valToApply)

    if (obj) return onChange(valToApply, true)

    onChange([valToApply.min, valToApply.max], true)
    applyRef.current = false
  }

  const handleEnter = (e) => {
    if (e.key === 'Enter') handleApply()
  }

  const handleClickAway = () => {
    // if (min === value.min && max === value.max) return
    // if (+min === +value.min && +max === +value.max) return

    if (applyRef.current) handleApply()
  }

  useEffect(() => {
    setValue((val) => ({ ...val, min }))
  }, [min])

  useEffect(() => {
    setValue((val) => ({ ...val, max }))
  }, [max])

  useEffect(() => {
    if (!reset) return

    setValue({ min: '', max: '' })

    if (obj) return onChange({})
    onChange([])
  }, [reset])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <MinMaxFilterContainer container alignItems="center" spacing={2}>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            onKeyPress={handleEnter}
            variant="outlined"
            type="number"
            size="small"
            {...other}
            value={value.min ?? ''}
            id={`${id}-min`}
            name="min"
            label={label.min}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={handleChange}
            onKeyPress={handleEnter}
            variant="outlined"
            type="number"
            size="small"
            {...other}
            value={value.max ?? ''}
            id={`${id}-max`}
            name="max"
            label={label.max}
          />
        </Grid>
      </MinMaxFilterContainer>
    </ClickAwayListener>
  )
}

export default MinMaxFilter

import React from 'react'

import LocationsFilter from '../LocationsFilter'
import DateRangeFilter from '../DateRangeFilter'
import AreaFilter from '../AreaFilter'
import FeaturesFilter from '../FeaturesFilter'
import { SidePane } from '../../../components'
import { SidePaneProps } from '../../../components/SidePane'
import DealTypeFilter from '../DealTypeFilter'
import AdStatusFilter from '../AdStatusFilter'
import PropertyCategoryFilter from '../PropertyCategoryFilter'
import RoomFilter from '../RoomFilter'
import FloorFilter from '../FloorFilter'
import ConstructionYearFilter from '../ConstructionYearFilter'
import PriceFilter from '../PriceFilter'
import PriceM2Filter from '../PriceM2Filter'

export type ListingFiltersProps = SidePaneProps & {
  filters: Record<string, any>
  onChange?: (filters: Record<string, any>) => void
}

const ListingFilters = (props: ListingFiltersProps) => {
  const { onChange, filters, ...other } = props

  return (
    <SidePane {...other}>
      <LocationsFilter
        onChange={onChange}
        countryFilter={filters.countryFilter}
        regionFilter={filters.regionFilter}
        zoneFilter={filters.zoneFilter}
        cityFilter={filters.cityFilter}
      />
      <DateRangeFilter onChange={onChange} dateFilter={filters.dateFilter} />
      <AdStatusFilter
        onChange={onChange}
        adStatusFilter={filters.adStatusFilter}
      />
      <DealTypeFilter
        onChange={onChange}
        dealTypeFilter={filters.dealTypeFilter}
      />
      <PropertyCategoryFilter
        onChange={onChange}
        propertyTypeFilter={filters.propertyTypeFilter}
        propertyCategoryFilter={filters.propertyCategoryFilter}
      />
      <RoomFilter onChange={onChange} roomFilter={filters.roomFilter} />
      <AreaFilter onChange={onChange} areaFilter={filters.areaFilter} />
      <FloorFilter onChange={onChange} floorFilter={filters.floorFilter} />
      <ConstructionYearFilter
        onChange={onChange}
        constructionYearFilter={filters.constructionYearFilter}
      />
      <PriceFilter onChange={onChange} priceFilter={filters.priceFilter} />
      <PriceM2Filter
        onChange={onChange}
        pricePerSquareMeterFilter={filters.pricePerSquareMeterFilter}
      />
      <FeaturesFilter
        onChange={onChange}
        featuresFilter={filters.featuresFilter}
      />
    </SidePane>
  )
}

export default ListingFilters

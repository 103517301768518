import { createAsyncThunk } from '@reduxjs/toolkit'

import alertService, { geoDataService } from '../../services/alert'

export const getAlerts = createAsyncThunk(
  'alert/getAlerts',
  alertService.getAlerts
)

export const getDistrictById = createAsyncThunk(
  'alert/getDistrictById',
  geoDataService.getDistrictById
)

export const getCityById = createAsyncThunk(
  'alert/getCityById',
  geoDataService.getCityById
)

export const getRegionById = createAsyncThunk(
  'alert/getRegionById',
  geoDataService.getRegionById
)

export const getGeoCenter = createAsyncThunk(
  'alert/getGeoCenter',
  geoDataService.getGeoCenter
)

export const getZonesByCity = createAsyncThunk(
  'alert/getZonesByCity',
  geoDataService.getZonesByCity
)

export const getZonesByDistrict = createAsyncThunk(
  'alert/getZonesByDistrict',
  geoDataService.getZonesByDistrict
)

export const getZonesByRegion = createAsyncThunk(
  'alert/getZonesByRegion',
  geoDataService.getZonesByRegion
)

export const getDistrictGeojsonByZone = createAsyncThunk(
  'alert/getDistrictGeojsonByZone',
  geoDataService.getDistrictGeojsonByZone
)

export const getCityGeojsonByZone = createAsyncThunk(
  'alert/getCityGeojsonByZone',
  geoDataService.getCityGeojsonByZone
)

export const getRegionGeojsonByZone = createAsyncThunk(
  'alert/getRegionGeojsonByZone',
  geoDataService.getRegionGeojsonByZone
)

export const deleteAlert = createAsyncThunk(
  'alert/deleteAlert',
  alertService.deleteAlert
)

export const createAlert = createAsyncThunk(
  'alert/createAlert',
  alertService.createAlert
)

export const updateAlert = createAsyncThunk(
  'alert/updateAlert',
  alertService.updateAlert
)

export const pauseAlert = createAsyncThunk(
  'alert/pauseAlert',
  alertService.pauseAlert
)

export const getListingsStats = createAsyncThunk(
  'alert/getListingsStats',
  alertService.getListingsStats
)

import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import styled, { css } from 'styled-components'

export const SearchBox = styled.div`
  display: flex;
  align-items: center;

  > .MuiIconButton-root {
    margin-right: 8px;
  }
`

export const Content = styled(Paper)`
  padding: 16px;
  padding-top: 8px;
  flex-grow: 1;
  margin-left: -280px;
  min-width: 250px;
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 3}px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 120px);

  &.contentShift {
    margin-left: 24px;
  }

  .loaderBox {
    position: relative;
    padding: 64px 32px;
  }
`

export const HistoryLayoutRoot = styled(Container)<{ $loading?: boolean }>`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  max-width: 100%;
  align-items: flex-start;

  > .MuiDrawer-docked {
    position: sticky;
    top: 16px;
    max-height: 100vh;

    > .MuiPaper-root {
      overflow-x: hidden;
      max-height: calc(100vh - 105px);
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      margin: 0;
      padding: 0;

      > .MuiDrawer-docked {
        top: unset;

        > .MuiPaper-root {
          max-height: 100vh;
          padding-bottom: 10px;
        }
      }
    }
  `}

  ${({ $loading }) =>
    $loading &&
    css`
      *:not(.MuiToggleButtonGroup-root) {
        pointer-events: none;
      }
    `}

  .highlight {
    background-color: #5cd6ff;
  }
`

const userStateSelector = (state) => state.user

const userDetailsSelector = (state) => {
  const { user } = state.user

  if (!user) return null

  return { ...user, name: `${user.first_name} ${user.last_name}` }
}

const rolesSelector = (state) => state.user.roles

const financialPlanSelector = (state) => state.user.config

const subscriptionSelector = (state) => state.user.subscription

const dashboardTemplatesSelector = (state) => state.user.dashboardTemplates

export {
  userStateSelector,
  userDetailsSelector,
  rolesSelector,
  financialPlanSelector,
  subscriptionSelector,
  dashboardTemplatesSelector,
}

import Grid from '@mui/material/Grid'
import styled from 'styled-components'

export const MinMaxFilterContainer = styled(Grid)`
  padding-top: 4px;

  .MuiInputBase-root {
    font-size: 1.2rem;
    padding-right: 4px;
  }

  input {
    padding: 12px 0px 12px 10px;
  }

  input,
  .MuiInputAdornment-positionEnd {
    font-size: 12px;
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
  }
`

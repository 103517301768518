import { createSelector } from '@reduxjs/toolkit'

import { getPriceData } from '../../services/history'

const listingHistoryStateSelector = (state) => state.listingHistory

const listingsSelector = (state) => state.listingHistory.listings

const listingAggregationsSelector = (state) => state.listingHistory.aggregations

const totalListingsSelector = (state) => state.listingHistory.total

const buyAggsSelector = (state) => state.listingHistory.buyData.aggregations

const rentAggsSelector = (state) => state.listingHistory.rentData.aggregations

const listingGeojsonSelector = (state) => state.listingHistory.geojson

const listingsExactLocationeSelector = createSelector(
  listingsSelector,
  (listings) => {
    return listings.filter((listing) => listing.exact_location)
  }
)

const buyDataSelector = createSelector(
  buyAggsSelector,
  (aggs) => aggs && getPriceData(aggs.price_over_time.price_over_time.buckets)
)

const rentDataSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs && getPriceData(aggs.price_over_time.price_over_time.buckets, 12)
)

const buyMaxDateSelector = createSelector(
  buyAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string
)

const rentMaxDateSelector = createSelector(
  rentAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string
)

const listingBaseInfoSelector = (state) => state.listingHistory.listingBaseInfo

const filteredListingBaseInfoSelector = createSelector(
  listingBaseInfoSelector,
  (listings) => listings?.filter((l) => l.exact_location) || []
)

const filteredListingBaseInfoCountSelector = createSelector(
  filteredListingBaseInfoSelector,
  (listings) => listings?.length
)

const listingsByIdSelector = (state) => state.listingHistory.listingsById

const listingSearchGeojsonSelector = (state) => state.listingHistory.features

const listingLoadingSelector = (state) => state.listingHistory.loading

const addressesLoadingLoadingSelector = (state) =>
  state.listingHistory.addressesLoading

const noLayersSelector = (state) => state.listingHistory.noLayers

const favoriteListingSelector = (state) => state.listingHistory.favoriteListing

const listingsFavoriteDashboardSelector = (state) =>
  state.listingHistory.listingsFavoriteDashboard

const loadingListingFavoriteSelector = (state) =>
  state.listingHistory.loadingFavorite

const locationsAggsSelector = (state) => state.listingHistory.locationsAggs

export {
  listingGeojsonSelector,
  listingHistoryStateSelector,
  listingsSelector,
  totalListingsSelector,
  listingAggregationsSelector,
  listingsExactLocationeSelector,
  buyDataSelector,
  rentDataSelector,
  buyMaxDateSelector,
  rentMaxDateSelector,
  listingBaseInfoSelector,
  listingsByIdSelector,
  filteredListingBaseInfoSelector,
  filteredListingBaseInfoCountSelector,
  listingSearchGeojsonSelector,
  listingLoadingSelector,
  addressesLoadingLoadingSelector,
  noLayersSelector,
  favoriteListingSelector,
  listingsFavoriteDashboardSelector,
  loadingListingFavoriteSelector,
  locationsAggsSelector,
}

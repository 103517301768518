/* eslint-disable max-lines */
// type Listing = {
//   land_id: string
//   land_geojson: any
//   listing_timestamp: string
//   type: string
// }

export const getListings = (
  listings: any[],
  sliderValue: any,
  isRent: boolean,
  isPurchase: boolean,
  tab: string
) => {
  if (tab !== 'listing')
    return {
      type: 'FeatureCollection',
      features: [],
    }

  return {
    type: 'FeatureCollection',
    features: listings?.map((listing) => {
      const years = listing?.map((t: { listing_timestamp: string }) =>
        Number(t.listing_timestamp.substring(0, 4))
      )

      const isListings = years?.filter(
        (year: number) => year >= sliderValue[0] && year <= sliderValue[1]
      )

      const listingType = listing?.map((t: { type: string }) => t.type)

      if (isListings?.length <= 0) {
        return {}
      }

      if (
        (!isRent && listingType?.includes('rent')) ||
        (!isPurchase && listingType?.includes('purchase'))
      ) {
        return {}
      }

      const index =
        years?.findIndex((year: number) => year === isListings[0]) || 0

      return {
        type: 'Feature',
        geometry: JSON.parse(listing?.[index]?.land_geojson),
        properties: listing?.[index],
      }
    }),
  }
}

// type Transaction = {
//   land_id: string
//   land_geojson: any
//   date: string
// }

const getTransactions = (transactions: any, sliderValue: any, tab: string) => {
  if (tab !== 'transaction')
    return {
      type: 'FeatureCollection',
      features: [],
    }

  return {
    type: 'FeatureCollection',
    features:
      transactions?.map((transaction: any) => {
        const years = transaction?.map((t: { date: string }) =>
          Number(t.date.substring(0, 4))
        )

        const isTransactions = years?.filter(
          (year: number) => year >= sliderValue[0] && year <= sliderValue[1]
        )

        if (isTransactions?.length <= 0) {
          return {}
        }

        const index =
          years?.findIndex((year: number) => year === isTransactions[0]) || 0

        return {
          type: 'Feature',
          geometry: JSON.parse(transaction?.[index].land_geojson),
          properties: transaction?.[index],
        }
      }) || [],
  }
}

// type Fao = {
//   fao: []
// }

const getFaos = (
  faos: any[],
  sliderValue: any,
  tab: string,
  devOnly?: boolean
) => {
  if (tab !== 'permit')
    return {
      type: 'FeatureCollection',
      features: [],
    }

  return {
    type: 'FeatureCollection',
    features:
      faos?.map((fao) => {
        const years = fao?.map((t: { inquiry_start_date: string }) =>
          Number(t.inquiry_start_date.substring(0, 4))
        )
        const classifications = fao?.map(
          (t: { classification: string }) => t.classification
        )

        const isFaos = years?.filter(
          (year: number) => year >= sliderValue[0] && year <= sliderValue[1]
        )

        if (isFaos?.length <= 0) {
          return {}
        }

        if (
          devOnly &&
          !(
            classifications?.includes('development') ||
            classifications?.includes('development_beta')
          )
        ) {
          return {}
        }

        const index =
          years?.findIndex((year: number) => year === isFaos[0]) || 0

        return {
          type: 'Feature',
          geometry: JSON.parse(fao?.[index]?.land_geojson),
          properties: fao?.[index],
        }
      }) || [],
  }
}

export type LandAddress = {
  street_nb?: number
  npa: string
  locality?: string
  street?: string
  city?: string
}

const toAddress = (address: LandAddress) => {
  return `${address?.street} ${address?.street_nb || ''}, ${address?.npa} ${
    address?.locality
  }`
}

type Land = {
  buildings: {
    addresses?: LandAddress[]
    buildings_administrative: { addresses?: LandAddress[] }[]
  }[]
}

const getAddresses = (land: Land) => {
  const addresses: LandAddress[] = []

  land?.buildings?.forEach((building) => {
    building.addresses?.forEach((address) => {
      addresses.push(address)
    })

    building.buildings_administrative?.forEach((admBuilding) => {
      admBuilding?.addresses?.forEach((address) => {
        addresses.push(address)
      })
    })
  })

  return addresses
}

const getAddressesStr = (land: Land) => {
  const addresses = getAddresses(land)

  return [...new Set(addresses.map(toAddress))]
}

export const getDataList = (
  data: any,
  sliderValue?: any,
  isRent?: boolean,
  isPurchase?: boolean,
  devOnly?: boolean
) => {
  // eslint-disable-next-line array-callback-return
  return data?.filter((d: any) => {
    if (
      d?.listing_timestamp?.substring(0, 4) < sliderValue[0] ||
      d?.listing_timestamp?.substring(0, 4) > sliderValue[1]
    ) {
      return false
    }

    if (
      d?.inquiry_start_date?.substring(0, 4) < sliderValue[0] ||
      d?.inquiry_start_date?.substring(0, 4) > sliderValue[1] ||
      d?.date?.substring(0, 4) < sliderValue[0] ||
      d?.date?.substring(0, 4) > sliderValue[1]
    ) {
      return false
    }

    if (!devOnly && isRent === undefined && isPurchase === undefined) {
      return true
    }

    if (
      devOnly &&
      !(
        d.classification?.includes('development') ||
        d.classification?.includes('development_beta')
      )
    ) {
      return false
    }

    if (
      (!isRent && d?.type === 'rent') ||
      (!isPurchase && d.type === 'purchase')
    ) {
      return false
    }

    return true
  })
}

export { getTransactions, getFaos, getAddressesStr, getAddresses }

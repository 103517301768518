import { combineReducers } from '@reduxjs/toolkit'

import authReducer from './auth'
import developerMapReducer from './developerMap'
import userReducer from './user'
import developerMapDataReducer from './DeveloperMapData'
import transactionHistoryReducer from './transactionHistory'
import faoHistoryReducer from './faoHistory'
import parcelReducer from './parcel'
import noteReducer from './note'
import DisplayMapReducer from './DisplayMap'
import landMapDetailsReducer from './landMapDetails'
import altimetryReducer from './altimetry'
import livabilityReducer from './livability'
import financialAnalysisReducer from './financialAnalysis'
import reportingReducer from './reporting'
import underExploitedReducer from './underExploited'
import ownershipReducer from './ownership'
import favoriteLandReducer from './favoriteLand'
import companyReducer from './company'
import neighbourhoodReducer from './neighbourhood'
import alertReducer from './alert'
import locationReducer from './location'
import regroupingReducer from './regroupingLand'
import developerMapFormReducer from './developerMapForm'
import listingHistoryReducer from './listingHistory'
import tagReducer from './tag'
import controlsMapReducer from './controlsMap'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  developerMap: developerMapReducer,
  developerMapData: developerMapDataReducer,
  transactionHistory: transactionHistoryReducer,
  faoHistory: faoHistoryReducer,
  parcel: parcelReducer,
  note: noteReducer,
  displayMap: DisplayMapReducer,
  landMapDetails: landMapDetailsReducer,
  altimetry: altimetryReducer,
  livability: livabilityReducer,
  financialAnalysis: financialAnalysisReducer,
  reporting: reportingReducer,
  underExploited: underExploitedReducer,
  ownership: ownershipReducer,
  favoriteLand: favoriteLandReducer,
  company: companyReducer,
  neighbourhood: neighbourhoodReducer,
  alert: alertReducer,
  location: locationReducer,
  regrouping: regroupingReducer,
  developerMapForm: developerMapFormReducer,
  listingHistory: listingHistoryReducer,
  tag: tagReducer,
  controlsMap: controlsMapReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default (state: any, action: any) => {
  if (action.type === 'auth/logout') {
    // eslint-disable-next-line no-param-reassign
    state = undefined
  }

  return rootReducer(state, action)
}

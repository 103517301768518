import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ProgressIndicator } from '../../../components'
import { useSearch, useThunk } from '../../../hooks'
import { useI18n } from '../../../context'
import { alertPreviewSelector, getAlerts } from '../../../redux/alert'
import AlertCard from '../AlertCard'
import { AlertListRoot } from './MyAlerts.style'

const sort = (a, b) =>
  new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

const AlertList = ({ filter }) => {
  const { t } = useI18n()
  const alerts = useSelector(alertPreviewSelector)
  const { dispatch, loading } = useThunk()

  const [page, setPage] = useState(1)

  const search = useSearch({
    q: filter,
    data: alerts,
    page,
    pageSize: 9,
    keys: [(option) => option.name],
    sort,
  })

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  useEffect(() => {
    dispatch(() => getAlerts())
  }, [])

  useEffect(() => {
    setPage(1)
  }, [filter])

  if (loading) {
    return (
      <AlertListRoot>
        <ProgressIndicator circular />
      </AlertListRoot>
    )
  }

  if (!alerts?.length) {
    return (
      <AlertListRoot>
        <p>{t('myAlert.noAlert')}</p>
      </AlertListRoot>
    )
  }

  if (filter && !search.result.length) {
    return (
      <AlertListRoot>
        <p>{t('common.noResult')}</p>
      </AlertListRoot>
    )
  }

  return (
    <AlertListRoot>
      <Grid container spacing={5} alignItems="stretch">
        {search.pagedResult.map((alert) => (
          <Grid item xs={12} md={6} lg={4} key={alert.id}>
            <AlertCard alertId={alert.id} />
          </Grid>
        ))}
      </Grid>

      {search.showPagination && (
        <>
          <Divider />

          <Pagination
            count={search.count}
            page={page}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
          />
        </>
      )}
    </AlertListRoot>
  )
}

export default React.memo(AlertList)

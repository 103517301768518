import React, { useMemo } from 'react'

import Switch from '@mui/material/Switch'
import Radio from '@mui/material/Radio'
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { CheckboxFilterItemRoot } from './CheckboxFilterItem.style'

export type CheckboxFilterItemProps = CheckboxProps & {
  count?: number
  label: string
  radio?: boolean
  switchItem?: boolean
  unstyle?: boolean
}

const CheckboxFilterItem = ({
  label,
  count,
  className,
  radio,
  switchItem,
  checked,
  unstyle,
  color = 'primary',
  ...other
}: CheckboxFilterItemProps) => {
  const Control = useMemo(() => {
    if (switchItem) return Switch

    return radio ? Radio : Checkbox
  }, [radio, switchItem])

  return (
    <CheckboxFilterItemRoot
      className={className}
      title={label}
      $unstyle={unstyle}
    >
      <FormControlLabel
        control={
          <Control {...other} {...(!radio && { checked })} color={color} />
        }
        label={label}
      />
      {count !== undefined && (
        <div className="CheckboxFilterItemCount">{count}</div>
      )}
    </CheckboxFilterItemRoot>
  )
}

export default CheckboxFilterItem

/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit'

const neighbourhoodStateSelector = (state) => state.neighbourhood

const faosSelector = createSelector(
  neighbourhoodStateSelector,
  (neighbourhood) => neighbourhood.faosAround
)

// const faoSetSelector = createSelector(faosSelector, (faos = []) =>
//   faos.filter((v, i, a) => a.findIndex((t) => t.land_id === v.land_id) === i)
// )

const faoSinceSelector = createSelector(
  faosSelector,
  (faos = []) =>
    faos.length > 0 &&
    Math.min.apply(
      null,
      faos?.map((fao) => new Date(fao?.inquiry_start_date))
    )
)

const faoSinceYearSelector = createSelector(
  faoSinceSelector,
  (fao) => fao && new Date(fao).getFullYear()
)

const transactionsSelector = createSelector(
  neighbourhoodStateSelector,
  (neighbourhood) => neighbourhood.transactionsAround
)

// const transactionSetSelector = createSelector(
//   transactionsSelector,
//   (transactions = []) =>
//     transactions.filter(
//       (v, i, a) => a.findIndex((t) => t.land_id === v.land_id) === i
//     )
// )

const transactionSinceSelector = createSelector(
  transactionsSelector,
  (transactions = []) =>
    transactions.length > 0 &&
    Math.min.apply(
      null,
      transactions?.map((transaction) => new Date(transaction?.date))
    )
)

const transactionSinceYearSelector = createSelector(
  transactionSinceSelector,
  (transaction) => transaction && new Date(transaction).getFullYear()
)

const transactionsByLandIdSelector = createSelector(
  transactionsSelector,
  (transactions = []) => {
    return transactions.reduce((val, transaction) => {
      ;(val[transaction.land_id] = val[transaction.land_id] || []).push(
        transaction
      )

      return val
    }, {})
  }
)

const transactionSetSelector = createSelector(
  transactionsByLandIdSelector,
  (transactions = {}) => Object.values(transactions)
)

const faoByLandIdSelector = createSelector(faosSelector, (faos = []) => {
  return faos.reduce((val, fao) => {
    ;(val[fao.land_id] = val[fao.land_id] || []).push(fao)

    return val
  }, {})
})

const faoSetSelector = createSelector(faoByLandIdSelector, (faos = {}) =>
  Object.values(faos)
)

const neighbourhoodStatsSetSelector = createSelector(
  faosSelector,
  transactionsSelector,
  (faos, transactions) => ({
    faos: faos?.length,
    transactions: transactions?.length,
  })
)

const buildingsSelector = createSelector(
  neighbourhoodStateSelector,
  (neighbourhood) => neighbourhood.buildingsAround?.filter(Boolean) || []
)

const buildingsDataSelector = createSelector(buildingsSelector, (buildings) => {
  return buildings.map((building) => {
    const year =
      building.building_year !== null
        ? building.building_year
        : building.building_period !== null && building?.building_period !== ''
        ? building.building_period.split('-')[1]
        : 0

    const height = +building.height
      ? +building.height
      : (building.floor_nb || building.ba_floor_nb) * 3 || 0

    const name = +year
      ? building.classification === 'underground'
        ? 'Underground'
        : 'Building'
      : building.classification === 'underground'
      ? 'Underground without date'
      : 'Building without date'

    return {
      type: 'Feature',
      geometry: JSON.parse(building?.building_geojson),
      properties: {
        ...building,
        codeNumber: building.code_number,
        AboveGround: building.classification !== 'underground',
        Area: `${building.area.toString()}m2`,
        Type: building.type,
        Floor_nb: building.floor_nb || building.ba_floor_nb,
        Unit: building.unit_nb,
        buildingYear: building.building_year,
        buildingPeriod: building.building_period,
        Classification: building.classification,
        height,
        name,
      },
    }
  })
})

const listingsAroundSelector = createSelector(
  neighbourhoodStateSelector,
  (neighbourhood) => neighbourhood.listingsAround
)

// const listingSetSelector = createSelector(
//   listingsAroundSelector,
//   (listings = []) =>
//     listings.filter(
//       (v, i, a) => a.findIndex((t) => t.land_id === v.land_id) === i
//     )
// )

const listingSinceSelector = createSelector(
  listingsAroundSelector,
  (listings = []) =>
    listings.length > 0 &&
    Math.min.apply(
      null,
      listings?.map((listing) => new Date(listing?.listing_timestamp))
    )
)

const listingsByLandIdSelector = createSelector(
  listingsAroundSelector,
  (listings = []) => {
    return listings.reduce((val, listing) => {
      ;(val[listing.land_id] = val[listing.land_id] || []).push(listing)

      return val
    }, {})
  }
)

const listingsSetSelector = createSelector(
  listingsByLandIdSelector,
  (listings = {}) => Object.values(listings)
)

export {
  faosSelector,
  transactionsSelector,
  buildingsSelector,
  buildingsDataSelector,
  faoSetSelector,
  transactionSetSelector,
  neighbourhoodStatsSetSelector,
  transactionsByLandIdSelector,
  faoByLandIdSelector,
  faoSinceSelector,
  transactionSinceSelector,
  transactionSinceYearSelector,
  faoSinceYearSelector,
  listingsAroundSelector,
  listingsSetSelector,
  listingSinceSelector,
  listingsByLandIdSelector,
}

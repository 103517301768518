import styled, { css } from 'styled-components'

export const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > button {
    margin-top: 16px;
  }
`

export const MyAlertsRoot = styled.div`
  padding: 16px;
  background-color: #f7f7f7;
  height: 100%;

  > h2 {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;

    > .MuiChip-root {
      margin-left: 8px;
      height: 20px;
    }

    .MuiChip-label {
      color: rgba(0, 0, 0, 0.5);
      font-size: 10px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      padding: 32px;
    }
  `}
`

export const AlertListRoot = styled.div`
  position: relative;
  margin-top: 24px;

  hr {
    margin: 16px 0;
  }

  > p {
    margin: 16px;
  }

  > .MuiPagination-root {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
// import * as Sentry from '@sentry/react'
import 'mapbox-gl/dist/mapbox-gl.css'

import { I18nProvider, ThemeProvider } from './context'
import Routes from './navigation'
import store from './redux/store'
import Header from './components/Header'
import { Body } from './App.styles'
import IOSInstallerMessage from './components/IOSInstallerMessage'
// import ErrorPage from './pages/error'
import ErrorAlert from './pages/error/ErrorAlert'

const App = () => {
  useEffect(() => {
    const loader = document.getElementById('loader')

    if (loader) loader.outerHTML = ''
  }, [])

  return (
    <Provider store={store}>
      <ThemeProvider>
        <Router>
          <I18nProvider>
            {/* This is commented ad Sentry do not work with latest version of react and typescript */}
            {/* <Sentry.ErrorBoundary fallback={<ErrorPage status="500" />}> */}
            <Header />
            <Body id="main">
              <ErrorAlert />
              <Routes />
            </Body>
            <IOSInstallerMessage />
            {/* </Sentry.ErrorBoundary> */}
          </I18nProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

// Sentry.withErrorBoundary(App, { fallback: <ErrorPage status="500" /> })

export default App

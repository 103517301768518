import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'
import { getCompanyId } from '../company'

const client = new ApiClient()

type OverideValuesProps = {
  data: {
    cos?: string
    ios?: string
    ius?: string
    cus?: string
    ibus?: string
  }
  landId: number
  landUsePlanAssignmentId: number
}

type DifferenceValuesProps = {
  landUsePlans: { id: string }[]
  futureLandUsePlans: { id: string }[]
}

const addOverideValuesAssignment = async ({
  data,
  landId,
  landUsePlanAssignmentId,
}: OverideValuesProps) => {
  return client.post(
    `/landuseplans/${getCurrentUserId()}/${getCompanyId()}/overide/assignment/${landId}/${landUsePlanAssignmentId}`,
    data
  )
}

const getLandUsePlanDocuments = async (landUsePlans: { id: string }[]) => {
  return client.get(
    `/landuseplans/${landUsePlans
      .map((plan) => plan.id)
      .join(',')}/land/documents`
  )
}

const getFutureLandUsePlanDocuments = async (
  futureLandUsePlans: { id: string }[]
) => {
  return client.get(
    `/futurelanduseplans/${futureLandUsePlans
      .map((plan) => plan.id)
      .join(',')}/land/documents`
  )
}

const getDifferenceLandUsePlanDocuments = async ({
  landUsePlans,
  futureLandUsePlans,
}: DifferenceValuesProps) => {
  let current = []
  let future = []

  future = await client.get(
    `/futurelanduseplans/${futureLandUsePlans
      .map((plan) => plan.id)
      .join(',')}/land/documents`
  )

  current = await client.get(
    `/landuseplans/${landUsePlans
      .map((plan) => plan.id)
      .join(',')}/land/documents`
  )

  current = current?.data
  future = future?.data

  return [current, future]
}

const underExploitedService = {
  getLandUsePlanDocuments,
  addOverideValuesAssignment,
  getFutureLandUsePlanDocuments,
  getDifferenceLandUsePlanDocuments,
}

export default underExploitedService

import React from 'react'
import { useLocation } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Modal from '@mui/material/Modal'

import ProgressIndicator from '../ProgressIndicator'
import Snackbar from '../Snackbar'
import { generateHistoryExcel } from '../../services/reporting'
import { useAsync } from '../../hooks'
import { useI18n } from '../../context'
import Select, { SelectProps } from '../Select'
import { ExportIcon } from '../icons'
import { HistorySortBoxRoot, ActionBox } from './HistorySortBox.style'

export type HistorySortBoxProps = SelectProps & {
  title: string
  exportExcel?: boolean
  actions?: React.ReactNode
}

const HistorySortBox = (props: HistorySortBoxProps) => {
  const { title, className, actions, exportExcel = true, ...other } = props
  const { t } = useI18n()
  const { pathname } = useLocation()
  const async = useAsync()

  const isFao = pathname === '/fao-history'
  const isTransaction = pathname === '/transaction-history'
  const isListing = pathname === '/listing-history'

  let exportText = t('common.exportListing')
  let fileName = ''

  if (isFao) {
    exportText = t('common.exportFao')
    fileName = t('common.fao.fileName')
  }

  if (isTransaction) {
    exportText = t('common.exportTransaction')
    fileName = t('common.transaction.fileName')
  }

  if (isListing) {
    exportText = t('common.exportListing')
    fileName = t('common.listing.fileName.excel')
  }

  const handleExport = () => {
    let data: any = localStorage.getItem('history-data')

    if (!data) return

    try {
      data = JSON.parse(data)
      data.fileName = fileName

      data.rows = data.rows.map((r: any) => ({
        ...r,
        landId: r.lands_id
          ?.split(',')
          .map(
            (landId: string) =>
              `https://app.popety.io/land-detail?landId=${landId}`
          ),
      }))

      async.dispatch(() => generateHistoryExcel(data))
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <HistorySortBoxRoot className={className}>
      <div className="Title">{title}</div>

      <div className="Content">
        {other.options && (
          <Select variant="outlined" size="small" {...other} fullWidth />
        )}
        <ActionBox>
          {exportExcel && (
            <Tooltip title={exportText}>
              <IconButton
                onClick={handleExport}
                edge={!actions ? 'end' : undefined}
              >
                <ExportIcon />
              </IconButton>
            </Tooltip>
          )}
          {actions}
        </ActionBox>
      </div>

      {async.loading && (
        <Modal open>
          <ProgressIndicator anchor="window" circular />
        </Modal>
      )}

      <Snackbar open={!async.loading && !!async.errors} error={!!async.errors}>
        {async.errors?.[0]?.message}
      </Snackbar>
    </HistorySortBoxRoot>
  )
}

export default HistorySortBox

import styled, { css } from 'styled-components'

export const BrandRoot = styled.div`
  color: #ededed;
  font-size: 16px;
  border: none;
  margin-left: 16px;

  > button {
    margin-top: -4px;
  }

  > span {
    font-style: italic;
    font-size: 10px;
    color: #00ade6;
    white-space: nowrap;
    padding-left: 5px;
  }

  a {
    margin-left: 4px;
    color: #ededed;
    font-size: inherit;
    border: none;
    line-height: 56px;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      font-size: 30px;

      > span {
        font-size: 16px;
      }

      a {
        margin-left: 16px;
        line-height: 64px;
      }
    }
  `}
`

import Accordion from '@mui/material/Accordion'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'

export const SettingsStepRoot = styled.div`
  .SidePane {
    max-width: 100%;
    width: 100%;

    .MuiDrawer-paper {
      overflow: hidden;
    }

    .DateRangeFilter,
    .CheckboxFilterItemCount,
    .RegionFilterItemCount,
    .StatusFilter {
      display: none;
    }
  }

  .SidePaneContent {
    padding: 0;
    max-width: 100%;
    min-width: 0;

    .MuiTextField-root {
      width: 100%;
    }

    > div:first-of-type,
    > .CloseIcon {
      display: none;
    }
  }

  > .MuiCollapse-root {
    max-height: 200px;
    overflow-y: auto;
  }

  > .MuiSwitch-root {
    margin-bottom: 16px;
    margin-left: -12px;
  }

  > .MuiAccordion-root {
    margin-top: 10px;
    margin-bottom: 10px;

    .MuiAccordionSummary-content {
      margin: 0;
      color: rgb(0, 173, 230);
      font-size: 14px;
    }
  }

  .MuiAutocomplete-tagSizeSmall {
    margin-top: -1px;
  }

  .MuiAutocomplete-root {
    margin-bottom: 16px;
  }
`

export const AdvSettingsAccordionRoot = styled(Accordion)`
  margin-top: 10px;
  margin-bottom: 10px;

  .MuiAccordionSummary-content {
    margin: 0;
    color: rgb(0, 173, 230);
    font-size: 14px;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    margin-top: -15px;
    max-height: 200px;
    padding: 8px 16px 0px;
    overflow-y: auto;
  }
`

export const InfoTitleRoot = styled.h5`
  color: rgb(141, 155, 163);
  font-size: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 10px;

  svg {
    font-size: 16px;
  }

  button {
    padding: 0px 0px 0px 4px;
  }
`

export const SettingsField = styled(TextField)`
  input {
    padding: 12px 10px;
    font-size: 12px;
  }

  .MuiInputBase-root {
    min-height: 35px;
  }
`

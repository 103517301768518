import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Divider from '@mui/material/Divider'
import Avatar from '@mui/material/Avatar'

import { getCurrentUserDetails, userDetailsSelector } from '../../../redux/user'
import { useI18n } from '../../../context'
import {
  DivContainer,
  AvatarContainer,
  AvatarBody,
  StyledSpan,
  BodyContainer,
  StyledAnalysisDiv,
  ColumnDivLeft,
  ColumnDivRight,
} from './UserDetails.style'

const getColorLimit = (user: any, analysisNbLimit: number) => {
  let colorLimit = 'rgba(39, 175, 21, 0.87)'

  if (
    user &&
    (user.analysis_counter || user.monthly_analysis_counter) &&
    user?.analysis_nb_limit &&
    (user.analysis_counter || user.monthly_analysis_counter) / analysisNbLimit >
      0.9
  ) {
    colorLimit = '#E27670'
  } else if (
    user &&
    (user.analysis_counter || user.monthly_analysis_counter) &&
    (user.analysis_counter || user.monthly_analysis_counter) / analysisNbLimit >
      0.7
  ) {
    colorLimit = 'orange'
  }

  return colorLimit
}

const UserDetails = () => {
  const dispatch = useDispatch()
  const { t } = useI18n()
  const user = useSelector(userDetailsSelector)

  useEffect(() => {
    if (!user) dispatch(getCurrentUserDetails() as any)
  }, [])

  const analysisNbLimit =
    user?.subscription_frequency === 'yearly' && user.analysis_nb_limit
      ? user.analysis_nb_limit * 12
      : user?.analysis_nb_limit || 0

  const analysisNb = user?.analysis_counter || user?.monthly_analysis_counter

  const colorLimit = getColorLimit(user, analysisNbLimit)

  return (
    <DivContainer>
      <AvatarContainer>
        <Avatar alt={user?.company_name} src={user?.company_logo} />
        <AvatarBody>
          {` ${user?.name}`}
          <StyledSpan>{user?.company_name}</StyledSpan>
        </AvatarBody>
      </AvatarContainer>
      <BodyContainer>
        <ColumnDivLeft>
          <StyledSpan>{t('common.subscription')}:</StyledSpan>
          {user?.plan_name}
        </ColumnDivLeft>
        <Divider orientation="vertical" flexItem />
        <ColumnDivRight>
          <StyledSpan>{t('common.analysis')}:</StyledSpan>
          <StyledAnalysisDiv>
            <span style={{ color: colorLimit }}>
              {analysisNb?.toString() || 0}
            </span>
            /
            {user?.plan_name !== 'Ultimate'
              ? analysisNbLimit
              : t('common.unlimited')}
          </StyledAnalysisDiv>
        </ColumnDivRight>
      </BodyContainer>
    </DivContainer>
  )
}

export default UserDetails

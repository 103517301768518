import { createSelector } from '@reduxjs/toolkit'

import { getHistoryPriceData } from '../../services/history'

const transactionHistoryStateSelector = (state) => state.transactionHistory

const transactionsSelector = (state) => state.transactionHistory.transactions

const aggregationsSelector = (state) => state.transactionHistory.aggregations

const aggregationDatesSelector = (state) =>
  state.transactionHistory.aggregations.dates.dates

const totalTransactionsSelector = (state) => state.transactionHistory.total

const priceIntervalSelector = (state) =>
  state.transactionHistory.priceData.calendar_interval

const priceAggsSelector = (state) =>
  state.transactionHistory.priceData.aggregations

const priceDataSelector = createSelector(
  priceAggsSelector,
  (aggs) =>
    aggs && getHistoryPriceData(aggs.price_over_time.price_over_time.buckets)
)

const compareFilterSelector = (state) => state.transactionHistory.compareFilter

const maxDateSelector = createSelector(
  priceAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string
)

const comparePriceAggsSelector = (state) =>
  state.transactionHistory.comparePriceData.aggregations

const comparePriceDataSelector = createSelector(
  comparePriceAggsSelector,
  (aggs) =>
    aggs && getHistoryPriceData(aggs.price_over_time.price_over_time.buckets)
)

const transactionLoadingSelector = (state) => state.transactionHistory.loading

const locationsAggsSelector = (state) => state.transactionHistory.locationsAggs

export {
  transactionHistoryStateSelector,
  transactionsSelector,
  totalTransactionsSelector,
  aggregationsSelector,
  aggregationDatesSelector,
  priceDataSelector,
  compareFilterSelector,
  priceIntervalSelector,
  priceAggsSelector,
  maxDateSelector,
  comparePriceDataSelector,
  transactionLoadingSelector,
  locationsAggsSelector,
}

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { arrayFy } from '../../../utils'
import { useQueryStringParser } from '../../../hooks'
import { updateSelectedAlert } from '../../../redux/alert'
import { useLocations } from '../ZonesStep'

const useAlert = () => {
  const query = useQueryStringParser()
  const hasQuery = !!Object.values(query).length

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const locations = useLocations()

  const [open, setOpen] = useState(hasQuery)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!hasQuery || !open) return

    try {
      const {
        transaction,
        fao,
        listing,
        cityFilter,
        zoneFilter,
        regionFilter,
        landUsePlansFilter,
        value,
        geoCenter,
        address,
        type = 'Zones',
        radius,
        from: _f,
        size: _s,
        page: _p,
        ...filters
      } = query

      const alertFromParams = {
        parameters: {},
        type,
        name: '',
        id: null,
        fao: fao === 'true',
        transaction: transaction === 'true',
        listing: listing === 'true',
        address,
        radius: radius ? +radius : undefined,
        geoCenter: geoCenter ? arrayFy(geoCenter).map(Number) : undefined,

        citiesSelected: arrayFy(cityFilter),
        zonesSelected: arrayFy(zoneFilter),
        regionsSelected: arrayFy(regionFilter),
        zoneAffectationSelected: arrayFy(landUsePlansFilter)
          ?.filter(Boolean)
          .map((lup) => ({ value: lup, label: lup })),
      }

      Object.keys(filters).forEach((key) => {
        filters[key] = arrayFy(filters[key])
      })

      if (filters.adStatusFilter) {
        filters.adStatusFilter = arrayFy(filters.adStatusFilter).map(Number)
      }

      // Keyword
      filters.name = filters.name || value

      if (alertFromParams.fao) {
        alertFromParams.building_permit_parameters = { ...filters }
      }

      if (alertFromParams.transaction) {
        alertFromParams.transaction_parameters = { ...filters }
      }

      if (alertFromParams.listing) {
        alertFromParams.listing_parameters = { ...filters }
      }

      alertFromParams.citiesSelected = alertFromParams.citiesSelected
        ?.map((c) => locations.citiesByName[c])
        .filter(Boolean)

      alertFromParams.regionsSelected = alertFromParams.regionsSelected
        ?.map((r) => locations.regionsByName[r])
        .filter(Boolean)

      alertFromParams.zonesSelected = alertFromParams.zonesSelected
        ?.map((z) => locations.zonesByName[z])
        .filter(Boolean)

      Object.keys(alertFromParams).forEach((k) => {
        if (alertFromParams[k] == null) delete alertFromParams[k]
      })

      dispatch(updateSelectedAlert(alertFromParams))
      handleOpen()
    } catch (error) {
      console.error(error)
    }
  }, [locations, query, open])

  useEffect(() => {
    if (!open) {
      navigate('/my-alerts', { replace: true })
    }
  }, [open])

  return { open, handleOpen, handleClose }
}

export default useAlert

import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

export type HistorySearchOpts = Record<string, any>

const sanitizeInput = (filter: HistorySearchOpts) => {
  return {
    ...filter,
    value:
      filter.value
        ?.replace(/[.*+\-/?^${}()|[\]\\]/g, ' ')
        .replace(/\s\s+/g, ' ')
        .trim() || '',
  }
}

const getFaos = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/faos/_search', sanitizeInput(filter))
}

const getFaoStats = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/faos/stats', sanitizeInput(filter))
}

const getFaoAggs = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/faos/aggs', sanitizeInput(filter))
}

const getTransactions = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/transactions/_search', sanitizeInput(filter))
}

const getTransactionStats = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/transactions/stats', sanitizeInput(filter))
}

const getTransactionsAggs = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/transactions/aggs', sanitizeInput(filter))
}

const getListings = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/listing/_search', sanitizeInput(filter))
}

const getListingStats = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/listing/stats', sanitizeInput(filter))
}

const getListingsAggs = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/listing/_search', sanitizeInput(filter))
}

const getListingsBaseinfo = ({ ...filter }: HistorySearchOpts) => {
  return client.post('/listing/baseInfo', sanitizeInput(filter))
}

const getListingsByIds = (ids: string | string[]) => {
  return client.post('/listing/byIds', sanitizeInput({ ids }))
}

const getListingRentAndBuyStats = ({ ...filter }: HistorySearchOpts) => {
  return Promise.all([
    getListingStats({ ...filter, dealTypeFilter: ['rent'] }),
    getListingStats({ ...filter, dealTypeFilter: ['purchase'] }),
  ])
}

const getListingsTileDetails = (tile: string, q = '') => {
  return client.get(`/tiles/listings/details?tile=${tile}&query=${q}`)
}

const getFaosTileDetails = (tile: string, q = '') => {
  return client.get(`/tiles/faos/details?tile=${tile}&query=${q}`)
}

const getTransactionsTileDetails = (tile: string, q = '') => {
  return client.get(`/tiles/transactions/details?tile=${tile}&query=${q}`)
}

const getGeojson = (id: number) => {
  return client.get(`/cities/${id}/geojson`)
}

const gePlotsFromListing = (values: any) => {
  return client.post('/parcelles/listing/_search', values.data || values, {
    signal: values.signal,
  })
}

const saveFavoriteListing = (values: any) => {
  return client.post(
    `/listing/save/favorite/${getCurrentUserId()}/${values.landId}/${
      values.listingId
    }`,
    { address: values.address, geoCenter: values.geoCenter }
  )
}

const getFavoriteListing = () => {
  return client.get(`/listing/${getCurrentUserId()}`)
}

const listingsFavoriteDashboard = () => {
  return client.get(`/users/${getCurrentUserId()}/listings/favorites/lands`)
}

const activeOrDesactivateFavoriteListing = ({ active, id }: any) => {
  client.put(`/listing/favorite/${active}/${id}`)

  return { id }
}

const history = {
  getGeojson,
  getFaos,
  getTransactions,
  getListings,
  getListingStats,
  getListingRentAndBuyStats,
  getListingsBaseinfo,
  getListingsByIds,
  getListingsTileDetails,
  getFaosTileDetails,
  getTransactionsTileDetails,
  getTransactionStats,
  getFaoStats,
  gePlotsFromListing,
  saveFavoriteListing,
  getFavoriteListing,
  listingsFavoriteDashboard,
  activeOrDesactivateFavoriteListing,
  getFaoAggs,
  getTransactionsAggs,
  getListingsAggs,
}

export default history

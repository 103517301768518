const getCalculatedValues = (editableData, area) => {
  const cus = (editableData.buildingGroundArea * editableData.floors) / area

  const floorsArea = editableData.buildingGroundArea * editableData.floors

  const livingAreaPerUnit =
    (floorsArea * (1 - editableData.walls / 100)) / editableData.unitNb

  return {
    cos: parseFloat((editableData.buildingGroundArea / area).toFixed(2)),
    cus: parseFloat(cus.toFixed(2)),
    livingWithWallsArea: editableData.buildingGroundArea * editableData.floors,
    livingArea: Math.round(floorsArea * (1 - editableData.walls / 100)),
    livingAreaPerUnit: Math.round(livingAreaPerUnit),
  }
}

const computeLandCost = (analysis) => {
  const data = analysis.analysis_data
  const bareLandPrice = Math.round(data.landArea * data.currentLandPrice)

  const constructedBuildingPrice = Math.round(
    data.currentLivingArea * data.currentBuildingPrice
  )

  const landPrice = bareLandPrice + constructedBuildingPrice

  const notaryFee =
    data?.notaryFee <= 0.09 ? data.notaryFee : (data.notaryFee / 100).toFixed(3)

  const transfertRight =
    data?.transfertRight <= 0.09
      ? data.transfertRight
      : (data.transfertRight / 100).toFixed(3)

  const notaryFees = Math.round(landPrice * notaryFee)
  const transfertRights = Math.round(landPrice * transfertRight)

  const otherLandCost = Math.round(
    notaryFees +
      transfertRights +
      data.cfcZeroAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
  )

  const landCostTotal = Math.round(landPrice + otherLandCost)

  return {
    landCostTotal,
    bareLandPrice,
    constructedBuildingPrice,
    landPrice,
    transfertRights,
    otherLandCost,
    notaryFees,
  }
}

const computePreliminaryCost = (analysis) => {
  const data = analysis.analysis_data
  const preparationCost = Math.round(data.preparationCost * data.landArea)
  const demolitonCost = Math.round(data.demolitonCost * data.currentLivingArea)

  const otherPreliminaryCost = Math.round(
    Math.round(
      data.cfcOneAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
    )
  )
  const preliminaryCostTotal = Math.round(
    preparationCost +
      demolitonCost +
      data.cfcOneAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
  )

  return {
    preparationCost,
    demolitonCost,
    otherPreliminaryCost,
    preliminaryCostTotal,
  }
}

const computeConstructionCost = (analysis) => {
  const data = analysis.analysis_data

  const buildingVolume = Math.round(
    data.livingWithWallsArea * data.correlativeHeight
  )

  const constructionCost = Math.round(data.constructionCost * buildingVolume)
  const landscapingCost = Math.round(data.landscapingCost * data.landArea)

  const constructionMiscellaneousCost = Math.round(
    (constructionCost +
      landscapingCost +
      data.cfcTwoAdditionalCodes.reduce((acc, code) => acc + code.value, 0)) *
      data.othersAdditional
  )

  const otherConstructionCost = Math.round(
    Math.round(
      data.cfcTwoAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
    )
  )

  const constructionCostTotal = Math.round(
    constructionCost +
      landscapingCost +
      constructionMiscellaneousCost +
      data.cfcTwoAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
  )

  return {
    constructionCost,
    buildingVolume,
    landscapingCost,
    otherConstructionCost,
    constructionCostTotal,
    constructionMiscellaneousCost,
  }
}

const computeAdditionalCost = (analysis, totalCostNoAdditional) => {
  const data = analysis.analysis_data

  const authorisationAndTaxes = Math.round(
    totalCostNoAdditional * data.authorisationAndTaxe
  )

  const interimInterest = Math.round(
    totalCostNoAdditional *
      (1 - data.ownFundsPercentage / 100) *
      (data.interestRate / 100 / 12) *
      data.constructionTime
  )

  const managementFees = Math.round(totalCostNoAdditional * data.managementFee)

  const othersMiscellaneousCost = Math.round(
    (totalCostNoAdditional +
      data.cfcFiveAdditionalCodes.reduce((acc, code) => acc + code.value, 0)) *
      data.othersAdditional
  )

  const othersAdditionalCostTotal = Math.round(
    othersMiscellaneousCost +
      data.cfcFiveAdditionalCodes.reduce((acc, code) => acc + code.value, 0)
  )

  const additionalCostTotal = Math.round(
    authorisationAndTaxes +
      interimInterest +
      managementFees +
      othersAdditionalCostTotal
  )

  const ownCapital = totalCostNoAdditional * (1 - data.ownFundsPercentage / 100)

  return {
    additionalCostTotal,
    authorisationAndTaxes,
    othersAdditionalCostTotal,
    othersMiscellaneousCost,
    managementFees,
    interimInterest,
    ownCapital,
  }
}

export {
  getCalculatedValues,
  computeLandCost,
  computePreliminaryCost,
  computeConstructionCost,
  computeAdditionalCost,
}

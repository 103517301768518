import { useLocation } from 'react-router-dom'

const groupParamsByKey = (params: URLSearchParams) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple

    // eslint-disable-next-line no-prototype-builtins
    if (acc.hasOwnProperty(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val]
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val]
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val
    }

    return acc
  }, {} as Record<string, any>)

/**
 * Hook to parse a query string into an object.
 * Multiple same keys are parsed as array of all the matching values
 * @param  {string} queryString query string to parse. If no query string is specified,
 * it will fallback to the current location search
 */
const useQueryStringParser = (queryString?: string) => {
  const { search } = useLocation()
  const params = new URLSearchParams(queryString || search)

  return groupParamsByKey(params)
}

export { useQueryStringParser }

import chroma from 'chroma-js'

export const getColorScale = (score: any) => {
  return chroma
    .scale(['ffbfbf', 'ffc3a5', 'FFE768', '8ED1A5', '00441b'])(score / 100)
    .rgb()
}

export const getColorScaleHex = (score: any) => {
  return chroma
    .scale(['ffbfbf', 'ffc3a5', 'FFE768', '8ED1A5', '00441b'])(score / 100)
    .hex()
}

export function colorToRGBArray(color: any) {
  if (Array.isArray(color)) {
    return color.slice(0, 3)
  }
  const c = color

  return [c.r, c.g, c.b]
}

export function colorToHex(color: any) {
  return colorToRGBArray(color).reduce(
    (acc: any, v: any) => `${acc}${v < 16 ? '0' : ''}${v.toString(16)}`,
    '#'
  )
}

export const getScoreColorHexScale = (score: any) =>
  colorToHex(getColorScale(score))

export const scaleScore = chroma
  .scale(['ffbfbf', 'ffd283', 'FFE768', '8ED1A5', '00441b'])
  .mode('lch')
  .colors(10)

export const scaleLivabilityScore = chroma
  .scale(['ffbfbf', 'FFE768', '00441b'])
  .mode('lch')
  .colors(10)

export const scaleDifferenceScore = chroma
  .scale(['ffbfbf', 'ffc3a5', 'f7f7f7', '337045', '00441b'])
  .mode('lch')
  .colors(10)

export const getDifferenceColorScale = (score: any) =>
  chroma
    .scale(['ffbfbf', 'ffc3a5', 'f7f7f7', '337045', '00441b'])(
      (score / 2 + 50) / 100
    )
    .rgb()

export const developmentScoreColorScale = (r: any) =>
  getColorScale(r.development_score)

export const developmentFutureScoreColorScale = (r: any) =>
  getColorScale(r.future_development_score)

export const developmentDifferenceScoreColorScale = (r: any) =>
  getDifferenceColorScale(r.difference_development_score)

export const underExploitedScoreColorScale = (r: any) =>
  getColorScale(r.under_exploited_score)

export const underExploiteFuturedScoreColorScale = (r: any) =>
  getColorScale(r.future_under_exploited_score)

export const underExploitedDifferenceScoreColorScale = (r: any) =>
  getDifferenceColorScale(r.difference_under_exploited_score)

export const titleScoreColorScale = (r: any) => {
  return colorToHex(getColorScale(r))
}

export const noDifferenceScoreColorScale = () => getDifferenceColorScale(0)

export const getColorScaleTenClasses = (score: any) =>
  chroma
    .scale(['FFE768', '8ED1A5', '00441b'])
    .classes(10)(score / 100)
    .rgb()

export const developmentScoreColorHexScale = (r: any, landUsePlan: any) =>
  colorToHex(
    landUsePlan === 'future'
      ? developmentFutureScoreColorScale(r)
      : developmentScoreColorScale(r)
  )

export const dataReliabilityColor = (usage: any, occupation: any) => {
  switch (true) {
    case usage && occupation:
      return [5, 57, 45]
    case usage || occupation:
      return [120, 198, 121]
    default:
      return [247, 252, 185]
  }
}

import React, { useState } from 'react'

import { FilterCategory, MinMaxFilter } from '../../../components'
import { useI18n } from '../../../context'

const FloorFilter = ({ onChange, floorFilter, ...other }) => {
  const { t } = useI18n()
  const [reset, setReset] = useState(false)

  const handleChange = (newValue, apply) => {
    if (reset && newValue?.length) setReset(false)
    onChange({ floorFilter: newValue }, apply)
  }

  const handleReset = () => {
    setReset(true)
  }

  return (
    <FilterCategory
      {...other}
      title={t('listing.floor')}
      onReset={floorFilter?.length && handleReset}
    >
      <MinMaxFilter
        id="floor"
        reset={reset}
        value={floorFilter}
        onChange={handleChange}
        obj={other.obj}
      />
    </FilterCategory>
  )
}

export default FloorFilter

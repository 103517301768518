import styled from 'styled-components'

export const ScoreDescriptionText = styled.div`
  font-size: 11px;
  padding-top: 8px;
  padding-right: 4px;
  font-style: italic;
  color: ${({ color }) => color};
`

export const ScoreDescriptionTitle = styled.div`
  font-size: 15px;
  color: ${({ color }) => color};
`

import Autocomplete from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@mui/material/Switch'

import { arrayFy } from '@/utils'
import { useI18n } from '../../../context'
import {
  alertListingSelector,
  alertListingSettingsSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import StepActions from '../StepActions'
import {
  SettingsField,
  SettingsStepRoot,
} from '../SettingsStep/SettingsStep.style'
import InfoTitle from '../SettingsStep/InfoTitle'
import ListingFilters from '../../ListingHistory/ListingFilters'
import SettingsCollapse from '../SettingsStep/SettingsCollapse'

const ListingSettingsStep = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState([])
  const { t } = useI18n()

  const listing = useSelector(alertListingSelector)
  const listingSettings = useSelector(alertListingSettingsSelector)
  const [filters, setFilters] = useState({ ...listingSettings })

  const updateSettings = (name) => (values) => {
    dispatch(
      updateSelectedAlert({
        listing_parameters: { ...listingSettings, [name]: values },
      })
    )
  }

  const handleChangeKeyword = (e, newValue) => {
    updateSettings('name')(newValue)

    setValue(newValue)
  }

  const handleChange = (e) => {
    dispatch(updateSelectedAlert({ listing: !!e.target.checked }))
  }

  const handleFilterChange = (newFilters) => {
    const newValue = { ...listingSettings, ...newFilters }

    dispatch(updateSelectedAlert({ listing_parameters: newValue }))
    setFilters(newValue)
  }

  return (
    <SettingsStepRoot>
      <Switch checked={!!listing} color="secondary" onChange={handleChange} />

      <SettingsCollapse>
        <InfoTitle
          title={t('myAlert.settings.keyword')}
          text={t('myAlert.settings.keywordDesc')}
        />

        <Autocomplete
          size="small"
          multiple
          freeSolo
          fullWidth
          autoSelect
          options={[]}
          value={arrayFy(listingSettings?.name || value)}
          onChange={handleChangeKeyword}
          renderInput={(params) => (
            <SettingsField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
            />
          )}
        />

        <ListingFilters filters={filters} open onChange={handleFilterChange} />
      </SettingsCollapse>

      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </SettingsStepRoot>
  )
}

export default ListingSettingsStep

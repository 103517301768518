import styled from 'styled-components'

export const MenuListContainer = styled.div`
  width: 250px;

  a {
    color: inherit;
  }

  .LanguageMenuButton {
    .MuiListItemIcon-root {
      min-width: 56px;
    }
  }

  .version {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);

    * {
      font-size: inherit;
    }
  }
`

export const MenuHeaderContainer = styled.div`
  padding: 24px 16px;

  > .MuiAvatar-root {
    margin-bottom: 16px;
  }

  > span {
    color: rgb(141, 155, 163);
  }

  > h6 {
    font-size: 16px;
    margin: 0;
  }
`

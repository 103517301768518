import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getZones = async () => {
  return client.get(`/users/${getCurrentUserId()}/zones`)
}

const getRegions = async () => {
  return client.get(`/users/${getCurrentUserId()}/region`)
}

const getCities = async () => {
  const response = await client.get('/cities')

  // To remove duplicate cities
  const cityByName: Record<string, any> = {}

  if (response.data) {
    response.data.forEach((city: any) => {
      if (city.name) cityByName[city.name] = city
    })

    response.data = Object.values(cityByName)
  }

  return response
}

const searchAllAddresses = async ({ value, types, size }: any) => {
  return client.post('/addresses/_searchAll', { value, size, types })
}

const searchAddress = async (address: string) => {
  return client.post(`/addresses/_search`, { address })
}

const locationService = {
  getZones,
  getRegions,
  getCities,
  searchAddress,
  searchAllAddresses,
}

export default locationService

export const calculateSb = (buildings: any) => {
  const cover = buildings.reduce(
    (
      acc: number,
      building: {
        areaOveride: number | string
        cover_area: string | number
        cover_area_perc: number
      }
    ) => {
      const area = building.areaOveride
        ? Number(building.areaOveride) * (building.cover_area_perc / 100)
        : Number(building.cover_area)

      return acc + area
    },
    0
  )

  return cover
}

export const calculateSdb = (buildings: any) => {
  const cover = buildings.reduce(
    (
      acc: number,
      building: {
        areaOveride: number | string
        cover_area: string | number
        cover_area_perc: number
        buildings_administrative: any
      }
    ) => {
      const area = building.areaOveride
        ? Number(building.areaOveride) * (building.cover_area_perc / 100)
        : Number(building.cover_area)

      return acc + area
    },
    0
  )

  return cover
}

export const calculateSp = (buildings: any) =>
  buildings.reduce(
    (
      acc: number,
      building: {
        floorNbOveride: number | string
        areaOveride: number | string
        floorAreaOveride: number | string
        cover_area: string | number
        cover_area_perc: number
        floor_nb: number
      }
    ) => {
      const floor = Number(building.floorNbOveride) || building.floor_nb || 1

      const coverArea = building.cover_area_perc / 100

      const area: number = building.areaOveride
        ? Number(building.areaOveride) * coverArea
        : Number(building.cover_area)

      const areaWithFloorOverride = building.floorAreaOveride
        ? Math.round(Number(building.floorAreaOveride) * coverArea)
        : Math.round(area * floor)

      const calculate = Number(areaWithFloorOverride)

      return acc + calculate
    },
    0
  )

export const calculateSpdSbpu = (buildings: any) =>
  buildings.reduce(
    (
      acc: number,
      building: {
        floorNbOveride: number | string
        areaOveride: number | string
        floorAreaOveride: number | string
        cover_area: string | number
        cover_area_perc: number
        floor_nb: number
      }
    ) => {
      const floor = Number(building.floorNbOveride) || building.floor_nb || 1

      const coverArea = Number(building.cover_area_perc / 100)

      let area: number = building.areaOveride
        ? Number(building.areaOveride) * coverArea
        : Number(building.cover_area)

      area *= 0.9

      const floorArea: number = Number(building.floorAreaOveride) * 0.9

      const areaWithFloorOverride = building.floorAreaOveride
        ? Math.round(floorArea * coverArea)
        : Math.round(area * floor)

      const calculate = Number(areaWithFloorOverride)

      return acc + calculate
    },
    0
  )

export const categories = [
  'Réservoir, silo et entrepôt',
  'Bâtiment d"exploitation agricole',
  'Autre bâtiment non classé ailleurs',
  'Bâtiments pour la garde d"animaux',
  'Bâtiments pour les cultures végétales',
  'Autres bâtiments d"exploitation agricole',
  'Bâtiment des transports et des communications sans garage',
  'Garage',
]

export const type = ['Habitation provisoire', 'Construction particulière']

export const classification = ['underground', 'projected']

export const spdSbpuType = [
  'Bâtiment d’habitation avec usage annexe',
  'Bâtiment exclusivement à usage d’habitation',
  'Bâtiment partiellement à usage d’habitation',
]

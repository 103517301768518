import styled, { css } from 'styled-components'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

const Title = styled(DialogTitle)`
  padding-top: 18px;
  padding-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .MuiIconButton-root:last-child {
    margin-left: auto;
    margin-right: -12px;
  }

  > .MuiIconButton-root {
    margin-top: -12px;
  }

  > .Left {
    > svg {
      margin-right: 12px;
    }

    > .MuiIconButton-root:first-child {
      margin-left: -12px;
    }
  }
`

const ModalRoot = styled(Dialog)<{ disableGutters?: boolean }>`
  .MuiDialogContent-root:last-child {
    margin-bottom: 16px;

    ${({ fullScreen }) =>
      fullScreen &&
      css`
        padding-left: 16px;
        padding-right: 16px;
      `}
  }

  .MuiDialog-paper {
    ${({ maxWidth, fullScreen }) =>
      !maxWidth &&
      !fullScreen &&
      css`
        width: 100%;
        max-width: 600px;
      `}
  }

  .MuiDialogContent-root {
    ${({ disableGutters }) =>
      disableGutters &&
      css`
        padding: 0;
        margin: 0;
      `}
  }

  .MuiPickersSlideTransition-transitionContainer,
  .MuiPickersZoomTransition-transitionContainer {
    height: 100%;
  }
`

export { ModalRoot, Title }

import Box from '@mui/material/Box'
import styled from 'styled-components'

export const FilterCategoryRoot = styled(Box)`
  padding: 0;
  padding-bottom: 12px;

  > h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .MuiIconButton-root {
      color: ${({ theme }) => theme.palette.text.secondary};
    }
  }
`

import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'

import { useI18n } from '../../../context'
import { userSelector } from '../../../redux'
import { MenuHeaderContainer } from './MobileMenu.style'

const MenuHeader = () => {
  const user = useSelector(userSelector)
  const { t } = useI18n()

  return (
    <Link to={user ? '/account' : '/login'}>
      <MenuHeaderContainer>
        <Avatar alt={user?.company_name} src={user?.companyLogo} />
        {user && <h6>{` ${user.first_name} ${user.last_name}`}</h6>}
        {!user && <h6>Popety.io</h6>}
        <span>{user?.email || t('common.logIn')}</span>
      </MenuHeaderContainer>
    </Link>
  )
}

export default MenuHeader

import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  userSelector,
  authRolesSelector,
  changeApp,
  appSelector,
} from '../../redux/auth'

// export type PrivateRouteProps = RouteProps & { component: any, admin?: boolean }

/*
 * Add 'exact' to use this component
 */
const PrivateRoute = ({ children, admin = false }) => {
  // For protected route if no jwt token, redirect to login page
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const app = useSelector(appSelector)
  const roles = useSelector(authRolesSelector)

  const location = useLocation()

  useEffect(() => {
    if (!roles?.includes('User_landdev') && app === 'landdev') {
      dispatch(changeApp('brokerinfo'))
    }
  }, [])

  const state = { from: location.pathname }
  const login = localStorage.jwt

  if (!login) {
    return <Navigate to="/login" state={state} replace />
  }

  if (!admin) return children

  if (admin && user?.roles?.includes('Admin_company')) return children

  return <Navigate to="/" state={state} replace />
}

export default PrivateRoute

import Box from '@mui/material/Box'
import styled from 'styled-components'

export const TableRoot = styled(Box)<{ $loading?: boolean }>`
  width: 100%;
  position: relative;

  .MuiTable-root {
    min-width: 800px;
  }

  .MuiTableCell-root {
    padding: 4px;
    font-size: 13px;
  }

  .MuiTableSortLabel-icon {
    opacity: 0.3;
  }

  .MuiTableSortLabel-root.Mui-active {
    svg {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`

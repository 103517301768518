import React from 'react'
import { useLocation } from 'react-router-dom'
import DehazeIcon from '@mui/icons-material/Dehaze'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { useI18n } from '../../context'
import SearchField from '../SearchField'
import { SearchFieldProps } from '../SearchField/SearchField'
import Button from '../Button'
import getHistoryQuery from './HistorySearchBox.util'
import { ActionBox, HistorySearchBoxRoot } from './HistorySearchBox.style'

export type HistorySearchBoxProps = SearchFieldProps & {
  open?: boolean
  onOpen: () => void
  onCreateAlerte?: () => void
  children?: React.ReactNode
  actions?: React.ReactNode
}

const HistorySearchBox = (props: HistorySearchBoxProps) => {
  const {
    onOpen,
    className,
    actions,
    children,
    onCreateAlerte,
    open,
    ...other
  } = props

  const { pathname } = useLocation()
  const fao = pathname === '/fao-history'
  const transaction = pathname === '/transaction-history'
  const listing = pathname === '/listing-history'

  const search = getHistoryQuery({ fao, transaction, listing })

  const handleAlertCreate = () => {
    if (onCreateAlerte) return onCreateAlerte()

    window.open(`/my-alerts${search}`, '_blank')
  }

  const { t } = useI18n()

  return (
    <HistorySearchBoxRoot className={className}>
      {!open && onOpen && (
        <IconButton onClick={onOpen} edge="start" sx={{ mt: '6px' }}>
          <DehazeIcon />
        </IconButton>
      )}
      <div>
        {children || <SearchField {...other} />}
        <ActionBox className={open ? 'open' : ''}>
          <Tooltip title={t('common.alertText')}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAlertCreate}
            >
              {t('common.newAlert')}
            </Button>
          </Tooltip>
          {actions}
        </ActionBox>
      </div>
    </HistorySearchBoxRoot>
  )
}

export default HistorySearchBox

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Hidden from '@mui/material/Hidden'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import { APP_VERSION } from '../../../config'
import { useI18n } from '../../../context'
import {
  appSelector,
  changeApp,
  forgetAuthUser,
  forgetUser,
  userSelector,
  logout,
  getCurrentUserDetails,
  updateLightVersion,
  lightVersionSelector,
} from '../../../redux'
import LanguageMenu from './LanguageMenu'
import UserDetails from './UserDetails'
import { UserMenuRoot } from './UserMenu.style'

export type UserMenuProps = DrawerProps & {
  onClose: () => void
}

const UserMenu = ({ onClose, ...other }: UserMenuProps) => {
  const { t } = useI18n()
  const location = useLocation()

  const user = useSelector(userSelector)
  const app = useSelector(appSelector)
  const lightVersion = useSelector(lightVersionSelector)

  const hasLanddev = user?.roles?.includes('User_landdev')

  const dispatch = useDispatch<any>()

  const handleLogout = () => {
    dispatch(logout())
    dispatch(forgetUser())
    dispatch(forgetAuthUser())
    onClose()
  }

  const handleAppChange = () => {
    dispatch(changeApp(app === 'landdev' ? 'brokerinfo' : 'landdev'))
    onClose()
  }

  useEffect(() => {
    onClose()
  }, [location])

  useEffect(() => {
    if (other.open) {
      dispatch(getCurrentUserDetails())
    }
  }, [other.open])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked

    onClose()
    setTimeout(() => dispatch(updateLightVersion(value)))
  }

  if (!user) return null

  return (
    <Drawer {...other} anchor="right" onClose={onClose}>
      <UserMenuRoot>
        <MenuItem disabled>
          <UserDetails />
        </MenuItem>
        <Divider />
        <LanguageMenu />
        <Divider />
        <MenuItem component={NavLink} to="/account">
          {t('common.myAccount')}
        </MenuItem>
        {/* <MenuItem component={NavLink} to="/dashboard">
          {t('common.myDashboard')}
        </MenuItem>
        <MenuItem component={NavLink} to="/my-alerts">
          {t('common.myAlerts')}
        </MenuItem> */}
        {user.roles?.includes('Admin_company') && (
          <MenuItem component={NavLink} to="/company-dashboard">
            {t('common.myCompanyDashboard')}
          </MenuItem>
        )}
        <Hidden smDown>
          <Divider />
          {/* <MenuItem component={NavLink} to="/glossary">
          {t('common.glossary')}
        </MenuItem> */}
          <MenuItem component={NavLink} to="/help">
            {t('common.help')}
          </MenuItem>
          <MenuItem component={NavLink} to="/user-guide">
            {t('common.user-guide')}
          </MenuItem>
          <MenuItem component={NavLink} to="/sources">
            {t('common.sources')}
          </MenuItem>
          {hasLanddev && (
            <MenuItem onClick={handleAppChange}>
              {app === 'landdev' ? 'BrokerInfo' : 'LandDev'}
            </MenuItem>
          )}
        </Hidden>
        <Divider />
        <MenuItem>
          <FormControlLabel
            control={
              <Switch
                checked={lightVersion}
                color="secondary"
                onChange={handleChange}
              />
            }
            label={t(`common.Light Version`)}
          />
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('common.signOut')}</MenuItem>
        <Divider />
        <MenuItem component="p" className="version" color="textSecondary">
          {APP_VERSION}
        </MenuItem>
      </UserMenuRoot>
    </Drawer>
  )
}

export default UserMenu

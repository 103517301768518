import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Hidden from '@mui/material/Hidden'
import Avatar from '@mui/material/Avatar'

import { useI18n } from '../../context'
import { userSelector } from '../../redux'
import Button from '../Button'
import { UserAvatarContainer } from './Header.style'
import SupportModal from './SupportModal'
import UserMenu from './UserMenu'

const UserAvatar = () => {
  const { t } = useI18n()
  const [anchorEl, setAnchorEl] = useState(null)

  const user = useSelector(userSelector)

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <UserAvatarContainer>
      <SupportModal />

      {!user && (
        <Button component={NavLink} to="/login">
          {t('common.logIn')}
        </Button>
      )}

      <Hidden mdDown>
        {user && (
          <div className="User" onClick={handleOpen}>
            <Avatar alt={user.name} src={user?.photo || '/assets/avatar'} />

            <Hidden lgDown>
              <Button color="inherit">{user.name}</Button>
            </Hidden>
          </div>
        )}
      </Hidden>

      <UserMenu open={Boolean(anchorEl)} onClose={handleClose} />
    </UserAvatarContainer>
  )
}

export default UserAvatar

import React, { useState } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import RadioGroup from '@mui/material/RadioGroup'

import { useI18n } from '../../context'
import CheckboxFilterItem from '../CheckboxFilterItem'
import ViewMoreLess from '../ViewMoreLess'

export type CheckboxFilterProps = Omit<BoxProps, 'onChange'> & {
  items: { count?: number; label?: string; key: string; [k: string]: any }[]
  filters: string[]
  onChange: (filters: string[], items?: CheckboxFilterProps['items']) => void
  min?: number
  max?: number
  radio?: boolean
  switchItem?: boolean
  unstyle?: boolean
  defaultLabel?: string
}

const Container = ({ children, radio, ...other }: any) => {
  return !radio ? children : <RadioGroup {...other}>{children}</RadioGroup>
}

const CheckboxFilter = (props: CheckboxFilterProps) => {
  const {
    defaultLabel,
    items,
    onChange,
    filters = [],
    unstyle,
    min,
    max,
    radio,
    switchItem,
    color,
    ...other
  } = props

  const { t } = useI18n()

  const [more, setMore] = useState(
    max && min ? items.length < (min || 0) : false
  )

  const list = max && min ? items.slice(0, more ? max : min) : items

  const disableExpansion =
    max && min ? items.length <= list.length && items.length <= min : true

  const handleChange = (filterKey: string) => () => {
    if (radio) return

    if (filters.includes(filterKey)) {
      onChange([...filters.filter((value) => value !== filterKey)], items)
    } else {
      onChange([...filters, filterKey], items)
    }
  }

  const handleToggle = () => {
    setMore((value) => !value)
  }

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange([e.target.value], items)
  }

  return (
    <Box {...other}>
      <ViewMoreLess
        more={more}
        onToggle={handleToggle}
        disable={disableExpansion}
        moreText={t('common.seeMore')}
        lessText={t('common.seeLess')}
      >
        <Container
          radio={radio}
          aria-label="filter"
          value={filters?.[0] || ''}
          onChange={handleRadioChange}
        >
          {list.map((item) => (
            <CheckboxFilterItem
              {...item}
              checked={filters.includes(item.key)}
              key={item.key}
              label={item.label || item.key || defaultLabel || item.key}
              onChange={handleChange(item.key)}
              radio={radio}
              switchItem={switchItem}
              value={item.key}
              unstyle={unstyle}
              color={color as any}
            />
          ))}
        </Container>
      </ViewMoreLess>
    </Box>
  )
}

export default CheckboxFilter

import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar'

export const IOSInstallerSnackbar = styled(Snackbar)`
  .MuiAlert-root {
    background-color: #00ade6;
    color: #fff;
  }

  .MuiAlert-message svg {
    position: relative;
    top: 4px;
    margin: 0 3px;
  }
`

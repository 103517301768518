import Box from '@mui/material/Box'
import styled from 'styled-components'

export const RegionFilterItemRoot = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;

  * {
    line-height: inherit;
  }

  .RegionFilterItemCount {
    color: #ccc;
    font-size: 0.875rem;
    padding-left: 4px;
  }
`

export const RegionName = styled.div`
  flex-grow: 1;
  cursor: pointer;
  color: #8f9ba2;
  font-size: 0.875rem;

  &.selected {
    color: #000;
    font-weight: 700;
  }
`

import ApiClient from '../apiClient'

import { MAPBOX_TOKEN } from '../../config/constants'

const client = new ApiClient()

const fetchPoisAround = async (parcelleId: any) => {
  return client.get(`/parcelles/${parcelleId}/pois/2400`)
}

type IsoChroneOpts = {
  type: string
  minutes?: number | number[]
  polygons?: boolean
  lon: number
  lat: number
}

const fetchIsochroneLayer = async (opts: IsoChroneOpts) => {
  const { type, polygons = true, lon, lat, minutes } = opts

  const minutesArray = minutes && (Array.isArray(minutes) ? minutes : [minutes])

  const contoursMinutes =
    minutesArray || (type === 'walking' ? [2, 5, 15] : [5, 15, 30])

  try {
    const contours = encodeURIComponent(contoursMinutes.join(','))
    const pol = polygons ? 'true' : 'false'

    const res = await fetch(
      `https://api.mapbox.com/isochrone/v1/mapbox/${type}/${lon}%2C${lat}?contours_minutes=${contours}&polygons=${pol}&generalize=0&access_token=${MAPBOX_TOKEN}`,
      { method: 'GET' }
    )

    return res.json()
  } catch (e) {
    throw new Error(e)
  }
}

const getPoisScore = async (id: any) => {
  return client.get(`/parcelles/pois/score/${id}`)
}

const livabilityService = {
  fetchPoisAround,
  fetchIsochroneLayer,
  getPoisScore,
}

export default livabilityService

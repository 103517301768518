/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import historyService from '../../services/history'

const currentYear = new Date().getFullYear()

const initialState = {
  transactions: [],
  priceData: {},
  comparePriceData: {},
  compareFilter: {},
  locationsAggs: {},
  aggregations: {
    dates: {
      dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    areas: { lands: { areas: { min: 0, max: 9999999 } } },
    reasons: { reasons: { buckets: [] } },
    ownership_types: { ownership_types: { buckets: [] } },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    landUsePlans: { land_use_principal_type: { buckets: [] } },
  },
  total: 0,
}

export const getTransactions = createAsyncThunk(
  'transactionHistory/getTransactions',
  historyService.getTransactions
)

export const getTransactionStats = createAsyncThunk(
  'transactionHistory/getTransactionStats',
  historyService.getTransactionStats
)

export const getComparedTransactionStats = createAsyncThunk(
  'transactionHistory/getComparedTransactionStats',
  historyService.getTransactionStats
)

export const getTransactionsAggs = createAsyncThunk(
  'transactionHistory/getTransactionsAggs',
  historyService.getTransactionsAggs
)

const transactionHistory = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    updateCompareFilter: (state, action) => {
      const fields = Object.keys(action.payload)

      fields.forEach((key) => {
        state.compareFilter[key] = action.payload[key]
      })

      if (state.compareFilter?.landUsePlansFilter?.length > 1) {
        state.compareFilter.landUsePlansFilter.shift()
      }
      if (state.compareFilter?.cityFilter?.length > 1) {
        state.compareFilter.cityFilter.shift()
      }
      if (state.compareFilter?.zoneFilter?.length > 1) {
        state.compareFilter.zoneFilter.shift()
      }
      if (state.compareFilter?.regionFilter?.length > 1) {
        state.compareFilter.regionFilter.shift()
      }
    },
  },
  extraReducers: {
    [getTransactions.pending]: (state) => {
      state.loading = true
    },
    [getTransactions.rejected]: (state) => {
      state.loading = false
    },
    [getTransactions.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        state.transactions = data.result || [{ ...data.data, id: data.id }]

        state.aggregations = {
          ...initialState.aggregations,
          ...data.aggregations,
        }

        state.total = data.count || (data.total ?? 0)
      }
    },
    [getTransactionStats.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.priceData = data
    },
    [getComparedTransactionStats.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.comparePriceData = data
    },
    [getTransactionsAggs.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.locationsAggs = data.aggregations
    },
  },
})

export const { updateCompareFilter } = transactionHistory.actions

export default transactionHistory.reducer

import React from 'react'
import { useSelector } from 'react-redux'

import { FilterCategory, LocationsFilterInput } from '../../../components'
import { useI18n } from '../../../context'
import { locationsAggsSelector } from '../../../redux/transactionHistory'

const RegionFilter = ({
  regionFilter,
  zoneFilter,
  cityFilter,
  onChange,
  landUsePlansFilter,
  exclude = {},
  ...other
}) => {
  const { t } = useI18n()
  const aggregations = useSelector(locationsAggsSelector)

  const regions = aggregations.regions?.regions.buckets
  const zones = aggregations.zones?.zones.buckets
  const cities = aggregations.cities?.cities.buckets
  const landUsePlans =
    aggregations.landUsePlans?.land_use_principal_type.buckets

  return (
    <FilterCategory {...other} title={t('transaction.location')}>
      <LocationsFilterInput
        zoneFilter={zoneFilter}
        regionFilter={regionFilter}
        cityFilter={cityFilter}
        landUsePlansFilter={landUsePlansFilter}
        onChange={onChange}
        data={{ regions, zones, cities, landUsePlans }}
        placeholder={other.placeholder}
        exclude={exclude}
      />
    </FilterCategory>
  )
}

export default RegionFilter

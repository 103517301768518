import React, { MouseEvent, ReactElement } from 'react'
import { DialogProps } from '@mui/material/Dialog'
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import DialogActions, { DialogActionsProps } from '../DialogActions'
import { ModalRoot, Title } from './Modal.style'

export type ModalProps = Omit<DialogProps, 'onClose'> &
  Omit<DialogActionsProps, 'children'> & {
    title?: string
    /**
     * Remove DialogContent padding and margin
     */
    disableGutters?: boolean
    onClose?: () => void
    /**
     * DialogActions children
     */
    actions?: React.ReactNode
    leftIcon?: ReactElement
    showCloseIcon?: boolean
    /**
     * Display the top and bottom dividers.
     */
    dividers?: DialogContentProps['dividers']
  }

const Modal = (props: ModalProps) => {
  const {
    title,
    actions,
    okText,
    cancelText,
    children,
    onOk,
    okButtonProps,
    cancelButtonProps,
    onCancel,
    onClose = () => null,
    loading,
    leftIcon,
    dividers,
    showCloseIcon,
    ...other
  } = props

  const hasActions = okText || cancelText || actions

  const handleCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (onCancel) onCancel(e)
    onClose()
  }

  return (
    <ModalRoot onClose={onClose} {...other}>
      {title && (
        <Title id={other['aria-labelledby']}>
          <span className="Left">
            {leftIcon && leftIcon}
            {title}
          </span>
          {showCloseIcon && (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Title>
      )}
      <DialogContent dividers={dividers}>{children}</DialogContent>
      {hasActions && (
        <DialogActions
          onCancel={handleCancel}
          cancelText={cancelText}
          okText={okText}
          onOk={onOk}
          okButtonProps={okButtonProps}
          cancelButtonProps={cancelButtonProps}
          loading={loading}
        >
          {actions}
        </DialogActions>
      )}
    </ModalRoot>
  )
}

export default Modal

const scoreLegend = (t: Translator) => [
  { name: t('search.development.low'), color: '#ffbfbf' },
  { color: '#ffc3a5' },
  { color: '#ffce89' },
  { color: '#ffd87a' },
  { color: '#ffe26e', name: t('search.development.medium') },
  { color: '#dee674' },
  { color: '#a8dc92' },
  { color: '#7ec094', name: t('search.development.high') },
  { color: '#428054' },
  { color: '#00441b' },
  { name: t('search.development.exceptional'), color: '#000f05' },
]

export const developmentOptions = (t: Translator) => [
  {
    value: 'development_score',
    label: t('search.legend.development'),
    src: '/assets/img/score.png',
    legend: scoreLegend(t),
  },
  {
    value: 'under_exploited_score',
    label: t('search.development.underExploitedScoreMap'),
    src: '/assets/img/score.png',
    legend: scoreLegend(t),
  },
  {
    value: 'existing_score',
    label: t('search.development.legacyScoreMap'),
    src: '/assets/img/score.png',
    legend: scoreLegend(t),
  },
  {
    value: 'livability_score',
    label: t('search.development.livabilityScoreMap'),
    src: '/assets/img/score.png',
    legend: scoreLegend(t),
  },
  // {
  //   value: 'white',
  //   label: t('search.white'),
  //   descriptions: 'White map',
  //   src: '/assets/img/white.png',
  // },
]

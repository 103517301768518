/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

import {
  calculateUnderExploitedScore,
  calculateDevelopmentScore,
  calculateGeoScore,
  // calculateLegacyScore,
  // calculateBuildingsGroupedAnalytics,
} from './underExploited.utils'

import extraReducers from './extraReducers'

const initialState = {
  parcelDetail: null,
  parcelNumbers: [],
  transactions: [],
  faos: [],
  groupedLandZones: null,
  overidesValues: {},
  rdppfs: null,
  regionRdppf: [],
  regionRdppfLink: null,
  geoPortalLink: null,
}

const parcel = createSlice({
  name: 'parcel',
  initialState,
  reducers: {
    resetLand: () => initialState,
    recalculateScore: (state, action) => {
      const { zone, land, landUsePlanStatus } = action.payload

      const utilisation =
        zone?.typeZone?.ius || state.parcelDetail.secondaryIndice
      const occupation = zone?.typeZone?.ios || state.parcelDetail.primaryIndice
      const indiceUsed =
        state.parcelDetail?.indice_used_edited?.split('_')?.[0] ||
        state.parcelDetail?.indice_used?.split('_')?.[0]

      // If any value used for calculate the score is overrided
      // we recalculate the under exploited score and the development score
      const isOverrided =
        !!zone?.[`${utilisation}_overide`]?.value ||
        !!zone?.[`${occupation}_overide`]?.value ||
        !!land?.sbOverride ||
        !!land.sbdOverride ||
        !!land.sbpuOverride ||
        !!land.spdOverride ||
        !!land.spOverride ||
        state.parcelDetail.isIndicesOverrided

      if (isOverrided) {
        // console.log({ indiceUsed })
        // We get the authorized indices. The overrided value or the official value or the value estimated by us
        const authorizedValueOverrided = zone?.[`${indiceUsed}_overide`]?.value

        // We get the authorized indices. The overrided value or the official value or the value estimated by us
        // const authorizedOccupation = zone?.[`${occupation}_overide`]?.value

        // We check if a current value have been overrided
        const currentIndiceOverrided = land?.[`current_${indiceUsed}Override`]

        // Here we select the authorized value used to calculate the score
        const authorized = authorizedValueOverrided || zone?.[land.indice_used]

        // Here we select the current value used to calculate the score
        // It's sorted by priority
        const current =
          currentIndiceOverrided || state.parcelDetail?.[indiceUsed]

        const underExploitedScoreOveride = calculateUnderExploitedScore(
          land?.std,
          current,
          authorized
        )

        const developmentScoreOveride = calculateDevelopmentScore(
          underExploitedScoreOveride,
          land?.geo_shape_score,
          land?.existing_score,
          land?.livability_score
        )

        state.parcelDetail[landUsePlanStatus] = {
          underExploitedScoreOveride,
          developmentScoreOveride,
        }
      } else {
        state.parcelDetail[landUsePlanStatus] = {
          underExploitedScoreOveride: null,
          developmentScoreOveride: null,
        }
      }
    },
    changeGeoShapeScore: (state, action) => {
      const data = action.payload

      state.parcelDetail.geo_shape_score = calculateGeoScore(
        data?.area,
        data?.maxLength,
        data?.altitudeMin,
        data?.altitudeMax
      )
    },
    // overideBuildings: (state, action) => {
    //   const data = action.payload

    //   state.parcelDetail.buildings = state.parcelDetail?.buildings?.map(
    //     (building) => {
    //       if (building.id === data.id) {
    //         return data
    //       }

    //       return building
    //     }
    //   )

    //   const find = state.parcelDetail.buildings?.find(
    //     (building) => building.areaOveride
    //   )

    //   if (find) {
    //     state.parcelDetail.sbOverride = state.parcelDetail.buildings.reduce(
    //       (acc, building) => {
    //         return acc + (Number(building.areaOveride) || Number(building.area))
    //       },
    //       0
    //     )
    //   } else {
    //     state.parcelDetail.sbOverride = null
    //   }

    //   state.parcelDetail = {
    //     ...state.parcelDetail,
    //     ...calculateBuildingsGroupedAnalytics(state.parcelDetail.buildings),
    //   }

    //   state.parcelDetail.existing_score = calculateLegacyScore(
    //     state.parcelDetail.average_protected_rank,
    //     state.parcelDetail.average_condition_year,
    //     state.parcelDetail.official_area || state.parcelDetail.area,
    //     state.parcelDetail.current_ios,
    //     state.parcelDetail.current_ios
    //   )
    // },
  },
  extraReducers,
})

export const { recalculateScore, changeGeoShapeScore, resetLand } =
  parcel.actions

export default parcel.reducer

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button, Snackbar } from '../../../components'
import { useI18n } from '../../../context'
import {
  createAlert,
  selectedAlertSelector,
  updateAlert,
} from '../../../redux/alert'

const FinalStepActions = ({ onComplete }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const alert = useSelector(selectedAlertSelector)
  const [error, setError] = useState('')

  const handleClick = () => {
    if (!alert.name) {
      return setError(`Veuillez, s'il vous plait, nommer votre alerte`)
    }

    if (alert.multiPolygon.coordinates.length === 0) {
      return setError(
        `Veuillez, s'il vous plait, selectionner une zone pour votre alerte`
      )
    }

    const input = {
      ...alert,
      radius: alert.radius * 1000,
      geoCenter: alert.geoCenter
        ? { type: 'Point', coordinates: alert.geoCenter }
        : null,
    }

    dispatch(!alert.id ? createAlert(input) : updateAlert(input))
    onComplete?.()
  }

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={handleClick}
        size="small"
        fullWidth
      >
        {!alert.id ? t('myAlert.final.create') : t('myAlert.final.update')}
      </Button>
      <Snackbar open={!!error} error={!!error}>
        {error}
      </Snackbar>
    </>
  )
}

export default FinalStepActions

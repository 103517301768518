import styled from 'styled-components'

export const HistorySearchBoxRoot = styled.div`
  display: flex;
  align-items: flex-start;

  > .MuiIconButton-root {
    margin-right: 8px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-top: 8px;
  }
`

export const ActionBox = styled.div`
  display: flex;
  align-items: center;

  > .MuiButton-root {
    min-width: 177px;
  }

  @media (max-width: 560px) {
    &:not(.open) {
      margin-left: -44px;
      width: calc(100% + 44px);
    }

    > .MuiButton-root {
      flex-grow: 1;
      margin-top: 8px;
    }
  }
`

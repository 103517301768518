import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'

import { updateLanguage } from '../../../redux'
import { supportedLanguages, useI18n } from '../../../context'
import { LanguageButton, LanguageMenuRoot } from './UserMenu.style'

const languages = Object.values(supportedLanguages).filter((l) => !l.hide)

const LanguageMenu = () => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => setAnchorEl(null)

  const handleOpen = (e) => setAnchorEl(e.currentTarget)

  const { currentLanguage, setCurrentLanguage, t } = useI18n()

  const LangIcon = supportedLanguages[currentLanguage].icon

  const handleLangageChange = (language) => () => {
    setCurrentLanguage(language)
    dispatch(updateLanguage(language))
    handleClose()
  }

  return (
    <>
      <LanguageButton onClick={handleOpen} className="LanguageMenuButton">
        <ListItemIcon>
          <LangIcon />
        </ListItemIcon>
        <ListItemText
          primary={t(`common.${supportedLanguages[currentLanguage].id}`)}
        />
      </LanguageButton>
      <LanguageMenuRoot
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map(({ id, icon: Icon }) => (
          <MenuItem key={id} onClick={handleLangageChange(id)}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={t(`common.${id}`)} />
          </MenuItem>
        ))}
      </LanguageMenuRoot>
    </>
  )
}

export default LanguageMenu

import { createAsyncThunk } from '@reduxjs/toolkit'

import parcelService from '../../services/parcel'
import landMapDetailsService from '../../services/landMapDetails'

export const getParcel = createAsyncThunk(
  'parcel/getParcel',
  parcelService.getParcel
)

export const getLandRdppfs = createAsyncThunk(
  'parcel/getLandRdppfs',
  parcelService.getLandRdppfs
)
export const getRegionRdppfs = createAsyncThunk(
  'parcel/getRegionRdppfs',
  parcelService.getRegionRdppfs
)

export const getParcelNumbers = createAsyncThunk(
  'parcel/getParcelNumbers',
  parcelService.getParcelNumbers
)

export const getParcelTransactions = createAsyncThunk(
  'parcel/getParcelTransactions',
  parcelService.getParcelTransactions
)

export const getParcelFao = createAsyncThunk(
  'parcel/getParcelFao',
  parcelService.getParcelFao
)

export const getGroupedLandZones = createAsyncThunk(
  'parcel/getGroupedLandZones',
  parcelService.getGroupedLandZones
)

export const getAuthorizedDistanceLimit = createAsyncThunk(
  'parcel/authorizedDistanceLimit',
  parcelService.getAuthorizedDistanceLimit
)

export const addOverideValues = createAsyncThunk(
  'underExploited/addOverideValues',
  parcelService.addOverideValuesAssignment
)

export const addOverideGroupingValuesAssignment = createAsyncThunk(
  'underExploited/addOverideGroupingValuesAssignment',
  parcelService.addOverideGroupingValuesAssignment
)

export const addFutureOverideValues = createAsyncThunk(
  'underExploited/addFutureOverideValues',
  parcelService.addFutureOverideValues
)

export const addOverideFutureGroupingValuesAssignment = createAsyncThunk(
  'underExploited/addOverideFutureGroupingValuesAssignment',
  parcelService.addOverideFutureGroupingValuesAssignment
)

export const getUserOverideValuesById = createAsyncThunk(
  'underExploited/getUserOverideValuesById',
  parcelService.getUserOverideValuesById
)

export const getUserGroupingOverideValuesById = createAsyncThunk(
  'underExploited/getUserGroupingOverideValuesById',
  parcelService.getUserGroupingOverideValuesById
)

export const getUserFutureGroupingOverideValuesById = createAsyncThunk(
  'underExploited/getUserFutureGroupingOverideValuesById',
  parcelService.getUserFutureGroupingOverideValuesById
)

export const getUserFutureOverideValuesById = createAsyncThunk(
  'underExploited/getUserFutureOverideValuesById',
  parcelService.getUserFutureOverideValuesById
)

export const updateOverideValues = createAsyncThunk(
  'underExploited/updateOverideValues',
  parcelService.updateOverideValues
)

export const updateOverideGroupingValuesAssignment = createAsyncThunk(
  'underExploited/updateOverideGroupingValuesAssignment',
  parcelService.updateOverideGroupingValuesAssignment
)

export const updateFutureOverideValues = createAsyncThunk(
  'underExploited/updateFutureOverideValues',
  parcelService.updateFutureOverideValues
)

export const updateOverideFutureGroupingValuesAssignment = createAsyncThunk(
  'underExploited/updateOverideFutureGroupingValuesAssignment',
  parcelService.updateOverideFutureGroupingValuesAssignment
)

export const getOverridedBuildings = createAsyncThunk(
  'getOverridedBuildings',
  parcelService.getOverridedBuildings
)

export const addOverridedBuilding = createAsyncThunk(
  'addOverridedBuilding',
  parcelService.addOverridedBuilding
)

export const updateOverridedBuilding = createAsyncThunk(
  'updateOverridedBuilding',
  parcelService.updateOverridedBuilding
)

export const getBuildingsDetails = createAsyncThunk(
  'getBuildingsDetails',
  parcelService.getBuildingsDetails
)

export const getLupDetails = createAsyncThunk(
  'underExploited/getLupDetails',
  parcelService.getLupDetails
)

export const getLandDetails = createAsyncThunk(
  'parcel/getLandDetails',
  parcelService.getLandDetails
)

export const getAssignementsByLupId = createAsyncThunk(
  'parcel/getAssignementsByLupId',
  parcelService.getAssignementsByLupId
)

export const getExternalLinks = createAsyncThunk(
  'parcel/getExternalLinks',
  landMapDetailsService.getExternalLinks
)

export const getIndicesByRegion = createAsyncThunk(
  'parcel/getIndicesByRegion',
  landMapDetailsService.getIndicesByRegion
)

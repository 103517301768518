import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../../context'
import { listingAggregationsSelector } from '../../../redux/listingHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'

const DealTypeFilter = ({ onChange, dealTypeFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(listingAggregationsSelector)

  const items = aggregations.deal_type?.deal_type.buckets.map((r) => ({
    ...r,
    count: r.doc_count,
    label: t(`listing.dealType.${r.key}`),
  }))

  const handleChange = (newValues) => {
    onChange({ dealTypeFilter: newValues })
  }

  if (!items?.length) return null

  return (
    <FilterCategory {...other} title={t('listing.deal_type')}>
      <CheckboxFilter
        filters={dealTypeFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default DealTypeFilter

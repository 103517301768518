/* eslint-disable max-lines */

const currentYear = new Date().getFullYear()

export const buildingMapLayout = ({
  buildingMapDisplay,
  map,
  buildingLayer = 'building_layer',
}) => {
  const layer = buildingMapDisplay || 'noise_exposure'

  const data = {
    noise_exposure: [
      'match',
      ['get', layer],
      'up to 45 db',
      '#91cf60',
      '45 to 52 db',
      '#ffffbf',
      'more than 52 db',
      '#fc8d59',
      /* other */ '#fff',
    ],
    lake_view: [
      'match',
      ['get', layer],
      '0 ha',
      '#fc8d59',
      'more than 0 up to 100ha',
      '#ffffbf',
      'more than 100ha',
      '#91cf60',
      /* other */ '#fff',
    ],
    travel_time_to_centers: [
      'match',
      ['get', layer],
      'short',
      '#91cf60',
      'medium',
      '#ffffbf',
      'long',
      '#fc8d59',
      /* other */ '#fff',
    ],
    public_transport_quality: [
      'match',
      ['get', layer],
      'very well served',
      '#1a9641',
      'well served',
      '#a6d96a',
      'medium served',
      '#ffffbf',
      'badly served',
      '#fdae61',
      'very badly served',
      '#d7191c',
      /* other */ '#fff',
    ],
    mountain_view: [
      'match',
      ['get', layer],
      '0 up to 4 peaks',
      '#fc8d59',
      '5 up to 13 peaks',
      '#ffffbf',
      'more than 13 peaks',
      '#91cf60',
      /* other */ '#fff',
    ],
    building_period: [
      'match',
      ['get', layer],
      '0-1919',
      '#67001f',
      '1919-1945',
      '#b2182b',
      '1946-1960',
      '#d6604d',
      '1961-1970',
      '#f4a582',
      '1971-1980',
      '#fddbc7',
      '1981-1985',
      '#d1e5f0',
      '1986-1990',
      '#d1e5f0',
      '1991-1995',
      '#92c5de',
      '1996-2000',
      '#92c5de',
      '2001-2005',
      '#4393c3',
      '2006-2010',
      '#4393c3',
      '2011-2015',
      '#2166ac',
      '2015-x',
      '#053061',
      /* other */ '#fff',
    ],
    renovation_period: [
      'match',
      ['get', layer],
      '0-1919',
      '#67001f',
      '1919-1945',
      '#b2182b',
      '1946-1960',
      '#d6604d',
      '1961-1970',
      '#f4a582',
      '1971-1980',
      '#fddbc7',
      '1981-1985',
      '#d1e5f0',
      '1986-1990',
      '#d1e5f0',
      '1991-1995',
      '#92c5de',
      '1996-2000',
      '#92c5de',
      '2001-2005',
      '#4393c3',
      '2006-2010',
      '#4393c3',
      '2011-2015',
      '#2166ac',
      '2015-x',
      '#053061',
      /* other */ '#fff',
    ],
    listing_rent_last_year: {
      property: layer,
      default: '#f0f0f0',
      stops: [
        [0, '#ffffe5'],
        [currentYear - 9, '#ffffe5'],
        [currentYear - 8, '#e6ebd3'],
        [currentYear - 7, '#cdd7c0'],
        [currentYear - 6, '#b4c4ae'],
        [currentYear - 5, '#9bb09b'],
        [currentYear - 4, '#829c89'],
        [currentYear - 3, '#698877'],
        [currentYear - 2, '#507464'],
        [currentYear - 1, '#376152'],
        [currentYear, '#1e4d3f'],
      ],
    },
    lands_building_permit_last_year: {
      property: layer,
      default: '#f0f0f0',
      stops: [
        [0, '#ffffe5'],
        [currentYear - 17, '#ffffe5'],
        [currentYear - 16, '#f0f3da'],
        [currentYear - 15, '#e2e8cf'],
        [currentYear - 14, '#d3dcc5'],
        [currentYear - 13, '#c4d0ba'],
        [currentYear - 12, '#b5c5af'],
        [currentYear - 11, '#a7b9a4'],
        [currentYear - 10, '#98ad99'],
        [currentYear - 9, '#89a28e'],
        [currentYear - 8, '#7b9684'],
        [currentYear - 7, '#6c8b79'],
        [currentYear - 6, '#5d7f6e'],
        [currentYear - 5, '#4f7363'],
        [currentYear - 4, '#406858'],
        [currentYear - 3, '#315c4d'],
        [currentYear - 2, '#225043'],
        [currentYear - 1, '#144538'],
        [currentYear, '#05392d'],
      ],
    },
    lands_transaction_last_year: {
      property: layer,
      default: '#f0f0f0',
      stops: [
        [0, '#ffffe5'],
        [currentYear - 17, '#ffffe5'],
        [currentYear - 16, '#f0f3da'],
        [currentYear - 15, '#e2e8cf'],
        [currentYear - 14, '#d3dcc5'],
        [currentYear - 13, '#c4d0ba'],
        [currentYear - 12, '#b5c5af'],
        [currentYear - 11, '#a7b9a4'],
        [currentYear - 10, '#98ad99'],
        [currentYear - 9, '#89a28e'],
        [currentYear - 8, '#7b9684'],
        [currentYear - 7, '#6c8b79'],
        [currentYear - 6, '#5d7f6e'],
        [currentYear - 5, '#4f7363'],
        [currentYear - 4, '#406858'],
        [currentYear - 3, '#315c4d'],
        [currentYear - 2, '#225043'],
        [currentYear - 1, '#144538'],
        [currentYear, '#05392d'],
      ],
    },
    protected_rank: {
      property: layer,
      stops: [
        [0, '#ffffff'],
        [1, '#74c476'],
        [2, '#fcae91'],
        [3, '#fb6a4a'],
        [4, '#de2d26'],
      ],
    },
    dwellings_nb: {
      property: layer,
      stops: [
        [0, '#f0f9e8'],
        [3, '#bae4bc'],
        [7, '#7bccc4'],
        [15, '#43a2ca'],
        [45, '#0868ac'],
      ],
    },
    floor_nb: {
      property: layer,
      stops: [
        [0, '#f0f9e8'],
        [3, '#bae4bc'],
        [6, '#7bccc4'],
        [12, '#43a2ca'],
        [30, '#0868ac'],
      ],
    },
    popety_classification: {
      property: layer,
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Retail', '#D3B39C'],
        ['Residential', '#F9D518'],
        ['Public', '#8C8C8C'],
        ['Other', '#CCCCCC'],
        ['Offices', '#9ecae1'],
        ['Not classified', '#CCCCCC'],
        ['Mixed_2', '#F3733C'],
        ['Mixed_1', '#F3A83C'],
        ['Industrial', '#C39EF9'],
        ['Garage', '#bbbbbb'],
      ],
    },
    category: {
      property: layer,
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Autres bâtiments d’hébergement de tourisme', '#D3B39C'],
        ["Autre bâtiment d'hébergement de tourisme", '#D3B39C'],
        ['Autre bâtiment non classé ailleurs', '#8C8C8C'],
        ['Autres bâtiments d’exploitation agricole', '#0DFA54'],
        ["Autres bâtiments d'exploitation agricole", '#0DFA54'],
        ['Autres bâtiments non classés ailleurs', '#8C8C8C'],
        ["Autres bâtiments pour l'hébergement collectif", '#603215'],
        ['Autres bâtiments pour l’hébergement collectif', '#603215'],
        ['Bâtiment à usage culturel ou récréatif', '#CCCCCC'],
        ['Bâtiment commercial', '#F3733C'],
        [
          'Bâtiment des transports et des communications sans garage',
          '#F3A83C',
        ],
        ["Bâtiment d'exploitation agricole", '#b0f99e'],
        ['Bâtiment d’exploitation agricole', '#b0f99e'],
        ["Bâtiment pour l'enseignement et la recherche", '#F9D518'],
        ['Bâtiments à usage récréatif ou culturel', '#CCCCCC'],
        ['Bâtiments commerciaux', '#F3733C'],
        ['Bâtiment industriel', '#8D12DE'],
        ['Bâtiments industriels', '#8D12DE'],
        ["Bâtiments pour la garde d'animaux", '#14CBFF'],
        ['Bâtiments pour la garde d’animaux', '#14CBFF'],
        ['Bâtiments pour l’enseignement et la recherche', '#F9D518'],
        ['Bâtiments pour les cultures végétales', '#9EFFA9'],
        ['Édifice cultuel et religieux', '#A4E0CF'],
        ['Édifices cultuels et religieux', '#A4E0CF'],
        ['Garage', '#A5DFF5'],
        ['Garages', '#A5DFF5'],
        ['Gares, aérogares, centraux téléphoniques', '#8A97DE'],
        ['Habitat communautaire', '#E0B805'],
        ['Hôpital et établissement de santé', '#DE958A'],
        ['Hôpitaux et établissements de santé', '#DE958A'],
        ['Hôtel', '#F8F7D7'],
        ['Hôtels', '#F8F7D7'],
        ['Immeubles à trois logements et plus', '#FA9114'],
        ['Immeuble à trois logements ou plus', '#FA9114'],
        ['Immeuble de bureaux', '#DE6312'],
        ['Immeubles de bureaux', '#DE6312'],
        ['Maison à deux logements', '#F55520'],
        ['Maisons à deux logements', '#F55520'],
        ['Maison individuelle', '#DE2712'],
        ['Maisons individuelles', '#DE2712'],
        ['Monument historique ou classé', '#FA1435'],
        ['Musée et bibliothèque', '#FFE49E'],
        ['Musées et bibliothèques', '#FFE49E'],
        ['Réservoir, silo et entrepôt', '#C2826E'],
        ['Réservoirs, silos et entrepôts', '#C2826E'],
        [
          'Restaurants et bars dans des bâtiments sans usage résidentiel',
          '#CDDE8A',
        ],
        ['Salle de sport', '#F69E67'],
        ['Salles de sport', '#F69E67'],
        ['Unknown', '#ff0000'],
      ],
    },
    type: {
      property: layer,
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Bâtiment d’habitation avec usage annexe', '#D3B39C'],
        ['Bâtiment exclusivement à usage d’habitation', '#F9D518'],
        ['Bâtiment partiellement à usage d’habitation', '#8C8C8C'],
        ['Bâtiment sans usage d’habitation', '#9ecae1'],
        ['Construction particulière', '#603215'],
        ['Habitation provisoire', '#CCCCCC'],
        ['Unknown', '#F3733C'],
      ],
    },
    solar_average_exposure: {
      property: layer,
      default: '#fff',
      stops: [
        [0, '#9ecae1'],
        [400, '#4292c6'],
        [800, '#ffffb2'],
        [1000, '#fecc5c'],
        [1200, '#fd8d3c'],
        [1400, '#e31a1c'],
      ],
    },
    // building_year: {
    //   property: layer,
    //   default: '#f0f0f0',
    //   stops: [
    //     [0, '#c22e00'],
    //     [1940, '#c22e00'],
    //     [1950, '#d0532b'],
    //     [1960, '#dd7755'],
    //     [1970, '#eb9c80'],
    //     [1975, '#eb9c80'],
    //     [1980, '#f8c0aa'],
    //     [1990, '#bae1e2'],
    //     [2000, '#8cced1'],
    //     [2010, '#5dbabf'],
    //     [2016, '#2fa7ae'],
    //     [currentYear, '#00939c'],
    //   ],
    // },
    // {
    // property: layer,
    // property: layer,
    // default: '#f0f0f0',
    // stops: [
    //   [0, '#c22e00'],
    //   [1940, '#c22e00'],
    //   [1950, '#d0532b'],
    //   [1960, '#dd7755'],
    //   [1970, '#eb9c80'],
    //   [1980, '#f8c0aa'],
    //   [1990, '#bae1e2'],
    //   [2000, '#8cced1'],
    //   [2010, '#5dbabf'],
    //   [2016, '#2fa7ae'],
    //   [currentYear, '#00939c'],

    // },
    unit_nb: {
      property: layer,
      default: '#f0f0f0',
      stops: [
        [1, '#fff7fb'],
        [2, '#ece7f2'],
        [3, '#d0d1e6'],
        [4, '#a6bddb'],
        [5, '#74a9cf'],
        [8, '#3690c0'],
        [11, '#0570b0'],
        [15, '#045a8d'],
        [20, '#023858'],
        [1000, '#082649'],
      ],
    },
    power_source_water_heater: {
      property: layer,
      default: '#fff',
      type: 'categorical',
      stops: [
        ['Mazout', '#000000'],
        ['Gaz', '#1f78b4'],
        ['Indéterminé', '#ccc'],
        ['Bois (générique)', '#b15928'],
        ['Aucune', '#b2df8a'],
        ['Electricité', '#a6cee3'],
        ['Chaleur produite à distance (générique)', '#e31a1c'],
        ['Air', '#fdbf6f'],
        ['Sonde géothermique', '#ff7f00'],
        ['Soleil (thermique)', '#33a02c'],
      ],
    },
    power_source_heater: [
      'match',
      ['get', layer],
      'Mazout',
      '#000000',
      'Gaz',
      '#1f78b4',
      'Indéterminé',
      '#ccc',
      'Bois (générique)',
      '#b15928',
      'Aucune',
      '#b2df8a',
      'Electricité',
      '#a6cee3',
      'Chaleur produite à distance (générique)',
      '#e31a1c',
      'Air',
      '#fdbf6f',
      'Sonde géothermique',
      '#ff7f00',
      'Soleil (thermique)',
      '#33a02c',
      /* other */ '#fff',
    ],
  }

  const matchExpression = data[layer]

  // console.log({ matchExpression, layer })

  // Change the color
  map.setPaintProperty(buildingLayer, 'fill-extrusion-color', matchExpression)
}

import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../../context'
import { listingAggregationsSelector } from '../../../redux/listingHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'

const AdStatusFilter = ({ onChange, adStatusFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(listingAggregationsSelector)

  const items = aggregations.ad_status?.ad_status.buckets.map((r) => ({
    ...r,
    count: r.doc_count,
    label: t(`listing.status.${r.key === 0 ? 'expired' : 'active'}`),
  }))

  const handleChange = (newValues) => {
    onChange({ adStatusFilter: newValues })
  }

  if (!items?.length) return null

  return (
    <FilterCategory {...other} title={t('listing.status')} beta>
      <CheckboxFilter
        filters={adStatusFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default AdStatusFilter

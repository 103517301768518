import Tooltip from '@mui/material/Tooltip'
import RoomIcon from '@mui/icons-material/Room'
import React from 'react'
import { useI18n } from '../../../context'
import { AlertAddressRoot, DisplayText } from './AlertCard.style'

const AlertAddress = ({ alert }) => {
  const { t } = useI18n()

  if (!alert.address && !alert.data?.[0]?.value) return null

  let title

  if (alert.address) {
    title = `${alert.address} - ${alert.radius}m`
  } else {
    title = `${alert.data.map((d) => d.value).join('/')}${`${
      alert.principalType.length > 0
        ? ` - ${alert.principalType.map((type) => type.value).join('/')}`
        : ''
    }`}`
  }

  return (
    <AlertAddressRoot className="AlertAddress">
      <Tooltip
        placement="top"
        title={
          <h5>
            {t('myAlert.places')}
            {alert.radius ? ` - Radius` : null}
          </h5>
        }
      >
        <RoomIcon className="icon" fontSize="small" />
      </Tooltip>
      <DisplayText title={title}>
        {alert.address
          ? `${alert.address} - ${alert.radius} m`
          : `${alert.data.map((d) => d.value).join('/')}${`${
              alert.principalType.length > 0
                ? ` - ${alert.principalType
                    .map((type) => type.value)
                    .join('/')}`
                : ''
            }`}`}
      </DisplayText>
    </AlertAddressRoot>
  )
}

export default AlertAddress

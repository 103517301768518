const onClickFeature = (state) => state.landMapDetails.onClickFeature

const firstTransactionSelector = (state) =>
  state.landMapDetails.firstTransaction

const firstFaoSelector = (state) => state.landMapDetails.firstFao

const isOwnerSelector = (state) => state.landMapDetails.isOwner

const regionRdppfLinkSelector = (state) => state.landMapDetails.regionRdppfLink

const regionGeoPortalLinkSelector = (state) =>
  state.landMapDetails.geoPortalLink

export {
  onClickFeature,
  firstTransactionSelector,
  firstFaoSelector,
  isOwnerSelector,
  regionRdppfLinkSelector,
  regionGeoPortalLinkSelector,
}

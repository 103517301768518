const popetyIoClassLegend = (t: Translator) =>
  [
    { value: 'Retail', color: '#D3B39C' },
    { value: 'Residential', color: '#F9D518' },
    { value: 'Public', color: '#8C8C8C' },
    {
      value: 'Other',
      color: '#CCCCCC',
    },
    { value: 'Offices', color: '#9ecae1' },
    // { value: 'Zentrumszonen', color: '#603215' },
    { value: 'Not classified', color: '#CCCCCC' },
    { value: 'Mixed_2', color: '#F3733C' },
    { value: 'Mixed_1', color: '#F3A83C' },
    { value: 'Industrial', color: '#C39EF9' },
    { value: 'Garage', color: '#bbbbbb' },
  ].map((l) => ({
    ...l,
    name: t(`classification.${l.value}` as I18nKey),
  }))

const categoryLegend = (t: Translator) =>
  [
    { value: "Autre bâtiment d'hébergement de tourisme", color: '#D3B39C' },
    { value: "Autres bâtiments d'exploitation agricole", color: '#0DFA54' },
    { value: 'Autres bâtiments non classés ailleurs', color: '#8C8C8C' },
    {
      value: "Autres bâtiments pour l'hébergement collectif",
      color: '#603215',
    },
    { value: 'Bâtiment à usage culturel ou récréatif', color: '#CCCCCC' },
    { value: 'Bâtiment commercial', color: '#F3733C' },
    {
      value: 'Bâtiment des transports et des communications sans garage',
      color: '#F3A83C',
    },
    { value: "Bâtiment d'exploitation agricole", color: '#b0f99e' },
    { value: "Bâtiment pour l'enseignement et la recherche", color: '#F9D518' },
    { value: 'Bâtiments industriels', color: '#8D12DE' },
    { value: "Bâtiments pour la garde d'animaux", color: '#14CBFF' },
    { value: 'Bâtiments pour les cultures végétales', color: '#9EFFA9' },
    { value: 'Édifices cultuels et religieux', color: '#A4E0CF' },
    { value: 'Garages', color: '#A5DFF5' },
    { value: 'Gares, aérogares, centraux téléphoniques', color: '#8A97DE' },
    { value: 'Habitat communautaire', color: '#E0B805' },
    { value: 'Hôpitaux et établissements de santé', color: '#DE958A' },
    { value: 'Hôtels', color: '#F8F7D7' },
    { value: 'Immeubles à trois logements et plus', color: '#FA9114' },
    { value: 'Immeubles de bureaux', color: '#DE6312' },
    { value: 'Maisons à deux logements', color: '#F55520' },
    { value: 'Maisons individuelles', color: '#DE2712' },
    { value: 'Monument historique ou classé', color: '#FA1435' },
    { value: 'Musées et bibliothèques', color: '#FFE49E' },
    { value: 'Réservoirs, silos et entrepôts', color: '#C2826E' },
    {
      value: 'Restaurants et bars dans des bâtiments sans usage résidentiel',
      color: '#CDDE8A',
    },
    { value: 'Salles de sport', color: '#F69E67' },
    { value: 'Unknown', color: '#ff0000' },
  ].map((l) => ({ ...l, name: t(`search.category.${l.value}` as I18nKey) }))

const typeLegend = (t: Translator) =>
  [
    { value: 'Bâtiment d’habitation avec usage annexe', color: '#D3B39C' },
    { value: 'Bâtiment exclusivement à usage d’habitation', color: '#F9D518' },
    { value: 'Bâtiment partiellement à usage d’habitation', color: '#8C8C8C' },
    { value: 'Bâtiment sans usage d’habitation', color: '#9ecae1' },
    {
      value: 'Construction particulière',
      color: '#603215',
    },
    { value: 'Habitation provisoire', color: '#CCCCCC' },
    { value: 'Unknown', color: '#F3733C' },
  ].map((l) => ({ ...l, name: t(`search.type.${l.value}` as I18nKey) }))

export const classificationOptions = (t: Translator) => {
  return [
    {
      value: 'popety_classification',
      label: t('search.legend.popety_classification'),
      src: '/assets/img/buildingLayers/classPopety.png',
      legend: popetyIoClassLegend(t),
      group: 'classification',
    },
    {
      value: 'category',
      label: t('search.legend.category'),
      src: '/assets/img/buildingLayers/catOfs.png',
      legend: categoryLegend(t),
      group: 'classification',
    },
    {
      value: 'type',
      label: t('search.legend.type'),
      src: '/assets/img/buildingLayers/catOfs.png',
      legend: typeLegend(t),
      group: 'classification',
    },
  ]
}

import React, { useCallback, useEffect, useState } from 'react'
import { ContainerProps } from '@mui/material/Container'

import { useLocations } from '../../pages/MyAlerts'
import { useMediaQuery } from '../../hooks'
import ProgressIndicator from '../ProgressIndicator'
import { HistoryLayoutRoot, Content } from './HistoryLayout.style'

type Filters = Record<string, any>

type Component<T = any> = React.ComponentType<
  T &
    Pick<HistoryLayoutProps, 'filters' | 'onChange'> & {
      open?: boolean
    }
>

type SidePaneType = Component<{ onClose?: () => void }>

type HeaderType = Component<{
  onClose?: () => void
  onSearch: () => void
  onOpen: () => void
  loading?: boolean
}>

export type HistoryLayoutProps = ContainerProps & {
  filters: Filters
  onSearch: () => void
  onChange: (filters: Filters, apply: false) => void
  SidePane: SidePaneType
  Header: HeaderType
  loading?: boolean
}

const HistoryLayout = (props: HistoryLayoutProps) => {
  const {
    filters,
    onSearch,
    onChange,
    SidePane,
    Header,
    children,
    loading,
    ...other
  } = props

  useLocations()

  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const [open, setOpen] = useState(onDesktop)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  useEffect(() => {
    setOpen(onDesktop)
  }, [onDesktop])

  useEffect(() => {
    setTimeout(() =>
      sessionStorage.setItem('history-query', JSON.stringify(filters))
    )
  }, [filters])

  return (
    <HistoryLayoutRoot maxWidth="xl" {...other} $loading={loading}>
      <SidePane
        filters={filters}
        onChange={onChange}
        open={open}
        onClose={handleClose}
      />

      <Content className={open ? 'contentShift' : ''}>
        {loading && <ProgressIndicator anchor="window" circular />}
        <Header
          open={open}
          onOpen={handleOpen}
          filters={filters}
          onChange={onChange}
          onSearch={onSearch}
          loading={loading}
        />
        {children}
      </Content>
    </HistoryLayoutRoot>
  )
}

export default HistoryLayout

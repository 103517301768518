/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import historyService from '../../services/history'
import parcelService from '../../services/parcel'

const currentYear = new Date().getFullYear()

const initialState = {
  priceData: {},
  comparePriceData: {},
  compareFilter: {},
  faos: [],
  locationsAggs: {},
  aggregations: {
    inquiry_start_dates: {
      inquiry_start_dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    results: { results: { buckets: [] } },
    work_types: { work_types: { buckets: [] } },
    type_names: { type_names: { buckets: [] } },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
    landUsePlans: { land_use_principal_type: { buckets: [] } },
  },
  total: 0,
  landInfoById: {},
}

export const getFaos = createAsyncThunk(
  'faoHistory/getFaos',
  historyService.getFaos
)

export const getLandEssentialInfo = createAsyncThunk(
  'faoHistory/getLandEssentialInfo',
  parcelService.getLandEssentialInfo
)

export const getFaoStats = createAsyncThunk(
  'faoHistory/getFaoStats',
  historyService.getFaoStats
)

export const getComparedFaoStats = createAsyncThunk(
  'faoHistory/getComparedFaoStats',
  historyService.getFaoStats
)

export const getFaosAggs = createAsyncThunk(
  'faoHistory/getFaoAggs',
  historyService.getFaoAggs
)

const faoHistory = createSlice({
  name: 'faoHistory',
  initialState,
  reducers: {
    updateCompareFilter: (state, action) => {
      const fields = Object.keys(action.payload)

      fields.forEach((key) => {
        state.compareFilter[key] = action.payload[key]
      })

      if (state.compareFilter?.landUsePlansFilter?.length > 1) {
        state.compareFilter.landUsePlansFilter.shift()
      }
      if (state.compareFilter?.cityFilter?.length > 1) {
        state.compareFilter.cityFilter.shift()
      }
      if (state.compareFilter?.zoneFilter?.length > 1) {
        state.compareFilter.zoneFilter.shift()
      }
      if (state.compareFilter?.regionFilter?.length > 1) {
        state.compareFilter.regionFilter.shift()
      }
    },
    changeDev: (state, action) => {
      state.dev = action.payload
    },
  },
  extraReducers: {
    [getFaos.pending]: (state) => {
      state.loading = true
    },
    [getFaos.rejected]: (state) => {
      state.loading = false
    },
    [getFaos.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        state.faos = data.result || [{ ...data.data, id: data.id }]

        state.aggregations = {
          ...initialState.aggregations,
          ...data.aggregations,
        }

        state.total = data.count || (data.total ?? 0)
      }
    },
    [getLandEssentialInfo.fulfilled]: (state, action) => {
      const { data } = action.payload?.data || []

      if (data) {
        state.landInfoById[data.land_id] = data
      }
    },
    [getFaoStats.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.priceData = data
    },
    [getComparedFaoStats.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.comparePriceData = data
    },
    [getFaosAggs.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.locationsAggs = data.aggregations
    },
  },
})

export const { updateCompareFilter, changeDev } = faoHistory.actions

export default faoHistory.reducer

import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getFavoriteLands = async () => {
  return client.get(`/users/${getCurrentUserId()}/favorites/lands`)
}

const getSharedFavoriteLands = async () => {
  return client.get(`/users/${getCurrentUserId()}/favorites/shared/lands`)
}

const addFavorite = async (landIds: any) => {
  return client.post(`/users/${getCurrentUserId()}/favorites/lands`, {
    landIds,
  })
}

const updateFavorite = async ({ landId, ...data }: any) => {
  const response = await client.post(
    `/users/${getCurrentUserId()}/favorites/lands/${landId}`,
    data
  )

  if (response.data) {
    response.data = { ...response.data, land_id: landId, ...data }
  }

  return response
}

const removeFavorite = async (landId: string) => {
  return client.delete(`/users/${getCurrentUserId()}/favorites/lands/${landId}`)
}

const isFavoriteLands = (landId: string) => {
  return client.get(`/users/${getCurrentUserId()}/favorites/lands/${landId}`)
}

const addGrouping = (landsIds: any) => {
  return client.post(
    `/users/${getCurrentUserId()}/favorites/grouping`,
    landsIds
  )
}

const getFavoriteGroupingLands = async () => {
  const lands = await client.get(
    `/users/${getCurrentUserId()}/grouping/favorite`
  )

  return lands
}

const favoriteLandService = {
  getFavoriteLands,
  isFavoriteLands,
  removeFavorite,
  addFavorite,
  updateFavorite,
  getSharedFavoriteLands,
  addGrouping,
  getFavoriteGroupingLands,
}

export default favoriteLandService

/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit'
import { groupBy } from '../../utils'

import {
  getAuthorizedCos,
  getAuthorizedCus,
  getAuthorizedFloorNumberMax,
  getAuthorizedUnitNumberMax,
  getCurrentLivingArea,
} from '../../services/parcel'

const parcelStateSelector = (state) => state.parcel

const groupedLandZonesSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.groupedLandZones?.geo_polygon
)

const parcelFaosSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.faos
)

const parcelFaoCountSelector = createSelector(
  parcelFaosSelector,
  (faos) => faos.length
)

const parcelFaoSinceDateSelector = createSelector(
  parcelFaosSelector,
  (faos) =>
    faos.length > 0 &&
    Math.min.apply(
      null,
      faos?.map((fao) => new Date(fao?.inquiry_start_date)?.getFullYear())
    )
)

const parcelTransactionsSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.transactions
)

const landRdppfsSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.rdppfs
)

const groupedRestrictionsSelector = createSelector(
  landRdppfsSelector,
  (landRppfs = []) =>
    landRppfs && Object.values(groupBy(landRppfs, 'rdppf_type_id'))
)

const landRestrictionsSelector = createSelector(
  landRdppfsSelector,
  (landRdppfs = []) =>
    [...new Set(landRdppfs?.map((rdppf) => rdppf.name))]?.map((name) => {
      return landRdppfs.find((item) => item.name === name)
    })
)

const landRegionRdppfSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.regionRdppf
)

const landTransactionSetSelector = createSelector(
  parcelTransactionsSelector,
  (transactions = []) =>
    transactions.filter(
      (v, i, a) => a.findIndex((t) => t.official_id === v.official_id) === i
    )
)

const parcelTransactionCountSelector = createSelector(
  landTransactionSetSelector,
  (transactions) => transactions.length
)

const parcelTransactionSinceDateSelector = createSelector(
  parcelTransactionsSelector,
  (transactions) =>
    transactions.length > 0 &&
    Math.min.apply(
      null,
      transactions?.map((transaction) =>
        new Date(transaction?.date)?.getFullYear()
      )
    )
)

const parcelDetailSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelDetail
)

const groupingIdSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel?.parcelDetail?.groupingId
)

const parcelNumbersSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelNumbers
)

const landIdSelector = (state) => state.parcel.landId

const regionIdSelector = createSelector(
  parcelDetailSelector,
  (parcelDetail) => parcelDetail?.region_id
)

const landAreaSelector = createSelector(
  parcelDetailSelector,
  (parcelDetail) => parcelDetail?.official_area || parcelDetail?.area
)

const authorizedCosSelector = createSelector(
  parcelDetailSelector,
  getAuthorizedCos
)

const authorizedCusSelector = createSelector(
  parcelDetailSelector,
  getAuthorizedCus
)

const authorizedFloorNumberMaxSelector = createSelector(
  parcelDetailSelector,
  getAuthorizedFloorNumberMax
)

const authorizedUnitNumberMaxSelector = createSelector(
  parcelDetailSelector,
  getAuthorizedUnitNumberMax
)

const currentLivingAreaSelector = createSelector(
  parcelDetailSelector,
  getCurrentLivingArea
)

const landAuthorizedInfoSelector = createSelector(
  parcelDetailSelector,
  authorizedCosSelector,
  authorizedFloorNumberMaxSelector,
  authorizedUnitNumberMaxSelector,
  currentLivingAreaSelector,
  authorizedCusSelector,
  (
    parcelDetail,
    authorizedCos,
    authorizedFloorNumberMax,
    authorizedUnitNumberMax,
    currentLivingArea,
    authorizedCus
  ) => {
    const std = parcelDetail?.std
    const landArea = parcelDetail?.official_area || parcelDetail?.area
    const area = std !== 0 ? std : landArea

    return {
      authorizedCos,
      authorizedFloorNumberMax,
      authorizedUnitNumberMax,
      landArea,
      area,
      std,
      currentLivingArea,
      authorizedCus,
    }
  }
)

const landUsePlansSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelDetail.land_use_plans
)

const futureLandUsePlansSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelDetail.future_land_use_plans
)

const overideValuesSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.overidesValues
)

const geoShapeScoreSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelDetail.geo_shape_score
)

const buildingsIdsOverridedSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.parcelDetail.buildingsOverridedIds
)

const lupAassignementsSelector = createSelector(
  parcelStateSelector,
  (parcel) => parcel.lupAassignements
)

const regionRdppfLinkSelector = (state) => state.parcel.regionRdppfLink

const regionGeoPortalLinkSelector = (state) => state.parcel.geoPortalLink

export {
  parcelStateSelector,
  overideValuesSelector,
  futureLandUsePlansSelector,
  landUsePlansSelector,
  parcelDetailSelector,
  landIdSelector,
  parcelNumbersSelector,
  landAreaSelector,
  authorizedCosSelector,
  authorizedFloorNumberMaxSelector,
  authorizedUnitNumberMaxSelector,
  landAuthorizedInfoSelector,
  currentLivingAreaSelector,
  authorizedCusSelector,
  parcelFaosSelector,
  parcelTransactionsSelector,
  parcelTransactionCountSelector,
  parcelFaoCountSelector,
  regionIdSelector,
  groupedLandZonesSelector,
  landTransactionSetSelector,
  parcelFaoSinceDateSelector,
  parcelTransactionSinceDateSelector,
  groupingIdSelector,
  geoShapeScoreSelector,
  landRdppfsSelector,
  buildingsIdsOverridedSelector,
  lupAassignementsSelector,
  landRegionRdppfSelector,
  regionRdppfLinkSelector,
  regionGeoPortalLinkSelector,
  landRestrictionsSelector,
  groupedRestrictionsSelector,
}

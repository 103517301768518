import React from 'react'
import { BoxProps } from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import FormHelperText from '@mui/material/FormHelperText'
import Chip from '@mui/material/Chip'

import { FilterCategoryRoot } from './FilterCategory.style'

export type FilterCategoryItemProps = BoxProps & {
  title: React.ReactNode
  helperText?: React.ReactNode
  onReset?: (e: any) => void
  beta?: boolean
}

const FilterCategory = ({
  title,
  onReset,
  children,
  helperText,
  beta,
  ...other
}: FilterCategoryItemProps) => (
  <FilterCategoryRoot {...other}>
    <h4>
      <span
        className="ellipsis"
        title={`${title} (beta)`}
        style={{ display: 'flex', gap: 5 }}
      >
        {title} {beta && <Chip label="beta" size="small" />}
      </span>
      {!!onReset && (
        <IconButton
          aria-label="reset filter"
          color="inherit"
          size="small"
          onClick={onReset}
          edge="end"
          title="Reset"
        >
          <SvgIcon viewBox="0 0 24 24" fontSize="small">
            <path
              fill="currentColor"
              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
            />
          </SvgIcon>
        </IconButton>
      )}
    </h4>
    {children}

    {helperText && (
      <FormHelperText sx={{ mt: 0.5, fontStyle: 'italic' }}>
        {helperText}
      </FormHelperText>
    )}
  </FilterCategoryRoot>
)

export default FilterCategory

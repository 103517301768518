/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit'

import { groupLocations } from '../../services/location'

const alertStateSelector = (state) => state.alert

const alertByIdSelector = (state) => state.alert.alertsById

const alertsSelector = createSelector(alertByIdSelector, (alertsById) =>
  Object.values(alertsById)
)

const alertPreviewSelector = createSelector(alertsSelector, (alerts) =>
  alerts.map((a) => ({ id: a.id, name: a.name, createdAt: a.createdAt }))
)

const selectedAlertSelector = createSelector(alertStateSelector, (alert) => ({
  ...alert.selectedAlert,
  createdAt: alert.selectedAlert.createdAt || new Date().toISOString(),
  viewport: { ...alert.selectedAlert.viewport },
}))

const zoneAffectationSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => {
    return selectedAlert.zoneAffectation
      ?.filter((zone) => zone.principal_type)
      .map((zone) => ({
        ...zone,
        value: zone.principal_type,
        label: zone.principal_type,
      }))
  }
)

const zoneAffectationSelectedSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => {
    return selectedAlert.zoneAffectationSelected
  }
)

const alertStatsSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => {
    const { faoCounter, transactionCounter, fao, transaction } = selectedAlert

    return { faoCounter, transactionCounter, fao, transaction }
  }
)

const alertTypeSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.type
)

const alertFaoSettingsSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.building_permit_parameters
)

const alertTransactionSettingsSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.transaction_parameters
)

const alertListingSettingsSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.listing_parameters
)

const alertRadiusSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.radius
)

const alertAddressSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.address
)

const alertParametersSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.parameters
)

const alertOwnershipTypeSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.ownershipType
)

const alertReasonsSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.reasons
)

const alertTransactionSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.transaction
)

const alertListingSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.listing
)

const alertFaoSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.fao
)

const alertNameSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.name
)

const alertFeaturesSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.features
)

const alertMultiPolygonSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.multiPolygon
)

const alertIdSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.id
)

const alertViewportSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => selectedAlert.viewport
)

const alertMapDataSelector = createSelector(
  selectedAlertSelector,
  (selectedAlert) => ({
    viewport: selectedAlert.viewport,
    type: selectedAlert.type,
    radius: selectedAlert.radius,
    geoCenter: selectedAlert.geoCenter,
    multiPolygon: selectedAlert.multiPolygon,
    parameters: selectedAlert.parameters,
  })
)

const alertSelectedLocationsSelector = createSelector(
  selectedAlertSelector,
  (alert) => {
    return groupLocations({
      cities: alert.citiesSelected,
      zones: alert.zonesSelected,
      regions: alert.regionsSelected,
    })
  }
)

const citiesSelectedSelector = createSelector(
  selectedAlertSelector,
  (alert) => alert.citiesSelected
)

const zonesSelectedSelector = createSelector(
  selectedAlertSelector,
  (alert) => alert.zonesSelected
)

const regionsSelectedSelector = createSelector(
  selectedAlertSelector,
  (alert) => alert.regionsSelected
)

const alertHasLocationsSelector = createSelector(
  alertSelectedLocationsSelector,
  (selectedLocations) => selectedLocations.length > 0
)

const alertUpdateCountSelector = createSelector(
  selectedAlertSelector,
  (alert) => alert.updateCount
)

export {
  alertIdSelector,
  alertNameSelector,
  alertMultiPolygonSelector,
  alertsSelector,
  selectedAlertSelector,
  zoneAffectationSelector,
  alertStatsSelector,
  alertTypeSelector,
  alertAddressSelector,
  alertRadiusSelector,
  alertParametersSelector,
  alertReasonsSelector,
  alertOwnershipTypeSelector,
  alertTransactionSelector,
  alertFaoSelector,
  alertFeaturesSelector,
  alertSelectedLocationsSelector,
  zoneAffectationSelectedSelector,
  alertHasLocationsSelector,
  citiesSelectedSelector,
  zonesSelectedSelector,
  regionsSelectedSelector,
  alertViewportSelector,
  alertMapDataSelector,
  alertFaoSettingsSelector,
  alertTransactionSettingsSelector,
  alertListingSettingsSelector,
  alertListingSelector,
  alertUpdateCountSelector,
  alertPreviewSelector,
  alertByIdSelector,
}

import useMuiMediaQuery, { Options } from '@mui/material/useMediaQuery'

const useMediaQuery = (
  query: string | ((theme: AppTheme) => string),
  options?: Options
) => {
  return useMuiMediaQuery<AppTheme>(query, options)
}

export default useMediaQuery

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  citiesSelectedSelector,
  alertTypeSelector,
  getCityById,
  getCityGeojsonByZone,
  getDistrictById,
  getDistrictGeojsonByZone,
  getRegionById,
  getRegionGeojsonByZone,
  getZonesByCity,
  getZonesByDistrict,
  getZonesByRegion,
  regionsSelectedSelector,
  zoneAffectationSelectedSelector,
  zonesSelectedSelector,
  updateSelectedAlert,
} from '../../../redux/alert'

const useLocationGeoJSON = (run = true) => {
  const dispatch = useDispatch()

  const citiesSelected = useSelector(citiesSelectedSelector)
  const regionsSelected = useSelector(regionsSelectedSelector)
  const zonesSelected = useSelector(zonesSelectedSelector)
  const zoneAffectationSelected = useSelector(zoneAffectationSelectedSelector)
  const type = useSelector(alertTypeSelector)

  const hasLocation =
    zonesSelected?.length || regionsSelected?.length || citiesSelected?.length

  const clearPolygon = () => {
    const input = { multiPolygon: { type: 'MultiPolygon', coordinates: [] } }

    dispatch(updateSelectedAlert(input))
  }

  const clearMultipolygon = () => {
    const input = {
      data: [],
      zoneAffectationSelected: [],
      zoneAffectation: [],
      multiPolygon: { type: 'MultiPolygon', coordinates: [] },
    }

    dispatch(updateSelectedAlert(input))
  }

  useEffect(() => {
    if (!run) return

    try {
      if (type !== 'Zones') return

      clearPolygon()

      if (!hasLocation) {
        // If no value is selected clear all data on the map
        clearMultipolygon()
      } else {
        const principalType = zoneAffectationSelected

        if (!principalType?.length) {
          if (regionsSelected?.length) {
            dispatch(getRegionById(regionsSelected))
            dispatch(getZonesByRegion(regionsSelected))
          }
          if (zonesSelected?.length) {
            dispatch(getDistrictById(zonesSelected))
            dispatch(getZonesByDistrict(zonesSelected))
          }
          if (citiesSelected?.length) {
            dispatch(getCityById(citiesSelected))
            dispatch(getZonesByCity(citiesSelected))
          }
        } else {
          if (citiesSelected?.length) {
            dispatch(
              getCityGeojsonByZone({
                cities: citiesSelected,
                zones: principalType,
              })
            )
          }
          if (regionsSelected?.length) {
            dispatch(
              getRegionGeojsonByZone({
                regions: regionsSelected,
                zones: principalType,
              })
            )
          }
          if (zonesSelected?.length) {
            dispatch(
              getDistrictGeojsonByZone({
                districts: zonesSelected,
                zones: principalType,
              })
            )
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [
    citiesSelected?.length,
    regionsSelected?.length,
    zonesSelected?.length,
    zoneAffectationSelected?.length,
    type,
    run,
  ])

  return { hasLocation }
}

export default useLocationGeoJSON

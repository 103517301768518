import React from 'react'
import Chip from '@mui/material/Chip'

import { FilterChipsRoot } from './FilterChips.style'

type FilterItem = { label: string; [k: string]: any }

export type FilterChipsProps = {
  items: FilterItem[]
  onDelete?: (value: FilterItem) => void
  onClearAll?: () => void
  size?: 'small' | 'medium'
  className?: string
}

const FilterChips = (props: FilterChipsProps) => {
  const { items = [], onDelete, onClearAll, size, ...other } = props

  const handleDelete = (item: FilterItem) => () => {
    onDelete?.(item)
  }

  return (
    <FilterChipsRoot {...other}>
      {items.map((item) => (
        <Chip
          key={item.label}
          label={item.label}
          onDelete={
            item.delete === false || !onDelete ? undefined : handleDelete(item)
          }
          title={item.label}
          size={size}
        />
      ))}
      {onClearAll && <span onClick={onClearAll}>Clear all filters</span>}
    </FilterChipsRoot>
  )
}

export default FilterChips

import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import FinancialIcon from '@mui/icons-material/AttachMoney'
import HomeWorkIcon from '@mui/icons-material/HomeWork'
import BuildingPermitIcon from '@mui/icons-material/LibraryBooks'

import { useI18n } from '../../../context'
import { AlertAddressRoot, DisplayText } from './AlertCard.style'

const AlertFao = ({ alert }) => {
  const { t } = useI18n()
  const items = [alert.listing, alert.fao, alert.transaction].filter(Boolean)

  if (!items.length) return null

  let title = []
  let Icon

  if (items.length > 1) {
    if (alert.fao) title.push(t('myAlert.fao'))
    if (alert.transaction) title.push(t('myAlert.transactions'))
    if (alert.listing) title.push(t('myAlert.stats.listings'))

    Icon = HomeWorkIcon
  } else if (alert.fao) {
    title = t('myAlert.fao')
    Icon = BuildingPermitIcon
  } else if (alert.listing) {
    title = t('myAlert.stats.listings')
    Icon = BuildingPermitIcon
  } else {
    title = t('myAlert.transactions')
    Icon = FinancialIcon
  }

  title = Array.isArray(title) ? title.join(' / ') : title

  return (
    <AlertAddressRoot className="AlertFao">
      <Tooltip placement="top" title={<h5>{title}</h5>}>
        <Icon className="icon" fontSize="small" />
      </Tooltip>
      <DisplayText title={title}>
        <b>{title}</b>
      </DisplayText>
    </AlertAddressRoot>
  )
}

export default AlertFao

import styled, { css } from 'styled-components'
import DialogActions from '@mui/material/DialogActions'

const DialogActionsRoot = styled(DialogActions)`
  padding-left: 16px;
  padding-right: 16px;

  ${({ variant }) =>
    variant === 'contained' &&
    css`
      padding-bottom: 16px;
    `}

  ${({ disableGutters }) =>
    disableGutters &&
    css`
      padding: 0;
    `}
`

export { DialogActionsRoot }
export default DialogActionsRoot

import ApiClient from '../apiClient'

const client = new ApiClient()

const getGeoCenter = async (id: string) => {
  return client.get(`/cities/${id}/geocenter`)
}

const getCityById = async (cities: any[]) => {
  return client.get(`/cities/${cities.map((c) => c.id).join(',')}/geojson`)
}

const getRegionById = async (regions: any[]) => {
  return client.get(`/region/${regions.map((r) => r.id).join(',')}/geojson`)
}

const getZonesByCity = async (cities: any[]) => {
  return client.get(`/zones/primary/${cities.map((c) => c.value).join(',')}`)
}

const getDistrictById = async (districts: any[]) => {
  return client.get(`/district/${districts.map((d) => d.id).join(',')}/geojson`)
}

const getZonesByDistrict = async (districts: any[]) => {
  return client.get(
    `/zones/primary/district/${districts.map((d) => d.value).join(',')}`
  )
}

const getZonesByRegion = async (regions: any[]) => {
  return client.get(
    `/zones/primary/region/${regions.map((r) => r.value).join(',')}`
  )
}

const getDistrictGeojsonByZone = async ({ districts, zones }: any) => {
  return client.get(
    `/district/${districts
      .map((d: any) => d.id)
      .join(',')}/landscapingareaprincipaltype/${zones
      .map((z: any) => z.value)
      .join(',')}/geojson`
  )
}

const getRegionGeojsonByZone = async ({ regions, zones }: any) => {
  return client.get(
    `/region/${regions
      .map((r: any) => r.id)
      .join(',')}/landscapingareaprincipaltype/${zones
      .map((z: any) => z.value)
      .join(',')}/geojson`
  )
}

const getCityGeojsonByZone = async ({ cities, zones }: any) => {
  return client.get(
    `/cities/${cities
      .map((c: any) => c.id)
      .join(',')}/landscapingareaprincipaltype/${zones
      .map((z: any) => z.value)
      .join(',')}/buildinglandsgeojson`
  )
}

const geoDataService = {
  getCityById,
  getGeoCenter,
  getRegionById,
  getZonesByCity,
  getDistrictById,
  getZonesByDistrict,
  getZonesByRegion,
  getDistrictGeojsonByZone,
  getRegionGeojsonByZone,
  getCityGeojsonByZone,
}

export default geoDataService

import React, { useEffect, useState } from 'react'

import MuiAlert, { AlertProps } from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MuiSnackbar, {
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material/Snackbar'

export type SnackbartProps = Omit<MuiSnackbarProps, 'children'> &
  AlertProps & {
    error?: boolean
    closable?: boolean
    open?: boolean
    children?: React.ReactNode
  }

const Snackbar = ({
  autoHideDuration = 6000,
  open = false,
  error,
  ...other
}: SnackbartProps) => {
  const [show, setShow] = useState(open)

  const handleClose = (_e: any, reason: string) => {
    if (reason === 'clickaway') return

    setShow(false)
  }

  useEffect(() => {
    setShow(open)
  }, [open])

  return (
    <MuiSnackbar
      {...(!error && { ...other, message: other.children })}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose as any}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    >
      {error ? (
        <MuiAlert
          {...other}
          severity={error ? 'error' : other.severity}
          elevation={6}
          onClose={handleClose as any}
          variant="filled"
        />
      ) : undefined}
    </MuiSnackbar>
  )
}

export default Snackbar

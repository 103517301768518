import { downloadURI } from '../../utils'

const formatModel = (model) => ({
  ...model,
  value: model.template,
  label: model.name,
  ...(model.region_id && { regionId: model.region_id }),
})

const getPrivateModel = (financialCompanyTemplate) => {
  if (!financialCompanyTemplate?.length) return null

  return {
    label: 'Vos modèles privées',
    options: financialCompanyTemplate.map(formatModel),
  }
}

export const formatReports = (data) => {
  const user = JSON.parse(localStorage.getItem('user') || '')

  const reports = data.filter((r) => r.category !== 'Dashboard')
  const financialTemplate = reports.filter((r) => r.category === 'Financial')
  const analysisTemplate = reports.filter((r) => r.category === 'Analysis')

  const analysisCompanyTemplate = analysisTemplate.filter(
    (r) => String(r.company_id) === String(user.company_id)
  )

  const analysisPublicTemplate = analysisTemplate.filter(
    (r) => r.company_id === null
  )

  const financialCompanyTemplate = financialTemplate.filter(
    (r) => String(r.company_id) === String(user.company_id)
  )

  const financial = financialTemplate.filter((r) => r.company_id === null)

  const reportsPrivate = reports.filter(
    (r) => String(r.company_id) === String(user.company_id)
  )

  return {
    financialPrivate: getPrivateModel(financialCompanyTemplate),
    financialPublic: financial.map(formatModel),
    analysisPrivate: getPrivateModel(analysisCompanyTemplate),
    analysisPublic: analysisPublicTemplate.map(formatModel),
    rdppf: [
      {
        value: 'RDPPF',
        name: 'Cadastre RDPPF',
        label: 'Cadastre RDPPF',
        description:
          'Service non fourni par Popety.io. Nous vous redirigeons vers le lien de téléchargement officiel.',
        type: 'pdf',
      },
    ],
    reportsPrivate: getPrivateModel(reportsPrivate),
  }
}

export const downloadReport = (blob, reportName) => {
  const url = window.URL.createObjectURL(blob)

  downloadURI(url, reportName)
}

import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import InputBase, { InputBaseProps } from '@mui/material/InputBase'

import { SearchFieldRoot } from './SearchField.style'

export type SearchFieldProps = InputBaseProps & {
  onChange: (value: string) => void
  onSearch: () => void
}

const SearchField = ({
  className,
  onChange,
  onSearch,
  ...other
}: SearchFieldProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }

  const handlePressEnter = (e: any) => {
    if (e.key === 'Enter') {
      onSearch()
      e.preventDefault()
      e.target.blur()
    }
  }

  return (
    <SearchFieldRoot className={className}>
      <SearchIcon />
      <InputBase
        placeholder="Rechercher"
        inputProps={{ 'aria-label': 'search' }}
        {...other}
        onKeyDown={handlePressEnter}
        onChange={handleChange}
      />
    </SearchFieldRoot>
  )
}

export default SearchField

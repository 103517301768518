import React from 'react'
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput'
import SearchIcon from '@mui/icons-material/Search'

import { SearchInputRoot } from './SearchInput.style'

const SearchInput = ({ className, sx, ...other }: OutlinedInputProps) => (
  <SearchInputRoot className={className} sx={sx}>
    <SearchIcon fontSize="small" />
    <OutlinedInput
      type="text"
      {...other}
      fullWidth
      autoComplete="off"
      inputProps={{
        'aria-label': 'search',
        ...other.inputProps,
      }}
      size="small"
    />
  </SearchInputRoot>
)

export default SearchInput

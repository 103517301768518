import React from 'react'
import { useSelector } from 'react-redux'

import { FilterCategory, LocationsFilterInput } from '../../../components'
import { useI18n } from '../../../context'
import { locationsAggsSelector } from '../../../redux/listingHistory'

const LocationsFilter = ({
  countryFilter,
  regionFilter,
  zoneFilter,
  cityFilter,
  onChange,
  loading = false,
  ...other
}) => {
  const { t } = useI18n()
  const aggregations = useSelector(locationsAggsSelector)

  const countries = [{ key: 'Suisse' }]
  const regions = aggregations?.regions?.regions?.buckets
  const zones = aggregations?.zones?.zones?.buckets
  const cities = aggregations?.cities?.cities?.buckets

  return (
    <FilterCategory {...other} title={t('listing.location')}>
      <LocationsFilterInput
        countryFilter={countryFilter}
        regionFilter={regionFilter}
        zoneFilter={zoneFilter}
        cityFilter={cityFilter}
        onChange={onChange}
        data={{
          countries: regions?.length ? countries : [],
          regions,
          zones,
          cities,
        }}
        loading={loading}
      />
    </FilterCategory>
  )
}

export default LocationsFilter

/* eslint-disable max-lines */
import React, { useEffect, useMemo } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { mapBy } from '../../utils'
import { useI18n } from '../../context'
import { groupLocations } from '../../services/location'
import RegionFilterItem from '../RegionFilterItem'
import { useLocations } from '../../pages/MyAlerts'
import { GroupLabel } from '../../pages/MyAlerts/ZonesStep/ZonesStep.style'
import ListboxComponent from './ListboxComponent'

const toLocation = (val) => val?.map((i) => ({ ...i, id: i.key })) || []

const getLocation = (newValue, group) => {
  return newValue
    .filter((val) => val.group === group)
    .map((i) => i.key || i.label)
}

const LocationsFilterInput = ({
  loading = false,
  countryFilter = [],
  regionFilter = [],
  zoneFilter = [],
  cityFilter = [],
  landUsePlansFilter = [],
  data = {},
  id = 'locations',
  exclude = {},
  onChange,
  ...other
}) => {
  const { t } = useI18n()

  const countries = toLocation(data.countries)
  const regions = toLocation(data.regions)
  const zones = toLocation(data.zones)
  const cities = toLocation(data.cities)
  const landUsePlans = toLocation(data.landUsePlans)

  const { citiesByName, zonesByName, hasData } = useLocations()

  const groupCount = {
    countries: countries?.length,
    zones: zones?.length,
    regions: regions?.length,
    cities: cities?.length,
    landUsePlans: landUsePlans?.count,
  }

  const excludeLocations = groupLocations({
    countries: exclude.countryFilter,
    regions: exclude.regionFilter,
    zones: exclude.zoneFilter,
    cities: exclude.cityFilter,
    landUsePlans: exclude.landUsePlansFilter,
  })

  const excludeLocationsByUid = mapBy('uid', excludeLocations)

  const locations = groupLocations({
    countries,
    regions,
    zones,
    cities,
    landUsePlans,
  }).filter((o) => !excludeLocationsByUid[o.uid])

  const selectedLocations = useMemo(
    () =>
      groupLocations({
        countries: countryFilter,
        regions: regionFilter,
        zones: zoneFilter,
        cities: cityFilter,
        landUsePlans: landUsePlansFilter,
      }),
    [
      countryFilter?.[0],
      regionFilter?.[0],
      zoneFilter?.[0],
      landUsePlans?.[0],
      countryFilter?.length,
      regionFilter?.length,
      zoneFilter?.length,
      landUsePlans?.length,
    ]
  )

  const handleChange = (e, newValue) => {
    const input = {
      countryFilter: getLocation(newValue, 'countries'),
      regionFilter: getLocation(newValue, 'regions'),
      zoneFilter: getLocation(newValue, 'zones'),
      cityFilter: getLocation(newValue, 'cities'),
      landUsePlansFilter: getLocation(newValue, 'landUsePlans'),
    }

    if (!input.regionFilter?.length && regionFilter?.length) {
      return onChange({
        ...input,
        zoneFilter: [],
        cityFilter: [],
        landUsePlansFilter: [],
      })
    }

    if (input.zoneFilter?.length) {
      input.zoneFilter.forEach((c) => {
        const zone = zonesByName[c]

        if (zone?.region && !input.regionFilter?.includes(zone.region)) {
          input.regionFilter.push(zone.region)
        }
      })
    } else if (zoneFilter.length) {
      return onChange({ ...input, cityFilter: [], landUsePlansFilter: [] })
    }

    if (input.cityFilter?.length) {
      input.cityFilter.forEach((c) => {
        const city = citiesByName[c]

        if (city?.region && !input.regionFilter?.includes(city.region)) {
          input.regionFilter.push(city.region)
        }

        if (city?.district && !input.zoneFilter?.includes(city.district)) {
          input.zoneFilter.push(city.district)
        }
      })
    } else if (cityFilter.length) {
      return onChange({ ...input, landUsePlansFilter: [] })
    }

    onChange(input)
  }

  useEffect(() => {
    if (regionFilter?.length || !cityFilter?.length) return

    const input = { regionFilter, zoneFilter }

    cityFilter.forEach((c) => {
      const city = citiesByName[c]

      if (city?.region && !input.regionFilter?.includes(city.region)) {
        input.regionFilter.push(city.region)
      }

      if (city?.district && !input.zoneFilter?.includes(city.district)) {
        input.zoneFilter.push(city.district)
      }
    })

    if (zoneFilter?.length) {
      zoneFilter.forEach((c) => {
        const zone = zonesByName[c]

        if (zone?.region && !input.regionFilter?.includes(zone.region)) {
          input.regionFilter.push(zone.region)
        }
      })
    }

    onChange(input)
  }, [regionFilter?.length, hasData])

  return (
    <Autocomplete
      size="small"
      fullWidth
      id={id}
      value={selectedLocations}
      disableListWrap
      ListboxComponent={ListboxComponent}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option.uid === (val.uid || val)}
      getOptionLabel={(option) => option.key || option?.label || option}
      renderOption={(p, option) => [
        p,
        <RegionFilterItem
          key={option.uid}
          name={option.key}
          count={option.doc_count}
        />,
      ]}
      options={locations}
      groupBy={(option) => option.group}
      renderGroup={(params) => ({
        ...params,
        group: (
          <GroupLabel key={params.uid} component="div" sx={{ padding: 0 }}>
            <span>{t(`common.${params.group}`).toUpperCase()}</span>
            <div>{groupCount[params.group]}</div>
          </GroupLabel>
        ),
      })}
      multiple
      filterSelectedOptions
      loading={loading}
      loadingText={t('common.loading')}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          {...other}
          placeholder={other?.placeholder || t('common.location')}
          inputProps={{
            ...other.inputProps,
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  )
}

export default React.memo(LocationsFilterInput)

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
    height: 100%;
    overflow: hidden;
  }

  body {
    font-family: "Roboto", sans-serif !important;
    font-size: 12px !important;
    line-height: 1.833;
    overflow-y: auto;
    position: relative;
    min-height: 100%;
    color: ${({ theme }) => theme.palette.text.primary}
  }

  html,
  body {
    overflow-x: hidden;
  }

  strong, b {
    font-weight: bolder;
  }

  /* Media Queries above 992px */
  @media (min-width: 1680px) {
    body {
      font-size: 14px !important;
    }
  }

  .text-center {
    text-align: center;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
    margin: 0;
  }

  .d-none {
    display: none;
  }

  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  /* clear cross button on input */

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display: none;
  }

  /** Material input */
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  
/* Change the input autofill colors in webkit browsers */

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  a {
    // color: inherit; /* blue colors for links too */
    color: #2196F3;
    text-decoration: inherit; /* no underline */
  }

  .MainLocation-Popper {
    > .MuiAutocomplete-paper, .MuiAutocomplete-listbox {
      min-height: calc(100vh - 180px);
    }
  }

  #spinner, #overlay, #spinner[style], #spinner[style], body > #spinner, body > #overlay  {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    background: transparent !important;
    color: transparent !important;
  }

  .numInput {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
`

export default GlobalStyle

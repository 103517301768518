import { createSelector } from '@reduxjs/toolkit'
import { groupBy } from '@/utils'

const displayMapStyleSelector = (state) => state.displayMap.mapStyle

const displayProspectFilterSelector = (state) => state.displayMap.prospectFilter

const displayTagFilterSelector = (state) => state.displayMap.tagFilter

const displayFavoriteFilterSelector = (state) => state.displayMap.favoriteFilter

const displayMapDisplaySelector = (state) => state.displayMap.mapDisplay

const thematicSelector = (state) => state.displayMap.thematic

const buildingThematicSelector = (state) => state.displayMap.buildingThematic

const landUsePlanTypeSelector = (state) => state.displayMap.landUsePlanType
const boundingSearchSelector = (state) => state.displayMap.boundingSearch

const geojsonSelector = (state) => state.displayMap.geojson

const geoCenterSelector = (state) => state.displayMap.geoCenter

const layoutSelector = (state) => state.displayMap.layout

const availableCitiesSelector = (state) => state.displayMap.availableCities

const isochroneSelector = (state) => state.displayMap.isochrone

const buildingMapDisplaySelector = (state) =>
  state.displayMap.buildingMapDisplay

const ofsStyleSelector = (state) => state.displayMap.ofsStyle

const ofsLayersSelector = (state) => state.displayMap.ofsLayers

const ofsLayersGroupedSelector = createSelector(
  ofsLayersSelector,
  (ofsLayers) =>
    Object.values(groupBy(ofsLayers, 'topic_name')).map((group) => {
      return { group: group?.[0]?.topic_name, options: [...group] }
    })
)

const ofsMapStyleSelector = (state) => state.displayMap.ofsMapStyle

export {
  ofsStyleSelector,
  displayMapStyleSelector,
  displayProspectFilterSelector,
  displayMapDisplaySelector,
  thematicSelector,
  landUsePlanTypeSelector,
  boundingSearchSelector,
  displayTagFilterSelector,
  displayFavoriteFilterSelector,
  geojsonSelector,
  geoCenterSelector,
  layoutSelector,
  availableCitiesSelector,
  isochroneSelector,
  buildingMapDisplaySelector,
  buildingThematicSelector,
  ofsLayersSelector,
  ofsLayersGroupedSelector,
  ofsMapStyleSelector,
}

import { isDev, isProd } from '../../config'
import HttpClient from './HttpClient'

/**
 * Backend api base url of popety
 * @namespace Config
 */
export const API_BASE_URL = isProd
  ? process.env.REACT_APP_API_BASE_URL
  : process.env.REACT_APP_API_BASE_URL_DEV || process.env.REACT_APP_API_BASE_URL

/**
 * REST Client that use native fetch to connect with the backend API.
 *
 * @namespace Services
 * @see https://developer.mozilla.org/fr/docs/Web/API/Fetch_API/Using_Fetch
 *
 * @example <caption>Usage</caption>
 * const client = new HttpClient()
 * const { data, errors } = await client.get('/users')
 * client.post('/users', userInput)
 * client.delete('/users/1')
 */
class ApiClient extends HttpClient {
  constructor(baseURL = API_BASE_URL) {
    super({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        locale: localStorage.getItem('language'),
      },
      interceptors: {
        request() {
          const accessToken = localStorage.getItem('jwt')

          if (accessToken) {
            const Authorization = `JWT ${accessToken}`

            return { headers: { Authorization } }
          }
        },
        async response(res) {
          const payload = await res.json()

          if (res.ok) return { ...res, data: payload }

          if (res.status === 401) {
            // logout
            localStorage.removeItem('jwt')
            localStorage.removeItem('user')

            sessionStorage.setItem('from', window.location.pathname)
            window.location.pathname = `/login`
          }

          // Format backend error to be displayed on the ui

          let errors = payload?.errors || payload?.error || payload

          errors = Array.isArray(errors) ? errors : [errors]

          if (isDev) {
            console.error(errors)
          }

          errors = errors.map((error) => {
            if (Array.isArray(error.message)) {
              return {
                ...error,
                ...error.message[0],
                message: error.message[0]?.msg || error.message[0]?.message,
              }
            }

            return error
          })

          return { ...res, errors: Array.isArray(errors) ? errors : [errors] }
        },
      },
    })
  }
}

export default ApiClient

const developerMapSelector = (state) => state.developerMap

const loading = (state) => state.developerMap.loading

const layersSelector = (state) => state.developerMap.layers

const totalLayersSelector = (state) => state.developerMap.layers?.total?.value

const searchAfterSelector = (state) => state.developerMap.layers?.search_after

const devLandsSelector = (state) => state.developerMap.lands

const devBuildingsSelector = (state) => state.developerMap.buildings

const buildingPermitYearSelector = () =>
  new Date(1996, 1, 1).toLocaleDateString()

const transactionYearSelector = () => new Date(1994, 1, 1).toLocaleDateString()

const listingRentYearSelector = () => new Date(2005, 1, 1).toLocaleDateString()

const historyStartDateSelector = () => ({
  building_permit_last_year: new Date(1996, 1, 1).toLocaleDateString(),
  transaction_last_year: new Date(1994, 1, 1).toLocaleDateString(),
  listing_rent_last_year: new Date(2005, 1, 1).toLocaleDateString(),
})

const fetchedlayersCountSelector = (state) =>
  state.developerMap.layers?.geojson?.features?.length

const landUsePlan = (state) => state.developerMap.landUsePlan
const futureLandUsePlan = (state) => state.developerMap.futureLandUsePlan

const viewportSelector = (state) => state.developerMap.viewport

const landUsePlanAssignement = (state) =>
  state.developerMap.landUsePlanAssignement
const futureLandUsePlanAssignement = (state) =>
  state.developerMap.futureLandUsePlanAssignement

const principalTypesSelector = (state) =>
  state.developerMap.principalTypes.filter((type) => type.principal_type)

const addresses = (state) => state.developerMap.addresses

const landUsePlanDisplaySelector = (state) =>
  state.developerMap.landUsePlanDisplay

const zoomSelector = (state) => state.developerMap.zoom

const overideLandUsePlansSelector = (state) =>
  state.developerMap.overideLandUsePlans

const futureOverideLandUsePlansSelector = (state) =>
  state.developerMap.futureOverideLandUsePlans

const mapViewSelector = (state) => state.developerMap.view

const mapDimensionSelector = (state) => state.developerMap.dimension

const popetyIoClassificationSelector = (state) =>
  state.developerMap.popetyClassification?.filter(
    (c) => c.popety_classification
  )

const OFSClassificationSelector = (state) =>
  state.developerMap.OFSClassification?.filter((c) => c.ofs_classification)

const OFSCategorySelector = (state) =>
  state.developerMap.OFSCategory?.filter((c) => c.ofs_category)

const heatingSystemSelector = (state) =>
  state.developerMap.heaterGenerator?.filter((c) => c.heater_generator)

const waterHeatingSystemSelector = (state) =>
  state.developerMap.waterHeaterGenerator?.filter(
    (c) => c.heater_water_generator
  )

const overridedPlotsSelector = (state) => state.developerMap.overridedPlots

export {
  futureOverideLandUsePlansSelector,
  overideLandUsePlansSelector,
  zoomSelector,
  developerMapSelector,
  landUsePlanDisplaySelector,
  loading,
  layersSelector,
  principalTypesSelector,
  addresses,
  landUsePlan,
  futureLandUsePlan,
  landUsePlanAssignement,
  futureLandUsePlanAssignement,
  fetchedlayersCountSelector,
  totalLayersSelector,
  searchAfterSelector,
  viewportSelector,
  buildingPermitYearSelector,
  transactionYearSelector,
  listingRentYearSelector,
  historyStartDateSelector,
  mapViewSelector,
  devLandsSelector,
  mapDimensionSelector,
  devBuildingsSelector,
  popetyIoClassificationSelector,
  OFSClassificationSelector,
  OFSCategorySelector,
  heatingSystemSelector,
  waterHeatingSystemSelector,
  overridedPlotsSelector,
}

import styled, { css } from 'styled-components'

import Button from '@mui/material/Button'

export const StyledButton = styled(Button)`
  ${({ $disableRadius }) =>
    $disableRadius &&
    css`
      border-radius: 0;
    `}

  ${({ variant, theme }) =>
    variant === 'custom' &&
    css`
      color: ${theme.palette.text.primary};
      border-radius: ${theme.shape.borderRadius * 1.25}px;
      background-color: white;
      text-transform: uppercase;
      font-weight: 600;
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    `}

    ${({ color, theme }) =>
    (color === 'error' || color === 'success' || color === 'info') &&
    css`
      color: ${theme.palette.primary.contrastText};
      background-color: ${theme.palette[color]?.main};
    `}
`

import { createSelector } from '@reduxjs/toolkit'

const reportingStateSelector = (state) => state.reporting

const reportingLoadingStateSelector = (state) => state.reporting.loading

const reportsSelector = createSelector(reportingStateSelector, (reporting) => {
  const key = 'shortid'
  const reports = [
    ...new Map(reporting.reports.map((item) => [item[key], item])).values(),
  ]

  return reports
})

const financialReportsSelector = createSelector(
  reportingStateSelector,
  (reporting) => reporting.financialReports
)

const detailsAnalysisReportSelector = createSelector(
  reportingStateSelector,
  (reporting) => {
    const res = []

    reporting.analysisReports?.forEach((report) => {
      if (report?.options)
        res.push(
          ...report.options.filter((r) => {
            if (r?.name === 'Analyse Detaillée') return r

            return false
          })
        )
    })

    return res
  }
)

const groupedFinancialReportsSelector = createSelector(
  financialReportsSelector,
  (financialReports) => {
    const res = []

    financialReports.filter(Boolean).forEach((report) => {
      if (report.options) {
        res.push(...report.options.map((o) => ({ ...o, group: report.label })))
      }
    })

    return res
  }
)

export {
  financialReportsSelector,
  reportingStateSelector,
  reportsSelector,
  detailsAnalysisReportSelector,
  groupedFinancialReportsSelector,
  reportingLoadingStateSelector,
}

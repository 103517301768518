import React from 'react'
import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

import { alertTypeSelector } from '../../../redux/alert'
import { useI18n } from '../../../context'
import FinalStep from '../FinalStep'
import GeoStep from '../GeoStep'
import PolygonStep from '../PolygonStep'
import RadiusStep from '../RadiusStep'
// import SettingsStep from '../SettingsStep'
import ZonesStep from '../ZonesStep'
import FaoSettingsStep from '../FaoSettingsStep'
import TransactionSettingsStep from '../TransactionSettingsStep'
import ListingSettingsStep from '../ListingSettingsStep'

const NotSep = () => {
  const { t } = useI18n()

  return <Typography>{t('myAlert.nothingSelected')}</Typography>
}

const Geo = {
  Zones: ZonesStep,
  Radius: RadiusStep,
  Polygone: PolygonStep,
}

const GeoContent = (props) => {
  const type = useSelector(alertTypeSelector)
  const Content = Geo[type] || NotSep

  return <Content {...props} />
}

const steps = {
  0: GeoStep,
  1: GeoContent,
  2: TransactionSettingsStep,
  3: FaoSettingsStep,
  4: ListingSettingsStep,
  // 5: SettingsStep,
  5: FinalStep,
}

const StepContent = ({ index, completed, ...other }) => {
  const Step = steps[index] || null

  return <>{!completed && <Step {...other} />}</>
}

export { steps }
export default StepContent

import styled from 'styled-components'

export const StepActionsRoot = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  > button {
    min-width: 46% !important;
    margin-top: 10px;
  }
`

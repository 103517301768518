import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import livabilityService from '../../services/livability'

const initialState = {
  pois: [],
  isochrone: [],
  poiCategoryScore: [],
}

export const fetchPoisAround = createAsyncThunk(
  'parcel/livability',
  livabilityService.fetchPoisAround
)

export const fetchIsochroneLayer = createAsyncThunk(
  'livability/isochrone',
  livabilityService.fetchIsochroneLayer
)

export const getPoisScore = createAsyncThunk(
  'livability/scores',
  livabilityService.getPoisScore
)

const livability = createSlice({
  name: 'livability.',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPoisAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.pois = data
    },

    [getPoisScore.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.poiCategoryScore = data
    },

    [fetchIsochroneLayer.fulfilled]: (state, action) => {
      const { payload } = action

      state.isochrone = {
        ...payload,
        features: payload.features?.map((f, index) => {
          if (index === 0) return { ...f, category: 'drive' }
          if (index === 1) return { ...f, category: 'bike' }
          if (index === 2) return { ...f, category: 'walking' }

          return 0
        }),
      }
    },
  },
})

export default livability.reducer

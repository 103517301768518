import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@mui/material/Slider'

import AddressesSearch from '../../../components/AddressesSearch'
import {
  alertAddressSelector,
  alertRadiusSelector,
  updateAlertAddress,
  updateSelectedAlert,
} from '../../../redux/alert'
import { useI18n } from '../../../context'
import StepActions from '../StepActions'
import { RadiusStepRoot } from './RadiusStep.style'

const radiusMarks = [
  { value: 0.1, label: '100 m' },
  { value: 0.5, label: '500 m' },
  { value: 1, label: '1 km' },
  { value: 2, label: '2 km' },
]

const RadiusStep = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const radius = useSelector(alertRadiusSelector)
  const address = useSelector(alertAddressSelector)

  const [internalRadius, setInternalRadius] = useState(radius)

  const valuetext = (value) => (value < 1 ? `${value * 1000} m` : `${value} km`)

  const handleRadiusChangeCommitted = (e, value) => {
    dispatch(updateSelectedAlert({ radius: value }))
  }

  const handleRadiusChange = (e, value) => {
    setInternalRadius(value)
  }

  const handleChange = (newAddress) => {
    dispatch(updateAlertAddress(newAddress))
  }

  useEffect(() => {
    setInternalRadius(radius)
  }, [radius])

  return (
    <RadiusStepRoot>
      <span>{t('myAlert.radius.details')}</span>
      <AddressesSearch
        all
        value={address}
        onChange={handleChange}
        placeholder={t('myAlert.radius.placeholder')}
      />
      <span>{t('myAlert.radius.details')}</span>
      <Slider
        marks={radiusMarks}
        value={internalRadius}
        onChange={handleRadiusChange}
        onChangeCommitted={handleRadiusChangeCommitted}
        getAriaValueText={valuetext}
        valueLabelFormat={valuetext}
        step={0.1}
        min={0.01}
        max={2}
        valueLabelDisplay="auto"
        size="small"
      />
      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </RadiusStepRoot>
  )
}

export default RadiusStep

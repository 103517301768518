/* eslint-disable no-nested-ternary */

import { getScoreColorHexScale } from '..'

export const radarSet = (landUsePlanType: any, feature: any) => {
  const scores = [
    {
      key: 'land',
      label: 'My Scores',
      values: {
        ues:
          landUsePlanType === 'future'
            ? feature?.future_under_exploited_score
              ? feature?.future_under_exploited_score
              : feature?.underExploitedScoreOveride ||
                feature?.under_exploited_score
            : feature?.underExploitedScoreOveride ||
              feature?.under_exploited_score,
        gss: feature?.geo_shape_score,
        cs: feature?.existing_score,
        pois: feature?.livability_score,
      },
    },
  ]

  if (landUsePlanType === 'difference') {
    scores.push({
      key: 'landFuture',
      label: 'My Scores',
      values: {
        ues: feature?.future_under_exploited_score
          ? feature?.future_under_exploited_score
          : feature?.under_exploited_score,
        gss: feature?.geo_shape_score,
        cs: feature?.existing_score,
        pois: feature?.livability_score,
      },
    })
  }

  return scores
}

export const radarColors = (landUsePlanType: any, feature: any) => {
  const colors = {
    land: getScoreColorHexScale(
      landUsePlanType === 'future'
        ? feature.developmentScoreOverideFuture ||
          feature?.future_development_score
          ? feature.developmentScoreOverideFuture ||
            feature?.future_development_score
          : feature?.underExploitedScoreOveride ||
            feature?.under_exploited_score
        : feature?.developmentScoreOveride || feature?.development_score
    ),
    landFuture: null,
  }

  if (landUsePlanType === 'difference')
    colors.landFuture = getScoreColorHexScale(
      feature.developmentScoreOverideFuture || feature?.future_development_score
    )

  return colors
}

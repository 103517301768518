/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import financialAnalysisService from '../../services/financialAnalysis'

const defaultAnalysis = {
  id: '0',
  name: 'New Draft',
  analysis_data: {
    currentLandPrice: 700,
    currentBuildingPrice: 8000,
    cfcZeroAdditionalCodes: [],
    cfcOneAdditionalCodes: [],
    cfcTwoAdditionalCodes: [],
    cfcFiveAdditionalCodes: [],
    sellingAdditionalCodes: [],
  },
}

const initialState = {
  analysisById: { 0: defaultAnalysis },
  selectedAnalysis: defaultAnalysis,
  hasAnalysis: false,
}

export const getGroupedFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/getGroupedFinancialAnalysis',
  financialAnalysisService.getGroupedFinancialAnalysis
)

export const getFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/getFinancialAnalysis',
  financialAnalysisService.getFinancialAnalysis
)

export const addFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/addFinancialAnalysis',
  financialAnalysisService.addFinancialAnalysis
)

export const addGroupedFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/addGroupedFinancialAnalysis',
  financialAnalysisService.addGroupedFinancialAnalysis
)

export const updateFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/updateFinancialAnalysis',
  financialAnalysisService.updateFinancialAnalysis
)

export const updateGroupedFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/updateGroupedFinancialAnalysis',
  financialAnalysisService.updateGroupedFinancialAnalysis
)

export const removeFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/removeFinancialAnalysis',
  financialAnalysisService.removeFinancialAnalysis
)

export const removeGroupedFinancialAnalysis = createAsyncThunk(
  'financialAnalysis/removeGroupedFinancialAnalysis',
  financialAnalysisService.removeGroupedFinancialAnalysis
)

const financialAnalysis = createSlice({
  name: 'financialAnalysis',
  initialState,
  reducers: {
    changeSelectedAnalysis: (state, action) => {
      state.selectedAnalysis = state.analysisById[action.payload]
    },
    updateSelectedAnalysisData: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.selectedAnalysis.analysis_data[key] = action.payload[key]
      })
    },
    addCFC: (state, action) => {
      const { cfcKey, ...input } = action.payload
      const cfcCodes = state.selectedAnalysis.analysis_data[cfcKey]

      cfcCodes.push(input)
    },
    updateCFC: (state, action) => {
      const { cfcKey, index, ...input } = action.payload
      const cfcCodes = state.selectedAnalysis.analysis_data[cfcKey]

      if (input.value !== undefined) {
        input.value = parseFloat(input.value || 0, 10)
      }

      Object.keys(input).forEach((key) => {
        cfcCodes[index][key] = input[key]
      })
    },
    deleteCFC: (state, action) => {
      const { cfcKey, index } = action.payload

      delete state.selectedAnalysis.analysis_data[cfcKey][index]

      state.selectedAnalysis.analysis_data[cfcKey] =
        state.selectedAnalysis.analysis_data[cfcKey].filter(Boolean)
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [getFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.analysisById = { 0: defaultAnalysis }

        data.forEach((analysis) => {
          state.analysisById[analysis.id] = analysis
        })

        state.hasAnalysis = data.length > 0

        state.selectedAnalysis =
          state.analysisById[state.selectedAnalysis.id] ||
          state.analysisById['0']
      }
    },
    [getGroupedFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.analysisById = { 0: defaultAnalysis }

        data.forEach((analysis) => {
          state.analysisById[analysis.id] = analysis
        })

        state.hasAnalysis = data.length > 0

        state.selectedAnalysis =
          state.analysisById[state.selectedAnalysis.id] ||
          state.analysisById['0']
      }
    },
    [addFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.analysisById[data.id] = data
        state.selectedAnalysis = data
      }
    },
    [addGroupedFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.analysisById[data.id] = data
        state.selectedAnalysis = data
      }
    },
    [removeFinancialAnalysis.pending]: (state) => {
      state.selectedAnalysis = defaultAnalysis
    },
    [removeFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload
      const id = action.meta.arg

      if (data) delete state.analysisById[id]
    },
    [removeGroupedFinancialAnalysis.pending]: (state) => {
      state.selectedAnalysis = defaultAnalysis
    },
    [removeGroupedFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload
      const id = action.meta.arg

      if (data) delete state.analysisById[id]
    },
    [updateFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) {
        state.analysisById[input.id] = input
        state.selectedAnalysis = input
      }
    },
    [updateGroupedFinancialAnalysis.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) {
        state.analysisById[input.id] = input
        state.selectedAnalysis = input
      }
    },
  },
})

export const {
  changeSelectedAnalysis,
  updateSelectedAnalysisData,
  addCFC,
  updateCFC,
  deleteCFC,
} = financialAnalysis.actions

export default financialAnalysis.reducer

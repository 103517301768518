import styled from 'styled-components'

export const RadiusStepRoot = styled.div`
  > span {
    color: rgb(141, 155, 163);
    font-style: italic;
    font-size: 12px;
  }

  > span:not(:fist-child) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .MuiSlider-valueLabel {
    z-index: 1;
    position: absolute;
    font-size: 1.2rem;
    transform: scale(0);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01071em;
    transform-origin: bottom center;
    top: -22px;
    left: calc(-50% - 4px);
    font-size: 11px;

    * {
      background: transparent;
      color: #000;
    }
  }

  .MuiSlider-marked {
    margin-top: 25px;
  }
`

import { createSelector } from '@reduxjs/toolkit'

import { groupLocations } from '../../services/location'

const locationStateSelector = (state) => state.location

const citiesSelector = createSelector(locationStateSelector, (location) =>
  location.cities?.map((city) => ({
    ...city,
    value: city.name,
    label: city.name,
  }))
)

const zonesSelector = createSelector(locationStateSelector, (location) =>
  location.zones?.map((zone) => ({
    ...zone,
    value: zone.name,
    label: zone.name,
  }))
)

const regionsSelector = createSelector(locationStateSelector, (location) =>
  location.regions?.map((region) => ({
    ...region,
    value: region.name,
    label: region.name,
  }))
)

const groupedLocationsSelector = createSelector(
  regionsSelector,
  zonesSelector,
  citiesSelector,
  (regions, zones, cities) => groupLocations({ regions, zones, cities })
)

const addressesSelector = createSelector(
  locationStateSelector,
  (location) => location.addresses
)

const allAddressesSelector = createSelector(
  locationStateSelector,
  (location) => location.allAddresses
)

const allAddressesCountSelector = createSelector(
  locationStateSelector,
  (location) => location.allAddressesCount
)

export {
  zonesSelector,
  regionsSelector,
  citiesSelector,
  groupedLocationsSelector,
  allAddressesSelector,
  addressesSelector,
  allAddressesCountSelector,
}

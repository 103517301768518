import { createSelector } from '@reduxjs/toolkit'

const favoriteStateSelector = (state) => state.favoriteLand

const favoriteLandsSelector = createSelector(
  favoriteStateSelector,
  (favoriteLand) => favoriteLand.favoriteLands
)

const favoriteLandIdsSelector = createSelector(
  favoriteStateSelector,
  (favoriteLand) => favoriteLand.favoriteLandIds
)

const sharedFavoriteLandsSelector = createSelector(
  favoriteStateSelector,
  (favoriteLand) => favoriteLand.sharedFavoriteLands
)

const favoriteGroupingLandsSelector = createSelector(
  favoriteStateSelector,
  (favoriteLand) => favoriteLand.favoriteGroupingLands
)

export {
  favoriteStateSelector,
  favoriteLandsSelector,
  sharedFavoriteLandsSelector,
  favoriteLandIdsSelector,
  favoriteGroupingLandsSelector,
}

import React, { useEffect, useState } from 'react'
import MuiAlert, { AlertProps as AlertBaseProps } from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export type AlertProps = AlertBaseProps & {
  error?: boolean
  closable?: boolean
  open?: boolean
  onClosed?: () => void
}

const Alert = ({
  open = false,
  closable = false,
  error,
  onClosed,
  ...other
}: AlertProps) => {
  const [show, setShow] = useState(open)

  const handleClose = () => {
    setShow(false)
    onClosed?.()
  }

  useEffect(() => {
    setShow(open)
  }, [open])

  if (!show) return null

  return (
    <MuiAlert
      {...(closable && {
        action: (
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ),
      })}
      className="mb-5"
      {...other}
      severity={error ? 'error' : other.severity}
    />
  )
}

export default Alert

/* eslint-disable max-lines */
import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'
import { getCompanyId } from '../company'
import HttpClient from '../apiClient/HttpClient'
import { removeEmpty } from './parcelServiceHelper'

const client = new ApiClient()

export type GetParcelInput = {
  nbCanton?: string | null
  nbParcelle?: string | null
  landId?: string | null
}

type OverideValuesProps = {
  data: {
    cos?: string
    ios?: string
    ius?: string
    cus?: string
    ibus?: string
  }
  landId: number
  landUsePlanAssignmentId: number
  isExtend: boolean
}

type GroupingOverideValuesProps = {
  data: {
    cos?: string
    ios?: string
    ius?: string
    cus?: string
    ibus?: string
  }
  groupingLandId: number
  landUsePlanAssignmentId: number
}

type OverideFutureValuesProps = {
  data: any
  landId: number
  futureLandUsePlanAssignmentId: number
  isExtend: boolean
}

type OverideValuesUpdateProps = {
  data: any
  id: number
}

type DistanceLimitProps = {
  id: number | string
  distance: number | string
}

/** swisstopo api to get land altitude */
const api = new HttpClient('https://api3.geo.admin.ch/rest/services')

const getParcel = async (input: GetParcelInput) => {
  const { nbCanton, nbParcelle, landId } = input

  const url = landId
    ? `/parcelles/${landId}`
    : `/parcelles/${nbCanton}|${nbParcelle}`

  const res = await client.get(url)

  // Get altitude max it it is < 0 using swisstopo api
  try {
    const land = res.data?.data

    if (land && (land.altitude_max < 0 || !land.altitude_max)) {
      const [easting, northing] = land.center_ch_projection?.split(';') || []

      if (easting && northing) {
        const data = await api.get(
          `/height?easting=${easting}&northing=${northing}`
        )

        if (data?.height) {
          res.data.data.altitude_max = +data.height
        }
      }
    }
  } catch (error) {
    console.error(error)
  }

  return res
}

const getParcelNumbers = async (cityId: string) => {
  return client.get(`/cities/${cityId}/parcelles`)
}

const getParcelFao = async (landId: string) => {
  return client.get(`/parcelles/${landId}/faos`)
}

const getLandRdppfs = async (landId: string) => {
  return client.get(`/parcelles/${landId}/rdppfs`)
}

const getRegionRdppfs = async (regionId: string) => {
  return client.get(`/parcelles/region/rdppfs/${regionId}`)
}

const getParcelTransactions = async (landId: string) => {
  return client.get(`/parcelles/${landId}/transactions`)
}

type Land = { land_id: string; land_use_plans: any[] }

const getGroupedLandZones = async (land: Land) => {
  const landId = land.land_id

  const maxLandUsePlan = land.land_use_plans.reduce((prev, current) => {
    return prev.distance_limit > current.distance_limit ? prev : current
  })

  return client.get(
    `/parcelles/${landId}/groupedZones/${maxLandUsePlan?.distance_limit || 3}`
  )
}

const getLandEssentialInfo = async (landId: string) => {
  return client.get(`/parcelles/${landId}/essential`)
}

const addOverideValuesAssignment = async ({
  data,
  landId,
  landUsePlanAssignmentId,
}: OverideValuesProps) => {
  return client.post(
    `/landuseplans/${getCurrentUserId()}/${getCompanyId()}/overide/assignment/${landId}/${landUsePlanAssignmentId}`,
    data
  )
}

const getUserOverideValuesById = async () => {
  return client.get(`/landuseplans/overide/assignment/${getCurrentUserId()}`)
}

const getUserGroupingOverideValuesById = async () => {
  return client.get(
    `/landuseplans/overide/grouping/assignment/${getCurrentUserId()}`
  )
}

const updateOverideValues = async ({ data, id }: OverideValuesUpdateProps) => {
  const filter = {
    isExtend: data.isExtend,
    overide: {
      ...removeEmpty({ ...data.overide }),
      typeZone: data.overide?.typeZone,
    },
  }

  const res = await client.put(
    `/landuseplans/overide/assignment/update/${id}`,
    filter
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const updateFutureOverideValues = async ({
  data,
  id,
}: OverideValuesUpdateProps) => {
  const filter = {
    isExtend: data.isExtend,
    overide: {
      ...removeEmpty({ ...data.overide }),
      typeZone: data.overide?.typeZone,
    },
  }

  const res = await client.put(
    `/futurelanduseplans/overide/assignment/future/update/${id}`,
    filter
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const addFutureOverideValues = async ({
  data,
  landId,
  futureLandUsePlanAssignmentId,
}: OverideFutureValuesProps) => {
  return client.post(
    `/futurelanduseplans/${getCurrentUserId()}/${getCompanyId()}/overide/future/assignment/${landId}/${futureLandUsePlanAssignmentId}`,
    data
  )
}

const getUserFutureOverideValuesById = async () => {
  return client.get(
    `/futurelanduseplans/overide/assignment/${getCurrentUserId()}`
  )
}

const getOverridedBuildings = async () => {
  return client.get(`/parcelles/${getCurrentUserId()}/overrided/buildings`)
}

const addOverridedBuilding = async (building: any) => {
  const { data } = await client.put(
    `/parcelles/${getCurrentUserId()}/overrided/add/${building.id}`,
    building
  )

  return { ...building, overridedId: data.id }
}

const updateOverridedBuilding = async ({ id, building }: any) => {
  const updated =
    !!building.areaOveride ||
    !!building.buildingYearOveride ||
    !!building.renovationOveride ||
    !!building.floorAreaOveride ||
    !!building.floorNbOveride ||
    !!building.heightFloorOveride ||
    !!building.unitNbOveride
  let res: { data?: { id?: number | string | null } } = {}

  if (!updated) {
    res = await client.put(`/parcelles/overrided/inactivate/${id}`)
  } else {
    res = await client.put(`/parcelles/overrided/update/${id}`, building)
  }

  return { ...building, overridedId: res?.data?.id }
}

const getBuildingsDetails = async (buildingsId: any[]) => {
  const { data } = await client.get(
    `/parcelles/buildings/${buildingsId
      .map((id) => encodeURIComponent(id))
      .join(',')}`
  )

  return data
}

const getLupDetails = async (landUsePlansId: any[]) => {
  const { data } = await client.get(
    `/parcelles/landUsePlans/${landUsePlansId
      .map((id) => encodeURIComponent(id))
      .join(',')}`
  )

  return data
}

const addOverideGroupingValuesAssignment = async ({
  data,
  groupingLandId,
  landUsePlanAssignmentId,
}: GroupingOverideValuesProps) => {
  return client.post(
    `/landuseplans/${getCurrentUserId()}/${getCompanyId()}/overide/assignment/grouping/${groupingLandId}/${landUsePlanAssignmentId}`,
    data
  )
}

const addOverideFutureGroupingValuesAssignment = async ({
  data,
  groupingLandId,
  landUsePlanAssignmentId,
}: GroupingOverideValuesProps) => {
  return client.post(
    `/futurelanduseplans/${getCurrentUserId()}/${getCompanyId()}/overide/future/assignment/grouping/${groupingLandId}/${landUsePlanAssignmentId}`,
    data
  )
}

const updateOverideGroupingValuesAssignment = async ({
  data,
  id,
}: OverideValuesUpdateProps) => {
  const filter = {
    isExtend: data.isExtend,
    overide: {
      ...removeEmpty({ ...data.overide }),
      typeZone: data.overide?.typeZone,
    },
  }

  const res = await client.put(
    `/landuseplans/overide/assignment/grouping/update/${id}`,
    filter
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const updateOverideFutureGroupingValuesAssignment = async ({
  data,
  id,
}: OverideValuesUpdateProps) => {
  const filter = {
    isExtend: data.isExtend,
    overide: {
      ...removeEmpty({ ...data.overide }),
      typeZone: data.overide?.typeZone,
    },
  }

  const res = await client.put(
    `/futurelanduseplans/overide/assignment/grouping/future/update/${id}`,
    filter
  )

  return {
    data: {
      ...data,
      land_use_plan_assignment_id: res.data?.land_use_plan_assignment_id,
    },
  }
}

const getUserFutureGroupingOverideValuesById = async () => {
  return client.get(
    `/futurelanduseplans/overide/grouping/assignment/${getCurrentUserId()}`
  )
}

const getLandDetails = async ({
  id,
  geoPolygon,
}: {
  id: any
  geoPolygon: any
}) => {
  const { data } = await client.get(`/parcelles/land/details/${id}`)

  return { ...data, geoPolygon }
}

const getAssignementsByLupId = async (lups: string[]) => {
  return client.get(`/landuseplanassignements/${lups.join(',')}/geojson`)
}

const getAuthorizedDistanceLimit = async ({
  id,
  distance,
}: DistanceLimitProps) => {
  return client.get(`/parcelles/${id}/${distance}/authorizedDistance`)
}

const parcelService = {
  getUserFutureOverideValuesById,
  updateFutureOverideValues,
  addFutureOverideValues,
  updateOverideValues,
  addOverideValuesAssignment,
  getParcel,
  getParcelNumbers,
  getParcelFao,
  getParcelTransactions,
  getGroupedLandZones,
  getLandEssentialInfo,
  getUserOverideValuesById,
  getLandRdppfs,
  getRegionRdppfs,
  getOverridedBuildings,
  addOverridedBuilding,
  updateOverridedBuilding,
  getBuildingsDetails,
  getLupDetails,
  addOverideGroupingValuesAssignment,
  addOverideFutureGroupingValuesAssignment,
  updateOverideGroupingValuesAssignment,
  updateOverideFutureGroupingValuesAssignment,
  getUserGroupingOverideValuesById,
  getUserFutureGroupingOverideValuesById,
  getLandDetails,
  getAssignementsByLupId,
  getAuthorizedDistanceLimit,
}

export default parcelService

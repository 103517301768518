import { MAPBOX_TOKEN } from '../../config'

const STYLE_PREFIX = 'mapbox://styles/'

export const switchBasemap = async (
  map: any,
  styleID: string,
  threeDSource: string
) => {
  const style = styleID.startsWith(STYLE_PREFIX)
    ? styleID.substring(STYLE_PREFIX.length)
    : styleID

  const response = await fetch(
    `https://api.mapbox.com/styles/v1/${style}?access_token=${MAPBOX_TOKEN}`
  )

  const newStyle = await response.json()

  if (!newStyle.message && map.getStyle()) {
    const currentStyle = map.getStyle()

    // ensure any sources from the current style are copied across to the new style

    // set the 3D in the new style
    if (map.getSource(threeDSource)) {
      newStyle.terrain = {
        source: 'mapbox-dem',
        exaggeration: 1.5,
      }
    }

    newStyle.sources = { ...currentStyle.sources, ...newStyle.sources }

    // find the index of where to insert our layers to retain in the new style
    let labelIndex = newStyle?.layers?.findIndex(
      (el: { id: string | undefined }) => {
        return el.id === 'waterway-label'
      }
    )

    // default to on top
    if (labelIndex === -1) {
      labelIndex = newStyle.layers.length
    }

    let appLayers = currentStyle?.layers?.filter(
      (el: { source: string | undefined; id: string | undefined }) =>
        // app layers are the layers to retain, and these are any layers which have a different source set
        el.source &&
        el.source !== 'mapbox://mapbox.satellite' &&
        el.source !== 'mapbox' &&
        el.source !== 'composite' &&
        el.source !== 'mapbox://mapbox.terrain-rgb'
    )

    if (newStyle?.name === 'Popetyio-prod-default') {
      const regionName = newStyle?.layers.filter(
        (layer: any) => layer.id === 'region-name-13nchb'
      )

      appLayers = [...appLayers, ...regionName]
    }

    newStyle.layers = [
      ...newStyle.layers.slice(0, labelIndex),
      ...appLayers,
      ...newStyle.layers.slice(labelIndex, -1),
    ]

    map.setStyle(newStyle)
  }
}

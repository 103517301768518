/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fromLocalStorage } from '../../utils'
import { updateLocalUser } from '..'

import userService from '../../services/user'

const defaultConfig = {
  wall: 22,
  demolitionPrice: 140,
  preparationPrice: 80,
  correlativeHeight: 4,
  landscapingPrice: 80,
  buildingPrice: 680,
  ownFunds: 15,
  interestRate: 1,
  constructionTime: 18,
  sellingPrice: 9000,
  salesCommissions: 3.5,
  notaryFee: 0.017,
  transfertRight: 0.033,
  authorisationAndTaxe: 0.04,
  managementFee: 0.03,
  othersAdditional: 0.02,
}

const initialState = {
  user: fromLocalStorage('user-details', true),
  roles: null,
  config: defaultConfig,
  dashboardTemplates: [
    { type: 'pdf', value: 'list', group: 'PDF' },
    { type: 'pdf', value: 'details', group: 'PDF' },
    { type: 'excel', value: 'excel', group: 'Excel' },
  ],
}

export const getCurrentUserDetails = createAsyncThunk(
  'user/getCurrentUserDetails',
  async (_data, thunkAPI) => {
    return userService.getCurrentUserDetails().then((res) => {
      if (res.data) {
        thunkAPI.dispatch(updateLocalUser({ ...res.data }))
      }

      return res
    })
  }
)

export const getRoles = createAsyncThunk('user/getRoles', userService.getRoles)

export const getFinancialDefault = createAsyncThunk(
  'user/getFinancialDefault',
  userService.getFinancialDefault
)

export const updateLanguage = createAsyncThunk(
  'user/updateLanguage',
  userService.updateLanguage
)

export const updateFinancialDefault = createAsyncThunk(
  'user/updateFinancialDefault',
  userService.updateFinancialDefault
)

export const terminatePlan = createAsyncThunk(
  'user/terminatePlan',
  userService.terminatePlan
)

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  userService.updatePassword
)

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Handle synchronous actions here

    forgetUser: (state) => {
      state.user = null
      state.zones = null
      state.roles = null
      state.config = defaultConfig
      state.subscription = null

      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
      localStorage.removeItem('user-details')
    },

    updateLocalUserDetails: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      localStorage.setItem('user-details', JSON.stringify(state.user))
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [getCurrentUserDetails.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.user = data
      localStorage.setItem('user-details', JSON.stringify(state.user))
    },
    [getRoles.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.roles = data
    },
    [getFinancialDefault.fulfilled]: (state, action) => {
      const { data } = action.payload

      data.date = new Date().toISOString()

      if (data) state.config = data
    },
    [updateFinancialDefault.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) state.config = { ...state.config, ...input, ...data }
    },
    [terminatePlan.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.user = { ...state.user, subscription_end_date: data }
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { forgetUser, updateLocalUserDetails } = user.actions

export default user.reducer

import ApiClient from '../apiClient'

const client = new ApiClient()

const getOwnerLandList = async (owner: string) => {
  const data = { value: owner }

  const response = await client.post(`/owners/_searchLand`, data)

  if (response.data && response.data.length !== 0) {
    response.data = response.data.result
  }

  return response
}

const ownershipService = {
  getOwnerLandList,
}

export default ownershipService

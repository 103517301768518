import React from 'react'
import { useSelector } from 'react-redux'

import { faoAggregationsSelector } from '../../../redux/faoHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'
import { useI18n } from '../../../context'

const StatusFilter = ({ onChange, resultsFilter, ...other }) => {
  const aggregations = useSelector(faoAggregationsSelector)
  const { t } = useI18n()

  const items = aggregations.results.results.buckets.map((r) => ({
    ...r,
    count: r.doc_count,
  }))

  const handleChange = (newValues) => {
    onChange({ resultsFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('fao.status')} className="StatusFilter">
      <CheckboxFilter
        filters={resultsFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default StatusFilter

import { toQueryParams } from '../../utils'

type GetHistoryQueryOpts = {
  fao?: boolean
  transaction?: boolean
  listing?: boolean
}

const getHistoryQuery = ({
  fao,
  transaction,
  listing,
}: GetHistoryQueryOpts) => {
  try {
    let query: any =
      typeof sessionStorage !== 'undefined'
        ? sessionStorage.getItem('history-query')
        : null

    if (!query) return undefined

    query = JSON.parse(query)
    const { zoneFilter, regionFilter, ...input } = query

    const search = {
      ...input,
      fao,
      transaction,
      listing,
      ...(!zoneFilter?.length && { regionFilter }),
      ...(!query.cityFilter?.length && { zoneFilter }),
    }

    return toQueryParams(search, (v) => v != null)
  } catch (error) {
    console.error(error)

    return undefined
  }
}

export default getHistoryQuery

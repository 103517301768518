import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../../context'
import { aggregationsSelector } from '../../../redux/transactionHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'

const ReasonFilter = ({ onChange, reasonFilter, ...other }) => {
  const aggregations = useSelector(aggregationsSelector)
  const { t } = useI18n()

  const { reasons } = aggregations.reasons
  const items = reasons.buckets.map((r) => ({ ...r, count: r.doc_count }))

  const handleChange = (newValues) => {
    onChange({ reasonFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('transaction.reason')}>
      <CheckboxFilter
        filters={reasonFilter}
        items={items}
        onChange={handleChange}
        min={10}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default ReasonFilter

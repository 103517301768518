import { getLegendColors } from '../lastYearColor'

const legend = (t: Translator, startDate?: string) => {
  const legends = getLegendColors(startDate)

  return [
    ...legends
      .sort((a, b) => +b.name - +a.name)
      .map((d) => ({
        ...d,
        // eslint-disable-next-line eqeqeq
        year: String(d.name) == '2005' && !startDate ? ' <= 2005' : d.name,
      })),
    { name: t('common.unknown'), color: 'rgba(240, 240, 240, 0.8)' },
  ]
}

export const historicOptions = (t: Translator, startDate?: string) => ({
  transaction_last_year: {
    value: 'transaction_last_year',
    label: 'Transaction',
    description: 'transaction_last_year',
    src: '/assets/img/last_transaction.png',
    legend: legend(t, startDate),
  },
  building_permit_last_year: {
    value: 'building_permit_last_year',
    label: t('search.fao'),
    description: 'building_permit_last_year',
    src: '/assets/img/last_building_permit.png',
    legend: legend(t, startDate),
  },
  listing_rent_last_year: {
    value: 'listing_rent_last_year',
    label: t('search.listingRent'),
    description: 'listing_rent_last_year',
    src: '/assets/img/lastRentalListing.png',
    legend: legend(t, startDate),
  },
})

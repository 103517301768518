/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import * as turf from '@turf/turf'
// import turfUnion from '@turf/union'
import { fromLocalStorage } from '../../utils'
import livabilityService from '../../services/livability'
import displayMapService from '../../services/DisplayMap'

import {
  DEFAULT_MAPSTYLE,
  DISPLAY_WITH_DISTINCT,
  HIDE,
  DEFAULT_BUILDING_MAPSTYLE,
} from '../../config/constants'

const stateKey = 'developperMap'

export const getMapDisplayState = () => {
  const user = fromLocalStorage('user', true)
  const displayMapState = fromLocalStorage(stateKey, true) || {}

  const isBroker = !user?.app || user?.app === 'brokerinfo'

  if (displayMapState?.mapDisplay !== 'principal_type' && isBroker) {
    displayMapState.mapDisplay = 'principal_type'
    displayMapState.thematic = null
  }

  return displayMapState
}

const initialState = {
  buildingMapStyle:
    getMapDisplayState()?.buildingMapStyle || DEFAULT_BUILDING_MAPSTYLE,
  mapStyle: getMapDisplayState()?.mapStyle || DEFAULT_MAPSTYLE,
  prospectFilter: DISPLAY_WITH_DISTINCT,
  tagFilter: HIDE,
  favoriteFilter: HIDE,
  mapDisplay: getMapDisplayState()?.mapDisplay || 'development_score',
  buildingMapDisplay:
    getMapDisplayState()?.buildingMapDisplay || 'popety_classification',
  landUsePlanType: 'now',
  boundingSearch: false,
  layout: getMapDisplayState()?.mapStyle === 'popety/cl3a7v8hy001y14pejeo2af8o',
  thematic: fromLocalStorage('thematic', true) || null,
  geojson: {},
  availableCities: [],
  geoCenter: null,
  ofsStyle: {},
  ofsLayers: [],
  ofsMapStyle: null,
}

export const isFutureAvailable = createAsyncThunk(
  'available/city',
  displayMapService.isFutureAvailable
)

export const getCityById = createAsyncThunk(
  'display/city',
  displayMapService.getCityById
)

export const getDistrictById = createAsyncThunk(
  'display/district',
  displayMapService.getDistrictById
)

export const getRegionById = createAsyncThunk(
  'display/region',
  displayMapService.getRegionById
)

export const getIsochroneLayer = createAsyncThunk(
  'developerMapForm/getIsochroneLayer',
  livabilityService.fetchIsochroneLayer
)

export const getOfsLayers = createAsyncThunk(
  'display/getOfsLayers',
  displayMapService.getOfsLayers
)

const displayMap = createSlice({
  name: 'displayMap',
  initialState,
  reducers: {
    changeSearch: (state, action) => {
      const { payload } = action

      state.boundingSearch = payload
    },
    changeLandUsePlan: (state, action) => {
      const { payload } = action

      state.landUsePlanType = payload
      if (payload === 'now') {
        state.mapDisplay = 'development_score'
      } else if (payload === 'future') {
        state.mapDisplay = 'future_development_score'
      } else if (payload === 'difference') {
        state.mapDisplay = 'difference_development_score'
      }
      localStorage.setItem(stateKey, JSON.stringify(state))
    },
    changeMapStyle: (state, action) => {
      const { payload } = action

      state.layout = payload === 'popety/cl3a7v8hy001y14pejeo2af8o'
      state.mapStyle = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
    },
    changeMapDisplay: (state, action) => {
      const { payload } = action

      state.mapDisplay = payload.value
      state.thematic = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
      localStorage.setItem('thematic', JSON.stringify(payload))
    },
    changeBuildingMapDisplay: (state, action) => {
      const { payload } = action

      state.buildingMapDisplay = payload.value
      state.buildingThematic = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
      localStorage.setItem('buildingThematic', JSON.stringify(payload))
    },
    changeProspect: (state, action) => {
      const { payload } = action

      state.prospectFilter = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
    },
    changeTag: (state, action) => {
      const { payload } = action

      state.tagFilter = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
    },
    changeFavorite: (state, action) => {
      const { payload } = action

      state.favoriteFilter = payload
      localStorage.setItem(stateKey, JSON.stringify(state))
    },
    changeGeoCenter: (state, action) => {
      const { payload } = action

      state.geoCenter = payload
    },
    changeGeoJson: (state, action) => {
      state.geojson = action.payload || {}
    },
    changeOfsStyle: (state, action) => {
      state.ofsStyle = action.payload || {}
      if (state.ofsStyle?.name) {
        localStorage.setItem('ofsStyle', JSON.stringify(state.ofsStyle))
      }
    },
    changeOfsMapStyle: (state, action) => {
      state.ofsMapStyle = action.payload
    },
  },
  extraReducers: {
    [getCityById.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length) {
        let geojson = JSON.parse(data[0].geojson_polygon)

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < data.length; i++) {
          geojson = turf.union(geojson, JSON.parse(data[i].geojson_polygon))
        }

        state.geojson = geojson
      }
    },
    [getDistrictById.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length) {
        let geojson = JSON.parse(data[0].geojson_polygon)

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < data.length; i++) {
          geojson = turf.union(geojson, JSON.parse(data[i].geojson_polygon))
        }

        state.geojson = geojson
      }
    },
    [getRegionById.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length) {
        let geojson = JSON.parse(data[0].geojson_polygon)

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < data.length; i++) {
          geojson = turf.union(geojson, JSON.parse(data[i].geojson_polygon))
        }

        state.geojson = geojson
      }
    },
    [isFutureAvailable.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length) {
        state.availableCities = data.map((city) => city.name)
      }
    },
    [getIsochroneLayer.fulfilled]: (state, action) => {
      state.isochrone = action.payload
    },
    [getOfsLayers.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.ofsLayers = data
      }
    },
  },
})

export const {
  changeMapStyle,
  changeProspect,
  changeMapDisplay,
  changeLandUsePlan,
  changeSearch,
  changeTag,
  changeFavorite,
  changeGeoJson,
  changeGeoCenter,
  changeBuildingMapDisplay,
  changeOfsStyle,
  changeOfsMapStyle,
} = displayMap.actions

export default displayMap.reducer

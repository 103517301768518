/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { groupBy, mapBy } from '../../utils'
import tagService from '../../services/tag'

const initialState = {
  tagsByLandId: {},
  tagsUserById: {},
}

export const getTagLands = createAsyncThunk(
  'tag/getTagLands',
  tagService.getTagLands
)

export const addTag = createAsyncThunk('tag/addTag', tagService.addTag)

export const removeTag = createAsyncThunk('tag/removeTag', tagService.removeTag)

export const deleteTag = createAsyncThunk('tag/deleteTag', tagService.deleteTag)

export const isTagLand = createAsyncThunk('tag/isTagLand', tagService.isTagLand)

export const updateTag = createAsyncThunk('tag/updateTag', tagService.updateTag)

export const getTagsByUser = createAsyncThunk(
  'tag/getTagsByUser',
  tagService.getTagsByUser
)

const tag = createSlice({
  name: 'tag',
  initialState,
  reducers: {},
  extraReducers: {
    // Handle async actions here, not in reducers
    [getTagLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.tags = data
        state.tagsByLandId = groupBy(data, 'land_id')
      }
    },

    [updateTag.pending]: (state, action) => {
      const { tag_id, color, value } = action.meta.arg

      const tags = Object.values({ ...state.tagsByLandId })
        .flat()
        .filter((t) => String(t.tag_id) === String(tag_id))

      tags.forEach((_tag) => {
        _tag.color = color
        _tag.value = value
      })
    },

    [removeTag.pending]: (state, action) => {
      const input = action.meta.arg
      const tags = Array.isArray(input) ? input : [input]

      tags.forEach(({ land_id, tag_id }) => {
        // delete state.tagsByLandId[id]
        state.tagsByLandId[land_id]
          .filter((t) => String(t.tag_id) === String(tag_id))
          .forEach((_tag) => {
            _tag.active = false
          })
      })
    },

    [deleteTag.fulfilled]: (state, action) => {
      const tag_id = action.meta.arg

      Object.keys({ ...state.tagsByLandId }).forEach((land_id) => {
        state.tagsByLandId[land_id] = state.tagsByLandId[land_id].filter(
          (t) => String(t.tag_id) !== String(tag_id)
        )
      })
    },

    [addTag.pending]: (state, action) => {
      const input = action.meta.arg
      const newTags = Array.isArray(input) ? input : [input]
      const { requestId } = action.meta

      newTags.forEach((t) => {
        if (Array.isArray(state.tagsByLandId[t.land_id])) {
          state.tagsByLandId[t.land_id].unshift({ ...t, requestId })
        } else {
          state.tagsByLandId[t.land_id] = [{ ...t, requestId }]
        }
      })
    },

    [addTag.fulfilled]: (state, action) => {
      const { data } = action.payload
      const tags = data?.data
      const { requestId } = action.meta

      if (tags?.length) {
        tags.forEach((t) => {
          if (state.tagsByLandId[t.land_id]) {
            state.tagsByLandId[t.land_id] = state.tagsByLandId[
              t.land_id
            ].filter((tg) => tg.requestId !== requestId)
          }

          if (Array.isArray(state.tagsByLandId[t.land_id])) {
            state.tagsByLandId[t.land_id].unshift(t)
          } else {
            state.tagsByLandId[t.land_id] = [t]
          }
        })
      }
    },
    [getTagsByUser.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.tagsUserById = mapBy('id', data)
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleTag } = tag.actions

export default tag.reducer

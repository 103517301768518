export const multiFilters = (filters, name, label, minMax) => {
  if (minMax) {
    const text = `${label}: ${filters[name][0] ?? ''} - ${
      filters[name][1] ?? ''
    }`

    return filters[name]?.filter((n) => n != null)?.length
      ? [{ key: name, name, label: text, minMax }]
      : []
  }

  return filters[name].map((filter) => ({
    key: filter,
    name,
    label: `${label}: ${filter}`,
    delete: name === 'countryFilter' ? false : undefined,
  }))
}

export const deleteFilter = (filters, item) => {
  const { name, key, minMax } = item
  let newFilters

  if (!key || minMax) {
    newFilters = []
  } else newFilters = filters[name].filter((f) => f !== key)

  const reset = {}

  if (name === 'zoneFilter') {
    reset.cityFilter = []
  }

  if (name === 'regionFilter') {
    reset.zoneFilter = []
    reset.cityFilter = []
  }

  if (name === 'countryFilter') {
    reset.regionFilter = []
    reset.zoneFilter = []
    reset.cityFilter = []
  }

  return { ...reset, [name]: newFilters }
}

/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// import TableData from '../../pages/DeveloperMapTable/LandTable/TableData'
import { LOCATION_TYPE } from '../../config'
import { fromLocalStorage, getGeoPoint } from '../../utils'
import developerMapServices from '../../services/developerMap'
// import { overideLandUsePlansMap } from './developerMapUtils'

const initialViewport = { latitude: 46.445101, longitude: 6.633394, zoom: 10 }

const initialState = {
  // buildings: TableData,
  cities: [],
  principalTypes: [],
  landUsePlan: [],
  futureLandUsePlan: [],
  landUsePlanAssignement: [],
  futureLandUsePlanAssignement: [],
  overideLandUsePlans: [],
  futureOverideLandUsePlans: [],
  landUsePlanDisplay: false,
  buildingPermitLastYear: null,
  transactionYear: null,
  listingRentYear: null,
  addresses: [],
  layers: {},
  lands: null,
  viewport: initialViewport,
  loading: false,
  zoom: initialViewport.zoom,
  view: 'map', // fromLocalStorage('map-view') || 'map',
  dimension: fromLocalStorage('map-dimension') || '2D',
  popetyClassification: [],
  OFSClassification: [],
  OFSCategory: [],
  heaterGenerator: [],
  waterHeaterGenerator: [],
  overridedPlots: {},
}

export const fetchPrincipalType = createAsyncThunk(
  'principalType',
  developerMapServices.fetchPrincipalType
)

export const fetchPrincipalTypeByRegion = createAsyncThunk(
  'principalTypeByRegion',
  developerMapServices.fetchPrincipalTypeByRegion
)

export const fetchPrincipalTypeByDistrict = createAsyncThunk(
  'principalTypeByDistrict',
  developerMapServices.fetchPrincipalTypeByDistrict
)

export const fetchLandUsePlan = createAsyncThunk(
  'landUsePlan',
  developerMapServices.fetchLandUsePlan
)

export const fetchFutureLandUsePlan = createAsyncThunk(
  'futureLandUsePlan',
  developerMapServices.fetchFutureLandUsePlan
)

export const fetchLandUsePlanAssignement = createAsyncThunk(
  'landUsePlanAssignement',
  developerMapServices.fetchLandUsePlanAssignement
)

export const fetchFutureLandUsePlanAssignement = createAsyncThunk(
  'futureLandUsePlanAssignement',
  developerMapServices.fetchFutureLandUsePlanAssignement
)

export const fetchAddresses = createAsyncThunk(
  'fecthAdresses',
  developerMapServices.fetchAddresses
)

export const getBuildingFilters = createAsyncThunk(
  'building/getBuildingFilters',
  developerMapServices.getBuildingFilters
)

export const submitForm = createAsyncThunk(
  'parcelles/_search',
  async (data, thunkAPI) => {
    const { developerMapForm } = thunkAPI.getState()
    let form = { ...developerMapForm, ...data }

    const view = data.display || fromLocalStorage('map-view') || 'list'

    if (view === 'list') {
      delete form.search_after
      form.geo = false
      form.fromMap = false

      const address = form.locations?.find(
        (l) => l.location_type === LOCATION_TYPE.ADDRESS
      )

      const land = form.locations?.find(
        (l) => l.location_type === LOCATION_TYPE.LAND
      )

      if (address || land) {
        const location = form.locations?.[0]
        const geoPoint = getGeoPoint(location?.geo_center)

        form.geoPoint = geoPoint
        form.geoCenter = [geoPoint?.lon, geoPoint?.lat]
        if (land) form.radius = 10

        if (!form.moreOptions) form.size = 1
      } else {
        form.geoPoint = null
      }

      if (!form.size || form.size === 142) {
        form.size = fromLocalStorage('list-query', true)?.size || 25
      }
    }

    if (view === 'map' && form.onMap) {
      form = { data: form, onMap: true }
    }

    form.type = form.type || form.data.type

    return developerMapServices.searchlands(form)
  }
)

export const fetchUsersOverideLands = createAsyncThunk(
  'fecthOverides',
  developerMapServices.fetchUsersOverideLands
)

export const fetchUsersOverideLandsOnMap = createAsyncThunk(
  'fetchUsersOverideLandsOnMap',
  developerMapServices.fetchUsersOverideLandsOnMap
)

export const fetchUsersFutureOverideLands = createAsyncThunk(
  'fecthFutureOverides',
  developerMapServices.fetchUsersFutureOverideLands
)

export const fetchUsersExtendedOverideLandsOnMap = createAsyncThunk(
  'fetchUsersExtendedOverideLandsOnMap',
  developerMapServices.fetchUsersExtendedOverideLandsOnMap
)

const developerMap = createSlice({
  name: 'developerMap',
  initialState,
  reducers: {
    resetLayers: (state) => {
      state.lands = null
    },
    resetLayersSearchAfter: (state) => {
      state.layers = {
        ...state.layers,
        search_after: [],
      }

      state.filterLayers = { ...state.layers }
    },
    changeZoom: (state, action) => {
      state.zoom = action.payload
    },
    changeTransactionLastYear: (state, action) => {
      state.transactionYear = action.payload
    },
    changeListingRentYearLastYear: (state, action) => {
      state.listingRentYear = action.payload
    },
    changeBuildingPermitLastYear: (state, action) => {
      state.buildingPermitLastYear = action.payload
    },
    updateView: (state, action) => {
      state.view = action.payload
      localStorage.setItem('map-view', action.payload)
    },
    updateDimension: (state, action) => {
      state.dimension = action.payload
      localStorage.setItem('map-dimension', action.payload)
    },
    changeViewport: (state, action) => {
      state.viewport = { ...state.viewport, ...action.payload }
    },
    // Handle syncronous actions here
    clearSuggestion: (state) => {
      state.principalTypes = []
      state.landUsePlan = []
      state.futureLandUsePlan = []
      state.landUsePlanAssignement = []
      state.futureLandUsePlanAssignement = []
    },
  },
  extraReducers: {
    [fetchPrincipalType.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.principalTypes = data
    },

    [fetchPrincipalTypeByRegion.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.principalTypes = data
    },

    [fetchPrincipalTypeByDistrict.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.principalTypes = data
    },

    [fetchLandUsePlan.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.landUsePlan = data
    },

    [fetchFutureLandUsePlan.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.futureLandUsePlan = data
    },

    [fetchLandUsePlanAssignement.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.landUsePlanAssignement = data
    },

    [fetchFutureLandUsePlanAssignement.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.futureLandUsePlanAssignement = data
    },

    [fetchAddresses.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.addresses = data.result
    },
    [submitForm.pending]: (state) => {
      state.loading = true
    },
    [submitForm.rejected]: (state) => {
      state.loading = false
    },
    [submitForm.fulfilled]: (state, action) => {
      const { res, values } = action.payload

      state.loading = false

      if ((values?.data?.type || values?.type) === 'building') {
        state.buildings = res.data
      } else {
        state.lands = res.data
      }
    },
    [fetchUsersOverideLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.overideLandUsePlans = data
    },
    [fetchUsersOverideLandsOnMap.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.overridedPlots = data
      }
    },
    [fetchUsersExtendedOverideLandsOnMap.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.overridedPlots = state.overridedPlots.concat(data)
    },
    [fetchUsersFutureOverideLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.futureOverideLandUsePlans = data
    },
    [getBuildingFilters.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        Object.keys(data).forEach((keys) => (state[keys] = data[keys]))
      }
    },
  },
})

export const {
  clearSuggestion,
  changeViewport,
  changeZoom,
  resetLayers,
  resetLayersSearchAfter,
  updateView,
  updateDimension,
  changeTransactionLastYear,
  changeListingRentYearLastYear,
  changeBuildingPermitLastYear,
} = developerMap.actions

export default developerMap.reducer

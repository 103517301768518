/* eslint-disable no-param-reassign */
import * as turf from '@turf/turf'
import turfUnion from '@turf/union'

export const updateDistrictByIdSate = (state, action) => {
  const { data } = action.payload

  if (data?.length) {
    const geoCenter = data[0].geojson_center
      ? [
          JSON.parse(data[0].geojson_center).coordinates[0],
          JSON.parse(data[0].geojson_center).coordinates[1],
        ]
      : null

    const geojson = data.reduce((acc, r) => {
      return acc?.type
        ? {
            ...turfUnion(
              acc,
              turf.multiPolygon(JSON.parse(r.geojson_polygon).coordinates)
            ),
          }
        : turf.multiPolygon(JSON.parse(r.geojson_polygon).coordinates)
    }, [])

    const { geometry } = geojson

    state.selectedAlert.multiPolygon = geometry

    const newViewport = data[0].geojson_center
      ? {
          latitude: geoCenter[1],
          longitude: geoCenter[0],

          zoom: 10,
        }
      : {}

    state.selectedAlert.viewport = {
      ...state.selectedAlert.viewport,
      ...newViewport,
    }

    state.selectedAlert.geoCenter = geoCenter

    const district = action.meta.arg

    state.selectedAlert.data = [
      ...(state.selectedAlert.data || []),
      {
        geoPolygon: [{ type: 'Feature', geometry: data }],
        location: district,
        geoCenter,
      },
    ]
  }
}

export const updateDistrictGeojsonByZoneSate = (state, action) => {
  const { data } = action.payload

  if (data) {
    const geojson = data.reduce((acc, r) => {
      return [
        ...acc,
        turf.polygon(JSON.parse(r.geojson_polygon).coordinates[0]),
      ]
    }, [])

    const union = turfUnion(...geojson)
    const { coordinates } = union.geometry

    state.selectedAlert.multiPolygon = {
      type: 'MultiPolygon',
      coordinates:
        state.selectedAlert.multiPolygon.coordinates.concat(coordinates),
    }
  }
}

import { developmentOptions } from './developmentOptions'
import { buildingsOptions } from './buildingsOptions'
import { historicOptions } from './historicOptions'
import { zoneOptions } from './zoneOptions'
import { classificationOptions } from './classificationOptions'
import { characteristicOptions } from './characteristicOptions'
import { locationOptions } from './locationOptions'
import { energyOptions } from './energyOptions'
import { historyOptions } from './historyOptions'

export const layerOptions = (t: Translator, startDate?: string) =>
  [
    { value: 'score', options: developmentOptions(t) },
    { value: 'zone', options: zoneOptions(t) },
    { value: 'buildings', options: buildingsOptions(t) },
    {
      value: 'historic',
      options: Object.values(historicOptions(t, startDate)),
    },
  ].map((l) => ({
    ...l,
    name: t(`search.legend.${l.value}` as I18nKey),
    options: l.options.map((o) => ({ ...o, group: l.value })),
  }))

export const buildingLayersOptions = (t: Translator) => [
  { value: 'classification', options: classificationOptions(t) },
  { value: 'characteristic', options: characteristicOptions(t) },
  { value: 'Location rating', options: locationOptions(t) },
  { value: 'energy', options: energyOptions(t) },
  { value: 'history', options: historyOptions(t) },
]

import styled, { css } from 'styled-components'
import AppBar from '@mui/material/AppBar'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'

export const HeaderMenuRoot = styled(AppBar)`
  height: 56px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: 64px;
    }
  `}

  a, .link {
    color: #8d9ba3;
    text-transform: uppercase;
    border-style: solid;
    border-width: 3px 0 0 0;
    border-color: transparent;
    text-decoration: none;
    cursor: pointer;

    ${({ theme }) => css`
      ${theme.breakpoints.up('md')} {
        line-height: 64px;
      }
    `}
  }
`

export const MenuContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-left: 16px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      margin-left: 8px;
    }
  `}

  > div:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
  }
`

export const UserAvatarContainer = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;

  .MuiAvatar-root {
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: gainsboro;

    ${({ border }) =>
      border &&
      css`
        border: 2px solid #42474b;
      `}
  }

  > a {
    margin-right: 6px;
    margin-left: auto;
    line-height: inherit;
  }

  > .User {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-left: 8px;

    > button {
      padding-right: 0;
    }
  }
`

export const MenuItem = styled.div`
  > a,
  > span {
    padding: 25px;
    transition: all 0.2s;
  }

  > a.active,
  > span.active {
    color: #ededed;
    background-color: #00ade6;
  }

  > a:hover,
  > span:hover {
    padding: 22px;
    color: #ededed;
    border-bottom-color: #ededed;
    border-bottom: 3px solid;
  }
`

export const HistoryMenu = styled(Menu)`
  a {
    color: inherit;
  }

  .MuiMenu-paper {
    top: 64px !important;
  }
`

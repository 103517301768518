import { useEffect, useRef, useState } from 'react'

const useSize = ({ w = 0 } = {}) => {
  const ref = useRef<HTMLElement>()
  const [width, setWidth] = useState(w)

  useEffect(() => {
    if (!ref.current) return

    const updateWidth = () => {
      setWidth(ref.current?.offsetWidth || w)
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return { ref, width }
}

export { useSize }

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Hidden from '@mui/material/Hidden'
// import DashboardIcon from '@mui/icons-material/Dashboard'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ListItemIcon from '@mui/material/ListItemIcon'
// import AddAlertIcon from '@mui/icons-material/AddAlert'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AssessmentIcon from '@mui/icons-material/Assessment'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'

import { MapSearchIcon } from '../../icons'
import { useI18n } from '../../../context'
import { appSelector, userSelector } from '../../../redux'
import LanguageMenu from '../UserMenu/LanguageMenu'
import MenuHeader from './MenuHeader'
import BottomMenuList from './BottomMenuList'
import { MenuListContainer } from './MobileMenu.style'

const MobileMenu = () => {
  const { t } = useI18n()
  const [open, setOpen] = useState(false)
  const location = useLocation()

  const user = useSelector(userSelector)
  const app = useSelector(appSelector)
  const showAnalysis = app === 'landdev' || app === null

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    handleClose()
  }, [location])

  if (!user) return null

  return (
    <>
      <Hidden mdUp>
        <IconButton
          onClick={handleOpen}
          aria-label="menu"
          color="inherit"
          edge="start"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <MenuListContainer role="presentation">
          <MenuHeader />

          <Divider />

          <List>
            <ListItem button component={NavLink} to="/developer-map">
              <ListItemIcon>
                <MapSearchIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.landMapSearch')} />
            </ListItem>
            {showAnalysis && (
              <ListItem button component={NavLink} to="/land-detail">
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={t('common.landDetailAnalysis')} />
              </ListItem>
            )}
            <ListItem button component={NavLink} to="/transaction-history">
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.transactionHistory')} />
            </ListItem>
            <ListItem button component={NavLink} to="/fao-history">
              <ListItemIcon>
                <LibraryBooksIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.faoHistory')} />
            </ListItem>
            <ListItem button component={NavLink} to="/listing-history">
              <ListItemIcon>
                <FormatAlignLeftIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.listingHistory')} />
            </ListItem>
          </List>

          <Divider />

          {/* <ListItem button component={NavLink} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={t('common.myDashboard')} />
          </ListItem> */}
          {/* <ListItem button component={NavLink} to="/my-alerts">
            <ListItemIcon>
              <AddAlertIcon />
            </ListItemIcon>
            <ListItemText primary={t('common.myAlerts')} />
          </ListItem> */}
          {user?.roles?.includes('Admin_company') && (
            <ListItem component={NavLink} to="/company-dashboard">
              <ListItemIcon>
                <PeopleAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('common.myCompanyDashboard')} />
            </ListItem>
          )}
          <LanguageMenu />

          <Divider />

          <BottomMenuList onClose={handleClose} />
        </MenuListContainer>
      </Drawer>
    </>
  )
}

export default MobileMenu

/* eslint-disable max-lines */
const currentYear = new Date().getFullYear()

const restrictions = [
  {
    name: 'reserved_zone',
    color: 'rgba(150, 150, 150, 0.8)',
  },
  { name: 'polluted_zone', color: 'rgba(194, 46, 0, 0.8)' },
  {
    name: 'underwater_protected_zone',
    color: 'rgba(0, 147, 156, 0.8)',
  },
  {
    name: 'forest_zone',
    color: 'rgba(153, 204, 153, 0.8)',
  },
  { name: 'built_sites_protected_zone', color: 'rgba(51, 51, 51, 0.8)' },
  {
    name: 'watercourse_limit_zone',
    color: 'rgba(0, 51, 102, 0.8)',
  },
  { name: 'forest_limit_zone', color: 'rgba(204, 204, 153, 0.8)' },
  { name: 'not_yet_defined_special_zone', color: 'rgba(0, 0, 0, 0.8)' },
  { name: 'mandatory_planning_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'archaeological_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'nature_protection_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'no_max_cos_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'other_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'protected_objects', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'danger_zone', color: 'rgba(255, 204, 0, 0.8)' },
  { name: 'building_right', color: 'rgba(255, 204, 0, 0.8)' },
  {
    name: 'multiple',
    color: 'rgba(248, 104, 51, 0.8)',
  },
]

export const layersMap = (
  mapDisplay,
  tags,
  landLayer,
  map,
  favorites,
  prospects,
  thematic,
  overridedPlots
) => {
  if (mapDisplay === 'tag' && tags) {
    const caseTagExpression = ['case']

    // Inserate color values for each tag
    for (const tag of tags) {
      caseTagExpression.push(
        ['in', ['get', 'land_id'], ['literal', tag.land_id]],
        tag.color
      )
    }
    // Add default
    caseTagExpression.push('#fff')

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', caseTagExpression)
  } else if (mapDisplay === 'favorite') {
    const caseFavoriteExpression = ['case']

    // Inserate color values for each tag
    // for (const favorite of favorites) {
    caseFavoriteExpression.push(
      ['in', ['get', 'land_id'], ['literal', favorites]],
      '#00ade6'
    )
    // }
    // Add default
    caseFavoriteExpression.push('#fff')

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', caseFavoriteExpression)
  } else if (mapDisplay === 'prospect') {
    const caseProspectExpression = ['case']

    // Inserate color values for each tag
    caseProspectExpression.push(
      ['in', ['get', 'land_id'], ['literal', prospects]],
      '#00ade6'
    )

    // Add default
    caseProspectExpression.push('#fff')

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', caseProspectExpression)
  } else if (mapDisplay === 'data_reliability') {
    let colorExpression = {
      property: 'official_usage_indice_extracted',
      type: 'categorical',
      stops: [
        [true, 'rgba(120,198,121,0.8)'],
        [false, 'rgba(247,252,185,0.8)'],
      ],
    }

    colorExpression = {
      property: 'official_occupation_indice_extracted',
      type: 'categorical',
      stops: [
        [true, 'rgba(120,198,121,0.8)'],
        [false, 'rgba(247,252,185,0.8)'],
      ],
    }
    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (mapDisplay === 'building_area') {
    const colorExpression = {
      property: mapDisplay,
      type: 'categorical',
      stops: [
        [true, '#00939c'],
        [false, '#c22e00'],
      ],
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (
    mapDisplay === 'average_building_year' ||
    mapDisplay === 'average_renovation_year'
  ) {
    const colorExpression = {
      property: mapDisplay,
      // type: 'categorical',
      default: '#f0f0f0',
      stops: [
        [0, '#c22e00'],
        [1940, '#c22e00'],
        [1950, '#d0532b'],
        [1960, '#dd7755'],
        [1970, '#eb9c80'],
        [1980, '#f8c0aa'],
        [1990, '#bae1e2'],
        [2000, '#8cced1'],
        [2010, '#5dbabf'],
        [2016, '#2fa7ae'],
        [currentYear, '#00939c'],
      ],
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (mapDisplay === 'unit_nb') {
    const colorExpression = {
      property: mapDisplay,
      // type: 'categorical',
      default: '#f0f0f0',
      stops: [
        [1, '#fff7fb'],
        [2, '#ece7f2'],
        [3, '#d0d1e6'],
        [4, '#a6bddb'],
        [5, '#74a9cf'],
        [8, '#3690c0'],
        [11, '#0570b0'],
        [15, '#045a8d'],
        [20, '#023858'],
        [1000, '#082649'],
      ],
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (mapDisplay === 'highest_building_protected_rank') {
    const colorExpression = {
      property: mapDisplay,
      default: '#f0f0f0',
      stops: [
        [0, '#f0f0f0'],
        [1, '#74c477'],
        [2, '#fcae91'],
        [3, '#fb6a4a'],
        [4, '#de2d26'],
      ],
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (mapDisplay === 'rdppfs') {
    const colorExpression = {
      property: 'restriction_type',
      default: '#f0f0f0',
      type: 'categorical',
      stops: restrictions.map((them) => [them.name, them.color]),
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (
    mapDisplay === 'transaction_last_year' ||
    mapDisplay === 'building_permit_last_year' ||
    mapDisplay === 'listing_rent_last_year'
  ) {
    const thematicSorted = thematic.legend
      .filter((them) => them.year)
      .sort((a, b) => Number(a.name) - Number(b.name))

    const colorExpression = {
      property: mapDisplay,
      default: '#f0f0f0',
      stops: thematicSorted?.map((them) => [Number(them.name), them.color]),
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else if (mapDisplay === 'principal_type') {
    const uniqValue = thematic?.legend.filter(
      (them, index, self) =>
        index === self.findIndex((find) => find.value === them.value)
    )

    const colorExpression = {
      property: mapDisplay,
      default: '#f0f0f0',
      type: 'categorical',
      stops: uniqValue
        ?.filter((them) => them.color)
        .map((them) => [them.value, them.color]),
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)
  } else {
    const caseTagExpression = ['case']
    const isDifference = mapDisplay?.split('_')?.[0] === 'difference'
    const { developmentGroupedPlots, underExploitedGroupedPlots } =
      overridedPlots

    const colorExpression = {
      property: mapDisplay,
      // default: '#f0f0f0',
      stops: !isDifference
        ? [
            [0, '#ffbfbf'],
            [25, '#ffc3a5'],
            [50, '#FFE768'],
            [75, '#8ED1A5'],
            [100, '#00441b'],
          ]
        : [
            [-100, '#ffbfbf'],
            [-50, '#ffc3a5'],
            [0, '#f0f0f0'],
            [50, '#8ED1A5'],
            [100, '#00441b'],
          ],
    }

    // Change the color
    map.setPaintProperty(landLayer, 'fill-color', colorExpression)

    if (
      mapDisplay === 'development_score' &&
      developmentGroupedPlots?.length > 0
    ) {
      for (const plot of developmentGroupedPlots) {
        if (plot.developmentScoreColor) {
          caseTagExpression.push(
            ['in', ['get', 'land_id'], ['literal', plot.land_id]],
            plot.developmentScoreColor
          )
        }
      }

      // The "default" values needed to be define and dislay colors
      caseTagExpression.push([
        'interpolate',
        ['linear'],
        ['get', mapDisplay],
        // the defined steps.
        !isDifference ? 0 : -100,
        '#ffbfbf',
        !isDifference ? 25 : -50,
        '#ffc3a5',
        !isDifference ? 50 : 0,
        '#FFE768',
        !isDifference ? 75 : 50,
        '#8ED1A5',
        100,
        '#00441b',
      ])

      map.setPaintProperty(landLayer, 'fill-color', caseTagExpression)
    } else if (
      mapDisplay === 'under_exploited_score' &&
      underExploitedGroupedPlots?.length > 0
    ) {
      for (const plot of underExploitedGroupedPlots) {
        if (plot.underExploitedScoreColor) {
          caseTagExpression.push(
            ['in', ['get', 'land_id'], ['literal', plot.land_id]],
            plot.underExploitedScoreColor
          )
        }
      }
      // The "default" values needed to be define and dislay colors
      caseTagExpression.push([
        'interpolate',
        ['linear'],
        ['get', mapDisplay],
        // the defined steps.
        !isDifference ? 0 : -100,
        '#ffbfbf',
        !isDifference ? 25 : -50,
        '#ffc3a5',
        !isDifference ? 50 : 0,
        '#FFE768',
        !isDifference ? 75 : 50,
        '#8ED1A5',
        100,
        '#00441b',
      ])
      map.setPaintProperty(landLayer, 'fill-color', caseTagExpression)
    }
  }
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useI18n } from '../../../context'
import { groupedLocationsSelector } from '../../../redux/location'
import {
  alertSelectedLocationsSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import useLocations from './useLocations'
import { GroupLabel } from './ZonesStep.style'

const LocationsSearch = ({ inputProps, ...props }) => {
  const { t } = useI18n()
  const dispatch = useDispatch()

  const { citiesByName, regionsByName, zonesByName } = useLocations()

  const zones = Object.values(zonesByName)
  const regions = Object.values(regionsByName)
  const cities = Object.values(citiesByName)

  const groupCount = {
    zones: zones?.length,
    regions: regions?.length,
    cities: cities?.length,
    allRegions: regions?.length,
  }

  const locations = useSelector(groupedLocationsSelector)
  const selectedLocations = useSelector(alertSelectedLocationsSelector)

  const options = [
    {
      uid: 'allRegions',
      value: 'allRegions',
      label: t('common.allRegions'),
      group: 'allRegions',
    },
    ...locations,
  ]

  const renderGroup = (params) => [
    <GroupLabel key={params.key} component="div">
      <span>{t(`common.${params.group}`).toUpperCase()}</span>
      <div>{groupCount[params.group]}</div>
    </GroupLabel>,
    params.children,
  ]

  const handleChange = (e, newValue) => {
    const input = {
      regionsSelected: newValue.filter((val) => val.group === 'regions'),
      zonesSelected: newValue.filter((val) => val.group === 'zones'),
      citiesSelected: newValue.filter((val) => val.group === 'cities'),
    }

    if (newValue.find((val) => val.group === 'allRegions')) {
      input.regionsSelected = [...input.regionsSelected, ...(regions || [])]
    }

    dispatch(updateSelectedAlert(input))
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      value={selectedLocations}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option.uid === val.uid}
      getOptionLabel={(option) => option.label}
      options={options}
      groupBy={(option) => option.group}
      renderGroup={renderGroup}
      multiple
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('myAlert.zones.places')}
          {...props}
          inputProps={{
            ...inputProps,
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  )
}

export default LocationsSearch

import { isDev, PAGES } from '../../config'
import { EnIcon, FrIcon, DeIcon /* , ItIcon */ } from '../../components/icons'

/**
 * App default locale
 */
const APP_DEFAULT_LOCALE = process.env.APP_DEFAULT_LOCALE || 'en-us'

const supportedLanguages = {
  de: {
    id: 'de',
    name: 'Allemand',
    icon: DeIcon,
    hide: process.env.I18N_DE === 'false',
  },
  fr: {
    id: 'fr',
    name: 'Français',
    icon: FrIcon,
    hide: process.env.I18N_FR === 'false',
  },
  /*
  it: {
    id: 'it',
    name: 'Italien',
    icon: ItIcon,
    hide: process.env.I18N_IT === 'false',
  },
  */
  en: {
    id: 'en',
    name: 'English',
    icon: EnIcon,
    hide: process.env.I18N_EN === 'false',
  },
} as const

export type SupportedLanguage = keyof typeof supportedLanguages

const isSupportedLanguage = (language: string) => {
  return (
    !!supportedLanguages[language as SupportedLanguage] ||
    !!supportedLanguages[language?.split(/[-_]/)[0] as SupportedLanguage]
  )
}

/**
 * Return the language part from the specified language (en-US) if it is not supported
 */
const parseLanguage = (language: string) => {
  if (!language) return language

  const locale = supportedLanguages[language as SupportedLanguage]?.id

  if (locale) return locale

  return supportedLanguages[language?.split(/[-_]/)[0] as SupportedLanguage]?.id
}

// const fallbackLanguage = parseLanguage(APP_DEFAULT_LOCALE)

const loadI18nPage = (page: string) => {
  return import(`../../i18n/${page}.json`).catch(() => {
    if (isDev) {
      console.error('load fallback translations:', { page })
    }

    return import(`../../i18n/${page}.json`).catch(() => null)
  })
}

/**
 * Dynamically loads only the translations that the user needs on the specified page
 * @param {string} language language to load.
 * @param {string} page the page which translations will be loaded
 */
const fetchTranslations = async (
  page: string = PAGES[0].key
): Promise<Record<string, any>> => {
  try {
    const translations = [
      loadI18nPage('common'),
      ...page.split(',').map((p) => loadI18nPage(p)),
    ]

    const modules = await Promise.all(translations)

    return Object.assign({}, ...modules.map((m) => m?.default))
  } catch (error) {
    console.error(error)

    return {}
  }
}

/**
 * Get the Navigator language
 */
const getBrowserLanguage = () => {
  try {
    if (typeof window !== 'undefined') {
      const navLanguage: string =
        navigator.language ||
        (navigator as any).userLanguage ||
        (navigator.languages && navigator.languages[0])

      return navLanguage || APP_DEFAULT_LOCALE
    }

    return APP_DEFAULT_LOCALE
  } catch (e) {
    console.error(e)

    return APP_DEFAULT_LOCALE
  }
}

export {
  getBrowserLanguage,
  isSupportedLanguage,
  fetchTranslations,
  APP_DEFAULT_LOCALE,
  parseLanguage,
  supportedLanguages,
}

import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const DeIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 640 480">
      <path fill="#ffce00" d="M0 320h640v160H0z" />
      <path d="M0 0h640v160H0z" />
      <path fill="#d00" d="M0 160h640v160H0z" />
    </SvgIcon>
  )
}

export default DeIcon

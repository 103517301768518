import React, { MouseEvent } from 'react'
import { DialogActionsProps as MuiDialogActionsProps } from '@mui/material/DialogActions'
import { ButtonProps } from '@mui/material/Button'

import Button from '../Button'

import DialogActionsRoot from './DialogActions.style'

export type DialogActionsProps = MuiDialogActionsProps & {
  /**
   * The text of the Cancel button	string
   */
  cancelText?: string
  /**
   * The text of the Confirm button
   */
  okText?: string
  /**
   * A callback of ok button
   */
  onOk?: (event: MouseEvent<HTMLButtonElement>) => void
  okButtonProps?: Omit<ButtonProps, 'onClick'>
  cancelButtonProps?: Omit<ButtonProps, 'onClick'>
  /**
   * A callback of cancel button
   */
  onCancel?: (event: MouseEvent<HTMLButtonElement>) => void
  /**
   * Indicates whether the dialog operation is in loading state
   */
  loading?: boolean
  /**
   * Disable dialog padding or margin
   */
  disableGutters?: boolean
}

/**
 * Modal actions container
 */
const DialogActions = (props: DialogActionsProps) => {
  const {
    children,
    onOk,
    cancelText,
    cancelButtonProps,
    okText,
    onCancel,
    loading,
    okButtonProps,
    ...other
  } = props

  return (
    <DialogActionsRoot {...other} {...{ variant: okButtonProps?.variant }}>
      {children}
      {cancelText && (
        <Button color="secondary" {...cancelButtonProps} onClick={onCancel}>
          {cancelText}
        </Button>
      )}
      {okText && (
        <Button
          color="secondary"
          {...okButtonProps}
          onClick={onOk}
          loading={loading}
          disabled={loading || okButtonProps?.disabled}
        >
          {okText}
        </Button>
      )}
    </DialogActionsRoot>
  )
}

export default DialogActions

/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines */
import * as turf from '@turf/turf'
import turfUnion from '@turf/union'

import * as actions from './actions'
import { calculateMbbArea } from './geo.utils'

import {
  getOverridedBuildings,
  updateOverridedBuilding,
  getBuildingsDetails,
} from './overridedBuildings.utils'

import { getLupDetails } from './underExploited.utils'

export default {
  [actions.getParcel.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.parcelDetail = data.data

    if (data) state.landId = data.data?.land_id

    if (data?.data?.rien) {
      const geojson = data.data?.geo_polygon?.reduce((acc, r) => {
        return [...acc, turf.multiPolygon(r?.coordinates)]
      }, [])

      const union = turfUnion(...geojson)
      const bbox = turf.bbox(union)

      const turfPolygon = turf.bboxPolygon(bbox)

      const coordinates = turfPolygon.geometry
        ? turfPolygon.geometry.coordinates
        : []

      const polygonLand = Array.isArray(
        coordinates[0] && coordinates[0][0] && coordinates[0][0][0]
      )
        ? coordinates[0][0]
        : coordinates[0]

      const bounds = polygonLand.map((i) => [+i[1], +i[0]])

      const arrayLength = bounds
        .map((point, index, array) => {
          if (index !== 0) {
            return Math.round(
              turf.distance(turf.point(array[index - 1]), turf.point(point)) *
                1000
            )
          }

          return 0
        })
        .filter(Boolean)

      const mbbBbox = turf.bbox(union?.geometry)
      const bboxPolygon = turf.bboxPolygon(mbbBbox)

      const pointLength = bboxPolygon?.geometry?.coordinates?.[0]
        .map((point, index, array) => {
          const distance =
            index !== 0 &&
            Math.round(
              // eslint-disable-next-line no-unsafe-optional-chaining
              turf.distance(turf.point(array?.[index - 1]), turf.point(point)) *
                1000
            )

          return distance
        })
        .filter(Boolean)

      state.parcelDetail = {
        ...state.parcelDetail,
        geo_polygon: union,
        mbb_area: calculateMbbArea(bboxPolygon, state.parcelDetail.mbb_area),
        mbb_length_orthogonal:
          pointLength[1] || state.parcelDetail.mbb_length_orthogonal,
        mbb_length_parallel:
          pointLength[0] || state.parcelDetail.mbb_length_orthogonal,
        arrayLength,
        groupingId: data?.groupingId,
      }
    }
  },
  [actions.getParcelNumbers.pending]: (state) => {
    state.parcelNumbers = []
  },
  // [actions.getLandRdppfs.fulfilled]: (state, action) => {
  //   const { data } = action.payload

  //   console.log({ data })

  //   if (data) {
  //     state.rdppfs = data
  //   }
  // },
  [actions.getParcelNumbers.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.parcelNumbers = data
  },
  [actions.getAssignementsByLupId.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.lupAassignements = data
  },
  [actions.getParcelTransactions.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.transactions = data
  },
  [actions.getLandRdppfs.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) {
      state.rdppfs = data
      state.parcelDetail.rdppfs = data.map((d) => {
        return { ...d, geojson: JSON.parse(d.geojson) }
      })
    }
  },
  [actions.getParcelFao.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.faos = data
  },
  [actions.getGroupedLandZones.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) {
      state.groupedLandZones = data

      try {
        state.groupedLandZones.geo_polygon = data?.geo_polygon_distance_limit
          ? JSON.parse(data?.geo_polygon_distance_limit)
          : null
      } catch (error) {
        console.error(error)
        data.geo_polygon = null
      }
    }
  },
  [actions.addOverideValues.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.land_use_plans]

    const index = state.parcelDetail.land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    newArr[index] = {
      ...newArr[index],
      ...data?.overide_json,
      overidesId: data?.id,
    }

    const { overide_json } = data

    const utilisation = `${overide_json?.typeZone?.ius}_overide`
    const occupation = `${overide_json?.typeZone?.ios}_overide`

    const indicesUsed = overide_json?.[utilisation]?.value
      ? utilisation
      : overide_json?.[occupation]?.value
      ? occupation
      : null

    state.parcelDetail.indice_used_edited = indicesUsed
    state.parcelDetail.isIndicesOverrided = !!indicesUsed
    state.parcelDetail.land_use_plans = newArr
  },
  [actions.updateOverideValues.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.land_use_plans]

    const index = state.parcelDetail.land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    newArr[index] = { ...newArr[index], ...data?.overide }

    const { overide } = data

    const utilisation = `${overide.typeZone.ius}_overide`
    const occupation = `${overide.typeZone.ios}_overide`

    const indicesUsed = overide?.[utilisation]?.value
      ? utilisation
      : overide?.[occupation]?.value
      ? occupation
      : null

    state.parcelDetail.indice_used_edited = indicesUsed
    state.parcelDetail.isIndicesOverrided = !!indicesUsed
    state.parcelDetail.land_use_plans = newArr
  },
  [actions.getUserOverideValuesById.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.land_use_plans]

    data.forEach((overides) => {
      if (
        Number(state.parcelDetail.land_id) === Number(overides.land_id) ||
        overides.extend
      ) {
        const index = state.parcelDetail.land_use_plans?.findIndex(
          (item) =>
            Number(item.id) === Number(overides.land_use_plan_assignment_id)
        )

        if (index !== -1) {
          state.overidesValues = overides
        }

        if (newArr[index]?.cover_area_perc > 0.5) {
          const overridedValues = overides?.overide_json?.[
            `${overides?.overide_json?.typeZone?.ius}_overide`
          ]?.value
            ? `${overides?.overide_json?.typeZone?.ius}_overide`
            : overides?.overide_json?.[
                `${overides?.overide_json?.typeZone?.ios}_overide`
              ]?.value
            ? `${overides?.overide_json?.typeZone?.ios}_overide`
            : null

          state.parcelDetail.indice_used_edited = overridedValues
        }

        newArr[index] = {
          ...newArr[index],
          ...overides?.overide_json,
          overidesId: overides.id,
          overidesValues: overides.overide_json,
        }
        state.parcelDetail.land_use_plans = newArr
      }
    })
  },
  [actions.getUserGroupingOverideValuesById.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.land_use_plans]

    data.forEach((overides) => {
      if (
        Number(state.parcelDetail.groupingId) === Number(overides.land_id) ||
        overides.extend
      ) {
        const index = state.parcelDetail.land_use_plans?.findIndex(
          (item) =>
            Number(item.id) === Number(overides.land_use_plan_assignment_id)
        )

        if (index !== -1) state.overidesValues = overides

        newArr[index] = {
          ...newArr[index],
          ...overides?.overide_json,
          overidesId: overides.id,
          overidesValues: overides.overide_json,
        }
        state.parcelDetail.land_use_plans = newArr
      }
    })
  },
  [actions.addFutureOverideValues.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.future_land_use_plans]

    const index = state.parcelDetail.future_land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    newArr[index] = {
      ...newArr[index],
      ...data?.overide_json,
      overidesId: data?.id,
    }

    const { overide_json } = data

    const utilisation = `${overide_json?.typeZone?.ius}_overide`
    const occupation = `${overide_json?.typeZone?.ios}_overide`

    const indicesUsed = overide_json?.[utilisation]?.value
      ? utilisation
      : overide_json?.[occupation]?.value
      ? occupation
      : null

    state.parcelDetail.indice_used_edited = indicesUsed
    state.parcelDetail.isIndicesOverrided = !!indicesUsed
    state.parcelDetail.future_land_use_plans = newArr
  },
  [actions.updateFutureOverideValues.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.future_land_use_plans]

    const index = state.parcelDetail.future_land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    newArr[index] = { ...newArr[index], ...data?.overide }

    const { overide_json } = data

    const utilisation = `${overide_json?.typeZone?.ius}_overide`
    const occupation = `${overide_json?.typeZone?.ios}_overide`

    const indicesUsed = overide_json?.[utilisation]?.value
      ? utilisation
      : overide_json?.[occupation]?.value
      ? occupation
      : null

    state.parcelDetail.indice_used_edited = indicesUsed
    state.parcelDetail.isIndicesOverrided = !!indicesUsed
    state.parcelDetail.future_land_use_plans = newArr
  },
  [actions.getUserFutureOverideValuesById.fulfilled]: (state, action) => {
    const { data } = action.payload

    const newArr = state?.parcelDetail?.future_land_use_plans && [
      ...state.parcelDetail.future_land_use_plans,
    ]

    data?.forEach((overides) => {
      if (
        Number(state.parcelDetail.land_id) === Number(overides.land_id) ||
        overides.extend
      ) {
        const index = state.parcelDetail.future_land_use_plans?.findIndex(
          (item) =>
            Number(item.id) === Number(overides.land_use_plan_assignment_id)
        )

        if (index !== -1) state.overidesValues = overides

        if (newArr) {
          newArr[index] = {
            ...newArr[index],
            ...overides?.overide_json,
            overidesId: overides.id,
            overidesValues: overides.overide_json,
          }
        }
        state.parcelDetail.future_land_use_plans = newArr
      }
    })
  },
  [actions.getOverridedBuildings.fulfilled]: getOverridedBuildings,

  [actions.addOverridedBuilding.fulfilled]: updateOverridedBuilding,
  [actions.updateOverridedBuilding.fulfilled]: updateOverridedBuilding,
  [actions.getBuildingsDetails.fulfilled]: getBuildingsDetails,
  [actions.getLupDetails.fulfilled]: getLupDetails,
  [actions.addOverideGroupingValuesAssignment.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) {
      state.valuesOverided = data
    }
  },
  [actions.updateOverideGroupingValuesAssignment.fulfilled]: (
    state,
    action
  ) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.land_use_plans]

    const index = state.parcelDetail.land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    if (newArr[index]) {
      newArr[index] = { ...newArr[index], ...data?.overide }
    }

    state.parcelDetail.land_use_plans = newArr
  },
  [actions.updateOverideFutureGroupingValuesAssignment.fulfilled]: (
    state,
    action
  ) => {
    const { data } = action.payload

    const newArr = [...state.parcelDetail.future_land_use_plans]

    const index = state.parcelDetail.future_land_use_plans?.findIndex(
      (item) => Number(item.id) === Number(data.land_use_plan_assignment_id)
    )

    newArr[index] = { ...newArr[index], ...data?.overide }

    state.parcelDetail.future_land_use_plans = newArr
  },
  [actions.getUserFutureGroupingOverideValuesById.fulfilled]: (
    state,
    action
  ) => {
    const { data } = action.payload

    const newArr = state?.parcelDetail?.future_land_use_plans && [
      ...state.parcelDetail.future_land_use_plans,
    ]

    data?.forEach((overides) => {
      if (
        Number(state.parcelDetail.groupingId) === Number(overides.land_id) ||
        overides.extend
      ) {
        const index = state.parcelDetail.future_land_use_plans?.findIndex(
          (item) =>
            Number(item.id) === Number(overides.land_use_plan_assignment_id)
        )

        if (index !== -1) state.overidesValues = overides

        if (newArr[index]) {
          newArr[index] = {
            ...newArr[index],
            ...overides?.overide_json,
            overidesId: overides.id,
            overidesValues: overides.overide_json,
          }
        }
        state.parcelDetail.future_land_use_plans = newArr
      }
    })
  },
  [actions.getLandDetails.fulfilled]: (state, action) => {
    const { payload } = action

    // const poly = turf.polygon(payload.geoPolygon.coordinates[0])

    // const distanceLimit = 1 - Number((payload.distance_limit / 10).toFixed(2))

    // const scaledPoly = turf.transformScale(
    //   poly,
    //   distanceLimit === 1 ? 0.7 : distanceLimit
    // )

    // state.parcelDetail.authorised_limit_geo_polygon = scaledPoly
    state.parcelDetail.mbb_area = payload.mbb_area
    state.parcelDetail.mbb_length_orthogonal = payload.mbb_length_orthogonal
    state.parcelDetail.mbb_length_parallel = payload.mbb_length_parallel
  },
  [actions.getAuthorizedDistanceLimit.fulfilled]: (state, action) => {
    const { data } = action.payload

    state.parcelDetail.authorised_limit_geo_polygon =
      data.authorised_limit_geo_polygon &&
      JSON.parse(data.authorised_limit_geo_polygon)
  },
  [actions.getRegionRdppfs.fulfilled]: (state, action) => {
    const { data } = action.payload

    state.regionRdppf = data
  },
  [actions.getExternalLinks.fulfilled]: (state, action) => {
    const { data } = action.payload

    state.regionRdppfLink = data.rdppf_link
    state.geoPortalLink = data.geo_portal_link
  },
  [actions.getIndicesByRegion.fulfilled]: (state, action) => {
    const { data } = action.payload

    state.parcelDetail.primaryIndice = data.primary_indice
    state.parcelDetail.secondaryIndice = data.secondary_indice
  },
}

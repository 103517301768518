import styled, { css } from 'styled-components'

export const Body = styled.main`
  position: absolute;
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  height: calc(100% - 56px);
  margin-top: 56px;

  ${({ theme }) => css`
    ${theme.breakpoints.up('md')} {
      height: calc(100% - 64px);
      margin-top: 64px;
    }
  `}
`

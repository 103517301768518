import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import {
  alertTypeSelector,
  resetSelectedAlert,
  updateSelectedAlert,
} from '../../../redux/alert'
import { useI18n } from '../../../context'
import { Button, Select } from '../../../components'
import { useQueryStringParser } from '../../../hooks'
import { GeoStepRoot } from './GeoStep.style'

const GeoStep = ({ onNext }) => {
  const dispatch = useDispatch()
  const type = useSelector(alertTypeSelector)
  const query = useQueryStringParser()
  const { state } = useLocation()

  const { t } = useI18n()

  const options = [
    { label: 'Zones', value: 'Zones' },
    { label: 'Radius', value: 'Radius' },
    { label: 'Polygone', value: 'Polygone' },
  ]

  const onChange = (newType) => {
    if (newType) {
      dispatch(resetSelectedAlert())
      dispatch(updateSelectedAlert({ type: newType.value }))
    } else {
      dispatch(updateSelectedAlert(null))
    }
  }

  return (
    <GeoStepRoot>
      <Select
        name="select-types"
        value={type}
        onChange={onChange}
        options={options}
        placeholder={t('myAlert.geo.placeholder')}
        fullWidth
        variant="outlined"
        size="small"
        margin="normal"
        disabled={!!(state?.open && Object.keys(query).length)}
      />
      <span>{type ? t(`myAlert.geo.${type}`) : ''}</span>
      <Button
        color="secondary"
        variant="contained"
        onClick={onNext}
        size="small"
        fullWidth
        disabled={!type}
      >
        {t('common.next')}
      </Button>
    </GeoStepRoot>
  )
}

export default GeoStep

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

import {
  multiPolygonToFeatures,
  multiPolygonToImg,
  sanitizeAlert,
} from '../../services/alert'
import extraReducers from './extraReducers'

const viewport = {
  latitude: 46.5232521407537,
  longitude: 6.63820918853145,
  zoom: 8,
  pitch: 0,
  bearing: 0,
}

const initialState = {
  alertsById: {},
  selectedAlert: {
    data: [],
    location: [],
    features: { type: 'FeatureCollection', features: [] },
    citiesSelected: [],
    zonesSelected: [],
    regionsSelected: [],
    zoneAffectationSelected: [],
    multiPolygon: { type: 'MultiPolygon', coordinates: [] },
    address: null,
    geoCenter: null,
    radius: null,
    zoneAffectation: null,
    name: null,
    fao: true,
    transaction: true,
    listing: true,
    type: '',
    faoCounter: null,
    transactionCounter: null,
    radiusCoordinates: null,
    viewport,
    building_permit_parameters: {},
    transaction_parameters: {},
    listing_parameters: {},
    parameters: { weekly: false },
    updateCount: 0,
  },
}

const alert = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    changeSelectedAlert: (state, action) => {
      state.selectedAlert = action.payload
        ? sanitizeAlert(state.alertsById[action.payload])
        : initialState.selectedAlert

      state.selectedAlert.faoCounter = null
      state.selectedAlert.transactionCounter = null
      state.selectedAlert.updateCount =
        (state.selectedAlert.updateCount || 0) + 1
    },
    updateSelectedAlert: (state, action) => {
      const fields = Object.keys(action.payload)

      fields.forEach((key) => {
        state.selectedAlert[key] = action.payload[key]
      })

      if (!fields.includes('name')) {
        state.selectedAlert.updateCount =
          (state.selectedAlert.updateCount || 0) + 1
      }

      if (!fields.includes('name')) {
        state.selectedAlert.updateCount =
          (state.selectedAlert.updateCount || 0) + 1
      }

      if (fields.includes('geoCenter')) {
        state.selectedAlert.viewport = {
          ...state.selectedAlert.viewport,
          latitude: state.selectedAlert.geoCenter[1],
          longitude: state.selectedAlert.geoCenter[0],

          zoom: 14,
        }
      }

      if (
        state.selectedAlert.type === 'Polygone' &&
        fields.includes('multiPolygon')
      ) {
        state.selectedAlert.features = multiPolygonToFeatures(
          state.selectedAlert.multiPolygon
        )
      }

      if (fields.includes('multiPolygon')) {
        state.selectedAlert.img = multiPolygonToImg(state.selectedAlert)
      }
    },
    updateAlertAddress: (state, action) => {
      const address = action.payload
      const addressGeoCenter = address.geo_center

      if (addressGeoCenter) {
        const lon = Number(address.geo_center?.split(' ')[0].substring(6))
        const lat = Number(address.geo_center?.split(' ')[1].slice(0, -1))
        const geoCenter = [
          addressGeoCenter?.lon || lon,
          addressGeoCenter?.lat || lat,
        ]

        state.selectedAlert.address = address.location_search
        state.selectedAlert.geoCenter = geoCenter

        state.selectedAlert.viewport = {
          ...state.selectedAlert.viewport,
          latitude: addressGeoCenter?.lat || lat,
          longitude: addressGeoCenter?.lon || lon,

          zoom: 14,
        }
      }

      state.selectedAlert.updateCount =
        (state.selectedAlert.updateCount || 0) + 1
    },
    clearGeojsonData: (state) => {
      state.selectedAlert = {
        ...initialState.selectedAlert,
        viewport: {
          ...initialState.selectedAlert.viewport,

          zoom: 8,
        },
      }
    },
    resetSelectedAlert: (state) => {
      state.selectedAlert.citiesSelected = []
      state.selectedAlert.zonesSelected = []
      state.selectedAlert.regionsSelected = []
      state.selectedAlert.zoneAffectationSelected = []
      state.selectedAlert.zoneAffectation = []
      state.selectedAlert.reasons = []
      state.selectedAlert.ownershipType = []
      state.selectedAlert.address = null
      state.selectedAlert.geoCenter = null
      state.selectedAlert.radius = null
      state.selectedAlert.faoCounter = null
      state.selectedAlert.transactionCounter = null
      state.selectedAlert.data = []
      state.selectedAlert.location = []
      state.selectedAlert.updateCount = 0
    },
  },
  extraReducers,
})

export const {
  changeSelectedAlert,
  updateSelectedAlert,
  resetSelectedAlert,
  clearGeojsonData,
  updateAlertAddress,
} = alert.actions

export default alert.reducer

/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import landMapDetailsService from '../../services/landMapDetails'

import {
  calculateUnderExploitedScore,
  calculateDevelopmentScore,
} from '../parcel/underExploited.utils'

import {
  weightedAverage,
  calculatePercentagePlotLandUsePlan,
} from '../../utils'

const initialState = {
  onClickFeature: null,
  firstTransaction: { first_transaction_date: null },
  firstFao: { first_fao_date: null },
  isOwner: { owners: true },
  regionRdppfLink: null,
  geoPortalLink: null,
}

export const fetchIsOwner = createAsyncThunk(
  'landMapDetails/fetchIsOwner',
  landMapDetailsService.fetchIsOwner
)

export const fetchTransactionPlot = createAsyncThunk(
  'landMapDetails/fetchTransactionPlot',
  landMapDetailsService.fetchTransactionPlot
)

export const fetchFaoPlot = createAsyncThunk(
  'landMapDetails/fetchFaoPlot',
  landMapDetailsService.fetchFaoPlot
)

export const getExternalLinks = createAsyncThunk(
  'landMapDetails/getExternalLinks',
  landMapDetailsService.getExternalLinks
)

const landMapDetails = createSlice({
  name: 'landMapDetails',
  initialState,
  reducers: {
    setOnClickFeature: (state, action) => {
      const { payload } = action

      if (payload === null) {
        state.onClickFeature = null
      } else {
        let underExploitedScoreOveride = null
        let developmentScoreOveride = null
        let underExploitedScoreOverideFuture = null
        let developmentScoreOverideFuture = null

        const newArr = [...(payload?.newFeatures?.land_use_plans || [])]
        const newArrFuture = payload?.newFeatures?.future_land_use_plans && [
          ...payload.newFeatures.future_land_use_plans,
        ]

        payload?.futureOverides?.forEach((futureOveride) => {
          const indexFuture =
            payload?.newFeatures?.future_land_use_plans?.findIndex(
              (item) =>
                Number(item.id) ===
                Number(futureOveride.land_use_plan_assignment_id)
            )

          if (
            indexFuture !== -1 &&
            newArrFuture?.[indexFuture]?.cover_area_perc >= 0.5
          ) {
            underExploitedScoreOverideFuture = calculateUnderExploitedScore(
              1,
              futureOveride?.overide_json[
                `${futureOveride?.overide_json?.typeZone?.ius}_overide`
              ] ||
                futureOveride?.overide_json[
                  `${futureOveride?.overide_json?.typeZone?.ios}_overide`
                ]
            )
            developmentScoreOverideFuture = calculateDevelopmentScore(
              underExploitedScoreOverideFuture,
              payload?.newFeatures?.geo_shape_score,
              payload?.newFeatures?.existing_score,
              payload?.newFeatures?.livability_score
            )

            newArrFuture[indexFuture] = {
              ...newArrFuture[indexFuture],
              ...futureOveride?.overide_json,
              underExploitedScoreOverideFuture,
              developmentScoreOverideFuture,
            }
          }
        })

        payload?.overides?.forEach((overide) => {
          if (
            overide?.extend ||
            Number(overide?.land_id) === Number(payload?.newFeatures.id)
          ) {
            const index = payload?.newFeatures?.land_use_plans?.findIndex(
              (item) =>
                Number(item.id) === Number(overide.land_use_plan_assignment_id)
            )

            if (index !== -1 && newArr?.[index]?.cover_area_perc >= 0.5) {
              underExploitedScoreOveride = calculateUnderExploitedScore(
                1,
                overide?.overide_json[
                  `${overide?.overide_json?.typeZone?.ius}_overide`
                ] ||
                  overide?.overide_json[
                    `${overide?.overide_json?.typeZone?.ios}_overide`
                  ]
              )
              developmentScoreOveride = calculateDevelopmentScore(
                underExploitedScoreOveride,
                payload?.newFeatures?.geo_shape_score,
                payload?.newFeatures?.existing_score,
                payload?.newFeatures?.livability_score
              )
            }

            newArr[index] = {
              ...newArr[index],
              ...overide?.overide_json,
              underExploitedScoreOveride,
              developmentScoreOveride,
            }
          }
        })

        state.onClickFeature = {
          ...payload?.newFeatures,
          id: [payload?.newFeatures?.id],
          land_use_plans: newArr,
          future_land_use_plans: newArrFuture,
          allFeature: [payload?.newFeatures],
          nb_parcelle: [payload?.newFeatures?.code_number],
          // building
          ...(payload.egid && { egids: [payload?.newFeatures?.egid] }),
        }
      }
    },
    setConcatLandData: (state, action) => {
      const { score, landFind, next, geo_polygon } = action.payload

      if (!landFind) {
        const plotsNumbers = state.onClickFeature.nb_parcelle.concat(
          next.code_number
        )
        const plotsIds = state.onClickFeature.id.concat(next.id)

        state.onClickFeature = {
          ...state.onClickFeature,
          id: plotsIds,
          area: state.onClickFeature.area + next.area,
          nb_parcelle: plotsNumbers,
          building_nb: state.onClickFeature.building_nb + next.building_nb,
          unit_nb: state.onClickFeature.unit_nb + next.unit_nb,
          geo_polygon,
          land_use_plans: score
            ? calculatePercentagePlotLandUsePlan(
                score,
                state.onClickFeature.area + next.area
              )
            : [],
          transaction_number:
            state.onClickFeature.transaction_number + next.transaction_number,
          building_permit_number:
            state.onClickFeature.building_permit_number +
            next.building_permit_number,
          allFeature: [...state.onClickFeature.allFeature, next],
          development_score: Math.round(
            weightedAverage(score, 'development_score')
          ),
          existingScore: weightedAverage(score, 'existing_score'),
          under_exploited_score: Math.round(
            weightedAverage(score, 'under_exploited_score')
          ),
          geo_shape_score: weightedAverage(score, 'geo_shape_score'),
          livability_score: weightedAverage(score, 'livability_score'),
          // building concat
          ...(state.onClickFeature.egid && {
            egids: [
              ...(state.onClickFeature.egids || [state.onClickFeature.egid]),
              next.egid,
            ].flat(),
            land_id: [
              state.onClickFeature.land_id ||
                state.onClickFeature?.lands?.[0]?.id,
              next.land_id || next?.lands?.[0]?.id,
            ]
              .flat()
              .at(0),
            gross_floor:
              (state.onClickFeature.gross_floor ||
                state.onClickFeature?.gross_floor ||
                0) + (next.area || next?.gross_floor || 0),
          }),
        }
      } else {
        state.onClickFeature = {
          ...state.onClickFeature,
          id: state.onClickFeature.id.filter((id) => id !== landFind.id),
          area: state.onClickFeature.area - landFind.area,
          building_nb: state.onClickFeature.building_nb - landFind.building_nb,
          unit_nb: state.onClickFeature.unit_nb - landFind.unit_nb,
          nb_parcelle: state.onClickFeature.nb_parcelle.filter(
            (nb) => nb !== next.code_number
          ),
          land_use_plans: calculatePercentagePlotLandUsePlan(
            score,
            state.onClickFeature.area - landFind.area
          ),
          geo_polygon,
          transaction_number:
            state.onClickFeature.transaction_number -
            landFind.transaction_number,
          building_permit_number:
            state.onClickFeature.building_permit_number -
            landFind.building_permit_number,
          allFeature: state.onClickFeature.allFeature.filter(
            (feature) => feature.id !== landFind.id
          ),
          development_score: weightedAverage(score, 'development_score'),
          existingScore: weightedAverage(score, 'existing_score'),
          under_exploited_score: weightedAverage(
            score,
            'under_exploited_score'
          ),
          geo_shape_score: weightedAverage(score, 'geo_shape_score'),
          livability_score: weightedAverage(score, 'livability_score'),
          // building
          ...(state.onClickFeature.egid && {
            egids: state.onClickFeature.egids?.filter((nb) => nb !== next.egid),
            land_id:
              state.onClickFeature.land_id ||
              state.onClickFeature?.lands?.[0]?.id,
            gross_floor:
              (state.onClickFeature.gross_floor ||
                state.onClickFeature?.gross_floor ||
                0) - (landFind.gross_floor || landFind?.gross_floor || 0),
          }),
        }
      }
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [fetchTransactionPlot.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.firstTransaction = data
    },
    [fetchFaoPlot.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.firstFao = data
    },
    [fetchIsOwner.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.isOwner = data
    },
    [getExternalLinks.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.regionRdppfLink = data.rdppf_link
      state.geoPortalLink = data.geo_portal_link
    },
  },
})

export const { setOnClickFeature, setConcatLandData } = landMapDetails.actions

export default landMapDetails.reducer

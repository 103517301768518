import React from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../../context'
import { aggregationsSelector } from '../../../redux/transactionHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'

const OwnershipTypeFilter = ({ onChange, ownershipTypeFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(aggregationsSelector)

  const items = aggregations.ownership_types.ownership_types.buckets.map(
    (r) => ({ ...r, count: r.doc_count })
  )

  const handleChange = (newValues) => {
    onChange({ ownershipTypeFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('transaction.ownershipType')}>
      <CheckboxFilter
        filters={ownershipTypeFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default OwnershipTypeFilter

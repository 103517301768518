const computeSellingCost = (analysis, projectCostTotal) => {
  const data = analysis.analysis_data

  const grossSellingPrice = Math.round(
    data.livingArea * data.projectSellingPrice
  )

  const salesComissionFees = Math.round(
    grossSellingPrice * (data.salesComission / 100)
  )

  const othersAdditionalCostTotal = data?.sellingAdditionalCodes
    ? Math.round(
        data.sellingAdditionalCodes?.reduce((acc, code) => acc + code.value, 0)
      )
    : 0

  const netSellingPrice = Math.round(
    grossSellingPrice - salesComissionFees + othersAdditionalCostTotal
  )

  const sellingPriceProfit = Math.round(netSellingPrice - projectCostTotal)

  const sellingPriceROI = Math.round(
    (sellingPriceProfit / projectCostTotal) * 100
  )

  return {
    grossSellingPrice,
    sellingPriceROI,
    sellingPriceProfit,
    netSellingPrice,
    salesComissionFees,
  }
}

export { computeSellingCost }

import styled from 'styled-components'

export const FinalStepRoot = styled.div`
  > .MuiTextField-root:not(:first-child) {
    margin-bottom: 15px;
    margin-top: 15px;
  }

  > span {
    color: rgb(141, 155, 163);
    font-style: italic;
    font-size: 12px;
  }
`

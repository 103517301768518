import formatDate from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import min from 'date-fns/min'
import max from 'date-fns/max'

const dateLocales = { en, fr }

/**
 * Format date for the given local.
 * By providing a default string of 'PP' or any of its variants for `formatStr`
 * it will format dates in whichever way is appropriate to the locale
 */
const format = (date: Date | string, formatStr = 'PP', options: any = {}) => {
  if (!date) return ''

  try {
    const locale = options?.locale || localStorage.getItem('language') || 'en'

    return formatDate(new Date(date), formatStr, {
      ...options,
      locale: dateLocales[locale as 'en'],
    })
  } catch (error) {
    return ''
  }
}

/**
 * Parse french format utc date string
 * @param {string} date utc date to parse. Ex: 30-06-2021
 */
const parseInvertedDate = (date: string) => {
  if (!date) return null

  return new Date(
    Date.UTC(+date.slice(6), +date.slice(3, 5) - 1, +date.slice(0, 2))
  )
}

const minDate = (...date: (string | Date)[]) => {
  return min(date.filter(Boolean).map((d) => new Date(d)))
}

const maxDate = (...date: (string | Date)[]) => {
  return max(date.filter(Boolean).map((d) => new Date(d)))
}

export { dateLocales, format, parseInvertedDate, minDate, maxDate }

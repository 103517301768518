import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import SearchIcon from '@mui/icons-material/Search'

import { useSelector } from 'react-redux'
import { alertByIdSelector } from '../../../redux/alert'
import { useI18n } from '../../../context'
import AlertCardActions from '../AlertCardActions'
import AlertAddress from './AlertAddress'
import AdvancedSettings from './AdvancedSettings'
import AlertFao from './AlertFao'
import AlertMapGL from './AlertMapGL'
import {
  AlertAddressRoot,
  AlertBodyRoot,
  AlertCardHeaderRoot,
  AlertCardRoot,
  DisplayText,
  TitleBox,
} from './AlertCard.style'

const AlertCard = ({ alertId }) => {
  const { t } = useI18n()
  const alertById = useSelector(alertByIdSelector)
  const alert = alertById[alertId]

  if (!alert) return null

  return (
    <AlertCardRoot elevation={6}>
      <AlertCardHeaderRoot>
        <TitleBox>
          <h3 title={alert.name}>{alert.name}</h3>
          <i>
            {t('myAlert.createdAt')}
            {alert.createdAt.substring(0, 10)}
          </i>
        </TitleBox>
        <AlertCardActions alert={alert} />
      </AlertCardHeaderRoot>
      <AlertBodyRoot>
        <div>
          <AlertAddressRoot>
            <Tooltip placement="top" title={<h5>{t('myAlert.searchType')}</h5>}>
              <SearchIcon className="icon" fontSize="small" />
            </Tooltip>{' '}
            <DisplayText>{alert.type}</DisplayText>
          </AlertAddressRoot>
          <AlertAddress alert={alert} />
          <AlertFao alert={alert} />
        </div>
        <AdvancedSettings alert={alert} />
      </AlertBodyRoot>
      <AlertMapGL alert={alert} />
    </AlertCardRoot>
  )
}

export default React.memo(AlertCard)

import {
  updateDistrictByIdSate,
  updateDistrictGeojsonByZoneSate,
} from './updateDistrictSate'
import {
  updateCityByIdSate,
  updateCityGeojsonByZoneSate,
} from './updateCityByIdSate'
import {
  updateRegionByIdSate,
  updateRegionGeojsonByZoneSate,
} from './updateRegionByIdSate'
import { updateGeoCenterSate } from './updateGeoCenterSate'
import * as actions from './actions'

const updateAffectationZone = (state, action) => {
  const { data } = action.payload

  if (data) state.selectedAlert.zoneAffectation = data
}

export default {
  [actions.getAlerts.fulfilled]: (state, action) => {
    action.payload.data?.forEach((a) => {
      state.alertsById[a.id] = a
    })
  },
  [actions.getDistrictById.fulfilled]: updateDistrictByIdSate,
  [actions.getCityById.fulfilled]: updateCityByIdSate,
  [actions.getRegionById.fulfilled]: updateRegionByIdSate,
  [actions.getGeoCenter.fulfilled]: updateGeoCenterSate,
  [actions.getZonesByCity.fulfilled]: updateAffectationZone,
  [actions.getZonesByDistrict.fulfilled]: updateAffectationZone,
  [actions.getZonesByRegion.fulfilled]: updateAffectationZone,
  [actions.getDistrictGeojsonByZone.fulfilled]: updateDistrictGeojsonByZoneSate,
  [actions.getRegionGeojsonByZone.fulfilled]: updateRegionGeojsonByZoneSate,
  [actions.getCityGeojsonByZone.fulfilled]: updateCityGeojsonByZoneSate,
  [actions.deleteAlert.fulfilled]: (state, action) => {
    const { data } = action.payload
    const id = action.meta.arg

    if (data) delete state.alertsById[id]
  },
  [actions.createAlert.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) {
      if (!data.data?.length) {
        data.data =
          data.regionsSelected?.filter((d) => ({
            ...d,
            value: d.value || d.name,
          })) || []

        if (data.zonesSelected?.length) {
          data.data.push(
            ...data.zonesSelected.filter((d) => ({
              ...d,
              value: d.value || d.name,
            }))
          )
        }

        if (data.citiesSelected?.length) {
          data.data.push(
            ...data.citiesSelected.filter((d) => ({
              ...d,
              value: d.value || d.name,
            }))
          )
        }
      }

      state.alertsById[data.id] = data
    }
  },
  [actions.updateAlert.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.alertsById[data.id] = data
  },
  [actions.pauseAlert.fulfilled]: (state, action) => {
    const { data } = action.payload
    const input = action.meta.arg

    if (data) {
      state.alertsById[input.id].pause = input.pause
    }
  },
  [actions.getListingsStats.fulfilled]: (state, action) => {
    const { data } = action.payload

    if (data) state.selectedAlert.listingCounter = data.count
  },
}

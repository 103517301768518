import React from 'react'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import IconButton from '@mui/material/IconButton'

import { ButtonContainer } from './FullscreenButton.style'

export type FullscreenButtonProps = {
  fullScreen?: boolean
  onToggle: () => void
}

const FullscreenButton = ({ fullScreen, onToggle }: FullscreenButtonProps) => {
  return (
    <ButtonContainer>
      <IconButton onClick={onToggle}>
        {!fullScreen ? (
          <FullscreenIcon fontSize="large" />
        ) : (
          <FullscreenExitIcon fontSize="large" />
        )}
      </IconButton>
    </ButtonContainer>
  )
}

export default FullscreenButton

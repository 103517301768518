import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { useI18n } from '../../../context'
import { RegionFilterItem, FilterCategory } from '../../../components'
import { listingAggregationsSelector } from '../../../redux/listingHistory'

const PropertyCategoryFilter = ({
  propertyTypeFilter,
  propertyCategoryFilter,
  onChange,
  ...other
}) => {
  const { t } = useI18n()
  const aggregations = useSelector(listingAggregationsSelector)

  const handleCategoryChange = (type) => (_e) => {
    const reset = { propertyTypeFilter: [] }

    if (propertyCategoryFilter?.includes(type)) {
      onChange({ propertyCategoryFilter: [], ...reset })
    } else {
      onChange({ propertyCategoryFilter: [type], ...reset })
    }
  }

  const handleTypeChange = (category) => (_e) => {
    if (propertyTypeFilter?.includes(category)) {
      onChange({ propertyTypeFilter: [] })
    } else {
      onChange({ propertyTypeFilter: [category] })
    }
  }

  if (!aggregations.property_category?.property_category.buckets.length) {
    return null
  }

  return (
    <FilterCategory {...other} title={t('listing.property_type')}>
      {aggregations.property_category.property_category.buckets.map(
        (category) => (
          <Fragment key={category.key}>
            <RegionFilterItem
              key={category.key}
              name={t(`listing.property_category.${category.key}`)}
              count={category.doc_count}
              onClick={handleCategoryChange(category.key)}
              selected={propertyCategoryFilter?.includes(category.key)}
            />
            {propertyCategoryFilter?.includes(category.key) &&
              aggregations.property_type?.property_type?.buckets.map((type) => (
                <Fragment key={type.key}>
                  <RegionFilterItem
                    key={type.key}
                    name={t(`listing.property_type.${type.key}`)}
                    count={type.doc_count}
                    onClick={handleTypeChange(type.key)}
                    selected={propertyTypeFilter?.includes(type.key)}
                    paddingLeft={2}
                  />
                </Fragment>
              ))}
          </Fragment>
        )
      )}
    </FilterCategory>
  )
}

export default PropertyCategoryFilter

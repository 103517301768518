import React, { useEffect } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import MuiMenuItem from '@mui/material/MenuItem'
import Chip from '@mui/material/Chip'

import { useI18n } from '../../context'
import { HistoryMenu, MenuItem } from './Header.style'

const HistoryMenuItem = () => {
  const { t } = useI18n()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { pathname } = useLocation()

  const active =
    pathname === '/transaction-history' || pathname === '/fao-history'

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    handleClose()
  }, [pathname])

  return (
    <>
      <MenuItem onClick={handleClick}>
        <span className={active ? 'active link' : 'link'}>
          {t('common.historique')}
        </span>
      </MenuItem>

      <HistoryMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MuiMenuItem
          selected={pathname === '/transaction-history'}
          component={NavLink}
          to="/transaction-history"
          onClick={handleClose}
        >
          {t('common.transactionHistory')}
        </MuiMenuItem>

        <MuiMenuItem
          selected={pathname === '/fao-history'}
          onClick={handleClose}
          component={NavLink}
          to="/fao-history"
        >
          {t('common.faoHistory')}
        </MuiMenuItem>

        <MuiMenuItem
          selected={pathname === '/listing-history'}
          onClick={handleClose}
          component={NavLink}
          to="/listing-history"
        >
          {`${t('common.listingHistory')}  `}
          <Chip variant="outlined" label="beta" size="small" sx={{ ml: 1 }} />
        </MuiMenuItem>
      </HistoryMenu>
    </>
  )
}

export default HistoryMenuItem

import { createSelector } from '@reduxjs/toolkit'

const developerMapDataSelector = (state) => state.developerMapData

const hoveredFeature = (state) => state.developerMapData.hoveredFeature
const favoritesLand = (state) => state.developerMapData.favorites
const prospectIdsSelector = (state) => state.developerMapData.prospectByIds

const favoritesIdsSelector = createSelector(favoritesLand, (favorites) =>
  favorites?.map((fav) => Number(fav.land_id))
)

const companyProspectSelector = (state) =>
  state.developerMapData.companyProspect

const companyProspectIdsSelector = createSelector(
  companyProspectSelector,
  (prospect) => prospect?.map((pro) => Number(pro.land_id))
)

const userFiltersSelector = (state) => state.developerMapData.userFilter
const companyFiltersSelector = (state) => state.developerMapData.companyFilter

export {
  developerMapDataSelector,
  hoveredFeature,
  favoritesLand,
  prospectIdsSelector,
  companyProspectSelector,
  userFiltersSelector,
  companyFiltersSelector,
  favoritesIdsSelector,
  companyProspectIdsSelector,
}

import Box from '@mui/material/Box'
import styled from 'styled-components'

export const CollapsePaneRoot = styled(Box)`
  width: 100%;

  > div.link {
    color: #2196f3;

    &:hover {
      cursor: pointer;
    }
  }
`

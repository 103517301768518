import ApiClient from '../apiClient'

const client = new ApiClient()

const getCityById = async (cities: any[]) => {
  return client.get(`/cities/${cities.map((c) => c).join(',')}/geojson`)
}

const getRegionById = async (regions: any[]) => {
  return client.get(`/region/${regions.map((r) => r).join(',')}/geojson`)
}

const getDistrictById = async (districts: any[]) => {
  return client.get(`/district/${districts.map((d) => d).join(',')}/geojson`)
}

const isFutureAvailable = async () => {
  return client.get(`/cities/future/available`)
}

const getOfsLayers = async () => {
  return client.get('/companies/ofs/layers')
}

const displayMapService = {
  getCityById,
  getRegionById,
  getDistrictById,
  isFutureAvailable,
  getOfsLayers,
}

export default displayMapService

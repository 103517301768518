import styled from 'styled-components'

export const AlertCardActionsRoot = styled.div`
  display: flex;
  margin-top: -25px;
  align-items: center;

  > label {
    margin-right: 0;
  }

  .MuiSvgIcon-root {
    color: #03254c;
  }
`

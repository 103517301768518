import React, { useEffect, useState } from 'react'
import { Viewer } from 'resium'

import './CesiumWidget.css'

// This function is use to load Cesium only when you use it
const setCesium = (Cesium) => {
  const ces = {
    Ion: Cesium.Ion,
    Color: Cesium.Color,
    Cartesian3: Cesium.Cartesian3,
    CesiumTerrainProvider: Cesium.CesiumTerrainProvider,
    UrlTemplateImageryProvider: Cesium.UrlTemplateImageryProvider,
    GeographicTilingScheme: Cesium.GeographicTilingScheme,
    Rectangle: Cesium.Rectangle,
    CesiumMath: Cesium.Math,
  }

  ces.Ion.defaultAccessToken = process.env.CESIUM

  ces.rectangle = ces.Rectangle.fromDegrees(
    5.013926957923385,
    45.35600133779394,
    11.477436312994008,
    48.27502358353741
  )

  ces.terrainProvider = new ces.CesiumTerrainProvider({
    requestVertexNormals: true,
    url: '//3d.geo.admin.ch/1.0.0/ch.swisstopo.terrain.3d/default/20200520/4326/',
  })

  ces.imageryProvider = new ces.UrlTemplateImageryProvider({
    url: '//wmts20.geo.admin.ch/1.0.0/ch.swisstopo.swissimage-product/default/current/4326/{z}/{x}/{y}.jpeg',
    tilingScheme: new ces.GeographicTilingScheme({
      numberOfLevelZeroTilesX: 2,
      numberOfLevelZeroTilesY: 1,
    }),
    rectangle: ces.rectangle,
  })

  return ces
}

const CesiumMap = ({ style, viewerProps, children }) => {
  const [cesiumLib, setCesiumLib] = useState(null)

  // load the zoom when the map is ready
  let viewer // This will be raw Cesium's Viewer object.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleReady = (tileset) => {
    if (viewer) {
      viewer.zoomTo(tileset)
    }
  }

  // Async loading
  useEffect(() => {
    import('cesium').then((Cesium) => {
      setCesiumLib(setCesium(Cesium))
    })
  }, [])

  if (!cesiumLib) return <></>

  return (
    <Viewer
      style={style}
      ref={(e) => {
        viewer = e && e.cesiumElement
      }}
      full
      {...viewerProps}
    >
      {children}
    </Viewer>
  )
}

export default CesiumMap

import React from 'react'
import { DrawerProps } from '@mui/material/Drawer'
import CloseIcon from '@mui/icons-material/ChevronLeft'
import IconButton from '@mui/material/IconButton'

import { SidePaneContent, SidePaneRoot } from './SidePane.style'

export type SidePaneProps = DrawerProps & {
  onClose?: () => void
}

const SidePane = ({
  children,
  onClose,
  className = '',
  ...other
}: SidePaneProps) => (
  <SidePaneRoot
    {...other}
    className={`SidePane ${className}`}
    variant="persistent"
    anchor="left"
    transitionDuration={0}
  >
    <SidePaneContent className="SidePaneContent">
      <IconButton className="CloseIcon" aria-label="close" onClick={onClose}>
        <CloseIcon />
      </IconButton>
      {children}
    </SidePaneContent>
  </SidePaneRoot>
)

export default SidePane

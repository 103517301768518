import { getLegendColors } from '../lastYearColor'

const currentYear = new Date().getFullYear()

const legend = (t: Translator, startDate?: string) => {
  const legends = getLegendColors(startDate)

  return [
    ...legends
      .sort((a, b) => +b.name - +a.name)
      .map((d, index) => ({
        ...d,
        name: legends.length - 1 === index ? `<= ${d.name}` : d.name,
        // eslint-disable-next-line
        year: String(d.name) == '2013' && !startDate ? ' <= 2013' : d.name,
      })),
    { name: t('common.unknown'), color: 'rgba(240, 240, 240, 0.8)' },
  ]
}

export const historyOptions = (t: Translator) => [
  {
    value: 'listing_rent_last_year',
    label: t('search.listingRent'),
    description: 'listing_rent_last_year',
    src: '/assets/img/buildingLayers/rental.png',
    legend: legend(t, (currentYear - 9).toString()),
    group: 'history',
  },
  {
    value: 'lands_building_permit_last_year',
    label: t('search.fao'),
    description: 'building_permit_last_year',
    src: '/assets/img/buildingLayers/permit.png',
    legend: legend(t, '2005'),
    group: 'history',
  },
  {
    value: 'lands_transaction_last_year',
    label: 'Transaction',
    description: 'transaction_last_year',
    src: '/assets/img/buildingLayers/transaction.png',
    legend: legend(t, '2005'),
    group: 'history',
  },
]

import React, { lazy, Suspense } from 'react'
import { Routes, Route } from 'react-router-dom'

import { PrivateRoute, ProgressIndicator } from '../components'

const Login = lazy(() => import('../pages/Login'))
const PasswordReset = lazy(() => import('../pages/PasswordReset'))
const DeveloperMap = lazy(() => import('../pages/DeveloperMap'))
const Account = lazy(() => import('../pages/Account'))
const LandDetail = lazy(() => import('../pages/LandDetail'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const Glossary = lazy(() => import('../pages/Glossary'))
const MyAlerts = lazy(() => import('../pages/MyAlerts'))
const CompanyDashboard = lazy(() => import('../pages/CompanyDashboard'))
const TransactionHistory = lazy(() => import('../pages/TransactionHistory'))
const FaoHistory = lazy(() => import('../pages/FaoHistory'))
const ListingHistory = lazy(() => import('../pages/ListingHistory'))
const Support = lazy(() => import('../pages/Support'))
const ErrorPage = lazy(() => import('../pages/error'))

const AppRoutes = () => (
  <Suspense fallback={<ProgressIndicator circular />}>
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DeveloperMap />
          </PrivateRoute>
        }
        path="/"
      />
      <Route
        element={
          <PrivateRoute>
            <DeveloperMap />
          </PrivateRoute>
        }
        path="/developer-map"
      />
      <Route path="/login" element={<Login />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
        path="/account"
      />

      <Route element={<Support />} path="/help" />
      <Route element={<Support />} path="/sources" />
      <Route element={<Support />} path="/user-guide" />

      <Route
        element={
          <PrivateRoute>
            <TransactionHistory />
          </PrivateRoute>
        }
        path="/transaction-history"
      />
      <Route
        element={
          <PrivateRoute>
            <FaoHistory />
          </PrivateRoute>
        }
        path="/fao-history"
      />
      <Route
        element={
          <PrivateRoute>
            <ListingHistory />
          </PrivateRoute>
        }
        path="/listing-history"
      />
      <Route
        element={
          <PrivateRoute>
            <LandDetail />
          </PrivateRoute>
        }
        path="/land-detail"
      />
      <Route
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
        path="/dashboard"
      />
      <Route
        element={
          <PrivateRoute>
            <Glossary />
          </PrivateRoute>
        }
        path="/glossary"
      />
      <Route
        element={
          <PrivateRoute>
            <MyAlerts />
          </PrivateRoute>
        }
        path="/my-alerts"
      />
      <Route
        element={
          <PrivateRoute>
            <CompanyDashboard />
          </PrivateRoute>
        }
        path="/company-dashboard"
        admin
      />

      <Route path="*" element={<ErrorPage status="404" />} />
    </Routes>
  </Suspense>
)

export default AppRoutes

import { toCamelCase, toSnakeCase } from '../../utils'
import ApiClient from '../apiClient'

const client = new ApiClient()

type UpdatePasswordInput = { newPassword: string }

type UpdateFinancialDefaultInput = Record<string, any>

const getCurrentUserId = () => {
  try {
    return JSON.parse(localStorage.getItem('user') || '')._id
  } catch (error) {
    return ''
  }
}

const getCurrentUserCompany = () => {
  try {
    return JSON.parse(localStorage.getItem('user') || '').company_id
  } catch (error) {
    return ''
  }
}

const getCurrentUserDetails = async () => {
  return client.get(`/users/${getCurrentUserId()}/detail`)
}

const getZones = async () => {
  return client.get(`/users/${getCurrentUserId()}/zones`)
}

const getRegions = async () => {
  return client.get(`/users/${getCurrentUserId()}/region`)
}

const getRoles = async () => {
  return client.get(`/users/${getCurrentUserId()}/roles`)
}

const updatePassword = async ({ newPassword }: UpdatePasswordInput) => {
  const response = await client.put(`/users/${getCurrentUserId()}/password`, {
    newPassword,
  })

  if (response.data) {
    localStorage.clear()
  }

  return response
}

const updateFinancialDefault = async (input: UpdateFinancialDefaultInput) => {
  const data = { config: toSnakeCase(input) }

  return client.post(`/users/${getCurrentUserId()}/config`, data)
}

const updateLanguage = async (lang: string) => {
  return client.put(`/users/${getCurrentUserId()}/lang`, { lang })
}

const getFinancialDefault = async () => {
  const response = await client.get(`/users/${getCurrentUserId()}/config`)
  const payload = response.data

  if (payload) {
    response.data = toCamelCase(response.data)
  }

  return response
}

const terminatePlan = async () => {
  return client.delete(`/users/${getCurrentUserId()}/subscriptions`)
}

const updateUserPhoto = async (photo: File | Blob) => {
  return client.put(
    `/users/${getCurrentUserId()}/update-photo`,
    { photo },
    { formData: true }
  )
}

const userService = {
  getCurrentUserDetails,
  getZones,
  getRoles,
  updatePassword,
  updateFinancialDefault,
  getFinancialDefault,
  terminatePlan,
  getRegions,
  updateLanguage,
  updateUserPhoto,
  getCurrentUserCompany,
}

export { getCurrentUserId, getCurrentUserCompany }
export default userService

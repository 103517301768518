import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Hidden from '@mui/material/Hidden'
import Box from '@mui/material/Box'

import { useI18n } from '../../context'
import {
  appSelector,
  userSelector,
  getCurrentUserDetails,
  userDetailsSelector,
} from '../../redux'
import Brand from './Brand'
import HistoryMenuItem from './HistoryMenuItem'
import UserAvatar from './UserAvatar'
import { HeaderMenuRoot, MenuContainer, MenuItem } from './Header.style'

const Hearder = () => {
  const { t } = useI18n()

  const userDetails = useSelector(userDetailsSelector)
  const app = useSelector(appSelector)
  const user = useSelector(userSelector)
  const showAnalysis = app === 'landdev' || app === null

  const dispatch = useDispatch()

  useEffect(() => {
    if (user && !userDetails) {
      dispatch(getCurrentUserDetails())
    }
  }, [user, userDetails])

  return (
    <HeaderMenuRoot position="fixed">
      <Grid container>
        <Grid item xs={9} md={4}>
          <Brand />
        </Grid>
        <Grid item xs={3} md={8}>
          <MenuContainer maxHeight="64px">
            {user && (
              <Box maxHeight="64px">
                <Hidden mdDown>
                  <MenuItem>
                    <NavLink to="/developer-map">
                      {t('common.landMapSearch')}
                    </NavLink>
                  </MenuItem>
                  {showAnalysis && (
                    <MenuItem>
                      <NavLink to="/land-detail">
                        {t('common.landDetailAnalysis')}
                      </NavLink>
                    </MenuItem>
                  )}
                  <HistoryMenuItem />
                  <MenuItem>
                    <NavLink to="/dashboard">{t('common.dashboard')}</NavLink>
                  </MenuItem>
                  <MenuItem>
                    <NavLink to="/my-alerts">{t('common.alerts')}</NavLink>
                  </MenuItem>
                </Hidden>
              </Box>
            )}
            <UserAvatar />
          </MenuContainer>
        </Grid>
      </Grid>
    </HeaderMenuRoot>
  )
}

export default Hearder

import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'

import AlertEditor from '../AlertEditor'
import { useMediaQuery } from '../../../hooks'

export type AlertEditorDialogProps = DialogProps & {
  alert?: Record<string, any>
}

const AlertEditorDialog = ({ alert, ...other }: AlertEditorDialogProps) => {
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'))

  return (
    <Dialog maxWidth="xl" fullWidth fullScreen={!onDesktop} {...other}>
      <AlertEditor open={other.open} alert={alert} onClose={other.onClose} />
    </Dialog>
  )
}

export default AlertEditorDialog

import styled, { css } from 'styled-components'

export const BodySyle = styled.div`
  width: 80%;
  margin: auto;

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      width: 100%;

      > .hnesEN {
        margin: 0;
      }
    }
  `}
`

import ApiClient from '../apiClient'

const client = new ApiClient()

export type GetParcelInput = {
  landId?: any
  precision?: number
  region?: string
}

const fetchAltimetry = async (input: GetParcelInput) => {
  const { landId, precision, region } = input

  return client.get(
    `/parcelles/${
      landId?.length > 1 ? landId?.map((id: number) => id) : landId
    }/altimetry/${region}/${precision}`
  )
}

const altimetryService = {
  fetchAltimetry,
}

export default altimetryService

import TextField from '@mui/material/TextField'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from '../../../components'
import { useI18n } from '../../../context'
import {
  alertIdSelector,
  alertNameSelector,
  alertParametersSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import FinalStepActions from './FinalStepActions'
import { FinalStepRoot } from './FinalStep.style'

const FinalStep = ({ onComplete }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const id = useSelector(alertIdSelector)
  const parameters = useSelector(alertParametersSelector)
  const name = useSelector(alertNameSelector)

  const options = [
    { label: t('myAlert.final.daily'), value: 'daily' },
    { label: t('myAlert.final.weekly'), value: 'weekly' },
  ]

  const frequency = parameters.weekly ? options[1] : options[0]

  const handleNameChange = (e) => {
    dispatch(updateSelectedAlert({ name: e.target.value }))
  }

  const handleFrequencyChange = (selected) => {
    dispatch(
      updateSelectedAlert({
        parameters: { ...parameters, weekly: selected.value === 'weekly' },
      })
    )
  }

  return (
    <FinalStepRoot>
      <span>{t('myAlert.final.frequency.text')}</span>
      <Select
        value={id ? frequency : ''}
        onChange={handleFrequencyChange}
        options={options}
        placeholder={t('myAlert.final.frequency')}
        fullWidth
        variant="outlined"
        size="small"
        margin="normal"
      />
      <TextField
        label={t('myAlert.final.alertName')}
        value={name || ''}
        onChange={handleNameChange}
        fullWidth
        variant="outlined"
        size="small"
        margin="normal"
      />
      <FinalStepActions onComplete={onComplete} />
    </FinalStepRoot>
  )
}

export default FinalStep

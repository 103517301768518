import { createSlice } from '@reduxjs/toolkit'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import { fromSessionStorage, fromLocalStorage } from '../../utils'

const thisYear = new Date().getFullYear()

const webglcontextlostAt = fromSessionStorage('webglcontextlost')

const loadLostState =
  differenceInMinutes(new Date(), new Date(webglcontextlostAt)) < 1

export const initialState = {
  radius: 200,
  geoPoint: null,
  locations: [],
  bareLand: false,
  privateOwner: true,
  area: { min: 0, max: 100000 },
  principalTypes: [],
  buildingArea: { min: 0, max: 100000 },
  buildingYear: { min: 0, max: thisYear },
  buildingRenovatedYear: { min: 0, max: thisYear },
  landUsePlans: [],
  assignements: [],
  hideBareLand: false,
  hideProtectedBuilding: false,
  hideUnknownYear: false,
  hideRenovatedBuilding: false,
  UnitPerOwner: { min: 0, max: 1000 },
  ownersNb: { min: 0, max: 1000 },
  unitNumber: { min: 0, max: 1000 },
  propertyType: 'private',
  propertyOwner: 'all',
  ownershipTypes: [],
  specialZone: 'display',
  noBuildingZone: 'hide',
  transaction: 'all',
  transactionYear: { min: 2005, max: thisYear },
  saleListingYear: { min: 2005, max: thisYear },
  rentListingYear: { min: 2005, max: thisYear },
  saleListing: 'all',
  rentListing: 'all',
  buildingPermit: 'all',
  buildingPermitYear: { min: 1995, max: thisYear },
  currentMaxFloorNb: { min: 0, max: 100 },
  currentMaxHeight: { min: 0, max: 1000 },
  reservedZone: 'display',
  pollutedZone: 'display',
  underwaterProtectedZone: 'display',
  geoCenter: [0],
  geoBoudingBox: null,
  onMap: false,
  search_after: [0],
  size: 25,
  updateCount: 0,
  duration: 10,
  transportType: 'driving',
  ...(loadLostState && fromLocalStorage('webglcontextlost_state', true)),
  type: localStorage.getItem('type-view') || 'land',
  isochrone: undefined,
  roomsNumber: { min: 0, max: 100 },
  solarPotential: { min: 0, max: 2000 },
  rentDate: { min: '2000-01-01', max: new Date().toISOString().split('T')[0] },
  transactionDate: {
    min: '2000-01-01',
    max: new Date().toISOString().split('T')[0],
  },
  buildingPermitDate: {
    min: '2000-01-01',
    max: new Date().toISOString().split('T')[0],
  },
  heatingSystem: [],
  waterHeatingSystem: [],
  popetyIoClassification: [],
  OFSClassification: [],
  OFSCategory: [],
}

const developerMapForm = createSlice({
  name: 'developerMapForm',
  initialState,
  reducers: {
    updateSearchCriteria: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
        state.updateCount += 1
      })

      if (Array.isArray(state.principalTypes)) {
        state.principalTypes = state.principalTypes.map((v) => v.value || v)
        state.updateCount += 1
      }

      delete state.cities
      delete state.address
      delete state.isochrone
    },
    resetSearchCriteria: (state) => {
      const locations = ['locations', 'geoPoint', 'geoCenter', 'type']

      Object.keys(initialState).forEach((key) => {
        if (!locations.includes(key)) {
          state[key] = initialState[key]
        }
      })
    },
  },
  extraReducers: {},
})

export const { updateSearchCriteria, resetSearchCriteria } =
  developerMapForm.actions

export default developerMapForm.reducer

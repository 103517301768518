import ApiClient from '../apiClient'

const client = new ApiClient()

type LoginInput = { email: string; password: string }

const login = async (input: LoginInput) => {
  const response = await client.post('/auth/login', input)
  const payload = response.data

  if (payload) {
    // Store token and user to local storage
    localStorage.setItem('jwt', payload.token)
    localStorage.setItem('user', JSON.stringify(payload.user))
  }

  if (response.errors) {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
  }

  return response
}

const sendPasswordResetEmail = (email: string) => {
  return client.post(`/auth/sendPasswordResetEmail`, { email })
}

const getSupports = () => {
  return client.get(`/supports`)
}
const authService = { login, sendPasswordResetEmail, getSupports }

export default authService

/* eslint-disable max-lines */

import { API_BASE_URL } from '@/services/apiClient/ApiClient'

/**
 * Handles variable substitution on the provided string. It scans through the string looking for
 * expressions enclosed in curly braces. If an expression is found, use it as a key on the object,
 * and if the key has a string value or number value, it is substituted for the bracket expression
 * and it repeats.
 * @namespace Utils
 * @param {string} str the provided string. If i
 * @param {object | null} obj values to replace in the provided string. If null or empty, the string is return as is
 */
const supplant = (str: string, obj?: Record<string, any> | null) => {
  if (!obj || Object.keys(obj).length === 0) return str

  return str?.replace(/{([^{}]*)}/g, (a, b) => {
    const value = obj[b]

    return typeof value === 'string' || typeof value === 'number'
      ? (value as string)
      : a
  })
}

const toSnakeCase = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce(
    // eslint-disable-next-line no-sequences
    (c, k) => ((c[k.replace(/([A-Z])/g, '_$1').toLowerCase()] = obj[k]), c),
    {} as Record<string, any>
  )
}

const toCamelCase = (obj: Record<string, any>) => {
  return Object.keys(obj).reduce(
    // eslint-disable-next-line no-sequences
    (c, k) => ((c[k.replace(/(_\w)/g, (m) => m[1].toUpperCase())] = obj[k]), c),
    {} as Record<string, any>
  )
}

const readableFinancialInteger = (x: number) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'")
}

const formatCurrency = (n: number, currency: string) => {
  return (
    currency +
    n.toFixed(0).replace(/./g, (c, i, a) => {
      return i > 0 && (a.length - i) % 3 === 0 ? `'${c}` : c
    })
  )
}

const downloadURI = (uri: string, name = '') => {
  const link = document.createElement('a')

  link.setAttribute('download', name)
  link.href = uri
  document.body.appendChild(link)
  link.click()
  link.remove()
}

const isIOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

/**
 * Clear localstorage and keep the specicified values
 * @param keep values to keep
 */
const clearLocalStorage = (...keep: string[]) => {
  if (typeof localStorage === 'undefined') return

  const data = {} as Record<string, any>

  keep.forEach((key) => {
    data[key] = localStorage.getItem(key)
  })

  localStorage.clear()

  Object.keys(data).forEach((key) => {
    if (data[key]) localStorage.setItem(key, data[key])
  })
}

const formatPrice = (
  price: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 0
) => {
  if (!price) return price

  return new Intl.NumberFormat('de-CH', {
    style: 'currency',
    currency: 'CHF',
    maximumFractionDigits,
    minimumFractionDigits,
  }).format(price)
}

const getPriceInfo = (priceData: any[]) => {
  const priceM2 = formatPrice(priceData[priceData.length - 1]?.priceM2, 0)

  const priceM2Min = priceData[priceData.length - 1]?.priceM2Range?.[0]
  const priceM2Max = priceData[priceData.length - 1]?.priceM2Range?.[1]

  const maxDate = priceData.slice(-1)[0]?.date

  let priceM2Range = `${priceM2Min ? formatPrice(priceM2Min, 0) : ''} - ${
    priceM2Max ? formatPrice(priceM2Max, 0) : ''
  }`

  if (!priceM2Min && !priceM2Max) priceM2Range = ''

  return { priceM2, priceM2Max, priceM2Range, priceM2Min, maxDate }
}

const groupBy = (items: any[], key: string) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  )

const removeDuplicate = (items: any[], key: string) => {
  return items?.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t[key] === value[key])
  )
}

const mapBy = (field: any, data: any[]) => {
  const res: any = {}

  data?.forEach?.((c) => {
    res[typeof field === 'function' ? field(c) : c[field]] = c
  })

  return res
}

const sum = (arr: any[], getter: any) => {
  if (!arr) return 0

  const getCurrentVal = (val: any) =>
    typeof getter === 'function'
      ? +getter(val) || 0
      : // eslint-disable-next-line no-unsafe-optional-chaining
        +val?.[getter] || +val || 0

  return arr.reduce((p, c) => (p || 0) + getCurrentVal(c), 0)
}

const truncate = (text: string, size: number) => {
  if (text.length > size) return `${text.substring(0, size)}...`

  return text
}

const includes = (array1: any, array2: any) => {
  const arr1 = Array.isArray(array1) ? array1 : [array1]
  const arr2 = (Array.isArray(array2) ? array2 : [array2]).map((r) => String(r))

  return arr1.some((r) => arr2.includes(String(r)))
}

const getGeoPoint = (geo_center: any) => {
  if (!geo_center) return null

  const lon =
    geo_center.lon || Number(geo_center.split?.(' ')?.[0].substring(6))

  const lat =
    geo_center.lat || Number(geo_center.split?.(' ')?.[1].slice(0, -1))

  return lon && lat ? { lat, lon } : null
}

const transformTileRequest = (url: string, resourceType?: string) => {
  try {
    const accessToken = localStorage.getItem('jwt')
    const headers = { Authorization: `JWT ${accessToken}` }

    if (resourceType && url.startsWith(API_BASE_URL as string)) {
      return { url, headers }
    }
  } catch (error) {
    console.error(error)
  }
}

export {
  supplant,
  toSnakeCase,
  toCamelCase,
  readableFinancialInteger,
  formatCurrency,
  downloadURI,
  isIOS,
  clearLocalStorage,
  formatPrice,
  getPriceInfo,
  groupBy,
  mapBy,
  sum,
  truncate,
  includes,
  removeDuplicate,
  getGeoPoint,
  transformTileRequest,
}

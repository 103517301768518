import { useEffect, useState } from 'react'

/**
 * Hook to handle asynchronous action lifecycle (pending, fulfilled, rejected).

 * @param action async to dispatch
 * @param payloadErrors indicates whether to check error fied on the payload object
 *
 * @example <caption> Initialize with async action</caption>
 * const { dispatch, loading, error } = useAsync(() => fetch(someData))
 *
 * // and later call dispatch() when you want to run the action
 *
 * @example <caption> Dispatch with async action</caption>
 * const { dispatch, loading, error } = useAsync()
 *
 * // and later call dispatch(() => fetch(someData)) when you want to run login action
 *
 * @namespace Hooks
 */
const useAsync = <V>(action?: () => Promise<V>, payloadErrors = true) => {
  const [errors, setErrors] = useState<any[] | undefined>()
  const [data, setData] = useState<V>()
  const [loading, setLoading] = useState(false)

  const getErrorsFromPayload = (payload: any) => {
    if (!payloadErrors) return

    const error = payload?.error || payload?.errors

    if (!error) return

    return Array.isArray(error) ? error : [error]
  }

  const dispatch = async (newAction = action) => {
    setLoading(true)

    if (!newAction) return

    newAction()
      ?.then((result) => {
        const err = getErrorsFromPayload(result)

        if (err) {
          setErrors(err as any)
        } else {
          setErrors(undefined)
          setData(result)
        }
        setLoading(false)
      })
      .catch((err: any) => {
        // Send error manually to sentry
        console.error(err)

        setErrors([{ message: 'An error has occured', code: '500' }] as any)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (action) dispatch()
  }, [])

  return { data: data as V, loading, dispatch, errors }
}

export default useAsync

import React, { forwardRef } from 'react'
import { ButtonProps as BaseButtonProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import { StyledButton } from './Button.style'

export type ButtonProps = BaseButtonProps & {
  /**
   * Indicates whether to use loading state
   * @default false
   */
  loading?: boolean
  disableRadius?: boolean
  variant?: 'custom' | BaseButtonProps['variant']
  color?: 'error' | 'success' | 'info' | BaseButtonProps['color']
  component?: React.ReactNode
  loaderSize?: number
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, loading, loaderSize = 20, disableRadius, ...other } = props

  return (
    <StyledButton
      {...{ ref, $disableRadius: disableRadius }}
      {...other}
      disabled={loading || other.disabled}
      endIcon={
        loading ? (
          <CircularProgress color="inherit" size={loaderSize} />
        ) : (
          other.endIcon
        )
      }
    >
      {children}
    </StyledButton>
  )
})

Button.displayName = 'Button'

export default Button

/* eslint-disable max-lines */
import React, { useState, useCallback } from 'react'
import { BoxProps } from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TablePagination, {
  TablePaginationProps,
} from '@mui/material/TablePagination'
import TableBody from '@mui/material/TableBody'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import TColumn from './TColumn'

import TRow, { Row, TableState, TRowProps } from './TRow'
import { TableRoot } from './Table.style'

export type TableProps = BoxProps &
  Partial<TablePaginationProps> &
  Omit<TRowProps, 'row' | 'setTableState'> & {
    rows?: Row[]
    data?: Row[]
    pageTop?: boolean
    loading?: boolean
    emptyText?: string
    onNext?: () => void
    onPrevious?: () => void
    onChange?: (value?: TableState) => void
    onPage?: (page: number) => void
    onPageSize?: (size: number) => void
    onSort?: (order?: string) => void
  }

const defaultTableState = {
  rowsPerPage: 25,
  rowsPerPageOptions: [25, 50, 100],
  selectedRows: [],
  orderBy: null,
}

const Table = (props: TableProps) => {
  const {
    columns = [],
    data,
    rows = data || [],
    tableState: state = defaultTableState,
    count = 0,
    onNext,
    onPrevious,
    onChange,
    labelRowsPerPage,
    pageTop,
    showFirstButton,
    showLastButton,
    getRowSelected,
    RowDetails,
    loading,
    emptyText,
    RowDetailsProps,
    onPage,
    onPageSize,
    onSort,
    ...other
  } = props

  const rowsByKey: Record<string, Row> = {}

  const noData = emptyText ?? (!rows?.length && !loading && '')

  const [tableState, setTableState] = useState<TableState>({
    ...defaultTableState,
    ...state,
  } as any)

  const { rowsPerPage, page, selectedRows = [] } = tableState
  const [orderBy, order] = tableState.orderBy?.split(':') || []

  if (getRowSelected) {
    rows.forEach((r) => {
      rowsByKey[getRowSelected(r)] = r
    })
  }

  const SelectProps = {
    ...other.SelectProps,
    labelId:
      other.SelectProps?.labelId ||
      (other.SelectProps?.id ? `label-${other.SelectProps?.id}` : undefined),
  }

  const apply = useCallback((newTableState: TableState) => {
    setTableState(newTableState)
    onChange?.(newTableState)
  }, [])

  const handlePageChange: TablePaginationProps['onPageChange'] = (
    _e,
    newPage
  ) => {
    if (newPage > (tableState?.page as number)) {
      onNext?.()
    } else if (newPage < (tableState?.page as number)) {
      onPrevious?.()
    }

    const newTableState = { ...tableState, page: newPage }

    if (newTableState.selectedRows?.length) {
      newTableState.selectedRows = []
    }

    apply(newTableState)
    onPage?.(newPage)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!getRowSelected) return

    const newTableState = { ...tableState }

    if (event.target.checked) {
      newTableState.selectedRows = rows.map(getRowSelected)
    } else {
      newTableState.selectedRows = []
    }

    apply(newTableState)
  }

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    const reset = property === orderBy && order === 'desc'

    const newTableState = {
      ...defaultTableState,
      ...tableState,
      orderBy: !reset ? `${property}:${isAsc ? 'desc' : 'asc'}` : undefined,
    }

    if (newTableState.page !== 0) newTableState.page = 0

    apply(newTableState)
    onSort?.(newTableState.orderBy)
  }

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPageSize = +e.target.value
    const newTableState = {
      ...defaultTableState,
      ...tableState,
      rowsPerPage: newPageSize,
    }

    if (newTableState.page !== 0) newTableState.page = 0

    if (newTableState.selectedRows?.length) {
      newTableState.selectedRows = []
    }

    apply(newTableState as any)
    onPageSize?.(newPageSize)
  }

  const Pagination = (state as any)?.page >= 0 && (
    <TablePagination
      rowsPerPageOptions={tableState.rowsPerPageOptions as any}
      component="div"
      count={count || rows.length}
      rowsPerPage={rowsPerPage as any}
      page={page as any}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      labelRowsPerPage={labelRowsPerPage}
      SelectProps={SelectProps}
      showFirstButton={showFirstButton}
      showLastButton={showLastButton}
    />
  )

  return (
    <TableRoot {...other} $loading={loading}>
      {pageTop && Pagination}

      <TableContainer>
        <MuiTable>
          <TableHead>
            <TableRow>
              {RowDetails && <TableCell />}
              {getRowSelected && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && selectedRows.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all' }}
                  />
                </TableCell>
              )}

              {columns.map((c) => (
                <TColumn
                  column={c}
                  key={c.key}
                  orderBy={orderBy}
                  order={order}
                  onSort={handleRequestSort}
                />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, j) => (
              <TRow
                key={row.key || j}
                row={row}
                getRowSelected={getRowSelected}
                onChange={apply as any}
                tableState={tableState}
                columns={columns}
                RowDetails={RowDetails}
                RowDetailsProps={RowDetailsProps}
              />
            ))}

            {(other.children || noData) && !loading && (
              <TableRow className="TableContent">
                <TableCell {...({ colSpan: '100%' } as any)}>
                  {other.children || <Typography p={2}>{noData}</Typography>}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>
      {Pagination}
    </TableRoot>
  )
}

export default Table

import React, { useState } from 'react'
import Chip from '@mui/material/Chip'

import { Button, SearchInput } from '../../../components'
import { useI18n } from '../../../context'
import AlertEditorDialog from '../AlertEditorDialog'
import AlertList from './AlertList'
import useAlert from './MyAlerts.hooks'
import { HeaderBox, MyAlertsRoot } from './MyAlerts.style'

const MyAlerts = () => {
  const { t } = useI18n()

  const { open, handleClose, handleOpen } = useAlert()
  const [filter, setFilter] = useState('')

  const handleFilterChange = (e) => {
    setFilter(e.target.value)
  }

  return (
    <MyAlertsRoot>
      <h2>
        {t('myAlert.alerts')} <Chip label="beta" />
      </h2>
      <HeaderBox>
        <SearchInput
          value={filter}
          onChange={handleFilterChange}
          placeholder={t('myAlert.placeholder')}
          sx={{ zIndex: 2 }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          sx={{ zIndex: 2 }}
        >
          {t('myAlert.newAlert')}
        </Button>
      </HeaderBox>
      <AlertList filter={filter} />
      <AlertEditorDialog open={open} onClose={handleClose} />
    </MyAlertsRoot>
  )
}

export default React.memo(MyAlerts)

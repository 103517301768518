import React from 'react'
import Box from '@mui/material/Box'
import TableCell from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'

import { Column } from './TRow'

export type TColumnProps = {
  onSort: (key: string) => void
  orderBy: string
  order: string
  column: Column
}

const TColumn = (props: TColumnProps) => {
  const { orderBy, order, column, onSort } = props
  const { label, render: _r, ...rest } = column

  const handleClick = () => {
    onSort(column.key)
  }

  return (
    <TableCell
      {...rest}
      sortDirection={(orderBy === column.key ? order : false) as any}
    >
      {!column.order && (rest.children || label)}

      {column.order && (
        <TableSortLabel
          active={orderBy === column.key}
          direction={(orderBy === column.key ? order : 'asc') as any}
          onClick={handleClick}
          sx={{ zIndex: 2 }}
        >
          {rest.children || label}
          {orderBy === column.key ? (
            <Box component="span" sx={visuallyHidden}>
              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </Box>
          ) : null}
        </TableSortLabel>
      )}
    </TableCell>
  )
}

export default TColumn

import ApiClient from '../apiClient'

const getCompanyId = () => {
  try {
    return JSON.parse(localStorage.getItem('user') || '').company_id
  } catch (error) {
    return ''
  }
}

const client = new ApiClient()

const getCompanyUsersAnalytics = async () => {
  return client.get(`/companies/${getCompanyId()}/users/analytics`)
}

const addUser = async (data: Record<string, any>) => {
  const response = await client.post(`/companies/${getCompanyId()}/users`, data)

  if (response.data) {
    response.data = {
      ...data,
      id: response.data,
      first_name: data.firstName,
      last_name: data.lastName,
      status: 'active',
      create_timestamp: Date.now(),
    }
  }

  return response
}

const updateUser = async ({ id, ...data }: Record<string, any>) => {
  const response = await client.put(
    `/companies/${getCompanyId()}/users/${id}`,
    data
  )

  if (response.data) {
    response.data = { ...data, id }
  }

  return response
}

const updateCompanyLogo = async (logo: File | Blob) => {
  return client.put(
    `/companies/${getCompanyId()}/update-logo`,
    { logo },
    { formData: true }
  )
}

const updateUserPassword = ({ id, password }: any) => {
  return client.put(`/companies/${getCompanyId()}/users/${id}/password`, {
    password,
  })
}

const updateCompanyColors = (colors: Record<string, any>) => {
  return client.put(`/companies/${getCompanyId()}/update-colors`, colors)
}

const updateUserRoles = async ({ id, roles }: any) => {
  const response = await client.put(
    `/companies/${getCompanyId()}/users/${id}/roles`,
    { roles }
  )

  if (response.data) {
    response.data = { id, roles }
  }

  return response
}

const activateUser = async (id: any) => {
  const response = await client.put(
    `/companies/${getCompanyId()}/users/${id}/activate`
  )

  if (response.data) {
    response.data = { id, status: 'active' }
  }

  return response
}

const inActivateUser = async (id: any) => {
  const response = await client.put(
    `/companies/${getCompanyId()}/users/${id}/inactivate`
  )

  if (response.data) {
    response.data = { id, status: 'inactive' }
  }

  return response
}

const getCompanyUsers = async () => {
  return client.get(`/companies/${getCompanyId()}/users`)
}

const updateCompany = async (values: any) => {
  return client.put(`/companies/${getCompanyId()}/update`, values)
}

const companyService = {
  getCompanyUsersAnalytics,
  addUser,
  updateUser,
  updateUserPassword,
  updateUserRoles,
  inActivateUser,
  activateUser,
  getCompanyUsers,
  updateCompanyLogo,
  updateCompanyColors,
  updateCompany,
}

export { getCompanyId }
export default companyService

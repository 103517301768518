/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import companyService from '../../services/company'

const initialState = {
  users: [],
  analyticsById: {},
}

export const getCompanyUsers = createAsyncThunk(
  'company/getCompanyUsers',
  companyService.getCompanyUsers
)

export const getCompanyUsersAnalytics = createAsyncThunk(
  'company/getCompanyUsersAnalytics',
  companyService.getCompanyUsersAnalytics
)

export const activateUser = createAsyncThunk(
  'company/activateUser',
  companyService.activateUser
)

export const inActivateUser = createAsyncThunk(
  'company/inActivateUser',
  companyService.inActivateUser
)

export const addUser = createAsyncThunk(
  'company/addUser',
  companyService.addUser
)

export const updateUser = createAsyncThunk(
  'company/updateUser',
  companyService.updateUser
)

export const updateUserPassword = createAsyncThunk(
  'company/updateUserPassword',
  companyService.updateUserPassword
)

export const updateCompanyColors = createAsyncThunk(
  'company/updateCompanyColors',
  companyService.updateCompanyColors
)

export const updateUserRoles = createAsyncThunk(
  'company/updateUserRoles',
  companyService.updateUserRoles
)

export const updateCompany = createAsyncThunk(
  'company/updateCompany',
  companyService.updateCompany
)

const mergeUpdate = (state, action) => {
  const { data } = action.payload

  if (data) {
    const analytic = state.analyticsById[data.id]

    if (analytic) {
      Object.keys(data).forEach((key) => {
        analytic[key] = data[key]
      })
    }
  }
}

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: {
    // Handle async actions here, not in reducers
    [getCompanyUsers.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.users = data
    },
    [getCompanyUsersAnalytics.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        data.forEach((analytic) => {
          state.analyticsById[analytic.id] = analytic
        })
      }
    },
    [addUser.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.users = [data, ...state.users]
        state.analyticsById[data.id] = data
      }
    },
    [updateUser.fulfilled]: mergeUpdate,
    [updateUserRoles.fulfilled]: mergeUpdate,
    [updateUserPassword.fulfilled]: mergeUpdate,
    [inActivateUser.fulfilled]: mergeUpdate,
    [activateUser.fulfilled]: mergeUpdate,
    [updateCompany.fulfilled]: mergeUpdate,
  },
})

export default company.reducer

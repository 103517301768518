/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import ownershipService from '../../services/ownership'

const initialState = {
  ownerLandList: null,
}

export const getOwnerLandList = createAsyncThunk(
  'ownership/getOwnerLandList',
  ownershipService.getOwnerLandList
)

const ownership = createSlice({
  name: 'ownership',
  initialState,
  reducers: {},
  extraReducers: {
    // Handle async actions here, not in reducers
    [getOwnerLandList.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.ownerLandList = data
      }
    },
  },
})

export default ownership.reducer

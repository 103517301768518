import React, { useCallback, useEffect, useState } from 'react'
import * as turf from '@turf/turf'
import { useSelector, useDispatch } from 'react-redux'
import turfUnion from '@turf/union'
import { Layer, Source } from 'react-map-gl'
import useResizeObserver from 'use-resize-observer'

import ReactMapGl from '../../../components/ReactMapGl'
import { alertMapDataSelector, updateSelectedAlert } from '../../../redux/alert'
import AlertStats from '../AlertStats'
import useLocationGeoJSON from '../ZonesStep/ZonesStep.hooks'
import useHistoryParameters from '../SettingsStep/SettingsStep.hooks'
import DrawPolygon from './DrawPolygon'
import { AlertMapRoot } from './AlertMap.style'

const fillOpacity = 0.5
const strokeColor = 'rgb(0, 0, 0)'

const layerStyle = {
  id: 'landLayer',
  type: 'fill',
  paint: {
    'fill-opacity': fillOpacity,
    'fill-outline-color': strokeColor,
    'fill-color': '#00ade6',
  },
}

const AlertMap = () => {
  const [features, setFeatures] = useState([])
  const { ref, height, width } = useResizeObserver({})

  const dispatch = useDispatch()

  useLocationGeoJSON()
  useHistoryParameters()

  const { viewport, type, radius, geoCenter, multiPolygon } =
    useSelector(alertMapDataSelector)

  useEffect(() => {
    if (geoCenter && type === 'Radius') {
      // set circle
      const opts = { steps: 64, units: 'kilometers' }
      const circle = [turf.circle(geoCenter, radius || 0.1, opts)]

      const coordinates = [[circle[0].geometry.coordinates[0]]]
      const input = { multiPolygon: { type: 'MultiPolygon', coordinates } }

      dispatch(updateSelectedAlert(input))
    }
  }, [dispatch, type, geoCenter, radius])

  const geojson = {
    type: 'FeatureCollection',
    features: [{ type: 'Feature', geometry: multiPolygon }],
  }

  useEffect(() => {
    if (type !== 'Polygone') return

    const union = features?.reduce((acc, r) => {
      return acc?.type
        ? {
            ...turfUnion(acc, r),
          }
        : r
    }, [])

    const { geometry } = union

    dispatch(
      updateSelectedAlert({
        multiPolygon: geometry || { type: 'MultiPolygon', coordinates: [] },
      })
    )
  }, [features, type])

  const onUpdate = useCallback((e) => {
    setFeatures((currFeatures) => {
      const isPolygonNotExists = currFeatures.findIndex((feature) => {
        return feature.id === e.features[0].id
      })

      if (isPolygonNotExists === -1) return [...currFeatures, e.features[0]]

      return currFeatures.map((obj) => {
        if (obj.id === e.features[0].id) return e.features[0]

        return obj
      })
    })
  }, [])

  const onDelete = useCallback((e) => {
    setFeatures((currFeatures) => {
      return currFeatures.filter((feature) => feature.id === e.features.id)
    })
  }, [])

  return (
    <AlertMapRoot ref={ref}>
      <AlertStats />
      <ReactMapGl
        viewport={viewport}
        geojson={geojson}
        isGeojson={!!multiPolygon?.coordinates?.length > 0}
        geoCenter={geoCenter}
        width={width}
        height={height}
      >
        <Source id="my-data" type="geojson" data={geojson}>
          <Layer {...layerStyle} />
        </Source>

        {type === 'Polygone' && (
          <DrawPolygon
            position="top-left"
            displayControlsDefault={false}
            controls={{ polygon: true, trash: true }}
            defaultMode="draw_polygon"
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        )}
      </ReactMapGl>
    </AlertMapRoot>
  )
}

export default AlertMap

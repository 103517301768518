import Box from '@mui/material/Box'
import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { appSelector } from '../../../redux'
import MobileMenu from '../MobileMenu'
import { BrandRoot } from './Brand.style'

const Brand = () => {
  const app = useSelector(appSelector)

  return (
    <BrandRoot>
      <MobileMenu />
      <NavLink to="/">
        <Box
          component="img"
          sx={{
            width: '146px',
            height: { xs: '28px', sm: '38px' },
            objectFit: 'contain',
            position: 'relative',
            top: { xs: '8px', sm: '12px' },
          }}
          src="/assets/brand.png"
          alt="logo"
        />
      </NavLink>
      <span>{app === 'brokerinfo' ? '</> BrokerInfo' : '</> LandDev'}</span>
    </BrandRoot>
  )
}

export default Brand

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import neighbourhoodService from '../../services/neighbourhood'

const initialState = {
  faosAround: [],
  transactionsAround: [],
  buildingsAround: [],
  listingAround: [],
}

export const fetchFaosAround = createAsyncThunk(
  'neighbourhood/faos',
  neighbourhoodService.fetchFaosAround
)

export const fetchTransactionsAround = createAsyncThunk(
  'neighbourhood/transactions',
  neighbourhoodService.fetchTransactionsAround
)

export const fetchBuildingAround = createAsyncThunk(
  'neighbourhood/buildings',
  neighbourhoodService.fetchBuildingAround
)

export const fetchListingAround = createAsyncThunk(
  'neighbourhood/listing',
  neighbourhoodService.fetchListingAround
)

const neighbourhood = createSlice({
  name: 'neighbourhood.',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchListingAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.listingAround = data?.listingAround
    },
    [fetchFaosAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.faosAround = data?.faos_lands
    },

    [fetchTransactionsAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.transactionsAround = data?.transactions_lands
    },

    [fetchBuildingAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.buildingsAround = data
    },
    [fetchListingAround.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.listingsAround = data
    },
  },
})

export default neighbourhood.reducer

import React, { useState } from 'react'

import { FilterCategory, MinMaxFilter } from '../../../components'
import { useI18n } from '../../../context'

const RoomFilter = ({ onChange, roomFilter, ...other }) => {
  const { t } = useI18n()

  const [reset, setReset] = useState(false)

  const handleChange = (newValue, apply) => {
    setReset(false)
    onChange({ roomFilter: newValue }, apply)
  }

  const handleReset = () => {
    setReset(true)
  }

  return (
    <FilterCategory
      {...other}
      title={t('listing.rooms_nb')}
      onReset={roomFilter?.length && handleReset}
    >
      <MinMaxFilter
        id="room"
        step=".01"
        value={roomFilter}
        reset={reset}
        onChange={handleChange}
        obj={other.obj}
      />
    </FilterCategory>
  )
}

export default RoomFilter

import React, { useMemo, useRef } from 'react'
import DeckGL from '@deck.gl/react'
import InteractiveMap, { NavigationControl, MapProvider } from 'react-map-gl'
import { FlyToInterpolator } from '@deck.gl/core'

import { useMediaQuery } from '../../hooks'
import { MAPBOX_TOKEN, DEFAULT_MAPSTYLE } from '../../config'

const INITIAL_VIEW_STATE = {
  latitude: 46.445101,
  longitude: 6.633394,
  zoom: 10,
}

interface Props {
  viewport?: any
  layers?: any
  mapStyle?: string
  children?: React.ReactNode
  deckGlProps?: any
  measuredRef?: any
}

// DeckGL react component
const Mapbox = ({
  viewport = INITIAL_VIEW_STATE,
  mapStyle = DEFAULT_MAPSTYLE,
  children,
  layers,
  deckGlProps,
  measuredRef,
}: Props) => {
  const onDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const mapRef = useRef<any>()

  const viewState = useMemo(
    () => ({
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      ...viewport,
    }),
    [viewport.latitude, viewport.longitude]
  )

  const onMapLoad = React.useCallback(() => {
    mapRef?.current?.getMap().on('webglcontextlost', () => {
      sessionStorage.setItem('webglcontextlost', 'true')
      // window.location.reload()
    })
  }, [])

  return (
    <DeckGL
      ContextProvider={MapProvider}
      {...deckGlProps}
      initialViewState={viewState}
      controller
      layers={layers}
      style={{ overflow: 'hidden' }}
    >
      <InteractiveMap
        ref={(e) => {
          // eslint-disable-next-line no-prototype-builtins
          if (measuredRef?.hasOwnProperty?.('current')) {
            measuredRef.current = e
          } else if (typeof measuredRef === 'function') {
            measuredRef(e)
            // eslint-disable-next-line no-param-reassign
          } else measuredRef = e

          mapRef.current = e
        }}
        mapStyle={mapStyle}
        mapboxAccessToken={MAPBOX_TOKEN}
        interactive
        onLoad={onMapLoad}
      >
        {onDesktop && (
          <NavigationControl
            style={{ right: '10px', bottom: '48px', position: 'relative' }}
            position="bottom-right"
          />
        )}
      </InteractiveMap>
      {children}
    </DeckGL>
  )
}

export default Mapbox

import { useEffect, useState } from 'react'

/**
 * Indicates whether we are on mobile devices to use native controls if possible
 */
const useNative = ({ isNative }: { isNative?: boolean } = {}) => {
  const onMobile =
    typeof navigator !== 'undefined' &&
    /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)

  const [native, setNative] = useState(
    isNative === undefined ? onMobile : isNative
  )

  useEffect(() => {
    setNative(isNative === undefined ? onMobile : isNative)
  }, [isNative, onMobile])

  return { native }
}

export default useNative

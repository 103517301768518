import ApiClient from '../apiClient'
import { API_BASE_URL } from '../apiClient/ApiClient'
import { regionUrlMap } from './reportingGenerator'
import { downloadReport } from './reportingHelpers'

const client = new ApiClient()

const getReports = async (regionId) => {
  const response = await client.get(`/reporting/${regionId}/fetchAllReports`)

  return response
}

const filterReports = ({ regionId, reports, type, egrid }) => {
  const filter = (report) =>
    !report.regionId || report.regionId.toString() === regionId.toString()

  if (type === 'All') {
    return reports.map((report) => {
      if (report?.label === 'Analysis') {
        return { label: 'Analysis', options: report.options.filter(filter) }
      }
      if (report?.label === 'Financial') {
        return { label: 'Financial', options: report.options.filter(filter) }
      }
      if (report?.label === 'RDPPF') {
        return { label: 'RDPPF', options: report.options.filter(() => egrid) }
      }

      return report
    })
  }

  return [
    {
      label: 'Modèles public',
      options: reports[0].options.filter(filter),
    },
    reports[1]
      ? { label: 'Vos modèles privées', options: reports[1].options }
      : null,
  ]
}

const getRegionsReports = ({ regionId, reports }) => {
  const regionReports = reports[0]?.options?.filter(
    (report) => report?.regionId && String(report.regionId) === String(regionId)
  )

  return {
    regionReports,
    privateReports: reports[1]
      ? { label: 'Vos modèles privées', options: reports[1].options }
      : null,
  }
}

const exportAnalysis = (data) => {
  data.url = window.location.href
  const { template, land, landId, extraInformation } = data
  const { value, category, prefix, extension, shortid } = template

  if (value === 'RDPPF') {
    const selectedLand = data.selectedLand || land
    const rdppfUrl = regionUrlMap[selectedLand.region_name]

    window.open(rdppfUrl + selectedLand.egrid_nb, '_blank', 'noopener')

    return Promise.resolve()
  }

  delete data.selectedLand

  const info = extraInformation || 'noExtraInfo'

  const filename = !extension
    ? data.fileName
    : `${prefix}-${land.city_name}-${land.code_number}.${extension}`

  return fetch(
    `${API_BASE_URL}/reporting/land/${landId}/${category}/${info}/${shortid}`,
    {
      method: 'POST',
      headers: {
        Authorization: `JWT ${localStorage.getItem('jwt')}`,
        'Content-Type': 'application/json',
        locale: localStorage.getItem('language'),
      },
      body: JSON.stringify({ data, filename }),
    }
  )
    .then((response) => response.blob())
    .then((blob) => downloadReport(blob, filename))
}

const reportingService = {
  getReports,
  getRegionsReports,
  filterReports,
  exportAnalysis,
}

export default reportingService

import React from 'react'

import { HistoryPeopleRoot } from './HistoryPeople.style'

export type HistoryTablePeopleProps = {
  people?: string[] | string
}

const HistoryPeople = ({ people = '', ...other }: HistoryTablePeopleProps) => {
  if (Array.isArray(people)) {
    const names = people[0].split(',')

    return names.map((name, index) => (
      <HistoryPeopleRoot
        key={name + index}
        dangerouslySetInnerHTML={{ __html: name }}
        {...other}
      />
    ))
  }

  const names = people?.split(',')

  if (!names || !names.length) return null

  return names.map((name, index) => (
    <HistoryPeopleRoot key={name + index} {...other}>
      {name}
    </HistoryPeopleRoot>
  ))
}

export default HistoryPeople

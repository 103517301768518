import styled from 'styled-components'

export const PolygonStepRoot = styled.div`
  > span {
    color: rgb(141, 155, 163);
    font-style: italic;
    font-size: 12px;
  }
`

export const AreaContainer = styled.div`
  max-height: 250px;
  overflow-y: auto;

  > div {
    display: flex;
    align-items: center;
  }

  h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

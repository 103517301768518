import styled from 'styled-components'

export const SearchFieldRoot = styled.div`
  flex-grow: 1;
  position: relative;
  margin-left: 0;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: rgba(255, 255, 255, 0.15);

  > .MuiSvgIcon-root {
    position: absolute;
    top: calc(50% - 12px);
  }

  > .MuiInputBase-root {
    color: inherit;
    width: 100%;

    input {
      width: 100%;
      padding: 8px 8px 8px 36px;
      transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`

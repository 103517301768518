import { i18n } from '../../context'
import { formatPrice, format } from '../../utils'

export const getHistoryPriceData = (data, coef = 1) => {
  const formatNum = (price) => {
    if (!price) return price

    if (coef === 12) return Math.round(price * coef)

    return +parseFloat(price * coef).toFixed(2)
  }

  return data.map((stats, index, array) => {
    const divider =
      1 * stats.doc_count +
      (array[index - 1] ? array[index - 1].doc_count : 0) +
      (array[index - 2] ? array[index - 2].doc_count : 0)

    const min =
      ((array[index - 1]
        ? array[index - 1].median_price.values['25.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['25.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['25.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const max =
      ((array[index - 1]
        ? array[index - 1].median_price.values['75.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['75.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['75.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const priceData =
      ((array[index - 1]
        ? array[index - 1].median_price.values['50.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['50.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['50.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const price = divider < 1 ? null : priceData
    const price_range = divider < 1 ? null : [min, max]

    return {
      date: stats.key_as_string,
      count: stats.doc_count,
      price: formatNum(price),
      priceRange: price_range?.map((i) => formatNum(i)),
    }
  })
}

export const getHistoryPriceInfo = (priceData = []) => {
  const price = formatPrice(priceData[priceData.length - 1]?.price, 0)

  const priceMin = priceData[priceData.length - 1]?.priceRange?.[0]
  const priceMax = priceData[priceData.length - 1]?.priceRange?.[1]

  const maxDate = priceData.slice(-1)[0]?.date

  let priceRange = `${priceMin ? formatPrice(priceMin, 0) : ''} - ${
    priceMax ? formatPrice(priceMax, 0) : ''
  }`

  if (!priceMin && !priceMax) priceRange = ''

  return { price, priceMax, priceRange, priceMin, maxDate }
}

export const getPriceLocations = (filters, defaultValue = '') => {
  const location = [
    filters?.cityFilter,
    filters?.zoneFilter,
    filters?.regionFilter,
  ]

  return location.find((d) => d?.length)?.join(', ') || defaultValue
}

let historyAnalytycData = {}

export const saveHistoryAnalyticData = (data) => {
  try {
    historyAnalytycData = { ...historyAnalytycData, ...data }
  } catch (error) {
    console.error(error)
  }
}

export const getHistoryAnalyticData = () => {
  return { ...historyAnalytycData }
}

export const clearHistoryAnalyticData = () => {
  historyAnalytycData = {}
}

export const getHistoryXaxis = (date, interval) => {
  if (interval === 'month') {
    return format(date, 'MMM yyyy')
  }
  if (interval === 'quarter') {
    const intervalText = i18n.t('common.quarter', { count: format(date, 'Q') })

    return `${intervalText} ${new Date(date).getFullYear()}`
  }

  return format(date, 'yyyy')
}

export const getHistoryMinusMonth = (month, data) => {
  const denum = data[data.length - 2 - month]?.count

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 2]?.count / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

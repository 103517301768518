import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      light: '#2d3d4d',
      main: '#041725',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#64dfff',
      main: '#00ade6',
      dark: '#007eb4',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgb(4, 26, 37)',
    },
    error: {
      main: '#e53935',
    },
    warning: {
      main: '#ffa000',
    },
    success: {
      main: '#00c853',
    },
    background: {
      default: '#ebebeb',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
})

export default theme

import React, { useEffect, useMemo, useState } from 'react'
import Map from 'react-map-gl'
import * as turf from '@turf/turf'

import { MAPBOX_TOKEN, LIGHT_MAPSTYLE } from '../../config'

const INITIAL_VIEW_STATE = {
  latitude: 46.445101,
  longitude: 6.633394,
  zoom: 10,
}

const ReactMapGl = ({
  viewport = INITIAL_VIEW_STATE,
  mapStyle = LIGHT_MAPSTYLE,
  children,
  geojson,
  width,
  height,
  ...other
}) => {
  const mapRef = React.useRef()
  const [loaded, setLoaded] = useState(false)

  const viewState = useMemo(
    () => ({
      ...viewport,
      height: '100%',
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
    }),
    [viewport.latitude, viewport.longitude]
  )

  const geoStr = JSON.stringify(geojson)

  const onMapLoad = React.useCallback(() => {
    setLoaded(true)
  }, [])

  useEffect(() => {
    const map = mapRef.current

    if (loaded && map && other.isGeojson) {
      const bbox = turf.bbox(geojson)

      map.fitBounds(bbox, { padding: 150 })
    }
  }, [viewport.latitude, viewport.longitude, geoStr, loaded, other.isGeojson])

  useEffect(() => {
    setTimeout(() => mapRef.current?.resize())
  }, [width, height])

  return (
    <Map
      {...other}
      ref={mapRef}
      onLoad={onMapLoad}
      mapStyle={mapStyle}
      mapboxAccessToken={MAPBOX_TOKEN}
      style={{ overflow: 'hidden' }}
      initialViewState={viewState}
      // viewState={viewState}
    >
      {children}
    </Map>
  )
}

export default ReactMapGl

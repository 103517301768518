/* eslint-disable no-param-reassign */

export const updateGeoCenterSate = (state, action) => {
  const { data } = action.payload

  if (data) {
    const geoCenter = JSON.parse(data.geojson_center).coordinates

    const newViewport = {
      latitude: geoCenter[1],
      longitude: geoCenter[0],

      zoom: 13,
    }

    state.selectedAlert.viewport = {
      ...state.selectedAlert.viewport,
      ...newViewport,
    }
  }
}

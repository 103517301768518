import styled from 'styled-components'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'

export const UserMenuRoot = styled(MenuList)`
  margin-top: 14px;
  min-width: 250px;

  display: flex;
  flex-direction: column;
  height: 100%;

  .MuiMenuItem-root {
    padding-left: 16px;
    padding-right: 16px;

    &.blue {
      color: #2196f3;
    }

    &:first-child {
      padding: 0 16px;
    }
  }

  a {
    color: inherit;
  }

  hr {
    margin: 6px 0;

    &:last-of-type {
      flex-grow: 1;
    }
  }

  .MuiAvatar-root {
    width: 35px;
    height: 35px;
    margin-right: 8px;
  }

  .version {
    font-size: 13px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgba(0, 0, 0, 0.54);

    * {
      font-size: inherit;
    }
  }
`

export const LanguageMenuRoot = styled(Menu)`
  .MuiMenuItem-root {
    padding-left: 16px;
    padding-right: 16px;
  }

  svg {
    font-size: 20px;
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }
`

export const LanguageButton = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: 42px;
  }
`

import React from 'react'
import { useSelector } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField'

import { DateRangeFilterContainer } from '../../FaoHistory/DateRangeFilter/DateRangeFilter.style'
import { useQueryStringParser } from '../../../hooks'
import { maxDate, minDate } from '../../../utils'
import { aggregationsSelector } from '../../../redux/transactionHistory'
import { FilterCategory } from '../../../components'
import { useI18n } from '../../../context'

const DateRangeFilter = ({ onChange, dateFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(aggregationsSelector)
  const { dates } = aggregations.dates
  const params = useQueryStringParser()

  const min = dates.min_as_string
  const max = dates.max_as_string

  const docCount = aggregations.dates.doc_count

  const value = {
    min: maxDate(
      params.dateFilter?.[0],
      dateFilter?.[0] || (docCount > 0 ? min : dateFilter?.[0] || '2000-01-01')
    ),
    max: minDate(
      params.dateFilter?.[1],
      dateFilter?.[1] || (docCount > 0 ? max : dateFilter?.[1]) || new Date()
    ),
  }

  const handleChange = (name) => (newDate) => {
    const newValue = { ...value, [name]: newDate }

    onChange({ dateFilter: [newValue.min, newValue.max] })
  }

  return (
    <FilterCategory
      {...other}
      title={t('transaction.date')}
      className="DateRangeFilter"
    >
      <DateRangeFilterContainer>
        <DatePicker
          renderInput={(p) => (
            <TextField
              {...p}
              size="small"
              margin="normal"
              inputProps={{ ...p.inputProps }}
            />
          )}
          inputFormat="dd/MM/yyyy"
          id="transaction-date-min"
          label="Min"
          value={value.min}
          onChange={handleChange('min')}
          maxDate={new Date(max)}
          minDate={new Date(min)}
          fullWidth
          size="small"
          autoOk
        />
        <DatePicker
          renderInput={(p) => (
            <TextField
              {...p}
              size="small"
              margin="normal"
              inputProps={{ ...p.inputProps }}
            />
          )}
          inputFormat="dd/MM/yyyy"
          id="transaction-date-max"
          label="Max"
          value={value.max}
          onChange={handleChange('max')}
          minDate={new Date(value.min)}
          maxDate={new Date()}
          fullWidth
          size="small"
          autoOk
        />
      </DateRangeFilterContainer>
    </FilterCategory>
  )
}

export default DateRangeFilter

import * as turf from '@turf/turf'

import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'
import { mergeAlertLocations } from '../location'
import {
  AlertInput,
  extractAlertInput,
  formatMutatedAlertResponse,
  multiPolygonToImg,
  updateSettingFileds,
} from './alertServiceHelper'

const client = new ApiClient()

const getAlerts = async () => {
  const response = await client.get(
    `/alerts/${getCurrentUserId()}/getAllAlerts`
  )
  const payload = response.data

  if (payload) {
    const alerts = payload.map((alert: any) => {
      const principalType = alert.principal_type?.filter(
        (pt: any, index: number) => alert.principal_type.indexOf(pt) === index
      )

      const zoneAffectationSelected = principalType.map((p: any) => ({
        value: p,
        label: p,
      }))

      const geoCenter = alert.geo_center
        ? JSON.parse(alert.geo_center).coordinates
        : turf.center(JSON.parse(alert.st_asgeojson))

      const locations = mergeAlertLocations(alert)

      const isValid = (value: any) => value && Object.keys(value).length !== 0

      updateSettingFileds(alert)

      return {
        ...alert,
        multiPolygon: JSON.parse(alert.st_asgeojson),
        transaction: alert.transactions,
        fao: alert.building_permit,
        geoCenter: alert.geo_center
          ? geoCenter
          : geoCenter.geometry.coordinates,
        data: locations,
        principalType: zoneAffectationSelected,
        citiesSelected: alert.cities.filter(isValid).map((c: any) => {
          return { ...c, value: c.city, label: c.city }
        }),
        zonesSelected: alert.zones.filter(isValid).map((z: any) => {
          return { ...z, value: z.zone, label: z.zone }
        }),
        regionsSelected: alert.regions.filter(isValid).map((rg: any) => {
          return { ...rg, value: rg.region, label: rg.region }
        }),
        zoneAffectationSelected,
        createdAt: alert.create_timestamp,
      }
    })

    response.data = alerts.map((alert: any) => ({
      ...alert,
      img: multiPolygonToImg(alert),
    }))
  }

  return response
}

const createAlert = async (input: AlertInput) => {
  const data = extractAlertInput(input)
  const response = await client.post(`/alerts/${getCurrentUserId()}/add`, data)

  return formatMutatedAlertResponse(response, data)
}

const updateAlert = async (input: AlertInput) => {
  const { id, ...data } = extractAlertInput(input)
  const response = await client.put(`/alerts/${id}/update`, data)

  return formatMutatedAlertResponse(response, { id, ...data })
}

const deleteAlert = async (id: string) => {
  return client.put(`/alerts/${id}/delete`)
}

const pauseAlert = async ({ id, pause }: any) => {
  return client.get(`/alerts/${id}/paused/${pause}`)
}

const alertService = {
  deleteAlert,
  createAlert,
  getAlerts,
  updateAlert,
  pauseAlert,
}

export default alertService

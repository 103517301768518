import Autocomplete from '@mui/material/Autocomplete'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Switch from '@mui/material/Switch'

import { arrayFy } from '@/utils'
import { useI18n } from '../../../context'
import InfoTitle from '../SettingsStep/InfoTitle'
import {
  alertFaoSelector,
  alertFaoSettingsSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import StepActions from '../StepActions'
import {
  SettingsField,
  SettingsStepRoot,
} from '../SettingsStep/SettingsStep.style'
import FaoFilters from '../../FaoHistory/FaoFilters'
import SettingsCollapse from '../SettingsStep/SettingsCollapse'

const FaoSettingsStep = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState([])
  const { t } = useI18n()

  const faoSettings = useSelector(alertFaoSettingsSelector)
  const fao = useSelector(alertFaoSelector)

  const [filters, setFilters] = useState({ ...faoSettings })

  const updateSettings = (name) => (values) => {
    dispatch(
      updateSelectedAlert({
        building_permit_parameters: { ...faoSettings, [name]: values },
      })
    )
  }

  const handleChangeKeyword = (e, newValue) => {
    updateSettings('name')(newValue)

    setValue(newValue)
  }

  const handleChange = (e) => {
    dispatch(updateSelectedAlert({ fao: !!e.target.checked }))
  }

  const handleFilterChange = (newFilters) => {
    const newValue = { ...faoSettings, ...newFilters }

    dispatch(updateSelectedAlert({ building_permit_parameters: newValue }))
    setFilters(newValue)
  }

  return (
    <SettingsStepRoot>
      <Switch checked={!!fao} color="secondary" onChange={handleChange} />

      <SettingsCollapse>
        <InfoTitle
          title={t('myAlert.settings.keyword')}
          text={t('myAlert.settings.keywordDesc')}
        />

        <Autocomplete
          size="small"
          multiple
          freeSolo
          fullWidth
          autoSelect
          options={[]}
          value={arrayFy(faoSettings?.name || value)}
          onChange={handleChangeKeyword}
          renderInput={(params) => (
            <SettingsField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
            />
          )}
        />

        <FaoFilters filters={filters} open onChange={handleFilterChange} />
      </SettingsCollapse>

      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </SettingsStepRoot>
  )
}

export default FaoSettingsStep

import { createSelector } from '@reduxjs/toolkit'
import { groupBy } from '@/utils'

import { getCurrentUserId } from '../../services/user'

const userId = String(getCurrentUserId())

const tagStateSelector = (state) => state.tag

const tagsByLandIdSelector = createSelector(
  tagStateSelector,
  (tag) => tag.tagsByLandId
)

const allTagsSelector = createSelector(tagStateSelector, (tag) => tag.tags)

const hasTagsSelector = createSelector(
  tagsByLandIdSelector,
  (tag) => Object.keys({ ...tag }).length
)

const tagsSelector = createSelector(tagsByLandIdSelector, (tagsByLandId) =>
  Object.values({ ...tagsByLandId })
    .flat()
    .filter((tg) => tg?.value)
)

// DONT DELETE IT
const tagsByTagsIdSelector = createSelector(tagsSelector, (tags) =>
  Object.values(groupBy(tags, 'tag_id')).map((tag) => {
    return {
      color: tag?.[0]?.color,
      land_id: tag.map((t) => Number(t.land_id)),
    }
  })
)

const filterTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.land_id === value.land_id)
  )
)

const uniqueTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter(
    (value, index, self) =>
      index === self.findIndex((tg) => tg.value === value.value)
  )
)

const sharedTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((t) => String(t?.author) !== userId).map((t) => t.tag_id)
)

const myTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((tg) => String(tg.user_id) === userId)
)

const myActiveTagsSelector = createSelector(myTagsSelector, (tags) =>
  tags.filter((tg) => tg?.active)
)

const activeTagsSelector = createSelector(tagsSelector, (tags) =>
  tags.filter((tg) => tg?.active)
)

const tagsByUserByIdSelector = createSelector(
  tagStateSelector,
  (tags) => tags.tagsUserById
)

const tagsByUserSelector = createSelector(tagsByUserByIdSelector, (tags) =>
  Object.values(tags)
)

export {
  tagsByLandIdSelector,
  hasTagsSelector,
  sharedTagsSelector,
  myTagsSelector,
  myActiveTagsSelector,
  tagsSelector,
  activeTagsSelector,
  uniqueTagsSelector,
  filterTagsSelector,
  tagsByTagsIdSelector,
  allTagsSelector,
  tagsByUserSelector,
}

import { layersMap } from './layersMaps'

export const layoutMap = (
  currentStyle,
  mapDisplay,
  map,
  tags,
  favorites,
  prospects,
  thematic
) => {
  if (
    currentStyle &&
    (mapDisplay === 'tag' ||
      mapDisplay === 'prospect' ||
      mapDisplay === 'favorite')
  ) {
    currentStyle.layers.forEach((layer) => {
      if (layer.type === 'symbol') {
        map.setPaintProperty(layer.id, `icon-opacity`, 1)
        map.setPaintProperty(layer.id, `text-opacity`, 1)
      } else if (layer.id === 'land_layer') {
        map.setPaintProperty(layer.id, `${layer.type}-opacity`, 0.7)
        map.setPaintProperty(
          layer.id,
          `${layer.type}-color`,
          'rgba(255, 255, 255, 1)'
        )
      } else if (layer.type !== 'hillshade') {
        map.setPaintProperty(layer.id, `${layer.type}-opacity`, 0.3)
      }
    })

    layersMap(
      mapDisplay,
      tags,
      'land_layer',
      map,
      favorites,
      prospects,
      thematic
    )
  } else {
    currentStyle.layers.forEach((layer) => {
      if (layer.type === 'symbol') {
        map.setPaintProperty(layer.id, `icon-opacity`, 1)
        map.setPaintProperty(layer.id, `text-opacity`, 1)
      } else if (layer.type !== 'hillshade') {
        map.setPaintProperty(layer.id, `${layer.type}-opacity`, 1)
      }
    })
    map.setPaintProperty('land_layer', 'fill-opacity', 1)
    map.setPaintProperty('land_layer', 'fill-color', 'rgba(0, 0, 0, 0)')
  }
}

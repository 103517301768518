import styled from 'styled-components'

export const ButtonContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 2.5}px;
  z-index: 1;
  right: 16px;
  top: 16px;

  .MuiIconButton-root {
    padding: 0;
  }
`

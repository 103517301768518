export const toQueryParams = (
  query: Record<string, any>,
  filter = (_value: any) => true
) => {
  try {
    const queryKeys = Object.keys({ ...query }).filter((k) =>
      filter(query?.[k])
    )

    if (!queryKeys.length) return undefined

    const params = new URLSearchParams()

    queryKeys.forEach((k) => {
      const value = query[k]

      if (Array.isArray(value)) {
        value?.forEach((item) => {
          params.append(k, item)
        })
      } else params.append(k, value)
    })

    return `?${params.toString()}`
  } catch (error) {
    console.error(error)

    return undefined
  }
}

export const arrayFy = (value: string | string[]) => {
  return typeof value === 'string' ? [value] : value || []
}

export const isValidHttpUrl = (str: string) => {
  let url

  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

import styled from 'styled-components'
import Accordion from '@mui/material/Accordion'

export const AccordionRoot = styled(Accordion)`
  margin: 0;
  padding: 0;
  position: initial;
  box-shadow: none;

  .MuiAccordionDetails-root {
    margin: 0;
    display: block;
    margin-top: -10px;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 4px;
  }
  .Mui-expanded {
    margin: 0;
  }
`

export const StyledSpan = styled.span`
  font-size: 120%;
`

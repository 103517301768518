import ApiClient from '../apiClient'

const client = new ApiClient()

type getExternalLinksProps = {
  id: number | string
}

const fetchTransactionPlot = async (regionName: any) => {
  return client.get(`/parcelles/${encodeURI(regionName)}/first/transaction`)
}

const fetchFaoPlot = async (regionName: any) => {
  return client.get(`/parcelles/${encodeURI(regionName)}/first/fao`)
}

const fetchIsOwner = async (regionName: any) => {
  return client.get(`/parcelles/${encodeURI(regionName)}/owner`)
}

const getExternalLinks = async ({ id }: getExternalLinksProps) => {
  return client.get(`/parcelles/externaLinks/${id}`)
}

const getIndicesByRegion = async ({ id }: getExternalLinksProps) => {
  return client.get(`/parcelles/indices/${id}`)
}

const landMapDetailsService = {
  fetchIsOwner,
  fetchTransactionPlot,
  fetchFaoPlot,
  getExternalLinks,
  getIndicesByRegion,
}

export default landMapDetailsService

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { faoAggregationsSelector } from '../../../redux/faoHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'
import { useI18n } from '../../../context'

const ClassificationFilter = ({ onChange, classificationFilter, ...other }) => {
  const aggregations = useSelector(faoAggregationsSelector)
  const { t } = useI18n()

  const items = useMemo(() => {
    const itemsBykey = {}

    aggregations.classifications?.classifications.buckets.forEach((r) => {
      itemsBykey[r.key] = {
        ...r,
        count: r?.doc_count || 0,
      }
    })

    if (itemsBykey.development_beta) {
      itemsBykey.development = {
        ...itemsBykey.development,
        key: 'development',
        count:
          (itemsBykey.development?.count || 0) +
          (itemsBykey.development_beta?.count || 0),
      }
      delete itemsBykey.development_beta
    }

    if (itemsBykey.other_beta) {
      itemsBykey.other = {
        ...itemsBykey.other,
        key: 'other',
        count:
          (itemsBykey.other?.count || 0) + (itemsBykey.other_beta?.count || 0),
      }
      delete itemsBykey.other_beta
    }

    return Object.values(itemsBykey).map((i) => ({
      ...i,
      label: t(`fao.${t(i.key)}`),
    }))
  }, [aggregations.classifications?.classifications, t])

  const handleChange = (newValues) => {
    onChange({ classificationFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('fao.classification')}>
      <CheckboxFilter
        filters={classificationFilter}
        items={items}
        onChange={handleChange}
      />
    </FilterCategory>
  )
}

export default ClassificationFilter

import Box from '@mui/material/Box'
import styled, { css } from 'styled-components'

export const CheckboxFilterItemRoot = styled(Box)<{ $unstyle?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .CheckboxFilterItemCount {
    color: #ccc;
    font-size: 0.875rem;
    padding-top: 4px;
  }

  > .MuiFormControlLabel-root:first-child {
    flex-grow: 1;
    cursor: pointer;
    color: #8f9ba2;
    font-size: 0.875rem;
    line-height: 20px;
  }

  ${({ $unstyle }) =>
    !$unstyle &&
    css`
      color: #8f9ba2;

      * {
        color: inherit;
      }

      span {
        font-size: 0.875rem;
      }

      .MuiCheckbox-root,
      .MuiRadio-root {
        padding-right: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .MuiFormControlLabel-root {
        > span.MuiFormControlLabel-label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 120px;
        }
      }
    `}
`

import Box from '@mui/material/Box'
import styled, { css } from 'styled-components'

export const AlertStatsRoot = styled(Box)`
  right: 0;
  margin: 15px;
  padding: 10px;
  z-index: 1;
  position: absolute;
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 2}px;
  background-color: white;
  min-width: 170px;
  min-height: 190px;

  > h4 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .MuiCircularProgress {
    margin-top: 25px;
    color: #00ade6;
  }
`

export const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  > .MuiCircularProgress-root {
    margin: auto;
    margin-top: 32px;
  }
`

export const Stat = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export const StatTitle = styled.h2`
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
  margin-bottom: 10px;

  ${({ onClick }) =>
    onClick &&
    css`
      color: #00ade6;
      cursor: pointer;
    `}
`

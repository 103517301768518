/* eslint-disable max-lines */
import { rgbToHex } from '@mui/system'
import chroma from 'chroma-js'

import { mapBy } from '../../helpers'

export const zoneOptions = (t: Translator) => [
  {
    value: 'principal_type',
    label: t('search.affectation'),
    description: 'principal_type',
    src: '/assets/img/zone_affectation.png',
    legend: [
      // ALL
      { value: 'DP', color: 'rgba(220, 220, 220, 0.8)' },
      { value: 'NaN', color: 'rgba(0, 0, 0, 0.8)' },
      { value: 'None', color: 'rgba(0, 0, 0, 0.8)' },
      {
        value: "Zone d'activités touristiques",
        color: 'rgba(254, 223, 229, 0.8)',
      },
      { value: 'Zone industrielle', color: 'rgba(203, 138, 230, 0.8)' },
      { value: 'Zone agricole protégée', color: 'rgba(232, 255, 210, 0.8)' },
      { value: 'Zone viticole', color: 'rgba(191, 254, 212, 0.8)' },
      { value: 'Zone viticole protégée', color: 'rgba(194, 236, 195, 0.8)' },
      // VAUD
      {
        value: "Zone d'habitation de forte densité",
        color: 'rgba(230, 132, 178, 0.8)',
      },
      {
        value: "Zone d'habitation de moyenne densité",
        color: 'rgba(255, 132, 139, 0.8)',
      },
      {
        value: "Zone d'habitation de faible densité",
        color: 'rgba(254, 191, 141, 0.8)',
      },
      {
        value: "Zone d'habitation de très faible densité",
        color: 'rgba(254, 254, 164, 0.8)',
      },
      {
        value: 'Zone de site construit protégé',
        color: 'rgba(237, 210, 143, 0.8)',
      },
      { value: 'Zone de centre historique', color: 'rgba(221, 198, 140, 0.8)' },
      {
        value: 'Zone de centre de localité (zone village)',
        color: 'rgba(191, 128, 130, 0.8)',
      },
      { value: 'Zone de hameau', color: 'rgba(197, 141, 142, 0.8)' },
      { value: 'Zone agricole', color: 'rgba(239, 254, 216, 0.8)' },
      { value: 'Zone agricole spécialisée', color: 'rgba(233, 253, 202, 0.8)' },
      { value: 'Zone naturelle protégée', color: 'rgba(201, 251, 223, 0.8)' },
      { value: 'Zone para-agricole', color: 'rgba(223, 254, 177, 0.8)' },
      { value: 'Zone de verdure', color: 'rgba(184, 223, 140, 0.8)' },
      { value: 'Aire forestière', color: 'rgba(132, 191, 134, 0.8)' },
      {
        value: "Zone d'installations (para-) publiques",
        color: 'rgba(131, 142, 255, 0.8)',
      },
      { value: 'Zone à options', color: 'rgba(192, 197, 254, 0.8)' },
      {
        value: "Zone d'activités artisanales",
        color: 'rgba(229, 223, 254, 0.8)',
      },
      {
        value: "Zone d'activités tertiaires",
        color: 'rgba(255, 143, 255, 0.8)',
      },
      {
        value: 'Zone de centres commerciaux',
        color: 'rgba(254, 220, 254, 0.8)',
      },
      { value: 'Zone de golf', color: 'rgba(190, 230, 149, 0.8)' },
      { value: 'Zone de camping', color: 'rgba(216, 218, 254, 0.8)' },
      { value: 'Zone de sport et loisirs', color: 'rgba(145, 254, 255, 0.8)' },
      { value: 'Zone équestre', color: 'rgba(174, 253, 254, 0.8)' },
      { value: 'Zone de piste de ski', color: 'rgba(199, 254, 255, 0.8)' },
      {
        value: "Zone d'aéroport / aérodrome",
        color: 'rgba(159, 159, 159, 0.8)',
      },
      { value: 'Zone militaire', color: 'rgba(199, 200, 137, 0.8)' },
      {
        value: "Zone d'extraction et dépôt de matériaux",
        color: 'rgba(243, 240, 251, 0.8)',
      },
      { value: 'Zone intermédiaire', color: 'rgba(209, 223, 217, 0.8)' },
      { value: 'Zone ferroviaire', color: 'rgba(238, 238, 238, 0.8)' },
      // GENEVA
      { value: '1', color: 'rgba(168, 0, 0, 0.8)' },
      { value: '2', color: 'rgba(255, 127, 128, 0.8)' },
      { value: '3', color: 'rgba(246, 163, 123, 0.8)' },
      { value: '4A', color: 'rgba(206, 136, 102, 0.8)' },
      { value: '4B', color: 'rgba(245, 202, 124, 0.8)' },
      { value: '4BP', color: 'rgba(248, 154, 97, 0.8)' },
      { value: '5', color: 'rgba(255, 255, 190, 0.8)' },
      { value: 'H', color: 'rgba(230, 230, 0, 0.8)' },
      { value: 'AG', color: 'rgba(186, 216, 186, 0.8)' },
      { value: 'IA', color: 'rgba(225, 116, 255, 0.8)' },
      { value: 'AM', color: 'rgba(197, 2, 255, 0.8)' },
      { value: 'AE', color: 'rgba(233, 190, 255, 0.8)' },
      { value: 'FE', color: 'rgba(116, 179, 255, 0.8)' },
      { value: 'BF', color: 'rgba(36, 116, 0, 0.8)' },
      { value: 'V', color: 'rgba(166, 245, 123, 0.8)' },
      { value: 'S', color: 'rgba(191, 233, 255, 0.8)' },
      { value: 'JF', color: 'rgba(102, 206, 171, 0.8)' },
      { value: 'PNP', color: 'rgba(255, 85, 0, 0.8)' },
      { value: 'ER', color: 'rgba(172, 219, 255, 0.8)' },
      { value: 'EP', color: 'rgba(225, 225, 225, 0.8)' },
      { value: 'D2', color: 'rgba(247, 148, 148, 0.8)' },
      { value: 'D3', color: 'rgba(251, 214, 198, 0.8)' },
      { value: 'D4A', color: 'rgba(242, 225, 216, 0.8)' },
      { value: 'D4AP', color: 'rgba(219, 166, 141, 0.8)' },
      { value: 'D4B', color: 'rgba(251, 231, 197, 0.8)' },
      { value: 'D4BP', color: 'rgba(248, 154, 97, 0.8)' },
      { value: 'D5', color: 'rgba(191, 191, 87, 0.8)' },
      { value: 'DIA', color: 'rgba(249, 229, 255, 0.8)' },
      // FRIBOURG
      {
        value: "Autre zone à l'intérieur de la zone à bâtir",
        color: 'rgba(167, 224, 244, 0.8)',
      },
      {
        value: 'Autre zone hors de la zone à bâtir',
        color: 'rgba(209, 223, 217, 0.8)',
      },
      {
        value: "Périmètre d'habitat à maintenir",
        color: 'rgba(212, 174, 112, 0.8)',
      },
      { value: 'Zone centre', color: 'rgba(191, 128, 130, 0.8)' },
      { value: "Zone d'activités", color: 'rgba(203, 138, 230, 0.8)' },
      { value: "Zone d'intérêt général", color: 'rgba(178, 178, 178, 0.8)' },
      { value: 'Zone de centre protégé', color: 'rgba(221, 198, 140, 0.8)' },
      { value: 'Zone de gravière', color: 'rgba(243, 240, 251, 0.8)' },
      {
        value: 'Zone de protection du site construit',
        color: 'rgba(255, 85, 0, 0.8)',
      },
      {
        value: 'Zone de résidence secondaire',
        color: 'rgba(247, 248, 203, 0.8)',
      },
      {
        value: 'Zone de tourisme et loisir',
        color: 'rgba(145, 254, 255, 0.8)',
      },
      {
        value: 'Zone des eaux et des rives',
        color: 'rgba(172, 219, 255, 0.8)',
      },
      { value: 'Zone libre', color: 'rgba(182, 249, 52, 0.8)' },
      { value: 'Zone mixte', color: 'rgba(230, 146, 65, 0.8)' },
      { value: 'Zone non constructible', color: 'rgba(240, 240, 240, 0.8)' },
      { value: 'Zone réservée', color: 'rgba(200, 200, 200, 0.8)' },
      {
        value: 'Zone résidentielle à faible densité',
        color: 'rgba(235, 246, 63, 0.8)',
      },
      {
        value: 'Zone résidentielle à haute densité',
        color: 'rgba(215, 67, 78, 0.8)',
      },
      {
        value: 'Zone résidentielle à moyenne densité',
        color: 'rgba(218, 159, 46, 0.8)',
      },
      { value: 'Zone spéciale', color: 'rgba(180, 180, 180, 0.8)' },
      {
        value: 'Zone tourisme et loisir non résidentielle',
        color: 'rgba(145, 254, 255, 0.8)',
      },
      { value: 'Zone non repertoriée', color: 'rgba(240, 240, 240, 0.8)' },
      {
        value: 'Zone centre: vieux village-vieille ville',
        color: 'rgba(205, 137, 102, 0.8)',
      },
      { value: 'Zone centre: centre ville', color: 'rgba(168, 56, 0, 0.8)' },
      { value: 'Zone à bâtir', color: 'rgba(255, 211, 127, 0.8)' },
      { value: 'Zone mixte avec habitat', color: 'rgba(168, 112, 0, 0.8)' },
      { value: 'Zone mayen', color: 'rgba(255, 255, 0, 0.8)' },
      {
        value:
          "Zone hameau, Zone de maintien de l'habitat rural, Zone village D1",
        color: 'rgba(115, 38, 0, 0.8)',
      },
      {
        value: 'Zone libre de constructions',
        color: 'rgba(211, 255, 190, 0.8)',
      },
      { value: 'Zone mixte sans habitat', color: 'rgba(255, 190, 232, 0.8)' },
      { value: 'Zone artisanale', color: 'rgba(255, 115, 223, 0.8)' },
      { value: "Zone centre d'achat", color: 'rgba(115, 0, 76, 0.8)' },
      {
        value: "Zone d'hébergement touristique",
        color: 'rgba(0, 92, 230, 0.8)',
      },
      { value: 'Zone camping', color: 'rgba(230, 230, 0, 0.8)' },
      { value: 'Zone camping résidentiel', color: 'rgba(255, 170, 0, 0.8)' },
      {
        value: 'Zone camping rural ou de passage',
        color: 'rgba(230, 0, 0, 0.8)',
      },
      {
        value: 'Zone de détente et loisirs',
        color: 'rgba(205, 102, 102, 0.8)',
      },
      { value: 'Zone golf primaire', color: 'rgba(0, 255, 197, 0.8)' },
      {
        value: "Zone de constructions et d'installations d'intérêt public",
        color: 'rgba(0, 112, 255, 0.8)',
      },
      {
        value: "Zone de transport à l'intérieur des zones à bâtir",
        color: 'rgba(178, 178, 178, 0.8)',
      },
      {
        value: "Zone de transport à l'extérieur des zones à bâtir",
        color: 'rgba(225, 225, 225, 0.8)',
      },
      { value: 'Zone spéciale primaire', color: 'rgba(76, 115, 0, 0.8)' },
      { value: 'Zone agricole 1', color: 'rgba(255, 255, 190, 0.8)' },
      { value: 'Zone agricole 2', color: 'rgba(190, 232, 255, 0.8)' },
      { value: 'Zone agricole spéciale', color: 'rgba(85, 255, 0, 0.8)' },
      {
        value: 'Zone de protection du paysage primaire',
        color: 'rgba(230, 0, 169, 0.8)',
      },
      {
        value: 'Zone de protection de la nature primaire',
        color: 'rgba(56, 168, 0, 0.8)',
      },
      {
        value: 'Autre zone de protection primaire',
        color: 'rgba(0, 0, 0, 0.8)',
      },
      { value: "Zone d'affectation différée", color: 'rgba(230, 152, 0, 0.8)' },
      {
        value: 'Zone extraction et dépôt de matériaux',
        color: 'rgba(255, 85, 0, 0.8)',
      },
      { value: 'Zone inculte', color: 'rgba(178, 178, 178, 0.8)' },
      // NEUCHATEL
      {
        value:
          "Zone agricole / Aire forestière / Cours d'eau et étendue d'eau / Espace de transport",
        color: 'rgba(239, 123, 149, 0.8)',
      },
      { value: 'Autre zone à bâtir', color: 'rgba(255, 87, 51, 0.8)' },
      { value: 'Zone commerciale', color: 'rgba(36, 106, 196, 0.8)' },
      {
        value: "Zone d'activités économiques",
        color: 'rgba(189, 156, 187, 0.8)',
      },
      { value: "Zone d'aérodrome", color: 'rgba(48, 231, 226, 0.8)' },
      { value: "Zone d'ancienne localité", color: 'rgba(174, 153, 142, 0.8)' },
      { value: 'Zone de centre ville', color: 'rgba(139, 42, 42, 0.8)' },
      { value: 'Zone de chalets', color: 'rgba(185, 74, 7, 0.8)' },
      {
        value: 'Zone de constructions basses',
        color: 'rgba(231, 225, 153, 0.8)',
      },
      { value: 'Zone de décharge', color: 'rgba(122, 90, 53, 0.8)' },
      { value: 'Zone de fermes', color: 'rgba(80, 167, 121, 0.8)' },
      {
        value: "Zone de maintien de l'habitat rural",
        color: 'rgba(23, 62, 11, 0.8)',
      },
      { value: 'Zone de parcs éoliens', color: 'rgba(176, 194, 171, 0.8)' },
      { value: 'Zone de plan spécial a', color: 'rgba(45, 112, 197, 0.8)' },
      { value: 'Zone de plan spécial b', color: 'rgba(24, 163, 170, 0.8)' },
      {
        value: "Zone de protection de l'ancienne localité",
        color: 'rgba(150, 176, 188, 0.8)',
      },
      {
        value: 'Zone de protection du patrimoine',
        color: 'rgba(150, 78, 94, 0.8)',
      },
      {
        value: 'Zone de sports - détente - loisirs a',
        color: 'rgba(255, 59, 194, 0.8)',
      },
      {
        value: 'Zone de sports - détente - loisirs b',
        color: 'rgba(170, 172, 224, 0.8)',
      },
      {
        value: 'Zone des terrains militaires',
        color: 'rgba(203, 220, 9, 0.8)',
      },
      { value: 'Zone de tourisme', color: 'rgba(44, 51, 9, 0.8)' },
      {
        value: 'Zone de traitement des déchets',
        color: 'rgba(3, 254, 209, 0.8)',
      },
      { value: 'Zone de transport', color: 'rgba(255, 205, 243, 0.8)' },
      { value: 'Zone de ville en damier', color: 'rgba(225, 69, 90, 0.8)' },
      {
        value: "Zone d'extraction de matériaux",
        color: 'rgba(185, 202, 254, 0.8)',
      },
      {
        value: "Zone d'habitation à faible densité",
        color: 'rgba(255, 226, 57, 0.8)',
      },
      {
        value: "Zone d'habitation à haute densité",
        color: 'rgba(253, 166, 77, 0.8)',
      },
      {
        value: "Zone d'habitation à moyenne densité",
        color: 'rgba(244, 180, 130, 0.8)',
      },
      {
        value: "Zone d'utilisation différée",
        color: 'rgba(115, 127, 142, 0.8)',
      },
      { value: "Zone d'utilité publique", color: 'rgba(147, 208, 192, 0.8)' },
      {
        value: 'Zone résidentielle densifiée',
        color: 'rgba(226, 187, 124, 0.8)',
      },
      { value: 'Zone spéciale a', color: 'rgba(118, 11, 255, 0.8)' },
      { value: 'Zone spéciale b', color: 'rgba(3, 43, 0, 0.8)' },
      // JURA
      {
        value: 'Zone de maisons de vacances',
        color: 'rgba(251, 202, 36, 0.8)',
      },
      { value: 'Zone de sport et de loisirs', color: 'rgba(2, 238, 22, 0.8)' },
      { value: "Zone d'habitation", color: 'rgba(188, 165, 36, 0.8)' },
      { value: 'Zone verte', color: 'rgba(36, 225, 0, 0.8)' },
      // BERN
      { value: 'Wohnzone, 1 Geschoss', color: '#FFFFB9' },
      { value: 'Wohnzone, 2 Geschosse', color: '#FEEBB7' },
      { value: 'Wohnzone, 3 Geschosse', color: '#FBC5AC' },
      { value: 'Wohnzone, 4 und mehr Geschosse', color: '#F9A4AB' },
      { value: 'Ferienhauszone', color: '#FFFFD9' },
      { value: 'Mischzone, 2 Geschosse', color: '#FEEBC6' },
      { value: 'Mischzone, 3 Geschosse', color: '#FCD8C6' },
      { value: 'Mischzone, 4 und mehr Geschosse', color: '#EAC5C5' },
      { value: 'Kernzone städtisch (geschlossene Bauweise)', color: '#C5B1A7' },
      { value: 'Kernzone ländlich', color: '#D8C6AB' },
      { value: 'Hotelzone', color: '#C3ECFF' },
      { value: 'Weilerzone', color: '#FDEBEC' },
      { value: 'Bestandeszone, Erhaltungszone', color: '#FCD8D8' },
      { value: 'Bauernhofzone', color: '#FBC5C6' },
      { value: 'Arbeitszone', color: '#D8EBEB' },
      { value: 'Zone für öffentliche Nutzungen', color: '#EBEBEB' },
      { value: 'Skipisten, Loipen, Beschneiungsfläche', color: '#EBFFFF' },
      { value: 'Zone für Sport- und Freizeitanlagen', color: '#EBFFB4' },
      { value: 'Grünzone', color: '#D8EBB0' },
      { value: 'Gartenbauzone (kommerziell)', color: '#C4D9AD' },
      { value: 'Landwirtschaftszone', color: '#EBF3CF' },
      { value: 'Intensivlandwirtschaftszone', color: '#D9D9C5' },
      { value: 'Abbau- und/oder Ablagerungszone', color: '#FCD4D7' },
      { value: 'Zone für militärische Nutzungen', color: '#A9D8C6' },
      { value: 'Überbauungsordnung', color: '#FFFFFF' },
      { value: 'Zone mit Planungspflicht', color: '#FFFFFF' },
      { value: 'Zone mit Sonderbauvorschriften', color: '' },
      { value: 'Übriges Gebiet', color: '#F6F6F5' },
      { value: 'Uferschutzplan', color: '#FFFFFF' },
      { value: 'Landschaftsschutzgebiet', color: '' },
      {
        value: 'Bestandes- und Erhaltungszone Wohnnutzung (Stadt Bern)',
        color: '#DCA3A9',
      },
      {
        value: 'Bestandes- und Erhaltungszone gemischte Nutzung (Stadt Bern)',
        color: '#D9C6C5',
      },
      { value: 'Abbau- und/oder Ablagerungszone', color: '#b26f4e' },
      { value: 'Arbeitszone', color: '#059fc6' },
      { value: 'Bauernhofzone', color: '#fd6f6f' },
      { value: 'Bestandeszone, Erhaltungszone', color: '#fcd8eb' },
      { value: 'Ferienhauszone', color: '#fef6a5' },
      { value: 'Gartenbauzone (kommerziell)', color: '#4b781a' },
      { value: 'Grünzone', color: '#7fc844' },
      { value: 'Hotelzone', color: '#a4fafd' },
      { value: 'Intensivlandwirtschaftszone', color: '#7f9e29' },
      { value: 'Kernzone ländlich', color: '#c27e31' },
      { value: 'Kernzone städtisch (geschlossene Bauweise)', color: '#800100' },
      { value: 'Mischzone, 2 Geschosse', color: '#7072ab' },
      { value: 'Mischzone, 3 Geschosse', color: '#6f4da1' },
      { value: 'Mischzone, 4 und mehr Geschosse', color: '#7027ab' },
      { value: 'Skipisten, Loipen, Beschneiungsfläche', color: '#beffe8' },
      { value: 'Weilerzone', color: '#f0c88a' },
      { value: 'Wohnzone, 1 Geschoss', color: '#fdfa67' },
      { value: 'Wohnzone, 2 Geschosse', color: '#fdd473' },
      { value: 'Wohnzone, 3 Geschosse', color: '#fd8801' },
      { value: 'Wohnzone, 4 und mehr Geschosse', color: '#d80000' },
      { value: 'Zone für militärische Nutzungen', color: '#e1e2e0' },
      { value: 'Zone für öffentliche Nutzungen', color: '#d1d1d1' },
      { value: 'Zone für Sport- und Freizeitanlagen', color: '#cccbcc' },

      // Basel-Landschaft
      { value: 'allgemeine Landwirtschaftszonen', color: '#f6fac7' },
      { value: 'Arbeitszonen', color: '#bfbffa' },
      { value: 'eingeschränkte Bauzonen', color: '#9eed81' },
      { value: 'GU', color: '' },
      { value: 'Mischzonen', color: '#efaf9f' },
      { value: 'QP', color: '' },
      { value: 'Rebbauzonen', color: '' },
      { value: 'Reservezonen nach Art. 18 Abs. 2 RPG', color: '' },
      {
        value: 'Schutzzonen für Lebensräume und Landschaften',
        color: '#2feeb9',
      },
      { value: 'Speziallandwirtschaftszonen', color: '#4abc83' },
      { value: 'Tourismus- und Freizeitzonen', color: '#f8b3e4' },
      { value: 'Wald', color: '#d0facd' },
      { value: 'weitere Bauzonen', color: '#e6d5f4' },
      { value: 'weitere Landwirtschaftszonen', color: '#f6fac7' },
      {
        value: 'weitere Schutzzonen ausserhalb der Bauzonen',
        color: '#2feec8',
      },
      {
        value: 'weitere Zonen nach Art. 18 Abs. 1 RPG ausserhalb der Bauzonen',
        color: '',
      },
      { value: 'Wohnzonen', color: '#f6b866' },
      { value: 'Zentrumszonen', color: '#cbb49f' },
      { value: 'Zonen für Gewässer und ihre Ufer', color: '#2f93f9' },
      { value: 'Zonen für öffentliche Nutzungen', color: '#aaabab' },

      // ZURICH
      { value: 'Bahnareale (VB)', color: '#ffffb3' },
      { value: 'Erholungszonen (E)', color: '#f2ff80' },
      { value: 'Gewässer (G)', color: '#d9ffff' },
      { value: 'Historische Kernzonen (K)', color: '#a1754c' },
      {
        value:
          'Industrie- und Gewerbezonen mit eingeschränktem Handels- und Dienstleistungsgewerbe (IG-eHD)',
        color: '#9999ff',
      },
      { value: 'Kantonale Freihaltezonen (Fk)', color: '#d9ffb3' },
      { value: 'Kantonale Landwirtschaftszonen (Lk)', color: '#e6e699' },
      { value: 'Kommunale Freihaltezonen (Fko)', color: '#5dc26c' },
      { value: 'Nicht zugewiesene Zonen (NZ)', color: '#ffffff' },
      { value: 'Quartiererhaltungszonen (Q)', color: '#ff7333' },
      { value: 'Strassen (VS)', color: '#ffd9d9' },
      { value: 'Wald (Wa)', color: '#a1954a' },
      { value: 'Wohnzonen 2 Vollgeschosse (W2)', color: '#123634' },
      { value: 'Wohnzonen 3 Vollgeschosse (W3)', color: '#10736d' },
      {
        value: 'Wohnzonen mit Gewerbeerleichterung 2 Vollgeschosse (WG2)',
        color: '#470000',
      },
      {
        value: 'Wohnzonen mit Gewerbeerleichterung 3 Vollgeschosse (WG3)',
        color: '#5c2727',
      },
      {
        value: 'Wohnzonen mit Gewerbeerleichterung 4 Vollgeschosse (WG4)',
        color: '#755757',
      },
      { value: 'Zentrumszonen (Z)', color: '#ff4ccc' },
      { value: 'Zonen für öffentliche Bauten (OE)', color: '#a6b3b3' },
      // Basel-Stadt
      { value: 'Bahnareal (AB)', color: '#E7E7E7' },
      { value: 'Arbeitsszone, gemäss Zone 2 (AZ2)', color: '#BCCAF8' },
      { value: 'Arbeitsszone, gemäss Zone 3 (AZ3)', color: '#CCCCDC' },
      { value: 'Freizeitgartenzone (FZG)', color: '#FEEDDD' },
      { value: 'Grünanlagenzone (GA)', color: '#D3FFBF' },
      { value: 'Grünzone (GR)', color: '#F1FECD' },
      { value: 'Landwirtschaftszone (LZ)', color: '#EFF1E9' },
      {
        value: 'Zone für Nutzung im öffentlichen Interesse (NOI)',
        color: '#D6D6D6',
      },
      { value: 'Stadt- und Dorfbild-Schonzone (SO)', color: '#FBD6F1' },
      { value: 'Stadt- und Dorfbild-Schutzzone (SU)', color: '#E4DCFF' },
      { value: 'Verkehrsfläche (VF)', color: '' },
      { value: 'Wald (WL)', color: '#D2DBBC' },
      { value: 'Zone 2 (Z2)', color: '#FFFFC0' },
      { value: 'Zone 2a (Z2A)', color: '#FFFFDC' },
      { value: 'Zone 2R (Z2R)', color: '#CFDFE5' },
      { value: 'Zone 3 (Z3)', color: '#FEEDBE' },
      { value: 'Zone 4 (Z4)', color: '#FCDCBE' },
      { value: 'Zone 5 (Z5)', color: '#FABCBD' },
      { value: 'Zone 5a (Z5A)', color: '#FBCBBD' },
      { value: 'Zone 6 (Z6)', color: '#E2C8BC' },
      { value: 'Industrie- und Gewerbezone (Z7)', color: '#EFF1E9' },

      // Aargau
      { value: 'Arbeitszonen', color: '#abc7ff' },
      { value: 'Eingeschränkte Bauzonen', color: '#98e4a6' },
      {
        value: 'Gallenkirch_Verkehrszonen innerhalb der Bauzonen',
        color: '#ffffff',
      },
      { value: 'Gallenkirch_Wohnzonen', color: '#ffad85' },
      { value: 'Gallenkirch_Zentrumszonen', color: '#d6bbaa' },
      {
        value: 'Gallenkirch_Zonen für öffentliche Nutzungen',
        color: '#c8c9c9',
      },
      { value: 'Kantons- und Nationalstrasse (VN)', color: '#9bc15c' },
      { value: 'Linn_Arbeitszonen', color: '#abc7ff' },
      { value: 'Linn_Verkehrszonen innerhalb der Bauzonen', color: '#ffffff' },
      { value: 'Linn_Wohnzonen', color: '#ffad85' },
      { value: 'Linn_Zentrumszonen', color: '#d6bbaa' },
      { value: 'Linn_Zonen für öffentliche Nutzungen', color: '#c8c9c9' },
      { value: 'Mischzonen', color: '#ee5252' },
      {
        value: 'Oberbozberg_Verkehrszonen innerhalb der Bauzonen',
        color: '#ffffff',
      },
      { value: 'Oberbozberg_Wohnzonen', color: '#ffad85' },
      { value: 'Oberbozberg_Zentrumszonen', color: '#d6bbaa' },
      {
        value: 'Oberbozberg_Zonen für öffentliche Nutzungen',
        color: '#c8c9c9',
      },
      { value: 'Tourismus- und Freizeitzonen', color: '#ffd3f0' },
      { value: 'Unterbozberg_Wohnzonen', color: '#ffad85' },
      { value: 'Unterbozberg_Zentrumszonen', color: '#d6bbaa' },
      {
        value: 'Unterbozberg_Zonen für öffentliche Nutzungen',
        color: '#c8c9c9',
      },
      { value: 'Verkehrszonen innerhalb der Bauzonen', color: '#ffffff' },
      { value: 'Wohnzonen', color: '#ffad85' },
      { value: 'Zentrumszonen', color: '#d6bbaa' },
      {
        value: 'Zone für öffentliche Bauten und Anlagen (OeBA)',
        color: '#ffd2ef',
      },
      { value: 'Zonen für öffentliche Nutzungen', color: '#c8c9c9' },

      // Thurgau
      { value: 'allgemeine Landwirtschaftszonen', color: '#61ad29' },
      { value: 'Arbeitszonen', color: '#71bfda' },
      { value: 'eingeschränkte Bauzonen', color: '#Da71c2' },
      { value: 'Höhenbeschränkung (HB)', color: '#D171da' },
      { value: 'Mischzonen', color: '#561d49' },
      { value: 'Rebbauzonen', color: '#0e3717' },
      {
        value: 'Schutzzonen für Lebensräume und Landschaften',
        color: '#02fd39',
      },
      { value: 'Speziallandwirtschaftszonen', color: '#127f0a' },
      { value: 'Tourismus- und Freizeitzonen', color: '#Dfa9e0' },
      { value: 'Unbebaubare Fläche / undefiniert', color: '#ffffff' },
      { value: 'Verkehrsflächen', color: '#F4fbad' },
      { value: 'Verkehrszonen innerhalb der Bauzonen', color: '#F4fbad' },
      { value: 'Wald', color: '#062001' },
      { value: 'weitere Bauzonen', color: '#D87d51' },
      {
        value: 'weitere Zonen nach Art. 18 Abs. 1 RPG ausserhalb der Bauzonen',
        color: '#F5e3ae',
      },
      { value: 'Wohnzonen', color: '#ffad85' },
      { value: 'Zentrumszonen', color: '#603215' },
      { value: 'Zonen für Gewässer unf ihre Ufer', color: '#50D4BA' },
      { value: 'Zonen für Kleinsiedlung', color: '#D45059' },
      { value: 'Zonen für öffentliche Nutzungen', color: '#989a9a' },
    ].map((l) => ({ ...l, name: t(`affectation.${l.value}` as I18nKey) })),
  },
  {
    value: 'building_area',
    label: t('search.buildingArea'),
    description: 'building_area',
    src: '/assets/img/construction_zone.png',
    legend: [
      { name: t('search.buildingZone'), color: 'rgba(0, 147, 156, 0.8)' },
      {
        name: t('search.display.noBuildingZone'),
        color: 'rgba(194, 46, 0, 0.8)',
      },
    ],
  },
  {
    value: 'rdppfs',
    label: t('search.rdppfs'),
    description: 'rdppfs',
    src: '/assets/img/restriction.png',
    legend: [
      {
        name: t('search.rddpf.reservedArea'),
        color: 'rgba(150, 150, 150, 0.8)',
      },
      { name: t('search.rddpf.pollutedArea'), color: 'rgba(194, 46, 0, 0.8)' },
      {
        name: t('search.rddpf.waterProtected'),
        color: 'rgba(0, 147, 156, 0.8)',
      },
      {
        name: t('search.rddpf.forestRegister'),
        color: 'rgba(153, 204, 153, 0.8)',
      },
      { name: t('search.rddpf.protectedArea'), color: 'rgba(51, 51, 51, 0.8)' },
      {
        name: t('search.rddpf.nonBuildingArea'),
        color: 'rgba(0, 51, 102, 0.8)',
      },
      { name: t('search.rddpf.forestArea'), color: 'rgba(204, 204, 153, 0.8)' },
      { name: t('search.rddpf.specialPlan'), color: 'rgba(0, 0, 0, 0.8)' },
      { name: t('search.rddpf.other'), color: 'rgba(255, 204, 0, 0.8)' },
      {
        name: t('search.rddpf.severalRestriction'),
        color: 'rgba(248, 104, 51, 0.8)',
      },
    ],
  },
  {
    value: 'data_reliability',
    label: t('search.availability'),
    description: 'data_reliability',
    src: '/assets/img/data_availability.png',
    legend: [
      { name: t('search.data.cosReliability'), color: 'rgba(5, 57, 45, 0.8)' },
      {
        name: t('search.data.cosReliability2'),
        color: 'rgba(120, 198, 121, 0.8)',
      },
      {
        name: t('search.data.cosNonReliability'),
        color: 'rgba(247, 252, 185, 0.8)',
      },
    ],
  },
]

export const zoneColors = mapBy(
  'value',
  zoneOptions((k) => k)[0]
    .legend.filter((v) => v.color)
    .map((v) => {
      const hex = !v.color?.startsWith('#') ? rgbToHex(v.color) : v.color

      return { ...v, color: v.color ? chroma(hex).rgb() : undefined }
    })
)

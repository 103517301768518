export const getPriceData = (data, coef = 1) => {
  const formatNum = (price) => {
    if (!price) return price

    if (coef === 12) return Math.round(price * coef)

    return +parseFloat(price * coef).toFixed(2)
  }

  return data.map((stats, index, array) => {
    const divider =
      1 * stats.doc_count +
      (array[index - 1] ? array[index - 1].doc_count : 0) +
      (array[index - 2] ? array[index - 2].doc_count : 0)

    const min =
      ((array[index - 1]
        ? array[index - 1].median_price.values['25.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['25.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['25.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const max =
      ((array[index - 1]
        ? array[index - 1].median_price.values['75.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['75.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['75.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const priceM2 =
      ((array[index - 1]
        ? array[index - 1].median_price.values['50.0'] *
          array[index - 1].doc_count
        : 0) +
        stats.median_price.values['50.0'] * stats.doc_count +
        (array[index - 2]
          ? array[index - 2].median_price.values['50.0'] *
            array[index - 2].doc_count
          : 0)) /
      divider

    const price_per_square_meter = divider < 5 ? null : priceM2
    const price_per_square_meter_range = divider < 5 ? null : [min, max]

    return {
      date: stats.key_as_string,
      listings: stats.doc_count,
      priceM2: formatNum(price_per_square_meter),
      priceM2Range: price_per_square_meter_range?.map((i) => formatNum(i)),
    }
  })
}

export const getPriceHistoryMinusMonth = (month, data) => {
  const denum = data[data.length - 1 - month]?.priceM2

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 1]?.priceM2 / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getListingHistoryMinusMonth = (month, data) => {
  const denum = data[data.length - 2 - month]?.listings

  if (!denum) return null

  // eslint-disable-next-line no-unsafe-optional-chaining
  const score = (data[data.length - 2]?.listings / denum - 1) * 100

  return score || score === 0 ? parseFloat(score.toFixed(2)) : null
}

export const getConfidenceScore = (array) => {
  const listingsNumber =
    (array[array.length - 1] ? array[array.length - 1].listings : 0) +
    (array[array.length - 2] ? array[array.length - 2].listings : 0) +
    (array[array.length - 3] ? array[array.length - 3].listings : 0)

  // eslint-disable-next-line no-nested-ternary
  return listingsNumber > 50 ? 3 : listingsNumber > 20 ? 2 : 1
}

let analytycData = {}

export const saveAnalyticData = (data, key) => {
  try {
    analytycData[key] = { ...analytycData[key], ...data }
  } catch (error) {
    console.error(error)
  }
}

export const getAnalyticData = () => {
  return { ...analytycData }
}

export const clearAnalyticData = () => {
  analytycData = {}
}

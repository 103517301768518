import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { toQueryParams } from '../../../utils'
import { useAsync } from '../../../hooks'
import {
  alertMultiPolygonSelector,
  alertTransactionSettingsSelector,
  citiesSelectedSelector,
  zoneAffectationSelectedSelector,
  regionsSelectedSelector,
  zonesSelectedSelector,
  alertHasLocationsSelector,
  alertFaoSettingsSelector,
  alertListingSettingsSelector,
  alertUpdateCountSelector,
} from '../../../redux/alert'
import historyService from '../../../services/history'

const useAlertStats = () => {
  const transitionSettings = useSelector(alertTransactionSettingsSelector)
  const faoSettings = useSelector(alertFaoSettingsSelector)
  const listingSettings = useSelector(alertListingSettingsSelector)
  const multiPolygon = useSelector(alertMultiPolygonSelector)
  const landUsePlans = useSelector(zoneAffectationSelectedSelector)

  const citiesSelected = useSelector(citiesSelectedSelector)
  const regionsSelected = useSelector(regionsSelectedSelector)
  const zonesSelected = useSelector(zonesSelectedSelector)
  const hasLocations = useSelector(alertHasLocationsSelector)
  const updateCount = useSelector(alertUpdateCountSelector)

  const listingAsync = useAsync()
  const faoAsync = useAsync()
  const transitionAsync = useAsync()

  const now = new Date()

  const date = [
    new Date(new Date().setDate(now.getDate() - 31)).toISOString(),
    now.toISOString(),
  ]

  const commonQuery = {
    cityFilter: citiesSelected.map((i) => i.value),
    zoneFilter: zonesSelected.map((i) => i.value),
    regionFilter: regionsSelected.map((i) => i.value),
    landUsePlansFilter: landUsePlans.map((i) => i.value),
    value: '',
    count: true,
  }

  const transitionFilter = {
    ...transitionSettings,
    dateFilter: date,
    keyword: transitionSettings?.name,
  }

  const faoFilter = {
    ...faoSettings,
    inquiryStartDateFilter: date,
    keyword: faoSettings?.name,
  }

  const listingFilter = {
    ...listingSettings,
    dateFilter: date,
    keyword: listingSettings?.name,
  }

  const state = {}

  if (!hasLocations && multiPolygon.coordinates?.length > 0) {
    state.polygon = JSON.stringify(multiPolygon)
  }

  const handleClick = (url, filter) => {
    const query = { ...commonQuery, ...filter }
    let search = toQueryParams(query, (v) => v != null)

    if (!hasLocations && multiPolygon.coordinates?.length > 0) {
      search += `&polygon=${JSON.stringify(multiPolygon)}`
    }

    window.open(`${url}${search}`)
  }

  const handleTransactionClick = () => {
    handleClick('/transaction-history', transitionFilter)
  }

  const handleFaoClick = () => {
    handleClick('/fao-history', faoFilter)
  }

  const handleListingClick = () => {
    handleClick('/listing-history', listingFilter)
  }

  useEffect(() => {
    if (!multiPolygon.coordinates?.length) return

    const input = { ...commonQuery, polygon: multiPolygon }

    if (hasLocations) {
      delete input.polygon
    }

    transitionAsync.dispatch(() =>
      historyService.getTransactions({ ...input, ...transitionFilter })
    )

    listingAsync.dispatch(() =>
      historyService.getListings({ ...input, ...listingFilter })
    )

    faoAsync.dispatch(() => historyService.getFaos({ ...input, ...faoFilter }))
  }, [
    citiesSelected?.length,
    regionsSelected?.length,
    zonesSelected?.length,
    landUsePlans?.length,
    hasLocations,
    multiPolygon.coordinates?.length,
    updateCount,
  ])

  const loading = faoAsync.loading || transitionAsync.loading

  const errors =
    faoAsync.errors || listingAsync.errors || transitionAsync.errors

  sessionStorage.setItem('faoAsync', JSON.stringify(faoAsync))

  return {
    handleFaoClick,
    handleTransactionClick,
    handleListingClick,
    loading,
    errors,
    empty: !loading && !multiPolygon?.coordinates?.length,
    transitionCount: transitionAsync.data?.data?.total ?? 0,
    faoCount: faoAsync.data?.data?.total ?? 0,
    listingCount: listingAsync.data?.data?.total ?? 0,
  }
}

export default useAlertStats

const getStorageItem = (storage, key, toJson = false) => {
  if (typeof storage === 'undefined') return undefined

  const data = storage.getItem(key)

  if (!data) return data

  if (toJson) {
    try {
      return JSON.parse(data)
    } catch (error) {
      console.error(error)

      return null
    }
  }

  return data
}

const fromSessionStorage = (key, toJson = false) => {
  return getStorageItem(sessionStorage, key, toJson)
}

const fromLocalStorage = (key, toJson = false) => {
  return getStorageItem(localStorage, key, toJson)
}

export { fromSessionStorage, fromLocalStorage }

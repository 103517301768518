const WALL_CONSTRUCTED_BUILDING = 0.22

const getAuthorizedCos = (parcelDetail) => {
  if (!parcelDetail) return 0

  let num

  if (
    parcelDetail.region_name === 'Vaud' ||
    parcelDetail.region_name === 'Genève'
  ) {
    num = parcelDetail.land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.cos || zone.cos_estimate) * zone.cover_area_perc,
      0
    )
  } else {
    num = parcelDetail.land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.ios || zone.ios_estimate) * zone.cover_area_perc,
      0
    )
  }

  const denum = parcelDetail.land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedCus = (parcelDetail) => {
  if (!parcelDetail) return 0

  let num

  if (
    parcelDetail.region_name === 'Vaud' ||
    parcelDetail.region_name === 'Genève'
  ) {
    num = parcelDetail.land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.cus || zone.cus_estimate) * zone.cover_area_perc,
      0
    )
  } else {
    num = parcelDetail.land_use_plans.reduce(
      (acc, zone) =>
        acc + (zone.ibus || zone.ibus_estimate) * zone.cover_area_perc,
      0
    )
  }

  const denum = parcelDetail.land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedFloorNumberMax = (parcelDetail) => {
  if (!parcelDetail) return 0

  const num = parcelDetail.land_use_plans.reduce(
    (acc, zone) =>
      acc +
      (zone.floor_nb ? zone.floor_nb : zone.floor_nb_estimate) *
        zone.cover_area_perc,
    0
  )

  const denum = parcelDetail.land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0
  )

  return +(num / denum).toFixed(2) || 0
}

const getAuthorizedUnitNumberMax = (parcelDetail) => {
  if (!parcelDetail) return 0

  const num = parcelDetail.land_use_plans.reduce(
    (acc, zone) =>
      acc + (zone.unit_nb || zone.unit_nb_estimate) * zone.cover_area_perc,
    0
  )

  const denum = parcelDetail.land_use_plans.reduce(
    (acc, zone) => acc + zone.cover_area_perc,
    0
  )

  return +(num / denum).toFixed(2) || 0
}

const getCurrentLivingArea = (parcelDetail) => {
  if (!parcelDetail?.buildings) return 0

  return (
    parcelDetail.buildings.reduce((accumulator, building) => {
      if (building.floor_nb) {
        return accumulator + building.floor_nb * building.area
      }

      return accumulator + building.area
    }, 0) *
    (1 - WALL_CONSTRUCTED_BUILDING)
  )
}

const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key]?.value && typeof obj[key]?.value === 'object')
      removeEmpty(obj[key])
    else if (obj[key]?.value === undefined) delete obj[key]
  })

  return obj
}

export {
  removeEmpty,
  getAuthorizedCos,
  getAuthorizedFloorNumberMax,
  getAuthorizedUnitNumberMax,
  getCurrentLivingArea,
  getAuthorizedCus,
}

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  bearing: 0,
  pitch: 0,
  zoom: 7.7,
  bounds: [],
  center: [],
}

const controlsMap = createSlice({
  name: 'controlsMap',
  initialState,
  reducers: {
    // Handle synchronous actions here
    changePitch: (state, action) => {
      state.pitch = action.payload
    },
    changeBearing: (state, action) => {
      state.bearing = action.payload
    },
    changeZoomMap: (state, action) => {
      state.zoom = action.payload
    },
    changeBounds: (state, action) => {
      state.bounds = action.payload
    },
    changeCenter: (state, action) => {
      state.center = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  changePitch,
  changeBearing,
  changeZoomMap,
  changeBounds,
  changeCenter,
} = controlsMap.actions

export default controlsMap.reducer

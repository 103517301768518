/* eslint-disable max-lines */
import React, { ReactNode, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import TableCell, { TableCellProps } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'

export type Column = TableCellProps & {
  label?: ReactNode
  key: string
  [k: string]: any
}

export type Row = TableCellProps & { key?: string; [k: string]: any }

export type TableState = {
  selectedRows?: string[] | Row[]
  rowsPerPage?: number
  rowsPerPageOptions?: number[]
  orderBy?: string
  page?: number
}

export type TRowProps = {
  columns?: Column[]
  row: Row
  onSelectedRowChange?: (val: any[]) => void
  getRowSelected?: (row: Row) => string
  tableState?: TableState
  onChange?: (val?: TableState) => void
  RowDetailsProps: any
  RowDetails?: React.ComponentType<{ columns: Column[]; data: Row }>
}

const TRow = (props: TRowProps) => {
  const {
    row,
    getRowSelected,
    RowDetails,
    onChange,
    columns = [],
    RowDetailsProps,
    tableState,
  } = props

  const { selectedRows } = tableState as any
  const [open, setOpen] = useState(false)

  const handleRowSelectChange = (_event: any) => {
    if (!getRowSelected) return

    const rowKey = getRowSelected(row)
    const selectedIndex = selectedRows.indexOf(rowKey)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, rowKey)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1))
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      )
    }

    onChange?.({ ...tableState, selectedRows: newSelected })
  }

  const borderProps = {
    borderBottom: !open ? undefined : 'none',
    transition: 'borderBottom 0.3s',
  }

  return (
    <>
      <TableRow
        className={row.className + (open ? ' open' : '')}
        style={row.style}
      >
        {RowDetails && (
          <TableCell
            sx={{ ...borderProps }}
            width="34px"
            className="CollapseTableCell"
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}

        {getRowSelected && (
          <TableCell padding="checkbox" sx={{ ...borderProps }}>
            <Checkbox
              color="primary"
              checked={selectedRows.includes(getRowSelected(row))}
              onChange={handleRowSelectChange}
            />
          </TableCell>
        )}

        {columns.map(({ render, key, ...rest }) => {
          return (
            <TableCell {...rest} sx={{ ...borderProps, ...rest?.sx }} key={key}>
              {render ? render(row) : row[key]}
            </TableCell>
          )
        })}
      </TableRow>

      {RowDetails && (
        <TableRow className="DetailsRow">
          <TableCell
            style={{ padding: 0 }}
            colSpan={columns.length + (getRowSelected ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className="RowDetailsContainer">
                <RowDetails data={row} columns={columns} {...RowDetailsProps} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

export default TRow

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import { useI18n } from '../../../context'
import {
  alertHasLocationsSelector,
  updateSelectedAlert,
  zoneAffectationSelectedSelector,
  zoneAffectationSelector,
} from '../../../redux/alert'

const ZoneAffectionSearch = ({ inputProps, ...props }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const zoneAffectation = useSelector(zoneAffectationSelector)
  const zoneAffectationSelected = useSelector(zoneAffectationSelectedSelector)
  const hasLocations = useSelector(alertHasLocationsSelector)

  const handleChange = (e, newValue) => {
    dispatch(updateSelectedAlert({ zoneAffectationSelected: newValue }))
  }

  if (
    !hasLocations ||
    (!zoneAffectation?.length && !zoneAffectationSelected?.length)
  ) {
    return null
  }

  return (
    <Autocomplete
      size="small"
      fullWidth
      value={zoneAffectationSelected || []}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      getOptionLabel={(option) => option.label}
      options={zoneAffectation || []}
      multiple
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={t('myAlert.zones.affectation')}
          {...props}
          inputProps={{
            ...inputProps,
            ...params.inputProps,
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  )
}

export default ZoneAffectionSearch

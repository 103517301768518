import ApiClient from '../apiClient'

const client = new ApiClient()

const fetchFaosAround = async (parcelleId: any) => {
  return client.get(
    `/parcelles/${parcelleId.length > 1 ? parcelleId[0] : parcelleId}/faos/500`
  )
}

const fetchTransactionsAround = async (parcelleId: any) => {
  return client.get(
    `/parcelles/${
      parcelleId.length > 1 ? parcelleId[0] : parcelleId
    }/transactions/500`
  )
}

const fetchBuildingAround = async (parcelleId: any) => {
  return client.get(
    `/parcelles/${
      parcelleId.length > 1 ? parcelleId[0] : parcelleId
    }/buildings/200`
  )
}

const fetchListingAround = async (id: any) => {
  return client.get(`/listing/${id.length > 1 ? id[0] : id}/400`)
}

const neighbourhoodService = {
  fetchFaosAround,
  fetchTransactionsAround,
  fetchBuildingAround,
  fetchListingAround,
}

export default neighbourhoodService

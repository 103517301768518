import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'

const ExportIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z" />
    </SvgIcon>
  )
}

export default ExportIcon

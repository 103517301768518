import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import AirplayIcon from '@mui/icons-material/Airplay'
import InfoIcon from '@mui/icons-material/Info'
import HelpIcon from '@mui/icons-material/Help'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'

import { useI18n } from '../../../context'
import {
  appSelector,
  changeApp,
  authRolesSelector,
  forgetAuthUser,
  forgetUser,
  logout,
} from '../../../redux'
import { APP_VERSION } from '../../../config'

export type BottomMenuListProps = {
  onClose: () => void
}

const BottomMenuList = ({ onClose }: BottomMenuListProps) => {
  const { t } = useI18n()
  const dispatch = useDispatch()

  const app = useSelector(appSelector)
  const roles = useSelector(authRolesSelector)

  const hasLanddev = roles?.includes('User_landdev')

  const handleAppChange = () => {
    dispatch(changeApp(app === 'landdev' ? 'brokerinfo' : 'landdev'))
    onClose()
  }

  const handleLogout = () => {
    dispatch(logout())
    dispatch(forgetUser())
    dispatch(forgetAuthUser())
    onClose()
  }

  return (
    <List>
      <ListItem button onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.signOut')} />
      </ListItem>
      <ListItem button component={NavLink} to="/sources">
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.sources')} />
      </ListItem>
      {hasLanddev && (
        <ListItem button onClick={handleAppChange}>
          <ListItemIcon>
            <AirplayIcon />
          </ListItemIcon>
          <ListItemText
            primary={app === 'landdev' ? 'BrokerInfo' : 'LandDev'}
          />
        </ListItem>
      )}
      <ListItem button component={NavLink} to="/glossary">
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.glossary')} />
      </ListItem>
      <ListItem button component={NavLink} to="/help">
        <ListItemIcon>
          <HelpIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.help')} />
      </ListItem>
      <ListItem button component={NavLink} to="/user-guide">
        <ListItemIcon>
          <LiveHelpIcon />
        </ListItemIcon>
        <ListItemText primary={t('common.user-guide')} />
      </ListItem>
      <ListItem component="p" className="version" color="textSecondary">
        <ListItemText primary={APP_VERSION} />
      </ListItem>
    </List>
  )
}

export default BottomMenuList

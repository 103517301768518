import Card from '@mui/material/Card'
import styled, { css } from 'styled-components'

export const AlertCardHeaderRoot = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const TitleBox = styled.div`
  flex-grow: 1;
  margin-right: 8px;
  > h3 {
    color: #03254c;
    overflow: hidden;
    font-size: 16px;
    margin-top: -5px;
    font-weight: 900;
    white-space: nowrap;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    text-transform: uppercase;
    max-width: 220px;
  }

  > i {
    font-size: 12px;

    ${({ theme }) => css`
      ${theme.breakpoints.up('sm')} {
        font-size: 14px;
      }
    `}
  }
`

export const DisplayText = styled.span`
  color: grey;
  overflow: hidden;
  font-size: 14px;
  font-weight: 200;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 280px;
`

export const AlertAddressRoot = styled.div`
  margin-bottom: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 4px;
  }
`

export const AlertBodyRoot = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
  }

  .icon {
    color: #03254c;
    &:hover {
      color: rgb(0, 173, 230);
    }
  }
`

export const AdvancedSettingsRoot = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > div:first-child {
    margin-top: 0;
    margin-bottom: 0;
    color: #03254c;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > span {
    display: block;
  }
`

export const AlertMapGLContainer = styled(Card)`
  background-color: whitesmoke;
  height: 200px;
`

export const AlertCardRoot = styled(Card)`
  padding: 20px;
  font-size: 14px;
  border-radius: ${({ theme }) => +theme.shape.borderRadius * 2}px;
  height: 100%;
  gap: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

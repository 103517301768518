/* eslint-disable max-lines */
import React, { forwardRef, useState, useMemo, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'

import { useNative } from '../../hooks'
import {
  getSelectValueKey,
  handleSelectChange,
  optionsToMap,
} from './Select.helper'

export type SelectProps = TextFieldProps & {
  options: any[]
  mapping?: { label: string; value: string }
  native?: boolean
  multiple?: boolean
  checkable?: boolean
}

const Select = forwardRef<HTMLDivElement, SelectProps>((props, ref) => {
  const {
    SelectProps: SelectPartialProps,
    options = [],
    mapping = { label: 'label', value: 'value' },
    native: isNative,
    checkable,
    multiple,
    value,
    placeholder,
    onChange = () => '',
    label,
    ...other
  } = props

  const { label: labelKey, value: valueKey } = mapping

  const optionsMap = useMemo(() => {
    return optionsToMap(options, valueKey)
  }, [options, valueKey])

  const { native } = useNative({ isNative })

  const selectValKey = getSelectValueKey({
    valueKey,
    value,
    multiple,
  })

  const renderValue = (selected: any) => {
    if (SelectPartialProps?.renderValue) {
      return SelectPartialProps?.renderValue(selected)
    }

    if (!selected?.length && placeholder) {
      return <InputLabel>{placeholder}</InputLabel>
    }

    if (!multiple) {
      return optionsMap[selected]?.[labelKey] || optionsMap[selected]
    }

    return selected.map((s: any) => optionsMap[s]?.[labelKey] || s).join(', ')
  }

  const [selectedValue, setSelectedValue] = useState<any>(selectValKey)

  const inputLabel = !placeholder ? label : selectedValue?.length && label

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const res = handleSelectChange({ event, native, multiple, optionsMap })

    onChange(res.selectedOption)
    setSelectedValue(res.selectedVal)
  }

  useEffect(() => {
    const newSelectValKey = getSelectValueKey({
      valueKey,
      value,
      multiple,
    })

    setSelectedValue(newSelectValKey)
  }, [value])

  return (
    <TextField
      ref={ref}
      {...other}
      select
      SelectProps={{
        ...SelectPartialProps,
        native,
        multiple,
        renderValue,
        displayEmpty: !native,
      }}
      onChange={handleChange}
      value={selectedValue}
      label={inputLabel || undefined}
    >
      {placeholder && !native && (
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
      )}
      {placeholder && native && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map((option: any) =>
        native ? (
          <option
            value={option[valueKey] ?? option}
            key={option[valueKey] || option}
          >
            {option[labelKey] ?? option}
          </option>
        ) : (
          <MenuItem
            value={option[valueKey] ?? option}
            key={option[valueKey] || option}
          >
            {checkable && multiple && (
              <Checkbox
                checked={selectedValue.indexOf(option[valueKey] ?? option) > -1}
              />
            )}
            {option[labelKey] ?? option}
          </MenuItem>
        )
      )}
    </TextField>
  )
})

export default Select

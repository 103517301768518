import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

import { featuresToMultiPolygon } from '../../../services/alert'
import {
  alertFeaturesSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import { useI18n } from '../../../context'
import StepActions from '../StepActions'
import { AreaContainer, PolygonStepRoot } from './PolygonStep.style'

const PolygonStep = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const { t } = useI18n()

  const { features } = useSelector(alertFeaturesSelector)

  const deletePolygonOnMap = (feature) => () => {
    const multiPolygon = featuresToMultiPolygon(
      features.filter((f) => f !== feature)
    )

    dispatch(updateSelectedAlert({ multiPolygon }))
  }

  return (
    <PolygonStepRoot>
      <span>{t('myAlert.polygone.details')}</span>
      <AreaContainer>
        {features?.map((f, index) => (
          <div key={index}>
            <h4>
              {t('myAlert.polygone.area')} {index + 1}
            </h4>
            <IconButton
              style={{ marginTop: -3 }}
              aria-label="delete"
              onClick={deletePolygonOnMap(f)}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </div>
        ))}
      </AreaContainer>
      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </PolygonStepRoot>
  )
}

export default PolygonStep

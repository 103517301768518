const publicTransportLegend = (t: Translator) =>
  [
    { value: 'very well served', color: '#1a9641' },
    { value: 'well served', color: '#a6d96a' },
    {
      value: 'medium served',
      color: '#ffffbf',
    },
    { value: 'badly served', color: '#fdae61' },
    { value: 'very badly served', color: '#d7191c' },
  ].map((l) => ({
    ...l,
    name: t(`search.public_transport.${l.value}` as I18nKey),
  }))

const noiseLegend = (t: Translator) =>
  [
    { value: 'up to 45 db', color: '#91cf60' },
    { value: '45 to 52 db', color: '#ffffbf' },
    {
      value: 'more than 52 db',
      color: '#fc8d59',
    },
  ].map((l) => ({
    ...l,
    name: t(`search.noise_exposure.${l.value}` as I18nKey),
  }))

// const timeTravelLegend = (t: Translator) =>
//   [
//     { value: 'short', color: '#91cf60' },
//     { value: 'medium', color: '#ffffbf' },
//     { value: 'long', color: '#fc8d59' },
//   ].map((l) => ({ ...l, name: t(`search.travel.${l.value}` as I18nKey) }))

export const locationOptions = (t: Translator) => {
  return [
    {
      value: 'public_transport_quality',
      label: t('search.legend.public_transport_quality'),
      src: '/assets/img/buildingLayers/transport.png',
      legend: publicTransportLegend(t),
      group: 'Location rating',
    },
    {
      value: 'noise_exposure',
      label: t('search.legend.noise_exposure'),
      src: '/assets/img/buildingLayers/noise.png',
      legend: noiseLegend(t),
      group: 'Location rating',
    },
    // {
    //   value: 'travel_time_to_centers',
    //   label: t('search.legend.travel_time_to_centers'),
    //   src: '/assets/img/score.png',
    //   legend: timeTravelLegend(t),
    //   group: 'Location rating',
    // },
  ]
}

import React, { useState, useEffect } from 'react'
import Alert from '@mui/material/Alert'

import { useMediaQuery } from '../../hooks'
import { isIOS } from '../../utils'
import { useI18n } from '../../context'
import { IOSShareIcon } from '../icons'
import { IOSInstallerSnackbar } from './IOSInstallerMessage.style'

const IOSInstallerMessage = () => {
  const { t } = useI18n()

  const isStandalone = useMediaQuery('(display-mode: standalone)')
  const isMobile = useMediaQuery('only screen and (max-width: 768px)')

  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    const iOS = isIOS()
    const installer = sessionStorage.getItem('installer')

    if (iOS && !isStandalone && isMobile && !installer) setShowMessage(true)
  }, [isStandalone, isMobile])

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') return

    setShowMessage(false)
    sessionStorage.setItem('installer', '1')
  }

  return (
    <IOSInstallerSnackbar
      open={showMessage}
      autoHideDuration={15000}
      onClose={handleClose}
    >
      <Alert icon={false} onClose={handleClose}>
        {t('common.toInstall.msg1')}
        <IOSShareIcon fontSize="small" />
        {t('common.toInstall.msg2')}
      </Alert>
    </IOSInstallerSnackbar>
  )
}

export default IOSInstallerMessage

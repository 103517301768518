import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { fromLocalStorage } from '../../utils'
import authService from '../../services/auth'

const initialState = {
  user: fromLocalStorage('user', true),
  isLoggedIn: !!fromLocalStorage('jwt'),
  lightVersion: !!fromLocalStorage('lightVersion'),
}

export const login = createAsyncThunk('auth/login', authService.login)

export const sendPasswordResetEmail = createAsyncThunk(
  'auth/sendPasswordResetEmail',
  authService.sendPasswordResetEmail
)

export const getSupports = createAsyncThunk(
  'auth/getSupports',
  authService.getSupports
)

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeApp: (state, action) => {
      state.user = { ...state.user, app: action.payload }
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    forgetAuthUser: (state) => {
      state.user = null
      state.isLoggedIn = false

      localStorage.removeItem('jwt')
      localStorage.removeItem('user')
    },
    updateLocalUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      localStorage.setItem('user', JSON.stringify(state.user))
    },
    updateLightVersion: (state, action) => {
      state.lightVersion = action.payload
      localStorage.setItem('lightVersion', state.lightVersion ? '1' : '')
      if (state.lightVersion) setTimeout(() => window.location.reload())
    },
    logout: (_state) => {
      // From here we can take action only at this "auth" state
      // But, as we have taken care of this particular "logout" action
      // in rootReducer, we can use it to CLEAR the complete Redux Store's state
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [login.fulfilled]: (state, action) => {
      const { data, errors } = action.payload

      if (data) {
        state.user = data.user
        const userLandDev = data.user?.roles.includes('User_landdev')

        if (userLandDev) {
          state.user = {
            ...state.user,
            app: userLandDev ? 'landdev' : 'brokerinfo',
          }
        }

        state.logged = true
      }

      if (errors) {
        state.user = null
        state.logged = false

        state.user = { ...state.user, app: 'brokerinfo' }
      }
    },
    [getSupports.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.supports = data
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  forgetAuthUser,
  changeApp,
  updateLocalUser,
  logout,
  updateLightVersion,
} = auth.actions

export default auth.reducer

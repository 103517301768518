import React from 'react'

import RegionFilter from '../RegionFilter'
import DateRangeFilter from '../DateRangeFilter'
import ReasonFilter from '../ReasonFilter'
import AreaFilter from '../AreaFilter'
import OwnershipTypeFilter from '../OwnershipTypeFilter'
import { SidePane } from '../../../components'
import { SidePaneProps } from '../../../components/SidePane'
import PriceFilter from '../PriceFilter'

export type TransactionFiltersProps = SidePaneProps & {
  filters?: Record<string, any>
  onChange?: (filters: Record<string, any>) => void
}

const TransactionFilters = (props: TransactionFiltersProps) => {
  const { onChange, filters, ...other } = props

  if (!filters) return null

  return (
    <SidePane {...other}>
      <RegionFilter
        onChange={onChange}
        regionFilter={filters.regionFilter}
        zoneFilter={filters.zoneFilter}
        cityFilter={filters.cityFilter}
        landUsePlansFilter={filters.landUsePlansFilter}
      />
      <DateRangeFilter onChange={onChange} dateFilter={filters.dateFilter} />
      <ReasonFilter onChange={onChange} reasonFilter={filters.reasonFilter} />
      <AreaFilter onChange={onChange} areaFilter={filters.areaFilter} />
      <PriceFilter onChange={onChange} priceFilter={filters.priceFilter} />
      <OwnershipTypeFilter
        onChange={onChange}
        ownershipTypeFilter={filters.ownershipTypeFilter}
      />
    </SidePane>
  )
}

export default TransactionFilters

/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { mapBy } from '../../utils'
import developerMapServices, {
  favoritesServices,
} from '../../services/developerMap'

const initialState = {
  subscriptions: [],
  favorites: [],
  companyFilter: [],
  userFilter: [],
  commercialActivities: [],
  companyProspect: [],
  prospectByIds: {},
  hoveredFeature: [],
}

export const addProspectLand = createAsyncThunk(
  'companies/:userId/prospect/:id',
  favoritesServices.addProspectLand
)

export const deleteProspectLand = createAsyncThunk(
  'delete/prospect',
  favoritesServices.deleteProspectLand
)

export const addFavoriteLand = createAsyncThunk(
  'users/:userId/favorite/lands',
  favoritesServices.addFavoriteLand
)

export const deleteFavoriteLand = createAsyncThunk(
  'users/:userId/favorite/lands/:id',
  favoritesServices.deleteFavoriteLand
)

export const addTagLand = createAsyncThunk(
  'users/:userId/tag/lands',
  favoritesServices.addTagLand
)

export const deleteTagLand = createAsyncThunk(
  'users/:userId/tag/lands/:id',
  favoritesServices.deleteTagLand
)

export const fetchUsersFavoriteLands = createAsyncThunk(
  'users/favorites/lands',
  developerMapServices.fetchUsersFavoriteLands
)

export const fetchUsersFilters = createAsyncThunk(
  'users/filters',
  developerMapServices.fetchUsersFilters
)

export const fetchCompaniesFilters = createAsyncThunk(
  'companies/filters',
  developerMapServices.fetchCompaniesFilters
)

export const fetchCommercialActivities = createAsyncThunk(
  'companies/commercialActivities',
  developerMapServices.fetchCommercialActivities
)

export const fetchCompanyProspects = createAsyncThunk(
  'companies/prospects',
  developerMapServices.fetchCompanyProspects
)

export const saveFilter = createAsyncThunk(
  'user/save/filter',
  developerMapServices.saveFilter
)

export const removeFilter = createAsyncThunk(
  'user/remove/filter',
  developerMapServices.removeFilter
)

const developerMapData = createSlice({
  name: 'developerMapData',
  initialState,
  reducers: {
    setHoveredFeature: (state, action) => {
      const { payload } = action

      state.hoveredFeature = payload
    },
  },
  extraReducers: {
    [fetchUsersFavoriteLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.favorites = data
      }
    },
    [fetchUsersFilters.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.userFilter = data
      }
    },
    [fetchCompaniesFilters.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.companyFilter = data
      }
    },
    [fetchCommercialActivities.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.commercialActivities = data
      }
    },
    [fetchCompanyProspects.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.companyProspect = data
        state.prospectByIds = mapBy(
          'land_id',
          data.map((l) => ({
            land_id: l.land_id,
            name: `${l.prospect_user_first_name} ${l.prospect_user_last_name}`,
            date: l.create_timestamp,
          }))
        )
      }
    },
    [addFavoriteLand.fulfilled]: (state, action) => {
      const { id } = action.payload

      // if (favorites) {

      state.favorites = state.favorites.concat({ land_id: id })
      // }
    },
    [deleteFavoriteLand.fulfilled]: (state, { payload }) => {
      const id = Array.isArray(payload.id) ? payload.id[0] : payload.id

      // if (favorites) {
      state.favorites = state.favorites.filter(
        (favorite) => Number(favorite.land_id) !== Number(id)
      )
      // }
    },
    [addTagLand.fulfilled]: (state, { payload }) => {
      const { tags } = payload
      const id = Array.isArray(payload.id) ? payload.id[0] : payload.id

      if (tags) {
        state.tags = tags.concat({ land_id: id })
      }
    },
    [deleteTagLand.fulfilled]: (state, { payload }) => {
      const { tags } = payload
      const id = Array.isArray(payload.id) ? payload.id[0] : payload.id

      if (tags) {
        state.tags = tags.filter((tag) => tag.land_id !== id)
      }
    },
    [addProspectLand.fulfilled]: (state, action) => {
      const { land, res } = action.payload

      if (land) {
        state.prospectByIds[land.id] = {
          land_id: land.id,
          name: `${JSON.parse(localStorage.user).first_name} ${
            JSON.parse(localStorage.user).last_name
          }`,
          date: new Date(),
        }

        state.companyProspect = state.companyProspect.concat({
          id: res,
          prospect_user_id: JSON.parse(localStorage.user)._id,
          prospect_user_first_name: JSON.parse(localStorage.user).first_name,
          prospect_user_last_name: JSON.parse(localStorage.user).last_name,
          company_id: JSON.parse(localStorage.user).company_id,
          land_id: land.id,
          create_timestamp: new Date(),
        })
      }
    },
    [deleteProspectLand.fulfilled]: (state, { payload }) => {
      const { id } = payload
      const landId = Array.isArray(id) ? id[0] : id

      delete state.prospectByIds[landId]

      state.companyProspect = state.companyProspect.filter(
        (prospect) => Number(prospect.land_id) !== Number(id)
      )
    },
    [saveFilter.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.userFilter = state.userFilter.concat(data).filter(Boolean)
    },
    [removeFilter.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.userFilter = state.userFilter.filter((filter) => {
          return filter.id.toString() !== data.toString()
        })
      }
    },
  },
})

export const { setHoveredFeature } = developerMapData.actions

export default developerMapData.reducer

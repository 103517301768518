import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getCities,
  getRegions,
  getZones,
  citiesSelector,
  regionsSelector,
  zonesSelector,
} from '../../../redux/location'

const useLocations = () => {
  const dispatch = useDispatch()

  const zones = useSelector(zonesSelector)
  const regions = useSelector(regionsSelector)
  const cities = useSelector(citiesSelector)

  const zonesByName = {}
  const regionsByName = {}
  const citiesByName = {}

  zones?.forEach((z) => {
    zonesByName[z.name] = z
  })

  regions?.forEach((r) => {
    regionsByName[r.name] = r
  })

  cities?.forEach((c) => {
    citiesByName[c.name] = c
  })

  useEffect(() => {
    if (!regions?.length) {
      dispatch(getRegions())
    }
    if (!zones?.length) {
      dispatch(getZones())
    }
    if (!cities?.length) {
      dispatch(getCities())
    }
  }, [])

  const hasData = cities.length

  return { citiesByName, regionsByName, zonesByName, hasData }
}

export default useLocations

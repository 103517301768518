import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getNotes = async (landId: any[]) => {
  const ids = Array.isArray(landId) ? landId.map((id) => id).join(',') : landId
  const type = Array.isArray(ids) && ids.length > 1 ? 'grouping' : 'individual'

  return client.get(`/users/${getCurrentUserId()}/history/lands/${ids}/${type}`)
}

const getAnalysisLandsByUser = async (userId: string) => {
  return client.get(`/users/${userId}/history/lands`)
}

const getAnalysisLands = () => getAnalysisLandsByUser(getCurrentUserId())

const addNote = async (data: Record<string, any>) => {
  const body = Array.isArray(data.landId)
    ? data
    : { ...data, landId: [data.landId] }

  const response = await client.post(`/users/${getCurrentUserId()}/notes`, {
    ...body,
    type: body.landId.length > 1 ? 'grouping' : 'individual',
  })

  const user = JSON.parse(localStorage.getItem('user') || '')

  if (response.data) {
    response.data = {
      ...data,
      action: 'note',
      first_name: user.first_name,
      last_name: user.last_name,
      ...response.data,
    }

    if (!response.data.note_id) {
      response.data.note_id = response.data.id
    }

    if (!response.data.land_id) {
      response.data.land_id = response.data.landId
    }
  }

  return response
}

const updateNote = async (input: Record<string, any>) => {
  const { note_id, content } = input

  const { data } = await client.put(
    `/users/${getCurrentUserId()}/notes/${note_id}`,
    { content }
  )

  return { ...input, update_timestamp: data.update_timestamp || new Date() }
}

const removeNote = async (noteId: string) => {
  client.delete(`/users/${getCurrentUserId()}/notes/${noteId}`)

  return { noteId }
}

const addReminder = async (data: Record<string, any>) => {
  const response = await client.post(
    `/users/${getCurrentUserId()}/reminders`,
    data
  )

  const user = JSON.parse(localStorage.getItem('user') || '')

  if (response.data) {
    const payload = {
      ...data,
      action: 'reminder',
      first_name: user.first_name,
      last_name: user.last_name,
      alarm_date: data.date,
      ...response.data,
    }

    // stringify and parse because it content date object
    response.data = JSON.parse(JSON.stringify(payload))
  }

  return response
}

const updateReminder = async ({ id, ...data }: Record<string, any>) => {
  return client.put(`/users/${getCurrentUserId()}/reminders/${id}`, data)
}

const removeReminder = async (reminderId: string) => {
  return client.delete(`/users/${getCurrentUserId()}/reminders/${reminderId}`)
}

const noteService = {
  removeNote,
  addNote,
  getNotes,
  updateNote,
  addReminder,
  updateReminder,
  removeReminder,
  getAnalysisLands,
  getAnalysisLandsByUser,
}

export default noteService

import React, { useEffect, useState, forwardRef } from 'react'
import { CircularProgressProps } from '@mui/material/CircularProgress'

import { CircularProgress, LinearProgress } from './ProgressIndicator.style'

export type ProgressIndicator = CircularProgressProps & {
  circular?: boolean
  position?: 'center' | 'top'
  timeout?: number
  anchor?: 'parent' | 'window'
}

/**
 * Indicates the progress of an operation
 */
const ProgressIndicator = forwardRef<HTMLDivElement, ProgressIndicator>(
  (props, ref) => {
    const {
      circular,
      position = circular ? 'center' : 'top',
      timeout = 0,
      anchor = 'parent',
      ...other
    } = props

    const [showLoading, setShowLoading] = useState(false)
    const progressProps = { ...other, position, anchor, ref }

    useEffect(() => {
      const timer = setTimeout(() => setShowLoading(true), timeout)

      return () => {
        clearTimeout(timer)
      }
    }, [])

    return (
      <>
        {circular && showLoading && <CircularProgress {...progressProps} />}
        {showLoading && !circular && (
          <LinearProgress {...(progressProps as any)} />
        )}
      </>
    )
  }
)

export default ProgressIndicator

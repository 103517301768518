import styled, { css } from 'styled-components'
import MuiCircularProgress from '@mui/material/CircularProgress'
import MuiLinearProgress from '@mui/material/LinearProgress'

type Pos = { position: string; anchor: string }

const positionStyle = ({ position, anchor }: Pos) => {
  if (position === 'top') {
    return css`
      position: ${anchor !== 'parent' ? 'fixed' : 'absolute'} !important;
      z-index: 10032;
      top: 0;
      left: 0;
    `
  }

  return css`
    position: ${anchor !== 'parent' ? 'fixed' : 'absolute'} !important;
    z-index: 10032;
    top: ${anchor !== 'parent' ? 'calc(50vh - 20px)' : 'calc(50% - 20px)'};
    left: ${anchor !== 'parent' ? 'calc(50vw - 20px)' : 'calc(50% - 20px)'};
  `
}

const CircularProgress = styled(MuiCircularProgress)`
  ${positionStyle};
  outline: 0;
`

const LinearProgress = styled(MuiLinearProgress)`
  height: 3px;
  width: 100%;
  left: 0;
  outline: 0;
  ${positionStyle};
`

export { CircularProgress, LinearProgress }

import ApiClient from '../apiClient'
import { getCompanyId } from '../company'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const getTagLands = async (preview?: boolean) => {
  return client.get(
    `/users/${getCurrentUserId()}/tags/companies/${getCompanyId()}?preview=${
      preview ? 'true' : ''
    }`
  )
}

const addTag = async (tags: any) => {
  return client.post(`/users/${getCurrentUserId()}/tags/lands`, { tags })
}

const updateTag = async (data: any) => {
  const response = await client.put(
    `/users/${getCurrentUserId()}/tags/${data.tag_id}`,
    data
  )

  if (response.data) {
    response.data = { ...response.data, ...data }
  }

  return response
}

const removeTag = async ({ land_id, tag_id }: any) => {
  return client.delete(
    `/users/${getCurrentUserId()}/tags/lands/${land_id}/${tag_id}`
  )
}

const deleteTag = async (tagId: string) => {
  return client.delete(`/users/${getCurrentUserId()}/tags/${tagId}`)
}

const isTagLand = (landId: string) => {
  return client.get(`/users/${getCurrentUserId()}/tags/lands/${landId}`)
}

const getTagsByUser = () => {
  return client.get(`/users/${getCurrentUserId()}/tags/all`)
}

const tagService = {
  getTagLands,
  isTagLand,
  removeTag,
  addTag,
  updateTag,
  deleteTag,
  getTagsByUser,
}

export default tagService

import React from 'react'

import { useI18n } from '../../../context'
import StepActions from '../StepActions'
import ZoneAffectionSearch from './ZoneAffectionSearch'
import LocationsSearch from './LocationsSearch'
import { InputContainer, ZonesStepRoot } from './ZonesStep.style'

const ZonesStep = ({ onNext, onPrevious }) => {
  const { t } = useI18n()

  return (
    <ZonesStepRoot>
      <span>{t('myAlert.radius.details')}</span>
      <InputContainer>
        <LocationsSearch />
        <ZoneAffectionSearch />
      </InputContainer>
      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </ZonesStepRoot>
  )
}

export default ZonesStep

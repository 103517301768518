import React from 'react'
import { HistoryAddressRoot } from './HistoryAddress.style'

export type HistoryAddressProps = {
  address?: string[] | string
}

const HistoryAddress = ({ address = '', ...other }: HistoryAddressProps) => {
  if (Array.isArray(address)) {
    const names = address[0].split(';')

    return names.map((name, index) => (
      <HistoryAddressRoot
        key={name + index}
        dangerouslySetInnerHTML={{ __html: name }}
        {...other}
      />
    ))
  }

  const names = address?.split(';')

  if (!names || !names.length) return null

  return names?.map((name, index) => (
    <HistoryAddressRoot key={name + index} {...other}>
      {name}
    </HistoryAddressRoot>
  ))
}

export default HistoryAddress

/* eslint-disable no-nested-ternary */

export const weightedAverage = (scores: any, type: string) => {
  if (!scores?.length) return 0

  const totalArea = scores?.reduce?.((acc: any, score: any) => {
    return acc + parseInt(score?.area, 10)
  }, 0)

  const pond = scores?.reduce?.((acc: any, cur: any) => {
    return type === 'existing_score'
      ? parseInt(acc + cur.existingScore * cur.area, 10)
      : type === 'development_score'
      ? parseInt(acc + cur.development_score * cur.area, 10)
      : type === 'under_exploited_score'
      ? parseInt(acc + cur.under_exploited_score * cur.area, 10)
      : type === 'livability_score'
      ? parseInt(acc + cur.livability_score * cur.area, 10)
      : type === 'geo_shape_score'
      ? parseInt(acc + cur.geo_shape_score * cur.area, 10)
      : type === 'future_existing_score'
      ? parseInt(acc + cur.future_existing_score * cur.area, 10)
      : type === 'future_development_score'
      ? parseInt(acc + cur.future_development_score * cur.area, 10)
      : type === 'future_under_exploited_score'
      ? parseInt(acc + cur.future_under_exploited_score * cur.area, 10)
      : type === 'future_geo_shape_score'
      ? parseInt(acc + cur.future_geo_shape_score * cur.area, 10)
      : type === 'future_livability_score'
      ? parseInt(acc + cur.future_livability_score * cur.area, 10)
      : null
  }, [])

  return Math.round(pond / totalArea)
}

export type LandUsePlans = {
  plan_type: string
  name: string
  id: number
  cover_area_perc: number
  distance_limit?: number
}

export const calculatePercentagePlotLandUsePlan = (
  plots: {
    area: number
    lup: LandUsePlans[]
  }[],
  totalArea: number
) => {
  if (!plots?.length) return []

  const plot = plots.map(({ area, lup }) => ({
    area,
    landUsePlans: lup.map((lu) => ({
      ...lu,
      area: Math.round(area * lu.cover_area_perc) * 100,
    })),
  }))

  const lupsGrouped = plot
    .flatMap(({ landUsePlans }) => landUsePlans)
    .reduce((lups, lup) => {
      if (!lups[lup.id])
        lups[lup.id] = {
          id: lup.id,
          name: lup.name,
          plan_type: lup.plan_type,
          cover_area_perc: 0,
          area: 0,
        }
      lups[lup.id].area += lup.area
      lups[lup.id].cover_area_perc =
        Math.round(lups[lup.id].area / totalArea) / 100

      return lups
    }, {} as Record<string, LandUsePlans & { area: number }>)

  return Object.values(lupsGrouped)
}

export const scores = (current: any, landFind: any) => {
  return current
    ?.filter((f: any) => f?.id !== landFind?.id)
    ?.reduce((acc: any, parcelle: any) => {
      return acc
        ? [
            ...acc,
            {
              id: parcelle.id,
              existingScore: parcelle.existing_score,
              development_score: parcelle.development_score,
              under_exploited_score: parcelle.under_exploited_score,
              geo_shape_score: parcelle.geo_shape_score,
              livability_score: parcelle.livability_score,
              area: parcelle.official_area || parcelle.area,
              future_development_score: parcelle.future_development_score,
              future_existing_score: parcelle.future_existing_score,
              future_under_exploited_score:
                parcelle.future_under_exploited_score,
              future_geo_shape_score: parcelle.future_geo_shape_score,
              lup: parcelle.land_use_plans,
            },
          ]
        : {
            id: parcelle.id,
            existingScore: parcelle.existing_score,
            development_score: parcelle.development_score,
            under_exploited_score: parcelle.under_exploited_score,
            geo_shape_score: parcelle.geo_shape_score,
            livability_score: parcelle.livability_score,
            area: parcelle.official_area || parcelle.area,
            future_livability_score: parcelle.future_livability_score,
            future_development_score: parcelle.future_development_score,
            future_existing_score: parcelle.future_existing_score,
            future_under_exploited_score: parcelle.future_under_exploited_score,
            future_geo_shape_score: parcelle.future_geo_shape_score,
            lup: parcelle.land_use_plans,
          }
    }, [])
}

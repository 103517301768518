/* eslint-disable max-lines */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import historyService from '../../services/history'

const currentYear = new Date().getFullYear()

const initialState = {
  rentData: {},
  buyData: {},
  listings: [],
  favoriteListing: [],
  geojson: null,
  listingsById: {},
  listingBaseInfo: [],
  addressesLoading: false,
  noLayers: false,
  aggregations: {
    dates: {
      dates: {
        min_as_string: '2005-01-01',
        max_as_string: currentYear.toString(),
      },
    },
    regions: { regions: { buckets: [] } },
    zones: { zones: { buckets: [] } },
    cities: { cities: { buckets: [] } },
  },
  features: {},
  total: 0,
  totalFeatures: 0,
  listingsFavoriteDashboard: [],
  loadingFavorite: false,
  locationsAggs: {},
}

export const getListingHistory = createAsyncThunk(
  'listingHistory/getListingHistory',
  historyService.getListings
)

export const getListingRentAndBuyStats = createAsyncThunk(
  'listingAnalytics/getListingRentAndBuyStats',
  historyService.getListingRentAndBuyStats
)

export const getGeojson = createAsyncThunk(
  'listingHistory/getGeojson',
  historyService.getGeojson
)

export const getListingsBaseinfo = createAsyncThunk(
  'listingHistory/getListingsBaseinfo',
  historyService.getListingsBaseinfo
)

export const getListingsByIds = createAsyncThunk(
  'listingHistory/getListingsByIds',
  historyService.getListingsByIds
)

export const gePlotsFromListing = createAsyncThunk(
  'listingHistory/gePlotsFromListing',
  historyService.gePlotsFromListing
)

export const saveFavoriteListing = createAsyncThunk(
  'listingHistory/saveFavoriteListing',
  historyService.saveFavoriteListing
)

export const getFavoriteListing = createAsyncThunk(
  'listingHistory/getFavoriteListing',
  historyService.getFavoriteListing
)

export const getListingsFavoriteDashboard = createAsyncThunk(
  'listingHistory/listingsFavoriteDashboard',
  historyService.listingsFavoriteDashboard
)

export const activeOrDesactivateFavoriteListing = createAsyncThunk(
  'listingHistory/activeOrDesactivateFavoriteListing',
  historyService.activeOrDesactivateFavoriteListing
)

export const getListingHistoryAggs = createAsyncThunk(
  'listingHistory/getListingHistoryAggs',
  historyService.getListingsAggs
)

const listingHistory = createSlice({
  name: 'listingHistory',
  initialState,
  reducers: {},
  extraReducers: {
    [getListingHistory.rejected]: (state) => {
      state.loading = false
    },
    [getListingHistory.pending]: (state, { meta }) => {
      const input = meta.arg

      state.loading = true

      if (
        state.aggregations.property_type &&
        !input.propertyTypeFilter?.length
      ) {
        state.aggregations.property_type = null
      }
    },
    [getListingHistory.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        const listings = data.result || [{ ...data.data, id: data.id }]

        state.listings = listings

        state.aggregations = {
          ...initialState.aggregations,
          ...data.aggregations,
        }

        state.total = data.count || (data.total ?? 0)

        const listingsById = state.listingsById || {}

        listings.forEach((l) => {
          listingsById[l.id] = l
        })

        state.listingsById = listingsById
      }
    },
    [getListingsBaseinfo.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.listingBaseInfo = data.result || [{ ...data.data, id: data.id }]
      }
    },
    [getListingsByIds.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        if (!state.listingsById) state.listingsById = {}

        data.forEach((l) => {
          state.listingsById[l.id] = l
        })
      }
    },
    [getListingRentAndBuyStats.fulfilled]: (state, action) => {
      const [rent, buy] = action.payload

      if (rent?.data) state.rentData = rent.data
      if (buy?.data) state.buyData = buy.data
    },
    [getGeojson.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.geojson = JSON.parse(data?.[0]?.geojson_polygon)
    },
    [gePlotsFromListing.pending]: (state) => {
      state.addressesLoading = true
      state.features = {}
    },
    [gePlotsFromListing.rejected]: (state) => {
      state.addressesLoading = false
      state.features = {}
    },
    [gePlotsFromListing.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.addressesLoading = false
      state.features = data.geojson
      state.noLayers = data.total.value === 0
    },
    [getFavoriteListing.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.favoriteListing = data.map((listing) => {
          return {
            ...listing,
            geo_center: `POINT(${
              JSON.parse(listing?.geo_center)?.coordinates?.[0]
            } ${JSON.parse(listing?.geo_center)?.coordinates?.[1]}`,
          }
        })
      }
    },
    [getListingsFavoriteDashboard.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.listingsFavoriteDashboard = data
      }
    },
    [saveFavoriteListing.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.listings = state.listings.map((listing) => {
          if (listing.id === Number(data.listingId)) {
            return {
              ...listing,
              favorite: true,
              address: data.address,
              geo_center: `POINT(${data?.geo_center?.coordinates?.[0]} ${data?.geo_center?.coordinates?.[1]}`,
            }
          }

          return listing
        })
      }
    },
    [activeOrDesactivateFavoriteListing.rejected]: (state) => {
      state.loadingFavorite = false
    },
    [activeOrDesactivateFavoriteListing.pending]: (state) => {
      state.loadingFavorite = true
    },
    [activeOrDesactivateFavoriteListing.fulfilled]: (state, { meta }) => {
      const input = meta.arg

      state.loadingFavorite = false
      if (input.id) {
        state.listingsFavoriteDashboard = state.listingsFavoriteDashboard.map(
          (listing) => {
            if (listing.favoriteListingId === input.id) {
              return { ...listing, active: !listing.active }
            }

            return listing
          }
        )
      }
    },
    [getListingHistoryAggs.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.locationsAggs = data.aggregations
      }
    },
  },
})

export default listingHistory.reducer

import styled from 'styled-components'
import ListSubheader from '@mui/material/ListSubheader'

export const ZonesStepRoot = styled.div`
  > span {
    color: rgb(141, 155, 163);
    font-style: italic;
    font-size: 12px;
  }
`

export const InputContainer = styled.div`
  > div:first-child {
    margin-bottom: 8px;
  }
`

export const GroupLabel = styled(ListSubheader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  background-color: white;
  width: 100%;

  > div:last-child {
    background-color: #ebecf0;
    border-radius: 2em;
    color: #172b4d;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    line-height: 8px;
    min-width: 1px;
    padding: 5px;
    text-align: center;
  }
`

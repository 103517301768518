/* eslint-disable max-lines */
import { createSelector } from '@reduxjs/toolkit'

export const developerMapFormSateSlector = (state) => state.developerMapForm

export const mapFormRadiusSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.radius
)

export const mapFormGeoPointSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.geoPoint
)

export const mapFormLocationSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.locations
)

export const mapFormMoreOptionsSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.moreOptions
)

export const mapFormBareLandSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.bareLand
)

export const mapFormPrivateOwnerSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.privateOwner
)

export const mapFormAreaSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.area
)

export const mapFormPrincipalTypesSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.principalTypes
)

export const mapFormBuildingAreaSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingArea
)

export const mapFormBuildingYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingYear
)

export const mapFormBuildingRenovatedYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingRenovatedYear
)

export const mapFormLandUsePlansSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.landUsePlans
)

export const mapFormAssignementsSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.assignements
)

export const mapFormHideBareLandSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.hideBareLand
)

export const mapFormHideProtectedBuildingSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.hideProtectedBuilding
)

export const mapFormHideUnknownYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.hideUnknownYear
)

export const mapFormHideRenovatedBuildingSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.hideRenovatedBuilding
)

export const mapFormUnitPerOwnerSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.UnitPerOwner
)

export const mapFormOwnersNbSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.ownersNb
)

export const mapFormUnitNumberSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.unitNumber
)

export const mapFormPropertyTypeSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.propertyType
)

export const mapFormPropertyOwnerSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.propertyOwner
)

export const mapFormOwnershipTypesSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.ownershipTypes
)

export const mapFormSpecialZoneSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.specialZone
)

export const mapFormNoBuildingZoneSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.noBuildingZone
)

export const mapFormTransactionSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.transaction
)

export const mapFormSaleListingSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.saleListing
)

export const mapFormSaleListingYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.saleListingYear
)

export const mapFormRentListingSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.rentListing
)

export const mapFormRentListingYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.rentListingYear
)

export const mapFormTransactionYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.transactionYear
)

export const mapFormBuildingPermitSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingPermit
)

export const mapFormBuildingPermitYearSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingPermitYear
)

export const mapFormCurrentMaxFloorNbSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.currentMaxFloorNb
)

export const mapFormCurrentMaxHeightSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.currentMaxHeight
)

export const mapFormReservedZoneSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.reservedZone
)

export const mapFormPollutedZoneSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.pollutedZone
)

export const mapFormUnderwaterProtectedZoneSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.underwaterProtectedZone
)

export const mapFormGeoCenterSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.geoCenter
)

export const mapFormGeoBoudingBoxSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.geoBoudingBox
)

export const mapFormOnMapSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.onMap
)

export const mapFormSearchAfterSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.search_after
)

export const searchTypeSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.type
)

export const isLandSearchSelector = createSelector(
  searchTypeSelector,
  (type) => type === 'land'
)

export const popetyIoClassificationFormSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.popetyIoClassification
)

export const OFSClassificationFormSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.OFSClassification
)

export const OFSCategoryFormSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.OFSCategory
)

export const heatingSystemFormSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.heatingSystem
)

export const waterHeatingSystemFormSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.waterHeatingSystem
)

export const mapRoomsNumberNumberSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.roomsNumber
)

export const SolarPotentialNumberSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.solarPotential
)

export const rentDateSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.rentDate
)

export const transactionDateSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.transactionDate
)

export const buildingPermitDateSelector = createSelector(
  developerMapFormSateSlector,
  (form) => form.buildingPermitDate
)

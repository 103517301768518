import React from 'react'

import { AlertMapGLContainer } from './AlertCard.style'

const AlertMapGL = ({ alert }) => {
  return (
    <AlertMapGLContainer>
      <img src={alert.img} alt={alert.name} width="100%" height={200} />
    </AlertMapGLContainer>
  )
}

export default React.memo(AlertMapGL, (p, n) => p.alert?.img === n.alert?.img)

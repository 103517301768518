import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import altimetryService from '../../services/altimetry'

const initialState = {
  altimetryGrid: null,
  loader: true,
}

export const fetchAltimetry = createAsyncThunk(
  'parcel/altimetry',
  altimetryService.fetchAltimetry
)

const altimetry = createSlice({
  name: 'altimetry',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAltimetry.pending]: (state) => {
      state.loader = true
      state.altimetryGrid = undefined
    },
    [fetchAltimetry.rejected]: (state) => {
      state.loader = false
      state.error = true
      state.altimetryGrid = null
    },
    [fetchAltimetry.fulfilled]: (state, action) => {
      const { data, errors } = action.payload

      state.error = errors
      state.altimetryGrid = data
      state.loader = false

      if (data.altitude_max == null || !data.altitude_min == null) {
        state.altimetryGrid = null
      }
    },
  },
})

export default altimetry.reducer

import ApiClient from '../apiClient'
import { getCurrentUserId } from '../user'

const client = new ApiClient()

const addProspectLand = async (land: any) => {
  try {
    const res = await client.post(
      `/companies/${JSON.parse(localStorage.user).company_id}/prospects/${
        land.id
      }`
    )

    return { land, res }
  } catch (e) {
    throw new Error(e)
  }
}

const deleteProspectLand = async (land: any) => {
  try {
    await client.delete(
      `/companies/${JSON.parse(localStorage.user).company_id}/prospects/${
        land.id
      }`
    )

    return land
  } catch (e) {
    throw new Error(e)
  }
}

const addFavoriteLand = async (lands: any) => {
  try {
    await client.post(`/users/${getCurrentUserId()}/favorites/lands`, {
      landIds: lands.id,
    })

    return lands
  } catch (e) {
    throw new Error(e)
  }
}

const deleteFavoriteLand = async (lands: any) => {
  try {
    await client.delete(
      `/users/${getCurrentUserId()}/favorites/lands/${lands.id}`
    )

    return lands
  } catch (e) {
    throw new Error(e)
  }
}

const addTagLand = async (lands: any) => {
  try {
    await client.post(`/users/${getCurrentUserId()}/tags/lands`, {
      landIds: lands.id,
    })

    return lands
  } catch (e) {
    throw new Error(e)
  }
}

const deleteTagLand = async (lands: any) => {
  try {
    await client.delete(`/users/${getCurrentUserId()}/tags/lands/${lands.id}`)

    return lands
  } catch (e) {
    throw new Error(e)
  }
}

export const favoritesServices = {
  addFavoriteLand,
  deleteFavoriteLand,
  addProspectLand,
  deleteProspectLand,
  addTagLand,
  deleteTagLand,
}

/* eslint-disable max-lines */
import {
  calculateLegacyScore,
  calculateBuildingsGroupedAnalytics,
} from './underExploited.utils'

import {
  calculateSp,
  calculateSdb,
  calculateSb,
  type,
  categories,
  classification,
  spdSbpuType,
  calculateSpdSbpu,
} from './calculateIndices.utils'

export const updateOverridedBuilding = (state: any, action: any) => {
  const data = action.payload

  state.parcelDetail.buildingsUpdateCount =
    (state.parcelDetail.buildingsUpdateCount || 0) + 1

  state.parcelDetail.buildings = state.parcelDetail?.buildings?.map(
    (building: { id: number }) => {
      if (building.id === data.id) {
        return data
      }

      return building
    }
  )

  state.parcelDetail.buildingsOverridedIds = state.parcelDetail
    ?.buildingsOverridedIds?.length
    ? [
        ...state.parcelDetail.buildingsOverridedIds,
        { id: data.overridedId, buildingId: data.id },
      ]
    : [{ id: data.overridedId, buildingId: data.id }]

  const find = state.parcelDetail.buildings?.find(
    (building: {
      areaOveride: string | number
      floorAreaOveride: string | number
    }) => building.areaOveride
  )

  if (find) {
    const sdbRecalculate = calculateSdb(
      state.parcelDetail.buildings
        .filter((building: any) => !type.includes(building.building_type))
        .filter(
          (building: any) =>
            !classification.includes(
              building.classification || !building.classification
            )
        )
    )

    const sbRecalculate = calculateSb(
      state.parcelDetail.buildings.filter(
        (building: any) =>
          !classification.includes(
            building.classification || !building.classification
          )
      )
    )

    state.parcelDetail.sbOverride = sbRecalculate
    state.parcelDetail.current_cosOverride = (
      sbRecalculate / state.parcelDetail.std
    ).toFixed(2)

    state.parcelDetail.current_iosOverride = (
      sdbRecalculate / state.parcelDetail.std
    ).toFixed(2)
    state.parcelDetail.sbdOverride = sdbRecalculate
  } else {
    state.parcelDetail.sbOverride = null
    state.parcelDetail.current_cosOverride = null
    state.parcelDetail.sbdOverride = null
    state.parcelDetail.current_iosOverride = null
  }

  const Overrided = state.parcelDetail?.buildings?.find(
    (building: {
      floorAreaOveride: string | number
      floorNbOveride: string | number
      areaOveride: string | number
    }) =>
      !!building.floorAreaOveride ||
      !!building.areaOveride ||
      !!building.floorNbOveride
  )

  if (Overrided) {
    const spRecalculate = Math.round(
      calculateSp(
        state.parcelDetail.buildings
          .filter(
            (building: any) =>
              !['Habitation provisoire'].includes(building.building_type)
          )
          .filter(
            (building: any) => !['projected'].includes(building.classification)
          )
      )
    )

    const sbpuSpdRecalculate = Math.round(
      calculateSpdSbpu(
        state.parcelDetail.buildings
          .filter((building: any) =>
            spdSbpuType.includes(building.building_type)
          )
          .filter((building: any) => !categories.includes(building.category))
          .filter(
            (building: any) => !['projected'].includes(building.classification)
          )
      )
    )

    const sbpuSp: any = Math.round(sbpuSpdRecalculate)

    state.parcelDetail.spOverride = spRecalculate
    state.parcelDetail.current_ibusOverride = (
      spRecalculate / state.parcelDetail.std
    ).toFixed(2)
    state.parcelDetail.current_iusOverride = (
      sbpuSp / state.parcelDetail.std
    ).toFixed(2)
    state.parcelDetail.current_cusOverride = (
      sbpuSp / state.parcelDetail.std
    ).toFixed(2)
    state.parcelDetail.sbpuOverride = sbpuSp
    state.parcelDetail.spdOverride = sbpuSp
  } else {
    state.parcelDetail.spOverride = null
    state.parcelDetail.sbpuOverride = null
    state.parcelDetail.spdOverride = null
    state.parcelDetail.current_ibusOverride = null
    state.parcelDetail.current_iusOverride = null
    state.parcelDetail.current_cusOverride = null
  }

  state.parcelDetail = {
    ...state.parcelDetail,
    ...calculateBuildingsGroupedAnalytics(
      state.parcelDetail.buildings,
      state.parcelDetail.average_protected_rank,
      state.parcelDetail.average_condition_year
    ),
  }

  state.parcelDetail.existing_score = calculateLegacyScore(
    state.parcelDetail.average_protected_rank,
    state.parcelDetail.average_condition_year,
    state.parcelDetail.official_area || state.parcelDetail.area,
    state.parcelDetail.ios,
    state.parcelDetail.ios
  )
}

export const getOverridedBuildings = (state: any, action: any) => {
  const { data } = action.payload
  let findBuildings = false

  if (data) {
    state.parcelDetail.buildings = state.parcelDetail?.buildings?.map(
      (building: { id: number | string }) => {
        const index = data.findIndex(
          (item: {
            building_id: number
            active: boolean
            override_json: any
          }) => {
            return (
              Number(item.building_id) === Number(building.id) &&
              item.active &&
              item.override_json
            )
          }
        )

        if (index !== -1) {
          findBuildings = true

          return data[index]?.override_json || building
        }

        return building
      }
    )

    state.parcelDetail.buildingsOverridedIds = data.map(
      (building: { id: number | string; building_id: number | string }) => {
        return {
          id: Number(building.id),
          buildingId: Number(building.building_id),
        }
      }
    )

    const findArea = state.parcelDetail?.buildings?.find(
      (building: { areaOveride: number | string }) => !!building.areaOveride
    )

    const Overrided = state.parcelDetail?.buildings?.find(
      (building: {
        floorAreaOveride: string | number
        floorNbOveride: string | number
        areaOveride: string | number
      }) =>
        !!building.floorAreaOveride ||
        !!building.areaOveride ||
        !!building.floorNbOveride
    )

    if (Overrided) {
      const spRecalculate = Math.round(
        calculateSp(
          state.parcelDetail.buildings
            .filter(
              (building: any) =>
                !['Habitation provisoire'].includes(building.building_type)
            )
            .filter(
              (building: any) =>
                !['projected'].includes(building.classification)
            )
        )
      )

      const sbpuSpdRecalculate = Math.round(
        calculateSpdSbpu(
          state.parcelDetail.buildings
            .filter((building: any) =>
              spdSbpuType.includes(building.building_type)
            )
            .filter((building: any) => !categories.includes(building.category))
            .filter(
              (building: any) =>
                !['projected'].includes(building.classification)
            )
        )
      )

      const sbpuSp: any = Math.round(sbpuSpdRecalculate)

      state.parcelDetail.spOverride = spRecalculate
      state.parcelDetail.current_ibusOverride = (
        spRecalculate / state.parcelDetail.std
      ).toFixed(2)
      state.parcelDetail.current_iusOverride = (
        sbpuSp / state.parcelDetail.std
      ).toFixed(2)
      state.parcelDetail.current_cusOverride = (
        sbpuSp / state.parcelDetail.std
      ).toFixed(2)
      state.parcelDetail.sbpuOverride = sbpuSp
      state.parcelDetail.spdOverride = sbpuSp
    } else {
      state.parcelDetail.spOverride = null
      state.parcelDetail.sbpuOverride = null
      state.parcelDetail.spdOverride = null
      state.parcelDetail.current_ibusOverride = null
      state.parcelDetail.current_iusOverride = null
      state.parcelDetail.current_cusOverride = null
    }

    if (findArea) {
      const sdbRecalculate = calculateSdb(
        state.parcelDetail.buildings
          .filter((building: any) => !type.includes(building.building_type))
          .filter(
            (building: any) => !classification.includes(building.classification)
          )
      )

      const sbRecalculate = calculateSb(
        state.parcelDetail.buildings.filter(
          (building: any) => !classification.includes(building.classification)
        )
      )

      state.parcelDetail.sbOverride = sbRecalculate
      state.parcelDetail.current_cosOverride = (
        sbRecalculate / state.parcelDetail.std
      ).toFixed(2)
      state.parcelDetail.current_iosOverride = (
        sdbRecalculate / state.parcelDetail.std
      ).toFixed(2)
      state.parcelDetail.sbdOverride = sdbRecalculate
    } else {
      state.parcelDetail.sbOverride = null
      state.parcelDetail.current_cosOverride = null
      state.parcelDetail.sbdOverride = null
      state.parcelDetail.current_iosOverride = null
    }

    if (findBuildings) {
      state.parcelDetail = {
        ...state.parcelDetail,
        ...calculateBuildingsGroupedAnalytics(
          state.parcelDetail.buildings,
          state.parcelDetail.average_protected_rank,
          state.parcelDetail.average_condition_year
        ),
      }

      state.parcelDetail.existing_score = calculateLegacyScore(
        state.parcelDetail.average_protected_rank,
        state.parcelDetail.average_condition_year,
        state.parcelDetail.official_area || state.parcelDetail.area,
        state.parcelDetail.ios,
        state.parcelDetail.ios
      )
    }
  }
}

export const getBuildingsDetails = (state: any, action: any) => {
  const { payload } = action
  // let buildings: any[] = []

  // if (payload) {
  //   buildings = payload?.geojson?.features?.filter(({ properties }: any) =>
  //     properties?.lands?.some(
  //       (land: any) => land?.id === state?.parcelDetail?.land_id
  //     )
  //   )
  // }

  state.parcelDetail.buildings = state.parcelDetail.buildings.map(
    (building: any) => {
      const find = payload?.find(
        (build: any) => Number(build.id) === Number(building.id)
      )

      if (find) {
        return {
          ...building,
          ...find,
          geo_polygon: JSON.parse(find.geo_polygon),
          geo_center: JSON.parse(find.geo_center),
        }
      }

      return building
    }
  )
}

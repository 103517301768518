import React from 'react'

import { Button } from '../../../components'
import { useI18n } from '../../../context'
import { StepActionsRoot } from './StepActions.style'

export type StepActionsProps = {
  onNext: () => void
  onPrevious: () => void
  disabled?: boolean
}

const SettingsStep = ({ onNext, onPrevious, disabled }: StepActionsProps) => {
  const { t } = useI18n()

  return (
    <StepActionsRoot>
      <Button
        onClick={onPrevious}
        variant="outlined"
        color="secondary"
        size="small"
      >
        {t('common.previous')}
      </Button>
      <Button
        color="secondary"
        variant="contained"
        onClick={onNext}
        size="small"
        disabled={disabled}
      >
        {t('common.next')}
      </Button>
    </StepActionsRoot>
  )
}

export default SettingsStep

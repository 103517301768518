import styled from 'styled-components'

export const GeoStepRoot = styled.div`
  > span {
    color: rgb(141, 155, 163);
    font-style: italic;
    font-size: 12px;
  }

  > button {
    margin-top: 8px;
  }
`

/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '../../utils'

import noteService from '../../services/note'

const initialState = {
  actionHistory: {},
  analysisLands: [],
  companyAnalysisLands: [],
}

export const getAnalysisLands = createAsyncThunk(
  'note/getAnalysisLands',
  noteService.getAnalysisLands
)

export const getAnalysisLandsByUser = createAsyncThunk(
  'note/getAnalysisLandsByUser',
  noteService.getAnalysisLandsByUser
)

export const getNotes = createAsyncThunk('note/getNotes', noteService.getNotes)

export const addNote = createAsyncThunk('note/addNote', noteService.addNote)

export const updateNote = createAsyncThunk(
  'note/updateNote',
  noteService.updateNote
)

export const removeNote = createAsyncThunk(
  'note/removeNote',
  noteService.removeNote
)

export const addReminder = createAsyncThunk(
  'note/addReminder',
  noteService.addReminder
)

export const updateReminder = createAsyncThunk(
  'note/updateReminder',
  noteService.updateReminder
)

export const removeReminder = createAsyncThunk(
  'note/removeReminder',
  noteService.removeReminder
)

const note = createSlice({
  name: 'note',
  initialState,
  reducers: {},
  extraReducers: {
    // Handle async actions here, not in reducers
    [getAnalysisLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.analysisLands = data
    },
    [getAnalysisLandsByUser.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.companyAnalysisLands = data
    },
    [getNotes.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.actionHistory = {
          ...state.actionHistory,
          ...mapBy(
            (r) => r.uid,
            data.map((r) => ({ ...r, uid: `${r.action}_${r.note_id || r.id}` }))
          ),
        }
      }
    },
    [addNote.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.actionHistory[
          `${data.action || 'note'}_${data.note_id || data.id}`
        ] = data
      }
    },
    [removeNote.fulfilled]: (state, action) => {
      const { noteId } = action.payload

      if (noteId) {
        delete state.actionHistory[`note_${noteId}`]
      }
    },
    [updateNote.fulfilled]: (state, action) => {
      const value = action.payload

      if (value) {
        state.actionHistory[`note_${value.note_id}`] = {
          ...state.actionHistory[`note_${value.note_id}`],
          ...value,
        }
      }
    },
    [addReminder.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (!data.land_id) {
        data.land_id = input.landId
      }

      if (data) {
        state.actionHistory[`${data.action || 'reminder'}_${data.id}`] = data
      }
    },
    [removeReminder.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) {
        delete state.actionHistory[`reminder_${input.id}`]
      }
    },
    [updateReminder.fulfilled]: (state, action) => {
      const { data } = action.payload
      const input = action.meta.arg

      if (data) {
        state.actionHistory[`reminder_${input.id}`] = {
          ...state.actionHistory[`reminder_${input.id}`],
          ...input,
        }
      }
    },
  },
})

export default note.reducer

import { createSelector } from '@reduxjs/toolkit'

import { getHistoryPriceData } from '../../services/history'

const faoHistoryStateSelector = (state) => state.faoHistory

const faosSelector = (state) => state.faoHistory.faos

const faoAggregationsSelector = (state) => state.faoHistory.aggregations

const inquiryStartDatesSelector = (state) =>
  state.faoHistory.aggregations.inquiry_start_dates.inquiry_start_dates

const totalFaosSelector = (state) => state.faoHistory.total

const landInfoByIdSelector = (state) => state.faoHistory.landInfoById

const compareFilterSelector = (state) => state.faoHistory.compareFilter

const devSelector = (state) => state.faoHistory.dev

const priceIntervalSelector = (state) =>
  state.faoHistory.priceData.calendar_interval

const priceAggsSelector = (state) => state.faoHistory.priceData.aggregations

const priceDataSelector = createSelector(
  priceAggsSelector,
  (aggs) =>
    aggs && getHistoryPriceData(aggs.price_over_time.price_over_time.buckets)
)

const maxDateSelector = createSelector(
  priceAggsSelector,
  (aggs) =>
    aggs?.price_over_time.price_over_time.buckets.slice(-1)[0]?.key_as_string
)

const comparePriceAggsSelector = (state) =>
  state.faoHistory.comparePriceData.aggregations

const comparePriceDataSelector = createSelector(
  comparePriceAggsSelector,
  (aggs) =>
    aggs && getHistoryPriceData(aggs.price_over_time.price_over_time.buckets)
)

const faoLoadingSelector = (state) => state.faoHistory.loading

const locationsAggsSelector = (state) => state.faoHistory.locationsAggs

export {
  faoHistoryStateSelector,
  faosSelector,
  totalFaosSelector,
  faoAggregationsSelector,
  inquiryStartDatesSelector,
  landInfoByIdSelector,
  compareFilterSelector,
  priceIntervalSelector,
  priceDataSelector,
  priceAggsSelector,
  maxDateSelector,
  devSelector,
  comparePriceDataSelector,
  faoLoadingSelector,
  locationsAggsSelector,
}

import React from 'react'
import { useSelector } from 'react-redux'

import { faoAggregationsSelector } from '../../../redux/faoHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'
import { useI18n } from '../../../context'

const WorkTypeFilter = ({ onChange, workTypeFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(faoAggregationsSelector)

  const { work_types } = aggregations.work_types
  const items = work_types.buckets.map((r) => ({ ...r, count: r.doc_count }))

  const handleChange = (newValues) => {
    onChange({ workTypeFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('fao.nature')}>
      <CheckboxFilter
        filters={workTypeFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default WorkTypeFilter

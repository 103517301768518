import Box from '@mui/material/Box'
import styled from 'styled-components'

export const SearchInputRoot = styled(Box)`
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: #fff;
  max-width: 300px;

  > svg {
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 16px;
  }

  input {
    padding: 8px 8px 8px 48px;
    transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

/* eslint-disable max-lines */
import * as turf from '@turf/turf'

import { MAPBOX_TOKEN } from '@/config'

export type Alert = {
  multiPolygon: { coordinates: any[]; [k: string]: any }
  [k: string]: any
}

const settingsFileds = [
  'listing_parameters',
  'transaction_parameters',
  'building_permit_parameters',
]

const minMaxFields = [
  'roomFilter',
  'floorFilter',
  'pricePerSquareMeterFilter',
  'constructionYearFilter',
  'priceFilter',
]

const multiPolygonToImg = (alert: any) => {
  let multiPolygon: any = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          stroke: '#555555',
          'stroke-width': 2,
          'stroke-opacity': 1,
          fill: '#00ADE6',
          'fill-opacity': 0.5,
        },
        geometry: alert.multiPolygon,
      },
    ],
  }

  if (alert.type === 'Zones') {
    let tol = 0.000001
    let len = 0

    try {
      // if geojson is too big we need to simplify it as we cannot pass too many character in URL
      // Maximum is a polygon of 20 points (to have a url less than 4096 characters)

      while (JSON.stringify(multiPolygon).replace(/\s/g, '').length > 5000) {
        // We use turf.js to simplify the polygon
        // if (showMap) break;
        len = JSON.stringify(multiPolygon).replace(/\s/g, '').length
        const options = { tolerance: tol, highQuality: false }

        multiPolygon = turf.simplify(turf.buffer(multiPolygon, 0), options)
        tol *= 5
        // if the loop is infinite we need to break the while whith compare the length of the polygon
        // we use setShowMap(true) to know if the loop was infinite et then we break it
        if (len === JSON.stringify(multiPolygon).replace(/\s/g, '').length)
          break
      }
    } catch (error) {
      console.error(error)
    }
  }

  const layers = JSON.stringify(multiPolygon)

  return `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/geojson(${encodeURIComponent(
    layers
  ).replace(/\s/g, '')})/auto/350x200?access_token=${MAPBOX_TOKEN}`
}

export const updateSettingFileds = (alert: any) => {
  settingsFileds.forEach((f) => {
    const settings = { ...alert[f] }

    if (settings.area?.max || settings.area?.min >= 0) {
      settings.areaFilter = [settings.area.min, settings.area.max]
    }
    delete settings.area

    if (settings.ownershipType?.length) {
      settings.ownershipTypeFilter = settings.ownershipType
    }
    delete settings.ownershipType

    if (settings.reasons?.length) {
      settings.reasonFilter = settings.reasons
    }
    delete settings.reasons

    if (settings.classification?.length) {
      settings.classificationFilter = settings.classification
    }
    delete settings.classification

    if (settings.typeName?.length) {
      settings.typeNameFilter = settings.typeName
    }
    delete settings.typeName

    if (settings.results?.length) {
      settings.resultsFilter = settings.results
    }
    delete settings.results

    if (settings.workType?.length) {
      settings.workTypeFilter = settings.workType
    }
    delete settings.workType

    alert[f] = settings
  })
}

const featuresToMultiPolygon = (features: any[]) => {
  const feature = features.reduce((acc, v) => {
    return [...acc, v.geometry.coordinates[0]]
  }, [])

  const { geometry } = turf.multiPolygon(feature)

  const coordinates = geometry?.coordinates.reduce((acc, coord) => {
    return [...acc, [coord]]
  }, [])

  return { type: 'MultiPolygon', coordinates }
}

const multiPolygonToFeatures = (multiPolygon: any) => {
  const features =
    multiPolygon?.coordinates?.map((coordinates: any) => ({
      type: 'Feature',
      geometry: { type: 'Polygon', coordinates },
    })) || []

  return { type: 'FeatureCollection', features }
}

const sanitizeAlert = (data: Alert) => {
  const alert = JSON.parse(JSON.stringify(data))
  const { geometry } = turf.centroid(alert.multiPolygon)

  const latitude = alert.geoCenter
    ? alert.geoCenter[1]
    : geometry?.coordinates[1]

  const longitude = alert.geoCenter
    ? alert.geoCenter[0]
    : geometry?.coordinates[0]

  const newAlert: Alert = {
    ...alert,
    radius: alert.radius ? alert.radius / 1000 : null,
    features: multiPolygonToFeatures(
      alert.type === 'Polygone' && alert.multiPolygon
    ),
  }

  updateSettingFileds(newAlert)

  return {
    ...newAlert,
    parameters: {
      ...newAlert.parameters,
      reasons: newAlert.parameters.reasons || [],
      ownershipType: newAlert.parameters.ownershipType || [],
    },
    geoCenter: newAlert.geoCenter
      ? [newAlert.geoCenter[0], newAlert.geoCenter[1]]
      : null,
    location: newAlert.data,
    radiusCoordinates: newAlert.coordinates,
    viewport: { longitude, latitude, zoom: 13 },
  }
}

const formatMutatedAlertResponse = async (response: any, input: any) => {
  if (response.data) {
    response.data = {
      ...response.data,
      ...input,
      createdAt:
        response.data.update_timestamp || response.data.create_timestamp,
      data: input.location,
      geoCenter: input.geoCenter.coordinates,
      principalType: input.zoneAffectationSelected,
    }
  }

  return response
}

export type AlertInput = {
  id?: string
  type: any
  citiesSelected: any[]
  regionsSelected: any[]
  zonesSelected: any[]
  multiPolygon: any
  zoneAffectationSelected: any[]
  fao: boolean
  transaction: any
  radius: number
  name: string
  parameters: any
  geoCenter: any
  address?: string
  [k: string]: any
}

const extractAlertInput = (data: AlertInput) => {
  const input = JSON.parse(JSON.stringify(data))

  input.geoCenter = input.geoCenter || turf.center(input.multiPolygon).geometry

  settingsFileds.forEach((f) => {
    const settings = { ...input[f] }

    if (settings.areaFilter?.length) {
      settings.area = {
        min: settings.areaFilter[0] || 0,
        max: settings.areaFilter[1] || 100000,
      }
    }
    delete settings.areaFilter

    if (settings.ownershipTypeFilter?.length) {
      settings.ownershipType = settings.ownershipTypeFilter
    }
    delete settings.ownershipTypeFilter

    if (settings.reasonFilter?.length) {
      settings.reasons = settings.reasonFilter
    }
    delete settings.reasonFilter

    if (settings.classificationFilter?.length) {
      settings.classification = settings.classificationFilter
    }
    delete settings.classificationFilter

    if (settings.typeNameFilter?.length) {
      settings.typeName = settings.typeNameFilter
    }
    delete settings.typeNameFilter

    if (settings.resultsFilter?.length) {
      settings.results = settings.resultsFilter
    }
    delete settings.resultsFilter

    if (settings.workTypeFilter?.length) {
      settings.workType = settings.workTypeFilter
    }
    delete settings.workTypeFilter

    minMaxFields.forEach((name) => {
      if (settings[name]?.length) {
        settings[name] = {
          min: settings[name][0] || 0,
          max: settings[name][1] || 100000,
        }
      }
    })

    input[f] = settings
  })

  return {
    ...input,
    ...(input.id && { id: input.id }),
    type: input.type,
    citiesSelected: input.citiesSelected,
    regionsSelected: input.regionsSelected,
    zonesSelected: input.zonesSelected,
    multiPolygon: input.multiPolygon,
    zoneAffectationSelected: input.zoneAffectationSelected,
    fao: input.fao,
    transaction: input.transaction,
    listing: input.listing,
    radius: input.radius,
    name: input.name,
    parameters: input.parameters,
    geoCenter: input.geoCenter,
    address: input.address,
  }
}

export {
  sanitizeAlert,
  formatMutatedAlertResponse,
  extractAlertInput,
  featuresToMultiPolygon,
  multiPolygonToFeatures,
  multiPolygonToImg,
}

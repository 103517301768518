import React from 'react'

import RegionFilter from '../RegionFilter'
import DateRangeFilter from '../DateRangeFilter'
import WorkTypeFilter from '../WorkTypeFilter'
import TypeNameFilter from '../TypeNameFilter'
import StatusFilter from '../StatusFilter'
import { SidePane } from '../../../components'
import { SidePaneProps } from '../../../components/SidePane'
import ClassificationFilter from '../ClassificationFilter'
import AreaFilter from '../AreaFilter'

export type FaoFiltersProps = SidePaneProps & {
  filters?: Record<string, any>
  onChange?: (filters: Record<string, any>) => void
}

const FaoFilters = (props: FaoFiltersProps) => {
  const { onChange, filters, ...other } = props

  if (!filters) return null

  return (
    <SidePane {...other}>
      <RegionFilter
        onChange={onChange}
        regionFilter={filters.regionFilter}
        zoneFilter={filters.zoneFilter}
        cityFilter={filters.cityFilter}
        landUsePlansFilter={filters.landUsePlansFilter}
      />
      <DateRangeFilter
        onChange={onChange}
        inquiryStartDateFilter={filters.inquiryStartDateFilter}
      />
      <ClassificationFilter
        onChange={onChange}
        classificationFilter={filters.classificationFilter}
      />
      <AreaFilter onChange={onChange} areaFilter={filters.areaFilter} />
      <TypeNameFilter
        onChange={onChange}
        typeNameFilter={filters.typeNameFilter}
      />
      <WorkTypeFilter
        onChange={onChange}
        workTypeFilter={filters.workTypeFilter}
      />
      <StatusFilter onChange={onChange} resultsFilter={filters.resultsFilter} />
    </SidePane>
  )
}

export default FaoFilters

import React from 'react'

import { useI18n } from '../../../context'
import { AdvancedSettingsRoot, DisplayText } from './AlertCard.style'
import ParametersDisplay from './ParametersDisplay'

const AdvancedSettings = ({ alert }) => {
  const { t } = useI18n()

  const hasSettings =
    (alert.parameters.area?.min && +alert.parameters.area.min !== 0) ||
    (alert.parameters.area?.max && +alert.parameters.area.max !== 100000) ||
    alert.parameters.reasons?.length > 0 ||
    alert.parameters.ownershipType?.length > 0 ||
    alert?.parameters?.name?.length > 0 ||
    alert.parameters.classification?.length > 0

  if (!hasSettings) return null

  let title

  if (
    alert.parameters.area &&
    (+alert.parameters.area.min !== 0 || +alert.parameters.area.max !== 100000)
  ) {
    title = (
      <>
        <b>{alert.parameters.area.min}</b>/<b>{alert.parameters.area.max}</b>m
        <sup>2</sup>
      </>
    )
  }

  const classifications = alert.parameters?.classification?.map((c) =>
    t(`myAlert.fao.${c}`)
  )

  return (
    <AdvancedSettingsRoot>
      <div title={t('myAlert.settings.advancedSettings')}>
        {t('myAlert.settings.advancedSettings')}
      </div>
      {title && <DisplayText title={title}>{title}</DisplayText>}
      <ParametersDisplay parameters={alert.parameters.reasons} />
      <ParametersDisplay parameters={alert.parameters.ownershipType} />
      <ParametersDisplay parameters={classifications} />
      {alert.parameters.name && (
        <ParametersDisplay
          parameters={`${t('myAlert.settings.key')}: ${alert.parameters.name}`}
        />
      )}
    </AdvancedSettingsRoot>
  )
}

export default AdvancedSettings

/* eslint-disable max-lines */
import ApiClient from '../apiClient'

const client = new ApiClient()

const fetchPrincipalType = async (cities) => {
  try {
    const response = await client.get(
      `/zones/primary/${cities
        .map((city) => encodeURIComponent(city.name || city))
        .join(',')}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchLandUsePlan = async (cities) => {
  try {
    const response = await client.get(
      `/zones/landuseplan/${cities
        .map((city) => encodeURIComponent(city.name || city))
        .join(',')}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchFutureLandUsePlan = async (cities) => {
  try {
    const response = await client.get(
      `/zones/futurelanduseplan/${cities
        .map((city) => encodeURIComponent(city.name || city))
        .join(',')}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchLandUsePlanAssignement = async (cities) => {
  try {
    const response = await client.get(
      `/zones/landuseassignement/${cities
        .map((city) => encodeURIComponent(city.name || city))
        .join(',')}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchFutureLandUsePlanAssignement = async (cities) => {
  try {
    const response = await client.get(
      `/zones/futurelanduseassignement/${cities
        .map((city) => encodeURIComponent(city.name || city))
        .join(',')}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchAddresses = async (value) => {
  try {
    const response = await client.post('/addresses/_searchAll', { value })

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchCompanyProspects = async () => {
  try {
    const response = await client.get(
      `/companies/${JSON.parse(localStorage.user).company_id}/prospects`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchCommercialActivities = async () => {
  try {
    const response = await client.get(
      `/companies/${
        JSON.parse(localStorage.user).company_id
      }/commercialActivities`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchCompaniesFilters = async () => {
  try {
    const response = await client.get(
      `/companies/${JSON.parse(localStorage.user).company_id}/filters`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchUsersFilters = async () => {
  try {
    const response = await client.get(
      `/users/${JSON.parse(localStorage.user)._id}/filters`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const fetchUsersFavoriteLands = async () => {
  try {
    const response = await client.get(
      `/users/${JSON.parse(localStorage.user)._id}/favorites/lands`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

export const saveFilter = async (values) => {
  try {
    const response = await client.post(
      `/users/${JSON.parse(localStorage.user)._id}/filters`,
      values
    )

    if (response.data) {
      response.data = {
        id: response.data,
        ...values,
        create_timestamp: new Date(),
      }
    }

    return response
  } catch (e) {
    throw new Error(e)
  }
}

export const removeFilter = async (filterId) => {
  try {
    const response = await client.delete(
      `/users/${JSON.parse(localStorage.user)._id}/filters/${filterId}`
    )

    return response
  } catch (e) {
    throw new Error(e)
  }
}

const searchlands = async (values) => {
  const url =
    values.type === 'building'
      ? '/parcelles/buildings/_search'
      : '/parcelles/_search'

  try {
    const response = await client.post(url, values.data || values, {
      signal: values.signal,
    })

    return { res: response, onMap: values.onMap, pos: values.pos, values }
  } catch (e) {
    throw new Error(e)
  }
}

const searchBuildings = async (values) => {
  try {
    const response = await client.post(
      '/parcelles/buildings/_search',
      values.data || values,
      { signal: values.signal }
    )

    return { res: response, onMap: values.onMap, pos: values.pos }
  } catch (e) {
    throw new Error(e)
  }
}

const fetchUsersOverideLands = async () => {
  try {
    return client.get(
      `/parcelles/assignment/${JSON.parse(localStorage.user)._id}/overide`
    )
  } catch (e) {
    throw new Error(e)
  }
}

const fetchUsersFutureOverideLands = async () => {
  try {
    return client.get(
      `/futurelanduseplans/overide/assignment/${
        JSON.parse(localStorage.user)._id
      }`
    )
  } catch (e) {
    throw new Error(e)
  }
}

const fetchPrincipalTypeByDistrict = async (districts) => {
  return client.get(
    `/zones/primary/district/${districts
      .map((d) => encodeURIComponent(d))
      .join(',')}`
  )
}

const fetchPrincipalTypeByRegion = async (regions) => {
  return client.get(
    `/zones/primary/region/${regions
      .map((r) => encodeURIComponent(r))
      .join(',')}`
  )
}

const getBuildingFilters = async () => {
  return client.get(`/parcelles/filters/building/informations`)
}

const fetchUsersOverideLandsOnMap = async () => {
  try {
    return client.get(
      `/parcelles/assignment/${JSON.parse(localStorage.user)._id}/map/override`
    )
  } catch (e) {
    throw new Error(e)
  }
}

const fetchUsersExtendedOverideLandsOnMap = async () => {
  try {
    return client.get(
      `/parcelles/assignment/${
        JSON.parse(localStorage.user)._id
      }/map/override/extended`
    )
  } catch (e) {
    throw new Error(e)
  }
}

const developerMapServices = {
  fetchPrincipalTypeByDistrict,
  fetchPrincipalTypeByRegion,
  fetchUsersFutureOverideLands,
  fetchUsersOverideLands,
  fetchUsersFavoriteLands,
  fetchUsersFilters,
  fetchCompaniesFilters,
  fetchCompanyProspects,
  fetchCommercialActivities,
  fetchPrincipalType,
  fetchLandUsePlan,
  fetchFutureLandUsePlan,
  fetchLandUsePlanAssignement,
  fetchFutureLandUsePlanAssignement,
  fetchAddresses,
  removeFilter,
  saveFilter,
  searchlands,
  searchBuildings,
  getBuildingFilters,
  fetchUsersOverideLandsOnMap,
  fetchUsersExtendedOverideLandsOnMap,
}

export default developerMapServices

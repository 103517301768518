import Autocomplete from '@mui/material/Autocomplete'
import Switch from '@mui/material/Switch'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { arrayFy } from '@/utils'
import TransactionFilters from '../../TransactionHistory/TransactionFilters'
import { useI18n } from '../../../context'
import {
  alertTransactionSelector,
  alertTransactionSettingsSelector,
  updateSelectedAlert,
} from '../../../redux/alert'
import InfoTitle from '../SettingsStep/InfoTitle'
import {
  SettingsField,
  SettingsStepRoot,
} from '../SettingsStep/SettingsStep.style'
import StepActions from '../StepActions'
import SettingsCollapse from '../SettingsStep/SettingsCollapse'

const TransactionSettingsStep = ({ onNext, onPrevious }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState([])
  const { t } = useI18n()

  const transactionSettings = useSelector(alertTransactionSettingsSelector)
  const transaction = useSelector(alertTransactionSelector)

  const [filters, setFilters] = useState({ ...transactionSettings })

  const updateSettings = (name) => (values) => {
    dispatch(
      updateSelectedAlert({
        transaction_parameters: { ...transactionSettings, [name]: values },
      })
    )
  }

  const handleChangeKeyword = (e, newValue) => {
    updateSettings('name')(newValue)

    setValue(newValue)
  }

  const handleChange = (e) => {
    dispatch(updateSelectedAlert({ transaction: !!e.target.checked }))
  }

  const handleFilterChange = (newFilters) => {
    const newValue = { ...transactionSettings, ...newFilters }

    dispatch(updateSelectedAlert({ transaction_parameters: newValue }))
    setFilters(newValue)
  }

  return (
    <SettingsStepRoot>
      <Switch
        checked={!!transaction}
        color="secondary"
        onChange={handleChange}
      />

      <SettingsCollapse>
        <InfoTitle
          title={t('myAlert.settings.keyword')}
          text={t('myAlert.settings.keywordDesc')}
        />

        <Autocomplete
          size="small"
          multiple
          freeSolo
          fullWidth
          autoSelect
          options={[]}
          value={arrayFy(transactionSettings?.name || value)}
          onChange={handleChangeKeyword}
          renderInput={(params) => (
            <SettingsField
              {...params}
              variant="outlined"
              inputProps={{ ...params.inputProps, autoComplete: 'chrome-off' }}
            />
          )}
        />

        <TransactionFilters
          filters={filters}
          open
          onChange={handleFilterChange}
        />
      </SettingsCollapse>

      <StepActions onPrevious={onPrevious} onNext={onNext} />
    </SettingsStepRoot>
  )
}

export default TransactionSettingsStep

import ApiClient from '../apiClient'
import { getCompanyId } from './companyService'

const client = new ApiClient()

const getCompanyCommercialActivityLands = async () => {
  return client.get(`/companies/${getCompanyId()}/commercialActivities`)
}

const addCommercialActivityLand = async (landId: string) => {
  const response = await client.post(
    `/companies/${getCompanyId()}/commercialActivities/${landId}`
  )

  const user = JSON.parse(localStorage.getItem('user') || '')

  if (response.data) {
    response.data = {
      id: response.data,
      commercial_activity_user_id: user._id,
      commercial_activity_user_first_name: user.first_name,
      commercial_activity_user_last_name: user.last_name,
      create_timestamp: new Date(),
      land_id: landId,
      customer_data_json: null,
      company_id: user.company_id,
    }
  }

  return response
}

const removeCommercialActivityLand = async (landId: string) => {
  return client.delete(
    `/companies/${getCompanyId()}/commercialActivities/${landId}`
  )
}

const commercialActivityService = {
  getCompanyCommercialActivityLands,
  addCommercialActivityLand,
  removeCommercialActivityLand,
}

export { getCompanyId }
export default commercialActivityService

import React, { useState } from 'react'

import { FilterCategory, MinMaxFilter } from '../../../components'
import { useI18n } from '../../../context'

const PriceM2Filter = ({ onChange, pricePerSquareMeterFilter, ...other }) => {
  const { t } = useI18n()

  const [reset, setReset] = useState(false)

  const handleChange = (newValue, apply) => {
    setReset(false)
    onChange({ pricePerSquareMeterFilter: newValue }, apply)
  }

  const handleReset = () => {
    setReset(true)
  }

  return (
    <FilterCategory
      {...other}
      title={t('listing.priceM2')}
      onReset={pricePerSquareMeterFilter?.length && handleReset}
    >
      <MinMaxFilter
        id="priceM2"
        step=".01"
        reset={reset}
        value={pricePerSquareMeterFilter}
        onChange={handleChange}
        obj={other.obj}
      />
    </FilterCategory>
  )
}

export default PriceM2Filter

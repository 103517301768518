import React, { useState } from 'react'

import { FilterCategory, MinMaxFilter } from '../../../components'
import { useI18n } from '../../../context'

const PriceFilter = ({ onChange, priceFilter, ...other }) => {
  const { t } = useI18n()

  const [reset, setReset] = useState(false)

  const handleChange = (newValue, apply) => {
    setReset(false)
    onChange({ priceFilter: newValue }, apply)
  }

  const handleReset = () => {
    setReset(true)
  }

  return (
    <FilterCategory
      {...other}
      title={t('transaction.price')}
      onReset={priceFilter?.length && handleReset}
      helperText={t('transaction.priceInfo')}
    >
      <MinMaxFilter
        id="price"
        reset={reset}
        value={priceFilter}
        onChange={handleChange}
        obj={other.obj}
      />
    </FilterCategory>
  )
}

export default PriceFilter

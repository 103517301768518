import React from 'react'
import { createRoot } from 'react-dom/client'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
// import { CaptureConsole } from '@sentry/integrations'
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Legend,
  Filler,
  Tooltip,
  RadialLinearScale,
} from 'chart.js'

import {
  isProd,
  // SENTRY_DNS,
  // SENTRY_RATE,
  APP_VERSION,
  APP_VERSION_KEY,
} from './config'
import App from './App'
import { clearLocalStorage } from './utils'
import ServiceWorkerWrapper from './components/ServiceWorkerRegister'

// Remove loader after 2s if it is not already removed to avoid infinite load
setTimeout(() => {
  if (typeof document !== 'undefined') {
    const loader = document.getElementById('loader')

    if (loader) loader.outerHTML = ''
  }
}, 2000)

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Legend,
  Filler,
  Tooltip,
  RadialLinearScale
)

/** ** Init sentry **** */

// if (isProd) {
//   Sentry.init({
//     dsn: SENTRY_DNS,
//     integrations: [
//       new CaptureConsole({ levels: ['error'] }),
//       new Integrations.BrowserTracing(),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: SENTRY_RATE,
//   })
// }

/** ** Clear localstorage on new app deployed **** */

const currentAppVersion = localStorage.getItem(APP_VERSION_KEY)

if (currentAppVersion !== APP_VERSION) {
  clearLocalStorage('user', 'jwt', 'language', 'lightVersion')
  localStorage.setItem(APP_VERSION_KEY, APP_VERSION)
}

/** ** App renderer **** */

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
  <>
    {isProd ? (
      <App />
    ) : (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )}
    <ServiceWorkerWrapper />
  </>
)

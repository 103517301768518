import pkg from '../../package.json'

/**
 * Indicates whether NODE_ENV is production
 * @namespace Config
 */
export const isProd = process.env.NODE_ENV === 'production'

/**
 * Indicates whether NODE_ENV is development
 * @namespace Config
 */
export const isDev = process.env.NODE_ENV === 'development'

export const NODE_ENV = process.env?.NODE_ENV

export const SENTRY_DNS =
  process.env.REACT_APP_SENTRY_DNS ||
  'https://c75d71cab3554c8aaf60ae206969709a@o926084.ingest.sentry.io/5875344'

export const SENTRY_RATE = Number(process.env.REACT_APP_SENTRY_RATE) || 1

export const ROOT_URL = process.env.REACT_APP_ROOT_URL

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

export const DEFAULT_BUILDING_MAPSTYLE =
  'mapbox://styles/popety/cl3mvgqdi002p15qtby6pcf1q'

export const DEFAULT_MAPSTYLE =
  'mapbox://styles/popety/cl3jyrrml000614n6aelcet8c'

export const SATELLITE_STREET_MAPSTYLE =
  'mapbox://styles/mapbox/satellite-streets-v11'

export const STREET_MAPSTYLE = 'mapbox://styles/mapbox/streets-v11'

export const SATELLITE_MAPSTYLE =
  'mapbox://styles/popety/cl449l1ah000414umwqgcr0pd'

export const LIGHT_MAPSTYLE = 'mapbox://styles/mapbox/light-v10'

export const DISPLAY_WITH_DISTINCT = 'displayWithDistinct'

export const DISPLAY = 'display'

export const DISPLAY_EXCLUSIVELY = 'displayExclusively'

export const DISPLAY_REGROUPING = 'displayRegrouping'

export const HIDE = 'hide'

export const DEFAULT_PROFILE_IMG =
  'https://storage.googleapis.com/popetyio-web/user-company-logo/default.png'

export const DEFAULT_POPETY_IMG =
  'https://storage.googleapis.com/popetyio-web/user-company-logo/popetyio-logo.jpeg'

export const APP_VERSION = process.env.REACT_APP_VERSION || pkg.version

export const APP_VERSION_KEY =
  process.env.REACT_APP_VERSION_KEY || 'popety.version'

export const YOUR_MAPTILER_API_KEY = 'get_your_own_OpIi9ZULNHzrESv6T2vL'

export const GOOGLE_MAP_TOKEN = process.env.GOOGLE_MAP_API_KEY || ''

/**
 * App pages. key is the unique key that identify each page. It is used to load i18n by page.
 * So add it must be unique and must match the its i18n folder like i18n/language/page where
 * page is the unique key of the page. Ex: i18n/fr/home
 * @namespace Config
 */
export const PAGES = [
  { pathname: '/', key: 'search' },
  { pathname: '/counter', key: 'counter' },
  { pathname: '/login', key: 'login' },
  { pathname: '/account', key: 'account' },
  { pathname: '/developer-map', key: 'search' },
  { pathname: '/land-detail', key: 'land-detail' },
  { pathname: '/transaction-history', key: 'transactionHistory' },
  { pathname: '/listing-history', key: 'listingHistory' },
  { pathname: '/fao-history', key: 'faoHistory' },
  { pathname: '/help', key: 'help' },
  { pathname: '/dashboard', key: 'dashboard' },
  { pathname: '/sources', key: 'sources' },
  { pathname: '/glossary', key: 'glossary' },
  {
    pathname: '/my-alerts',
    key: 'myAlerts,listingHistory,faoHistory,transactionHistory',
  },
  { pathname: '/company-dashboard', key: 'companyDashboard' },
  { pathname: '/user-guide', key: 'user-guide' },
] as const

export const LOCATION_TYPE = {
  ADDRESS: 'address',
  REGION: 'region',
  CITY: 'city',
  DISTRICT: 'district',
  LAND: 'land',
} as const

export const SWITZERLAND_GEO_CENTER = [8.304620661, 46.813258144]

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

export const CESIUM_3D_URL =
  'https://vectortiles0.geo.admin.ch/3d-tiles/ch.swisstopo.swisstlm3d.3d/20201020/tileset.json'

export const CESIUM_VEGETATION_URL =
  'https://vectortiles0.geo.admin.ch/3d-tiles/ch.swisstopo.vegetation.3d/20190313/tileset.json'

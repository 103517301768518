/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import regroupingService from '../../services/regrouping'

const initialState = {
  regroupingLands: [],
  regroupingLandsIds: [],
}

export const getRegroupingLands = createAsyncThunk(
  'regrouping/lands',
  regroupingService.getRegroupingLands
)

const regrouping = createSlice({
  name: 'regrouping',
  initialState,
  reducers: {},
  extraReducers: {
    // Handle async actions here, not in reducers
    [getRegroupingLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.regroupingLands = data

        state.regroupingLandsIds.push(
          ...data.map((land) => parseInt(land.land_id, 10))
        )
      }
    },
  },
})

export default regrouping.reducer

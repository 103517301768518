import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import { useI18n } from '../../../context'
import useAlertStats from './AlertStats.hook'
import {
  AlertStatsRoot,
  Stat,
  StatsContainer,
  StatTitle,
} from './AlertStats.style'

const AlertStats = () => {
  const { t } = useI18n()
  const stats = useAlertStats()

  if (stats.empty) return null

  const count = (v) => (v >= 10000 ? '10000+' : v)

  return (
    <AlertStatsRoot>
      <h4>{t('myAlert.stats.lastMonth')}</h4>
      <StatsContainer>
        {!stats.loading ? (
          <>
            <Stat display={stats.faoCount ? undefined : 'none'}>
              <StatTitle
                onClick={stats.faoCount ? stats.handleFaoClick : undefined}
              >
                {count(stats.faoCount)}
              </StatTitle>
              <h5>{t('myAlert.stats.fao')}</h5>
            </Stat>

            <Stat display={stats.transitionCount ? undefined : 'none'}>
              <StatTitle
                onClick={
                  stats.transitionCount
                    ? stats.handleTransactionClick
                    : undefined
                }
              >
                {count(stats.transitionCount)}
              </StatTitle>
              <h5>{t('myAlert.stats.transaction')}</h5>
            </Stat>

            <Stat display={stats.listingCount ? undefined : 'none'}>
              <StatTitle
                onClick={
                  stats.listingCount ? stats.handleListingClick : undefined
                }
              >
                {count(stats.listingCount)}
              </StatTitle>
              <h5>{t('myAlert.stats.listings')}</h5>
            </Stat>
          </>
        ) : (
          <CircularProgress />
        )}
      </StatsContainer>
    </AlertStatsRoot>
  )
}

export default AlertStats

import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'

import { FilterCategory, MinMaxFilter } from '../../../components'
import { useI18n } from '../../../context'

const endAdornment = (
  <InputAdornment position="end">
    m<sup>2</sup>
  </InputAdornment>
)

const AreaFilter = ({ onChange, areaFilter, ...other }) => {
  const { t } = useI18n()

  const [reset, setReset] = useState(false)

  const handleChange = (newValue, apply) => {
    setReset(false)
    onChange({ areaFilter: newValue }, apply)
  }

  const handleReset = () => {
    setReset(true)
  }

  return (
    <FilterCategory
      {...other}
      title={t('transaction.landArea')}
      onReset={areaFilter?.length && handleReset}
    >
      <MinMaxFilter
        id="landArea"
        value={areaFilter}
        onChange={handleChange}
        InputProps={{ endAdornment }}
        reset={reset}
        obj={other.obj}
      />
    </FilterCategory>
  )
}

export default AreaFilter

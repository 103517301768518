import Typography from '@mui/material/Typography'
import Collapse from '@mui/material/Collapse'
import React, { useState } from 'react'

import { useI18n } from '../../../context'

const SettingsCollapse = ({ children }) => {
  const { t } = useI18n()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen((v) => !v)
  }

  return (
    <>
      <Typography
        color="secondary.main"
        onClick={handleToggle}
        sx={{ cursor: 'pointer', marginBottom: '8px' }}
      >
        {open
          ? t('myAlert.settings.hideAdvancedSettings')
          : t('myAlert.settings.seeAdvancedSettings')}
      </Typography>

      <Collapse in={open}>{children}</Collapse>
    </>
  )
}

export default React.memo(SettingsCollapse)

/* eslint-disable no-param-reassign */
import * as turf from '@turf/turf'
import turfUnion from '@turf/union'

export const updateRegionByIdSate = (state, action) => {
  const { data } = action.payload

  if (data?.length) {
    const geoCenter = data[0].geojson_center
      ? [
          JSON.parse(data[0].geojson_center).coordinates[0],
          JSON.parse(data[0].geojson_center).coordinates[1],
        ]
      : null

    const geojson = data.reduce((acc, r) => {
      return [
        ...acc,
        turf.multiPolygon(JSON.parse(r.geojson_polygon).coordinates),
      ]
    }, [])

    const union = turfUnion(...geojson)
    const { coordinates } = union.geometry

    state.selectedAlert.multiPolygon = {
      type: 'MultiPolygon',
      coordinates: state.selectedAlert.multiPolygon.coordinates.concat(
        coordinates?.[0]?.length > 10 ? [coordinates] : coordinates
      ),
    }

    const newViewport = data[0].geojson_center
      ? {
          latitude: geoCenter[1],
          longitude: geoCenter[0],

          zoom: 9,
        }
      : {}

    state.selectedAlert.viewport = {
      ...state.selectedAlert.viewport,
      ...newViewport,
    }

    state.selectedAlert.geoCenter = geoCenter

    const region = action.meta.arg

    state.selectedAlert.data = [
      ...(state.selectedAlert.data || []),
      {
        geoPolygon: [{ type: 'Feature', geometry: data }],
        location: region,
        geoCenter,
      },
    ]
  }
}

export const updateRegionGeojsonByZoneSate = (state, action) => {
  const { data } = action.payload

  if (data) {
    const { coordinates } = JSON.parse(data[0].geojson_polygon)

    state.selectedAlert.multiPolygon = {
      type: 'MultiPolygon',
      coordinates:
        state.selectedAlert.multiPolygon.coordinates.concat(coordinates),
    }
  }
}

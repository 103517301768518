import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { supplant } from '../../utils'
import { PAGES } from '../../config'

import {
  APP_DEFAULT_LOCALE,
  fetchTranslations,
  getBrowserLanguage,
  parseLanguage,
} from './i18nHelpers'

function useI18nProvider(initialLocale?: string) {
  const fallbackLanguage = parseLanguage(APP_DEFAULT_LOCALE)

  const [currentLanguage, setCurrentLanguage] = useState(() => {
    let lang = initialLocale && parseLanguage(initialLocale)

    if (lang) return lang

    if (typeof localStorage !== 'undefined') {
      lang = localStorage.getItem('language') as string

      if (lang) return lang
    }

    lang = parseLanguage(getBrowserLanguage())

    if (lang) return lang

    return fallbackLanguage
  })

  const { pathname } = useLocation()
  const loadedPages = useRef<Record<string, boolean>>()

  const [translations, setTranslations] = useState(
    {} as Record<string, Record<string, string>>
  )

  const changeLanguage = async (language: string) => {
    const lang = parseLanguage(language)

    if (!lang) return

    const page = PAGES.find((p) => p.pathname === pathname) || PAGES[0]
    const pageId = `${page.key}.${lang}`
    const pageAlreadyLoaded = !!loadedPages.current?.[pageId]

    if (lang === currentLanguage && pageAlreadyLoaded) {
      return
    }

    // Sure it is the first time we load the translations of this page in this language
    // So we Mark page as alreadry loaded
    loadedPages.current = { ...loadedPages.current, [pageId]: true }

    const newTranslations = await fetchTranslations(page.key)

    setTranslations((previousTranslations) => ({
      ...previousTranslations,
      ...newTranslations,
    }))

    localStorage.setItem('language', lang)
    setCurrentLanguage(lang)
  }

  /**
   * Translates the given key id
   * @param key key to translate
   * @param values options
   * @return the translation or the specified key if it doesn't exist
   */
  const t: Translator = (key, values) => {
    let message
    const defaultValue = key?.split('.').slice(-1)[0]

    // Check plural form
    if (values?.count && (values?.count === 0 || values?.count > 1)) {
      message =
        translations?.[`${key}_plural`]?.[currentLanguage] ||
        translations?.[`${key}_plural`]?.[fallbackLanguage] ||
        translations?.[key]?.[currentLanguage] ||
        translations?.[`${key}`]?.[fallbackLanguage]

      return supplant(message, values) || defaultValue
    }

    message =
      translations?.[key]?.[currentLanguage] ||
      translations?.[`${key}`]?.[fallbackLanguage]

    return supplant(message, values) || defaultValue
  }

  useEffect(() => {
    document.documentElement.lang = currentLanguage
  }, [currentLanguage])

  useEffect(() => {
    changeLanguage(currentLanguage)
  }, [pathname])

  return {
    currentLanguage,
    setCurrentLanguage: changeLanguage,
    translations,
    fallbackLanguage,
    t,
  }
}

export default useI18nProvider

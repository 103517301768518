/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import favoriteLandService from '../../services/favoriteLand'

const initialState = {
  sharedFavoriteLands: [],
  favoriteLands: [],
  favoriteLandIds: [],
  favoriteGroupingLands: [],
}

export const addGroupingFavorite = createAsyncThunk(
  'favoriteLand/addGrouping',
  favoriteLandService.addGrouping
)

export const getFavoriteLands = createAsyncThunk(
  'favoriteLand/getFavoriteLands',
  favoriteLandService.getFavoriteLands
)

export const getSharedFavoriteLands = createAsyncThunk(
  'favoriteLand/getSharedFavoriteLands',
  favoriteLandService.getSharedFavoriteLands
)

export const addFavorite = createAsyncThunk(
  'favoriteLand/addFavorite',
  favoriteLandService.addFavorite
)

export const removeFavorite = createAsyncThunk(
  'favoriteLand/removeFavorite',
  favoriteLandService.removeFavorite
)

export const isFavoriteLands = createAsyncThunk(
  'favoriteLand/isFavoriteLands',
  favoriteLandService.isFavoriteLands
)

export const updateFavorite = createAsyncThunk(
  'favoriteLand/updateFavorite',
  favoriteLandService.updateFavorite
)

export const getFavoriteGroupingLands = createAsyncThunk(
  'favoriteGroupingLands/getFavoriteGroupingLands',
  favoriteLandService.getFavoriteGroupingLands
)

const favoriteLand = createSlice({
  name: 'favoriteLand',
  initialState,
  reducers: {
    // Handle synchronous actions here
    toggleFavorite: (state, action) => {
      const id = Number(action.payload)
      const isFavorite = state.favoriteLandIds.includes(id)

      if (!isFavorite) {
        state.favoriteLandIds = [...state.favoriteLandIds, id]
      } else {
        state.favoriteLandIds = state.favoriteLandIds.filter(
          (land) => land !== id
        )

        state.favoriteLands = state.favoriteLands.filter(
          (land) => String(land.land_id) !== String(id)
        )
      }
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [getFavoriteLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.favoriteLands = data

        state.favoriteLandIds.push(
          ...data.map((land) => parseInt(land.land_id, 10))
        )
      }
    },
    [getSharedFavoriteLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) state.sharedFavoriteLands = data
    },
    [updateFavorite.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        const favorite = state.favoriteLands.find(
          (land) => land.land_id === data.land_id
        )

        if (favorite) favorite.shared = data.shared
      }
    },
    [isFavoriteLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data?.length === 0) {
        state.favoriteLandIds = state.favoriteLandIds.filter(
          (id) => id !== action.meta.arg
        )
      } else if (data?.length) {
        state.favoriteLandIds = [
          ...state.favoriteLandIds,
          ...data.map((land) => parseInt(land.land_id, 10)),
        ]
      }
    },
    [getFavoriteGroupingLands.fulfilled]: (state, action) => {
      const { data } = action.payload

      if (data) {
        state.favoriteGroupingLands = data

        // console.log({ data })

        // state.favoriteGroupingLandsIds.push(
        //   ...data.map((land) => parseInt(land.land_id, 10))
        // )
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { forgetUser, toggleFavorite } = favoriteLand.actions

export default favoriteLand.reducer

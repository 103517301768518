/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import reportingService from '../../services/reporting'

const initialState = {
  reports: [
    {
      name: 'Analyse détaillée',
      category: 'Analysis',
      type: 'pdf',
      shortid: 'Dnh6nss',
      description: 'Analyse détaillée',
    },
    {
      name: 'Financial plan',
      category: 'Financial',
      type: 'pdf',
      lang: 'fr',
      shortid: 'U5QYR0UC8d',
      description: 'Financial plan',
    },
  ],
  analysisReports: [],
  financialReports: [],
}

export const getReports = createAsyncThunk(
  'reporting/getReports',
  reportingService.getReports
)

const reporting = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    getRegionsReports: (state, action) => {
      const data = reportingService.getRegionsReports({
        regionId: action.payload,
        reports: state.financialReports,
      })

      state.financialReports = [
        { label: 'Modèles public', options: data.regionReports },
        data.privateReports,
      ]
    },
  },
  extraReducers: {
    // Handle async actions here, not in reducers
    [getReports.pending]: (state) => {
      state.loading = true
    },
    [getReports.pending]: (state) => {
      state.loading = false
    },
    [getReports.fulfilled]: (state, action) => {
      const { data } = action.payload

      state.loading = false

      if (data) {
        state.reports = [...state.reports, ...data]
      }
    },
  },
})

export const { getRegionsReports } = reporting.actions

export default reporting.reducer

import { createSelector } from '@reduxjs/toolkit'

const noteStateSelector = (state) => state.note

const actionHistorySelector = (state) => state.note.actionHistory

const analysisLandsSelector = (state) => state.note.analysisLands

const companyAnalysisLandsSelector = (state) => state.note.companyAnalysisLands

const actionHistoriesSelector = createSelector(actionHistorySelector, (h) =>
  Object.values(h).sort(
    (a, b) =>
      new Date(b.create_timestamp).getTime() -
      new Date(a.create_timestamp).getTime()
  )
)

const notesSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'note')
)

const remindersSelector = createSelector(actionHistoriesSelector, (h) =>
  h.filter((actionHistory) => actionHistory.action === 'reminder')
)

export {
  notesSelector,
  actionHistoriesSelector,
  noteStateSelector,
  analysisLandsSelector,
  companyAnalysisLandsSelector,
  remindersSelector,
}

import Grid from '@mui/material/Grid'
import styled from 'styled-components'

export const AlertEditorRoot = styled(Grid)`
  .MuiStepButton-root {
    color: #03254c;
  }

  .ControlsCard {
    height: 100%;
    display: flex;
    flex-direction: column;

    .MuiStepper-root {
      padding: 24px;
    }
  }

  .Close {
    margin-top: auto;
  }
`

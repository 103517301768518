import React from 'react'
import { useSelector } from 'react-redux'

import { faoAggregationsSelector } from '../../../redux/faoHistory'
import { FilterCategory, CheckboxFilter } from '../../../components'
import { useI18n } from '../../../context'

const TypeNameFilter = ({ onChange, typeNameFilter, ...other }) => {
  const { t } = useI18n()
  const aggregations = useSelector(faoAggregationsSelector)

  const items = aggregations.type_names.type_names.buckets.map((r) => ({
    ...r,
    count: r.doc_count,
  }))

  const handleChange = (newValues) => {
    onChange({ typeNameFilter: newValues })
  }

  if (!items.length) return null

  return (
    <FilterCategory {...other} title={t('fao.typeName')}>
      <CheckboxFilter
        filters={typeNameFilter}
        items={items}
        onChange={handleChange}
        min={5}
        max={1000}
        defaultLabel={t('common.unknown')}
      />
    </FilterCategory>
  )
}

export default TypeNameFilter

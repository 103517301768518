import React, { useCallback, useEffect, useState } from 'react'

import FullscreenButton from '../FullscreenButton'
import {
  MapFullscreenContainerRoot,
  Content,
} from './MapFullscreenContainer.style'

const MapFullscreenContainer = ({
  children,
  open: _open = false,
  onClosed,
  className = '',
  onToggle,
  ...other
}) => {
  const [open, setOpen] = useState(_open)

  const handleEscape = useCallback((event) => {
    if (event.keyCode === 27) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])

  const handleToggle = () => {
    setOpen((o) => !o)
  }

  useEffect(() => {
    setOpen(_open)
  }, [_open])

  useEffect(() => {
    onToggle?.(open)
    if (!open) {
      onClosed?.()
    }
  }, [open])

  return (
    <MapFullscreenContainerRoot
      {...other}
      className={`${className} ${open ? 'fullScreen' : ''}`}
    >
      <Content open={open}>
        <FullscreenButton fullScreen={open} onToggle={handleToggle} />
        {children}
      </Content>
    </MapFullscreenContainerRoot>
  )
}

export default MapFullscreenContainer

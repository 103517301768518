/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/default-props-match-prop-types */

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import { useControl } from 'react-map-gl'

import type { MapRef, ControlPosition } from 'react-map-gl'

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition

  onCreate: (evt: { features: object[] }) => void
  onUpdate: (evt: { features: object[]; action: string }) => void
  onDelete: (evt: { features: object[] }) => void
}

export default function DrawControl(props: DrawControlProps) {
  useControl<MapboxDraw>(
    ({ map }: { map: MapRef }) => {
      map.on('draw.create', (e) => {
        props.onCreate(e)
      })
      map.on('draw.update', (e) => {
        props.onUpdate(e)
      })
      map.on('draw.delete', (e) => {
        props.onDelete(e)
      })

      return new MapboxDraw(props)
    },
    ({ map }: { map: MapRef }) => {
      map.off('draw.create', (e) => {
        props.onCreate(e)
      })
      map.off('draw.update', (e) => {
        props.onUpdate(e)
      })
      map.off('draw.delete', (e) => {
        props.onDelete(e)
      })
    },
    { position: props.position }
  )

  return null
}

DrawControl.defaultProps = {
  onCreate: () => {},
  onUpdate: () => {},
  onDelete: () => {},
}
